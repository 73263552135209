/** @jsxImportSource @emotion/react */

import { ReactNode } from "react"
import { FaGuitar, FaParagraph } from "react-icons/fa"
import { ImPencil2 } from "react-icons/im"
import { MdCheckBox, MdRadioButtonChecked } from "react-icons/md"

export const questionTypeByID = (id: number, iconSize?: number, iconColor?: string): {title: string, icon: ReactNode, description: string} => {

  const result = {
    0: {
      title: "-",
      icon: null,
      description: "",
    },
    1: {
      title: "Konten",
      icon: <FaParagraph size={!iconSize ? 16 : iconSize} color={!iconColor ? "#333333" : iconColor} />,
      description: "Slide konten pembelajaran",
    },
    2: {
      title: "Hanya penilaian",
      icon: <FaGuitar size={!iconSize ? 16 : iconSize} color={!iconColor ? "#333333" : iconColor} />,
      // description: "Tidak ada pengumpulan jawaban, dosen langsung menilai outcome mahasiswa. Contoh penggunaan: penilaian berbasis aspek, sikap, presentasi, praktikum, tes lisan",
      description: "Hanya penilaian. Contoh: pengumpulan offline / beda sistem / aspek / sikap / presentasi / praktikum / tes lisan",
    },
    3: {
      title: "Esai",
      icon: <ImPencil2 size={!iconSize ? 16 : iconSize} color={!iconColor ? "#333333" : iconColor} />,
      description: "Pertanyaan dengan jawaban esai + lampiran",
    },
    4: {
      title: "Single choice",
      icon: <MdRadioButtonChecked size={!iconSize ? 16 : iconSize} color={!iconColor ? "#333333" : iconColor} />,
      description: "A, B, C, D : pilih salah satu",
    },
    5: {
      title: "Multiple choice",
      icon: <MdCheckBox size={!iconSize ? 16 : iconSize} color={!iconColor ? "#333333" : iconColor} />,
      description: "A, B, C, D : pilih lebih dari satu",
    },
  }[id]

  if(result) {
    return  result
  } 

  return {
    title: "-",
    icon: null,
    description: "-",
  }
}
