/** @jsxImportSource @emotion/react */

import { useNavigate, useParams } from 'react-router-dom'
import Flex from '../../../shared/components/IFSuites/Flex'
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import Small from '../../../shared/components/IFSuites/typography/Small'
import { Button } from '../../../shared/components/IFSuites/Button'
import { MdMenuBook, MdQuiz, } from 'react-icons/md'
import { FaChartLine, FaCog, FaInfo, FaRegCalendarAlt, FaSitemap, FaSlidersH, FaUsers } from 'react-icons/fa'
import useAuth from '../../../shared/hooks/use-auth'
import { IoGameController } from 'react-icons/io5'
import { GiBowman, GiSprout } from 'react-icons/gi'

export const useClassroomTab = () => {
  const { classroomID, tab } = useParams()

  const navigate = useNavigate()

  const goTo = (tabParam: "sessions" | "grade" | "assessments" | "detail") => {
    navigate(`/classroom/${classroomID}/${tabParam}`, { replace: true })
  }

  return {tab, goTo}
}

const ClassroomTab = () => {
  const {tab, goTo} = useClassroomTab()
  const auth = useAuth()

  const iconSize = 24 
  const iconColor = '#1A3647'

  return (
    <FlexRow
      jc='stretch'
      px={0}
      gap={0}
      css={t => ({
        background: t.palette?.gray?.[100],
        paddingBottom: t.spacing(1),
        'small': {
          color: t.palette?.blueGray?.[700],
          fontWeight: 400,
        }
      })}
    >
      <Button
        br={0}
        fullWidth
        variant={`soft`}
        color={tab === 'sessions' ? 'primary' : 'secondary'}
        onClick={() => {
          goTo('sessions')
        }}
      >
        <Flex ai='center'>
          <FaRegCalendarAlt color={iconColor} size={iconSize} />
          <Small
          >
            Jadwal 
          </Small>
        </Flex>
      </Button>
    
      <Button
        br={0}
        fullWidth
        variant={`soft`}
        color={tab === 'assessments' ? 'primary' : 'secondary'}
        onClick={() => {
          goTo('assessments')
        }}
      >
        <Flex ai='center'>
          <GiBowman color={iconColor} size={iconSize} />
          <Small
            css={t => ({
            })}
          >
            Asesmen
          </Small>
        </Flex>
      </Button>

      {auth.isLecturer() &&
        <Button
          br={0}
          fullWidth
          variant={`soft`}
          color={tab === 'grade' ? 'primary' : 'secondary'}
          onClick={() => {
            goTo("grade") 
          }}
        >
          <Flex ai='center'>
            <GiSprout color={iconColor} size={iconSize} />
            <Small
              css={t => ({
              })}
            >
              Progres
            </Small>
          </Flex>
        </Button>
      }

      <Button
        br={0}
        fullWidth
        variant={`soft`}
        color={tab === 'detail' ? 'primary' : 'secondary'}
        onClick={() => {
          goTo('detail')
        }}
      >
        <Flex ai='center'>
          <FaSitemap color={iconColor} size={iconSize} />
          <Small
            css={t => ({
            })}
          >
            RPS
          </Small>
        </Flex>
      </Button>
    </FlexRow>
  )
}

export default ClassroomTab
