/** @jsxImportSource @emotion/react */

import { ReactNode, useState } from "react"
import { FaCaretDown } from "react-icons/fa"
import ContextMenu from "../../../ContextMenu"
import ListItem from "../../../List/Item"
import { Button } from "../../Button"
import DividerGrow from "../../Divider/Grow"
import Flex from "../../Flex"
import { CommonProps } from "../../interfaces"

interface SelectProps {
  label?: string
  value: string
  slim?: true
  onChange: (value: string) => void,
  // onChange: () => void,
  fullWidth?: true 
  items: {
    label: ReactNode 
    value: string
  }[]
}

const initContextMenuConfig = {
  open: false,
  x: 0,
  y:0,
}

const Select = ({slim, items, label, value, onChange, fullWidth, ...other}: CommonProps & SelectProps) => {

  const [contextMenuConfig, setContextMenuConfig] = useState(initContextMenuConfig)
  const smallerLabel = value.length > 0
  const isSlim = slim === undefined ? false : true 

  return (
    <Flex
      css={t => ({
        position: `relative`,
        width: fullWidth ? `100%` : `auto`,
      })}
    >
      <Button
        color="info"
        onClick={e => {
          setContextMenuConfig(v => ({
            ...v,
            open: !contextMenuConfig.open,
          }))
        }}
        variant="soft"
        css={t => ({
          // border: `1px solid #00000022`,
          border: `1px solid ${t.palette?.gray?.[500]}`,
          outline: `none`,
          ':hover': {
            border: `1px solid ${t.palette?.secondary?.main}`,
            boxShadow: `0 0 0 0.2rem ${t.palette?.secondary?.main}33`,
          },
          paddingTop: isSlim ? 4 : t.spacing(2) + 4,
          paddingBottom: isSlim ? 4 : t.spacing(1) + 4,
          paddingLeft: isSlim ? t.spacing(1) : t.spacing(2),
          paddingRight: isSlim ? t.spacing(1) : t.spacing(2),
          position: `relative`,
          borderRadius: 4,
          background: t.palette?.gray?.[100],
          '&:hover': {
            background: t.palette?.gray?.[200],
            color: t.palette?.gray?.[700],
            'div': {
              color: t.palette?.gray?.[700],
            },
            'span': {
              color: t.palette?.gray?.[700],
            },
            'svg': {
              fill: t.palette?.gray?.[700],
            }
          }
        })}
        fullWidth={fullWidth === undefined ? undefined : true}
      >
        {!isSlim &&
          <span
            css={t => ({
              lineHeight: 0,
              fontSize: smallerLabel ? 10 : 14,
              fontWeight: 400,
              // color: smallerLabel ? `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0.7)`,
              // color: `${t.palette?.secondary?.dark}cc`,
              position: `absolute`,
              top: smallerLabel ? t.spacing(1) + 4 : `50%`,
              left: smallerLabel ? t.spacing(1) + 4 : t.spacing(2),
            })}
          >
            {label}
          </span>
        }
        {items.find(item => item.value === value)?.label}
        <DividerGrow />
        <FaCaretDown />
      </Button>
      <ContextMenu
        open={contextMenuConfig.open}
        width={"auto"}
        top={48}
        left={0}
        onClose={() => {
          setContextMenuConfig(v => ({
            ...v,
            open: false,
          }))
        }}
        menus={items.map((item, index) => (
          <ListItem
            onClick={() => {
              onChange(item.value) 
              setContextMenuConfig(v => ({
                ...v,
                open: false,
              }))
            }}
            key={`select-${label?.replaceAll(" ", "")}-item-${index}`}
            css={t => ({
              transition: ".4s",
            })}
          >
            {item.label}
          </ListItem>
        ))}  
      />
    </Flex>
  )
}

export default Select

// https://codesandbox.io/s/react-withclickoutside-forked-bok38?fontsize=14&hidenavigation=1&theme=dark&file=/src/withClickOutside.js
