/** @jsxImportSource @emotion/react */

import { ReactNode } from "react"
import { FaBuilding, FaClock, FaListOl, FaShareAlt, FaStar, FaUser } from "react-icons/fa"
import { IoGameController } from "react-icons/io5"
import { useNavigate } from "react-router"
import { Button } from "../../../../../shared/components/IFSuites/Button"
import DividerGrow from "../../../../../shared/components/IFSuites/Divider/Grow"
import Flex from "../../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../../shared/components/IFSuites/Flex/Row"
import Small from "../../../../../shared/components/IFSuites/typography/Small"
import ListItem from "../../../../../shared/components/List/Item"
import useAuth from "../../../../../shared/hooks/use-auth"
import useBreakpoint from "../../../../../shared/hooks/use-breakpoint"

interface AssessmentListItemProps {
  title: string
  learningID: string
  elapsed?: ReactNode 
  isActive?: boolean
  playerType: "lecturer" | "student"
}

const AssessmentListItem = ({
  title,
  isActive,
  learningID,
  elapsed,
  playerType,
}: AssessmentListItemProps) => {
  const navigate = useNavigate()
  const auth = useAuth()

  const bp = useBreakpoint()

  return (
    <ListItem
      glow={isActive}
      onClick={(_) => {}}
    >
      <Flex
        gap={2}
      >
        <FlexRow>
          {title} 
        </FlexRow>
        <FlexRow>
          {elapsed} 
        </FlexRow>
        <FlexRow>
          <Button
            size="small"
            color="success"
            variant="contained"
            onClick={() => {
              navigate(`/classroom/assessment/${learningID}/${playerType === "lecturer" ? "grading" : "game/intro"}`)
            }}
          >
            Yuk {playerType === "lecturer" ? "Periksa" : "Kerjakan"}
          </Button>
          {playerType === "lecturer" &&
            <Button
              size="small"
              color="info"
              variant="contained"
              onClick={() => {
                navigate(`/classroom/assessment/${learningID}/edit`)
              }}
            >
              Kelola Soal <FaListOl />
            </Button>
          }
          <Button color="secondary" onClick={() => {
            navigate(`/share?path=/classroom/assessment/${learningID}/game/intro`)
          }}>
            <FaShareAlt />
          </Button>
        </FlexRow>
      </Flex>
    </ListItem>
  )
}

export default AssessmentListItem
