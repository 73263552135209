/** @jsxImportSource @emotion/react */

import { useNavigate } from "react-router-dom"

import Page from "../../../shared/components/Page"
import ListLayout from "../../../shared/components/List/Layout"
import { MdLeaderboard } from "react-icons/md"

const PortalOtherMenus = () => {

  const navigate = useNavigate()

  return (
    <Page
      title={`Menu Lainnya`}
    >
      <ListLayout
        items={[
          {
            title: "User Leaderboard",
            icon: <MdLeaderboard />,
            onClick: () => {},
            content: "Siapa User paling aktif di IF Digital Service ? Kumpulkan poin dengan berinteraksi, menjawab, atau mengisi konten"
          },
        ]}
      />
    </Page>
  )
}

export default PortalOtherMenus
