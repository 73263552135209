/** @jsxImportSource @emotion/react */

import _ from "lodash"
import axios from "axios"
import { useAtom } from "jotai"
import { useEffect, } from "react"
import useAuth from "../../../shared/hooks/use-auth"
import layoutAtom from "../../../shared/states/layout"
import { API_URL } from "../../../shared/variables/config"
import { initLearningCreate, learningCreateAtom } from "../states/learning-create"

const useLearningAdd = () => {
  const [, setLayout] = useAtom(layoutAtom)
  const auth = useAuth()
  const [learningCreate, setLearningCreate] = useAtom(learningCreateAtom)

  const post = (onSuccess: () => void) => {
    const authInfo = auth.getInfo()

    setLayout(v => ({
      ...v,
      loading: {
        ...v.loading,
        open: true,
      },
    }))

    const url = `${API_URL}/academic/learnings`
    
    const authorizationBearer = `Bearer ${auth.getToken()}`

    axios.post(
      url,
      learningCreate,
      {
        headers: {
          "Authorization": authorizationBearer,
        },
        withCredentials: false,
      },
    ).then(response => {
      onSuccess()
    }).catch(e => {
      console.log("error", e)
    }).finally(() => {

      setLearningCreate(initLearningCreate)

      setLayout(v => ({
        ...v,
        loading: {
          ...v.loading,
          open: false,
          message: "",
        },
      }))

    })

  } 

  useEffect(() => {
    localStorage.setItem("learningCreate", JSON.stringify(learningCreate))
  }, [learningCreate])

  return { learningCreate, setLearningCreate, post }
}

export default useLearningAdd
