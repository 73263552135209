/** @jsxImportSource @emotion/react */

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { GRAPHQL_URL } from '../../variables/config'

// use sessionStorage instead of localStorage

const httpLink = createHttpLink({
  uri: `${GRAPHQL_URL}/`,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken')
  const currentRole = localStorage.getItem('hasuraCurrentRole')
  const loginInfo = localStorage.getItem('loginInfo')
  const lecturerID = localStorage.removeItem("lecturerID")
  const studentID = localStorage.removeItem("studentID")

  if (loginInfo == null) {
    return {
      headers: {
        ...headers,
        "Authorization": token ? `Bearer ${token}` : "",
        "X-Hasura-Role": currentRole,
        "X-Hasura-Lecturer-Id": lecturerID,
        "X-Hasura-Student-Id": studentID,
      }
    }
  } else {
    return {
      headers: {
        ...headers,
        "Authorization": token ? `Bearer ${token}` : "",
        "X-Hasura-Role": currentRole,
        "X-Hasura-Lecturer-Id": lecturerID,
        "X-Hasura-Student-Id": studentID,
      }
    }
  }


})

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

// console.log("clearStore()")
// apolloClient.clearStore()
