/** @jsxImportSource @emotion/react */

import { useWindowSize } from "react-use"
import Flex from "../Flex"
import { CommonProps, FlexProps } from "../interfaces"

interface BackdropProps {
  opacity?: number
  onClick: () => void 
  open: boolean
}

const Backdrop = ({opacity, onClick, open, children, ...other}: CommonProps & BackdropProps & FlexProps) => {

  const windowSize = useWindowSize()

  return (
    <Flex
      className="Backdrop"
      onClick={() => onClick()}
      css={t => ({
        pointerEvents: open ? "all" : "none",
        position: `fixed`,
        top: 0,
        left: 0,
        zIndex: 12,
        display: open ? `flex` : `flex`,
        alignItems: `center`,
        width: windowSize.width,
        height: windowSize.height,
        background: `rgba(0, 0, 0, ${open ? opacity ? opacity : .4 : .0})`,
        padding: t.spacing(2),
        transition: `.4s`,
        // paddingBottom: t.spacing(10),
        // overflowY: "scroll",
      })}
      {...other}
    >
      {open && children}
    </Flex>
  )
}

export default Backdrop
