/** @jsxImportSource @emotion/react */

import Flex from "../../Flex"
import { CommonProps } from "../../interfaces"

const Small = ({children, ...other}: CommonProps) => {
  return (
    <Flex
      className="Small"
      css={t => ({
        'small': {
          marginTop: 0,
          marginBottom: 0,
          color: t.palette?.gray?.[700]
        },
      })}
      w={"auto"}
      {...other}
    >
      <small>{children}</small>
    </Flex>
  )
}

export default Small
