/** @jsxImportSource @emotion/react */

import axios from "axios"
import { useAtom } from "jotai"
import { useEffect, useState } from "react"
import useAuth from "../../../shared/hooks/use-auth"
import layoutAtom from "../../../shared/states/layout"
import appConfig, { API_URL } from "../../../shared/variables/config"
import { ClassroomListItemWS } from "../List/models"

interface UseClassroomListProps {
  year: number
  semester: number
  showAll: boolean
}

const useClassroomList = ({year, semester, showAll}: UseClassroomListProps) => {

  const [, setLayout] = useAtom(layoutAtom)
  const [classroomList, setClassroomList] = useState<ClassroomListItemWS[]>([])
  const auth = useAuth()

  useEffect(() => {
    const authInfo = auth.getInfo()

    setLayout(v => ({
      ...v,
      loading: {
        ...v.loading,
        open: true,
      },
    }))

    const url = `${API_URL}/academic/classrooms?programme_id=${
      authInfo?.programmeID}&year=${year}&semester=${semester}&enrolledby=${
        showAll ? "all" : "me"
      }&timestamp=${
            new Date().getTime()}`
    
    const authorizationBearer = `Bearer ${auth.getToken()}`

    axios.get(
      url,
      {
        headers: {
          "Authorization": authorizationBearer,
        },
        withCredentials: false,
      },
    ).then(response => {
      const data: ClassroomListItemWS[] = response.data["data"]

      if(!Array.isArray(data)) {
        throw "Response is not an array"
      }

      console.log("ini datanya", Array.isArray(data))
      setClassroomList(data)
    }).catch(e => {
      console.log("error", e)
    }).finally(() => {

      setLayout(v => ({
        ...v,
        loading: {
          ...v.loading,
          open: false,
          message: "",
        },
      }))
    })

  }, [year, semester, showAll])

  return {classroomList, setClassroomList}
}

export default useClassroomList
