/** @jsxImportSource @emotion/react */

import { gql, useLazyQuery, useQuery } from "@apollo/client"
import DialogSimpleContent from "../../../../../../shared/components/IFSuites/Dialog/SimpleContent"
import Flex from "../../../../../../shared/components/IFSuites/Flex"
import Small from "../../../../../../shared/components/IFSuites/typography/Small"
import List from "../../../../../../shared/components/List"
import ListItem from "../../../../../../shared/components/List/Item"
import { LearningQuestionWS } from "../../../../models/learning"

interface Props {
  open: boolean
  onClose: () => void  
  classroomItemID: string
  onSelect: (learningQuestions: LearningQuestionWS[]) => void
  // questionOps: {
  //   clear: () => void
  //   push: (...items: LearningQuestionWS[]) => void
  // } 
}

interface RelatedAssessmentsQ {
  _: {
    learning_template_id: string
    learning_id: string
    title: string
    grading_comp: {
      grading_comp_id: string
      title: string
    }
    classroom_item: {
      classroom: {
        code: string
      }
    }
  }[]
}

interface AssessmentQuestionsQ {
  _: {
    learning_id: string
    questions: {
      description: object
      item_order: number
      attachments: {
        title: string
        url: string
      }[]
      question_type_id: number
      answer_choices: {
        id: string   
        label: string
      }[]
      score_weight: number
      clo: {
        clo: {
          code: string
        }
        course_year_sem_clo_id: string
      }
    }[]
  }
}

const DialogDuplicateAssessment = ({
  open,
  onClose,
  classroomItemID,
  onSelect,
}: Props) => {

  const GET_RELATED_ASSESSMENTS = gql`
    query GetRelatedAssessments($classroom_item_id: uuid = "") {
      _: academic_learning(where: {classroom_item: {classroom: {cys: {classrooms: {items: {classroom_item_id: {_eq: $classroom_item_id}}}}}}, learning_template_id: {_eq: "2"}}) {
        learning_template_id
        learning_id
        title
        grading_comp {
          grading_comp_id
          title
        }
        classroom_item {
          classroom {
            code
          }
        }
      }
    }
  `

  const relatedAssessmentsQ = useQuery<RelatedAssessmentsQ>(
    GET_RELATED_ASSESSMENTS,
    {
      variables: {
        classroom_item_id: classroomItemID,
      }
    }
  )

  const GET_ASSESSMENT_QUESTIONS = gql`
    query GetAssessmentQuestions($learning_id: uuid = "") {
      _: academic_learning_by_pk(learning_id: $learning_id) {
        learning_id
        questions(order_by: {item_order: asc}) {
          description
          item_order
          attachments
          question_type_id
          answer_choices
          score_weight
          clo {
            clo {
              code
            }
            course_year_sem_clo_id
          }
        }
      }
    }
  `

  const [getQuestions, getQuestionsOps] = useLazyQuery<AssessmentQuestionsQ>(
    GET_ASSESSMENT_QUESTIONS,
  )

  return (
    <DialogSimpleContent
      title={`Duplikasi Soal dari Kelas Lain`}
      open={open}
      onClose={onClose}
    >
      <List>
      {relatedAssessmentsQ.data ? relatedAssessmentsQ.data._.map((item, key) => (
        <ListItem
          key={`duplicate-assessment-item-${key}`}
          onClick={() => {
            getQuestions({
              variables: {
                learning_id: item.learning_id
              }
            }).then(res => {
              if(res.data) {
                onSelect(res.data._.questions.map(v => ({
                  description: v.description, 
                  attachments: v.attachments, 
                  score_weight: v.score_weight, 
                  question_type_id: v.question_type_id, 
                  answer_choices: v.answer_choices, 
                  true_values: [],
                  course_year_sem_clo_id: v.clo.course_year_sem_clo_id,
                  clo_code: v.clo.clo.code, 
                  item_order: v.item_order, 
                })))
              }
            }).finally(() => {
              onClose()
            })
          }}
        >
          <Flex
            gap={0}
          >
            <Small>
              Kelas {item.classroom_item.classroom.code} - {item.classroom_item.classroom.code}
            </Small>
            {item.title}
          </Flex>
        </ListItem>
      )) : []}
      </List>
    </DialogSimpleContent>
  )
}

export default DialogDuplicateAssessment
