import { gql } from "@apollo/client";

export interface PlayersByKeywordQ {
  player_player: {
    player_id: string
    nickname: string
    username: string
    as_student: {
      full_name: string
      nim: string
      student_id: string
      programme: {
        title: string
      }
    } | null
    as_staff: {
      staff_id: string
      full_name: string
      nip: string
      as_lecturer: {
        nidn: string | null
        lecturer_id: string
      }
      programme: {
        title: string
      }
    } | null
  }[]
}

export const GET_STUDENTS_BY_KEYWORD = gql`
  query GetStudentByKeyword($_ilike: String = "") {
    player_player(where: {as_student: {_or: [
      {full_name: {_ilike: $_ilike}}, 
      {nim: {_ilike: $_ilike}}
    ]}}, order_by: {as_staff: {full_name: asc}, as_student: {full_name: asc}}) {
      player_id
      nickname
      username
      as_student {
        full_name
        nim
        student_id
        programme {
          title
        }
      }
      as_staff {
        staff_id
        full_name
        nip
        as_lecturer {
          nidn
          lecturer_id
        }
        programme {
          title
        }
      }
    }
  }
`

export const GET_LECTURERS_BY_KEYWORD = gql`
  query GetLecturerByKeyword($_ilike: String = "") {
    player_player(where: {
      as_staff: {
        _and: [
          {_or: [
            {full_name: {_ilike: $_ilike}}, 
            {nip: {_ilike: $_ilike}},
            {programme: {
              title: {_ilike: $_ilike}
            }}
          ]}, 
          {
            as_lecturer: {lecturer_id: {_is_null: false}}
          }
        ]
      }
    }, order_by: {as_staff: {full_name: asc}, as_student: {full_name: asc}}) {
      player_id
      nickname
      username
      as_student {
        full_name
        nim
        student_id
      }
      as_staff {
        staff_id
        full_name
        nip
        as_lecturer {
          nidn
          lecturer_id
        }
        programme {
          title
        }
      }
    }
  }
`

// GET DEFENSE EVALUATOR BY KEYWORD
