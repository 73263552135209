/** @jsxImportSource @emotion/react */

import { useLazyQuery } from "@apollo/client"
import dayjs from "dayjs"
import { useEffect } from "react"
import { FaClock, FaUser } from "react-icons/fa"
import { useLocation, useNavigate } from "react-router-dom"
import { Button } from "../../../../shared/components/IFSuites/Button"
import DividerGrow from "../../../../shared/components/IFSuites/Divider/Grow"
import Flex from "../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../shared/components/IFSuites/Flex/Row"
import Paper from "../../../../shared/components/IFSuites/Paper"
import Small from "../../../../shared/components/IFSuites/typography/Small"
import List from "../../../../shared/components/List"
import ListItem from "../../../../shared/components/List/Item"
import Page from "../../../../shared/components/Page"
import useLayout from "../../../../shared/hooks/use-layout"
import { GET_TICKETS, TicketQ } from "../get-tickets.gql"

// List of tickets generated by the user, or by all of the users
const HelpdeskTicketList = () => {

  const navigate = useNavigate()
  const layout = useLayout()
  const location = useLocation()

  const [getTickets, getTicketsOps] = useLazyQuery<TicketQ>(
    GET_TICKETS,
  )

  useEffect(() => {
    layout.loadingShow("Memuat tiket bantuan...")
    getTicketsOps.refetch().then(_ => {
      
    }).finally(() => {
      layout.loadingHide()
    })

  }, [location])
  

  return (
    <Page
      title={`Bantuan`}
    >
      <Paper
        br={0}
      >
        <List
          title={
            <Flex>
              <FlexRow>
                <Flex>
                  Tiket Bantuan
                  <Small>Pertanyaan / keluhan / aspirasi</Small>
                </Flex>
                <DividerGrow />
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    navigate(`/helpdesk/ticket/add`) 
                  }}
                >
                  Buat Tiket 
                </Button>
              </FlexRow>
            </Flex>
          }
        >
        {getTicketsOps.data?._.map((item, key) => {

          const datetimeCreated = dayjs(item.datetime_created).add(7, "hours")

          return (
            <ListItem>
              <Flex>
                <FlexRow>
                  {item.subject}
                </FlexRow>
                <FlexRow ai={`center`}>
                  <Small><FaClock /> {datetimeCreated.format("dddd, DD MMM YYYY - HH:mm")}</Small> 
                </FlexRow>
              </Flex>
            </ListItem>
          )
        })}  
        </List>
      </Paper>
    </Page>
  )
}

export default HelpdeskTicketList
