/** @jsxImportSource @emotion/react */

import { forwardRef, Ref,} from "react"
import { CommonProps, FlexProps } from "../interfaces"

const Flex = (
  {id, jc, ai, children, gap, w, m, mx, my, mb, mt, ml, mr, p, px, py, pl, pr, pt, pb, ...other}: CommonProps & FlexProps,
  ref: Ref<HTMLDivElement> 
) => {
  return (
    <div
      ref={ref}
      id={id}
      className="Flex"
      css={t => ({
        display: "flex",
        flexDirection: "column",
        alignItems: ai === undefined ? "start" : ai,
        justifyContent: jc === undefined ? "center" : jc,
        gap: gap === undefined ? t.spacing(1) : t.spacing(gap),
        boxSizing: "border-box",
        width: w === undefined ? `100%` : w,
        paddingTop: pt ? t.spacing(pt) : py ? t.spacing(py) : p ? t.spacing(p) : 0, 
        paddingBottom: pb ? t.spacing(pb) : py ? t.spacing(py) : p ? t.spacing(p) : 0, 
        paddingLeft: pl ? t.spacing(pl) : px ? t.spacing(px) : p ? t.spacing(p) : 0, 
        paddingRight: pr ? t.spacing(pr) : px ? t.spacing(px) : p ? t.spacing(p) : 0, 
        marginTop: mt ? t.spacing(mt) : my ? t.spacing(my) : m ? t.spacing(m) : 0, 
        marginBottom: mb ? t.spacing(mb) : my ? t.spacing(my) : m ? t.spacing(m) : 0, 
        marginLeft: ml ? t.spacing(ml) : mx ? t.spacing(mx) : m ? t.spacing(m) : 0, 
        marginRight: mr ? t.spacing(mr) : mx ? t.spacing(mx) : m ? t.spacing(m) : 0, 
      })}
      {...other}
    >
      {children}
    </div>
  )
}

export default forwardRef(Flex) 
