/** @jsxImportSource @emotion/react */

import { useEffect } from "react"
import { FaUser } from "react-icons/fa"
import NormalEditor from "../../../../../shared/components/Editor/MGLexical/NormalEditor"
import Flex from "../../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../../shared/components/IFSuites/Flex/Row"

const GradingContent = ({
  question,
  answer,
  participant,
}: {
  question: object | undefined,
  answer: object | null
  participant: string,
}) => {

  return (
    <Flex
      px={2}
      pt={1}
      gap={0}
      pr={5}
      pb={20}
      css={t => ({
      })}
    >
      <FlexRow>
        <FaUser /> {participant}
      </FlexRow>
      <FlexRow>
        Pertanyaan
      </FlexRow>
      <NormalEditor
        initEditorStateString={JSON.stringify(question)}
        isEditable={false}
        onEditorStateChange={() => {}}
        trackInitEditorStateChange={true}
      />
      <FlexRow>
        Jawaban
      </FlexRow>
      {answer ? 
        <NormalEditor
          initEditorStateString={JSON.stringify(answer)}
          isEditable={false}
          onEditorStateChange={() => {}}
          trackInitEditorStateChange={true}
        /> : `Belum ada jawaban`
      }
    </Flex>
  )
}

export default GradingContent
