import { gql } from "@apollo/client";

export const UPDATE_PLAYER_FCM = gql`
  mutation UpdatePlayerFCM($fcm_latest_registration_token: String = "") {
    update_player_player(_set: {fcm_latest_registration_token: $fcm_latest_registration_token}, where: {}) {
      returning {
        player_id
      }
    }
  }
`
