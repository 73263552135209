/** @jsxImportSource @emotion/react */

import { useRef } from "react"
import { useClickAway, useWindowSize } from "react-use"
import Flex from "../IFSuites/Flex"
import Paper from "../IFSuites/Paper"
import List from "../List"

interface ContextMenuProps {
  open: boolean
  top: number
  left: number
  width: number | string
  menus: JSX.Element[]
  onClose: () => void 
}

const ContextMenu = ({open, top, left, width, menus, onClose} : ContextMenuProps) => {

  const size = useWindowSize()

  const ref = useRef(null)
  useClickAway(ref, () => {
    onClose()
  })

  if(open) {
    return (
      <Paper
        ai="start"
        jc="flex-start"
        br={1}
        shadow={8} 
        ref={ref}
        css={t => ({
          position: "absolute",
          top: top,
          left: left, 
          width: width,
          overflowY: "auto",
          maxHeight: size.height / 2,
          zIndex: 9,
        })}
      >
        <Flex
          py={1}
          css={t => ({
            '.children-container': {
              '& > div': {
                borderTop: "none",
                paddingTop: t.spacing(1),
                paddingBottom: t.spacing(1),
              }
            },
          })}
        >
          <List
            title={null}
          >
            {menus}
          </List>
        </Flex>
      </Paper>
    )
  }

  return null
}

export default ContextMenu
