import { gql } from "@apollo/client";

export const GET_DEFENSE_EVALUATION_FORM = gql`
  query GetDefenseByID($defense_id: uuid = "", $player_id: uuid = "") {
    defense_defense_by_pk(defense_id: $defense_id) {
      defense_id
      datetime_start
      student_id
      student {
        full_name
        nim
        whatsapp_number
      }
      title
      place {
        place_id
        title
      }
      evaluators {
        defense_evaluator_id
        comments
        player {
          as_staff {
            full_name
            nip
          }
          player_id
        }
        type {
          title
          evaluator_type_id
        }
        gradings {
          comments
          datetime_updated
          grading_comp_id
          grading_id
          score
          score_components
        }
      }
      is_done
      decree_url
      document_url
      defense_category_id
      category {
        title
        types {
          evaluator_type_id
          title
        }
      }
    }
    defense_grading_comp(where: {grading_comp_group: {evaluator_type: {defense_evaluators: {evaluator_player_id: {_eq: $player_id}, defense_id: {_eq: $defense_id}}}}}) {
      grading_comp_id
      percentage
      title
    }
    defense_defense_evaluator(where: {evaluator_player_id: {_eq: $player_id}, defense_id: {_eq: $defense_id}}) {
      defense_evaluator_id
    }
  }
`

export interface getDefenseEvaluationFormQ {
  defense_defense_by_pk: {
    defense_id: string
    datetime_start: string
    student_id: string
    student: {
      full_name: string
      nim: string
      whatsapp_number: string
    }
    title: string
    place: {
      place_id: string
      title: string
    }
    evaluators: {
      defense_evaluator_id: string
      comments: string
      player: {
        as_staff: {
          full_name: string
          nip: string
        }
        player_id: string
      }
      type: {
        title: string
        evaluator_type_id: number
      }
      gradings: {
        comments?: string
        datetime_updated: string
        grading_comp_id: string
        grading_id: string
        score: number 
        score_components?: object[] 
      }[]
    }[]
    is_done: number
    decree_url: string | null
    document_url: string | null
    defense_category_id: number
    category: {
      title: string
      types: {
        evaluator_type_id: number 
        title: string
      }[]
    }
  }
  defense_grading_comp: {
    grading_comp_id: string
    percentage: number
    title: string
  }[]
  defense_defense_evaluator: {
    defense_evaluator_id: string
  }[]
}
