/** @jsxImportSource @emotion/react */

import { useNavigate } from "react-router-dom"
import List from "../../shared/components/List"
import ListItem from "../../shared/components/List/Item"
import Page from "../../shared/components/Page"

const Marketplace = () => {

  const navigate = useNavigate()

  return (
    <Page
      title={`Karir Profesi & Akademik`}
    >
      <List
        title={`Coming soon`}
      >
        <ListItem
          onClick={() => {
          }} 
        >
          Promosikan skill dan produk barang jasa mu, atau tawarkan pengerjaan pekerjaanmu !
        </ListItem>
      </List>
    </Page>
  )
}

export default Marketplace
