/** @jsxImportSource @emotion/react */

import { FaChartBar } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import List from "../../shared/components/List"
import ListItem from "../../shared/components/List/Item"
import Page from "../../shared/components/Page"

const Indicator = () => {

  const navigate = useNavigate()

  return (
    <Page
      title={`Indikator Jurusan`}
    >
      <List>
        <ListItem
          onClick={() => {
            navigate('/indicator/plo')
          }} 
        >
          <FaChartBar /> Capaian Pembelajaran Lulusan (CPL)
        </ListItem>
      </List>
    </Page>
  )
}

export default Indicator
