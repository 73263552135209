/** @jsxImportSource @emotion/react */

import { useEffect, useRef } from "react"
import { FaAngleDown, FaAngleUp } from "react-icons/fa"
import { useWindowSize } from "react-use"
import { Button } from "../../../../../shared/components/IFSuites/Button"
import Flex from "../../../../../shared/components/IFSuites/Flex"
import Small from "../../../../../shared/components/IFSuites/typography/Small"
import GradingAvatar from "../Avatar"

const ParticipantAvatar = ({
  onClick,
  selected,
  title,
  label,
  hitUp,
  hitDown,
  parentElement,
  isGraded,
}: {
  onClick: () => void
  selected: boolean
  title: string
  label: string
  hitUp: number | null
  hitDown: number | null
  parentElement: HTMLDivElement | null
  isGraded?: boolean
}) => {

  const ref = useRef<HTMLDivElement>(null)
  const size = useWindowSize()

  useEffect(() => {

    if(selected && ref.current !== null && parentElement) {
      const y = ref.current.getBoundingClientRect().y
      if((size.height - y <= 168)) {
        parentElement.scrollBy(0, 40)
      }
      // console.log(`${title} terpilih hitDown`, y, size.height, hitDown, parentElement, ref)
    }

  }, [ref, selected, hitDown, parentElement])

  useEffect(() => {

    if(selected && ref.current !== null && parentElement) {
      const y = ref.current.getBoundingClientRect().y
      if((size.height - y >= 152)) {
        parentElement.scrollBy(0, -40)
      }
      // console.log(`${title} terpilih hitUp`, y, size.height, hitUp, parentElement, ref)
    }

  }, [ref, selected, hitUp, parentElement])
  
  return (
    <GradingAvatar
      ref={ref}
      onClick={onClick}
      selected={selected}
      title={title}
      label={label}
      isGraded={isGraded}
    />
  )
} 


const GradingParticipants = ({
  participants,
  selectedLearningParticipationID,
  onChange,
}: {
  onChange: (id: string) => void,
  selectedLearningParticipationID: string
  participants: {
    fullName: string
    nim: string
    learningParticipationID: string
    isGraded?: boolean
  }[]
}) => {

  const participantScrollRef = useRef<HTMLDivElement>(null)
  const size = useWindowSize()

  return (
    <Flex
      jc='flex-start'
      ref={participantScrollRef}
      css={t => ({
        position: "fixed",
        background: t.palette?.gray?.[50], 
        width: t.spacing(6),
        top: 0,
        right: 0,
        height: size.height,
        overflowY: "auto",
      })}
    >
      <Flex
        ai='center'
        pt={10}
        pb={12}
        css={t => ({
          // background: t.palette?.yellow?.[700],
        })}
      >
        <Flex
          ai='center'
          css={t => ({
            position: "fixed",
            top: t.spacing(7),
            left: size.width - t.spacing(6),
            background: t.palette?.gray?.[50], 
            width: t.spacing(6), 
          })}
        >
          <Small>
            Mhsw.
          </Small>
        </Flex>
        <Flex
          py={2}
          ai='center'
          css={t => ({
            position: "fixed",
            bottom: t.spacing(0),
            left: size.width - t.spacing(6),
            background: t.palette?.gray?.[50], 
            width: t.spacing(6), 
          })}
        >
          <Button
            variant='icon'
            color='secondary'
            onClick={() => {}}
          >
            <FaAngleUp />
          </Button>
          <Button
            variant='icon'
            color='secondary'
            onClick={() => {}}
          >
            <FaAngleDown />
          </Button>
        </Flex>

        {participants.map((item, key) => {

          const splittedFullName = item.fullName.split(' ')
          const initial = `${splittedFullName[0][0]}${splittedFullName[1] ? splittedFullName[1][0] : ''}`
          const selected = item.learningParticipationID === selectedLearningParticipationID 

          return (
            <ParticipantAvatar 
              isGraded={item.isGraded}
              selected={selected}
              onClick={() => {
                onChange(item.learningParticipationID) 
              }}
              key={`participant-item-${key}`}
              label={initial}
              title={`${item.fullName} (${item.nim})`}
              hitUp={0}
              hitDown={0}
              parentElement={participantScrollRef.current}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export default GradingParticipants
