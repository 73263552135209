/** @jsxImportSource @emotion/react */

import { useAtom } from "jotai"
import { useEffect, useState } from "react"
import { FaEdit, FaFile, FaLink, FaPlus, FaTimes } from "react-icons/fa"
import { useNavigate, useParams } from "react-router-dom"
import { Button } from "../../../../shared/components/IFSuites/Button"
import Flex from "../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../shared/components/IFSuites/Flex/Row"
import InputText from "../../../../shared/components/IFSuites/forms/InputText"
import Paper from "../../../../shared/components/IFSuites/Paper"
import Small from "../../../../shared/components/IFSuites/typography/Small"
import Page from "../../../../shared/components/Page"
import ClassroomMaterialAddDialogLink from "./Dialog/Link"
import { learningCreateAtom } from "../../states/learning-create"
import useLayout from "../../../../shared/hooks/use-layout"
import NormalEditor from "../../../../shared/components/Editor/MGLexical/NormalEditor"
import { useMutation, useQuery } from "@apollo/client"
import { INSERT_MATERIAL } from "../insert-material.qgl"
import LinkFiles from "../../../../shared/components/links/Files"
import { GET_MATERIAL_BY_ID, MaterialQ } from "../get-material.gql"
import { useDebounce, useInterval } from "react-use"
import { UPDATE_MATERIAL } from "../update-material.gql"
import useBreakpoint from "../../../../shared/hooks/use-breakpoint"
import DialogDuplicate from "./Dialog/Duplicate"

const initialValue = {
  title: "",
  url: "",
  classroom_item_id: "",
}

interface DialogSettings {
  open: boolean
  dialogType: "link" | "upload" 
  initialValue: {
    title: string,
    url: string,
    classroom_item_id: string,
  } 
}

const ClassroomMaterialAdd = () => {

  const bp = useBreakpoint()
  const { classroomItemID, learningID } = useParams()
  const [learningCreate, setLearningCreate] = useAtom(learningCreateAtom)
  const [descriptionInit, setDescriptionInit] = useState<object | null>(null)
  const layout = useLayout()
  const navigate = useNavigate()
  const mode: "new" | "update" = learningID ? "update" : "new"

  const [dialogSettings, setDialogSettings] = useState<DialogSettings>({
    open: false,
    dialogType: "link",
    initialValue: initialValue, 
  })

  const [dialogConfig, setDialogConfig] = useState<{
    addLink: {
      open: boolean
    }
    duplicateMaterial: {
      open: boolean
    }
  }>({
    addLink: {
      open: false,
    },
    duplicateMaterial: {
      open: false,
    },
  })

  useDebounce(() => {
    console.log("auto save using debounce...")
    // Update 
    if(mode === "update") {
      layout.loadingShow("Materi disimpan otomatis ...")
      update({
        variables: {
          attachments: learningCreate.attachments,
          learning_id: learningID,
          description: learningCreate.description,
          title: learningCreate.title,
        } 
      }).then(res => {
      }).catch(e => {
      }).finally(() => {
        layout.loadingHide()
      })
    }
  }, 1000, [learningCreate.description])

  const [insert, insertOps] = useMutation(
    INSERT_MATERIAL, 
  )

  const [update, updateOps] = useMutation(
    UPDATE_MATERIAL, 
  )

  const materialGet = useQuery<MaterialQ>(GET_MATERIAL_BY_ID, {
    nextFetchPolicy: "network-only",
    variables: {
      learning_id: learningID,
    }
  })

  const classroomItemIDFromMaterial = materialGet.data?._.classroom_item_id

  useEffect(() => {
    materialGet.refetch()
  }, [])

  useEffect(() => {
    if(classroomItemID && !learningID) {
      setDescriptionInit(learningCreate.description)
      console.log("new material")
    }

    const materialGetData = materialGet.data

    if(!classroomItemID && learningID && materialGetData !== undefined && materialGetData._.description) {
      console.log("edit material", materialGetData._.attachments)
      setDescriptionInit(materialGetData._.description)
      setLearningCreate(v => ({
        ...v,
        description: materialGetData._.description,
        attachments: materialGetData._.attachments,
        title: materialGetData._.title,
      }))
    }
  }, [classroomItemID, learningID, materialGet.data])

  return (
    <Page
      title={mode === "update" ? `Perbarui Materi` : `Tambah Materi`}
    >
      <Paper
        br={0}
      >
        <Flex py={1} px={2} ai="end">
          <Button
            br={0.5}
            variant={"contained"}
            color={"secondary"}
            size="small"
            // variant="contained"
            // color="success"
            onClick={() => {
              layout.confirmCancelable({
                content: <Flex>{mode === "update" ? "Perbarui" : "Tambah"} materi ?</Flex>,
                onClick: () => {
                  layout.loadingShow("Menambahkan materi ke server...")

                  // Insert
                  if(mode === "new") {
                    insert({
                      variables: {
                        attachments: learningCreate.attachments,
                        classroom_item_id: classroomItemID,
                        description: learningCreate.description,
                        title: learningCreate.title,
                      } 
                    }).then(res => {
                      layout.confirm({
                        content: <Flex>Materi berhasil dibuat</Flex>,
                        onClick: () => {
                          // https://stackoverflow.com/questions/42173786/react-router-pass-data-when-navigating-programmatically
                          navigate(-1)
                        }
                      })
                    }).catch(e => {
                      layout.confirm({
                        content: <Flex>Materi gagal dibuat ! pastikan setiap komponen lengkap</Flex>,
                        onClick: () => {}
                      })
                    }).finally(() => {
                      layout.loadingHide()
                    })
                  }

                  // Update 
                  if(mode === "update") {
                    update({
                      variables: {
                        attachments: learningCreate.attachments,
                        learning_id: learningID,
                        description: learningCreate.description,
                        title: learningCreate.title,
                      } 
                    }).then(res => {
                      layout.confirm({
                        content: <Flex>Materi berhasil diperbarui</Flex>,
                        onClick: () => {
                          // https://stackoverflow.com/questions/42173786/react-router-pass-data-when-navigating-programmatically
                          navigate(-1)
                        }
                      })
                    }).catch(e => {
                      layout.confirm({
                        content: <Flex>Materi gagal dibuat ! pastikan setiap komponen lengkap</Flex>,
                        onClick: () => {}
                      })
                    }).finally(() => {
                      layout.loadingHide()
                    })
                  }

                },
              })
            }}
          >
            {mode === "update" ? "Simpan Perubahan" : "Submit"} 
          </Button>
        </Flex>
      </Paper>

      <Flex
        py={1}
        px={2}
        jc="center"
        css={t => ({
          flexDirection: bp === "mobile" ? "column" : "row",
        })}
      >
        <Button
          br={0.5}
          size="small"
          color="secondary"
          fullWidth={bp === "mobile" ? true : undefined}
          onClick={() => {
            console.log("buka dialog duplikasi asesmen", classroomItemID || materialGet.data?._.classroom_item_id)
            setDialogConfig(v => ({
              ...v,
              duplicateMaterial: {
                open: true,
              },
            })) 
          }}
        >
          <FaPlus /> Duplikasi Materi dari Kelas Lain
        </Button>
      </Flex>

      <Paper
        br={0}
      >
        <Flex
          p={2}
          gap={2}
        >
          <InputText
            label="Judul"
            value={learningCreate.title}
            onChange={e => {
              setLearningCreate(v => ({
                ...v,
                title: e.target.value,
              }))
            }}
            fullWidth
          />
        </Flex>
      </Paper>

      <Paper
        br={0}
      >
        <FlexRow
          p={2}
        >
          <FaEdit />
          <Small>Konten</Small>
        </FlexRow>
        <Flex
          px={2}
          gap={0}
        >
          {descriptionInit &&
            <NormalEditor
              isEditable={true}

              // Initial text editor state
              initEditorStateString={JSON.stringify(descriptionInit)}
              trackInitEditorStateChange={true}

              // Current text editor state
              onEditorStateChange={value => {
                setLearningCreate(v => ({
                  ...v,
                  description: value,
                }))

              }}
            />
          }
        </Flex>
      </Paper>

      <Paper
        br={0}
      >
        <Flex
          gap={2}
        >
          <FlexRow pt={2} px={2}>
            <FaFile />
            <Small>Lampiran</Small>
          </FlexRow>
          <FlexRow px={2} jc="center">
            <Button
              br={0.5}
              color={"secondary"}
              fullWidth
              size="small"
              onClick={() => {
                setDialogSettings(v => ({
                  ...v,
                  open: true,
                }))
              }}
            >
              <FaLink size={16} />
              Tambah Link
            </Button>
          </FlexRow>
          <Flex gap={0}>
            <LinkFiles
              links={learningCreate.attachments.map((attachment, key) => ({
                label: attachment.title,
                url: attachment.url,
                rightElement: <Button
                  variant="icon"
                  size="small"
                  color="error"
                  onClick={() => {
                    setLearningCreate(v => ({
                      ...v,
                      attachments: [
                        ...v.attachments.slice(0, key),
                        ...v.attachments.slice(key + 1),
                      ]
                    }))
                  }}
                >
                  <FaTimes />
                </Button>,
              }))}
            />
          </Flex>
        </Flex>
      </Paper>
      <ClassroomMaterialAddDialogLink
        initialValue={dialogSettings.initialValue}
        open={dialogSettings.open}
        onSave={(attachment) => {
          setLearningCreate(v => ({
            ...v,
            attachments: [
              ...v.attachments,
              attachment, 
            ],
          }))

          setDialogSettings(v => ({
            ...v,
            open: false,
            initialValue: initialValue, 
          }))
        }}
        onClose={() => {
          setDialogSettings(v => ({
            ...v,
            open: false,
            initialValue: initialValue, 
          }))
        }}
      />
      {(classroomItemID !== undefined || classroomItemIDFromMaterial !== undefined) &&
        <DialogDuplicate
          classroomItemID={classroomItemID !== undefined ? classroomItemID : classroomItemIDFromMaterial !== undefined ? classroomItemIDFromMaterial : ""}
          open={dialogConfig.duplicateMaterial.open}
          onClose={() => {
            setDialogConfig(v => ({
              ...v,
              duplicateMaterial: {
                open: false,
              },
            })) 
          }}
          onSelect={materialFromOtherClass => {
            setDescriptionInit(materialFromOtherClass._.description)
            setLearningCreate(v => ({
              ...v,
              title: materialFromOtherClass._.title,
              description: materialFromOtherClass._.description,
              attachments: materialFromOtherClass._.attachments,
            }))
            console.log("materialFromOtherClass", materialFromOtherClass)
          }}
        />
      }
    </Page>
  )
}

export default ClassroomMaterialAdd
