/** @jsxImportSource @emotion/react */

import { ReactNode } from "react"
import List from ".."
import Flex from "../../IFSuites/Flex"
import FlexRow from "../../IFSuites/Flex/Row"
import H4 from "../../IFSuites/typography/H4"
import Small from "../../IFSuites/typography/Small"
import ListItem from "../Item"

interface ListLayoutProps {
  title?: ReactNode
  items: {
    title: ReactNode 
    content?: ReactNode
    icon: ReactNode
    onClick: () => void
  }[]
}

const ListLayout = ({title, items}: ListLayoutProps) => {

  return (
    <List 
      title={title}
      css={t => ({
        color: t.palette?.blueGray?.[700],
      })}
    >
      {items.map((item, index) => {
        return (
          <ListItem
            onClick={item.onClick} 
          >
            <FlexRow gap={2}>
              {item.icon}
              <Flex gap={0}>
                <H4>{item.title}</H4> 
                <Small>{item.content}</Small>
              </Flex>
            </FlexRow>
      
          </ListItem>
        )
      })}
    </List>
  )
}

export default ListLayout
