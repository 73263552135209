/** @jsxImportSource @emotion/react */

import Flex from "../../../shared/components/IFSuites/Flex"
import QRCode from 'react-qr-code'
import Page from "../../../shared/components/Page"
import { useWindowSize } from "react-use"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import { TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share'
import { useLocation, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import useBreakpoint from "../../../shared/hooks/use-breakpoint"
import Small from "../../../shared/components/IFSuites/typography/Small"
import { FaWhatsapp } from "react-icons/fa"

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const PortalShare = () => {
  const navigate = useNavigate()
  const windowSize = useWindowSize()
  const query = useQuery()
  const br = useBreakpoint()
  const path = query.get("path")

  return (
    <Page
      title={`Share`}
    >
      <Flex
        css={t => ({
        })}
      >
        {path &&
          <Flex
            p={2}
            ai={`center`}
            jc={`center`}
          >
            <QRCode size={br === "laptop" ? window.innerWidth - 32 : window.innerHeight/2} value={path} />
          </Flex>
        }
        
        {path &&
          <Flex
            p={2}
            ai={`center`}
            jc={`center`}
          >
            <WhatsappShareButton
              url={`https://informatika.digital/#${path}`}
              style={{
                background: `#25d366`,
                boxShadow: `0 3px 5px 2px #25d36633`,
                borderBottomColor: `#129644`,
                borderBottomWidth: 4,
                borderBottomStyle: `solid`,
                borderRadius: 5,
              }}
            >
              <FlexRow p={2} jc={"center"} css={{color: "white"}}>
                <FaWhatsapp /><Small css={{color: "white"}}> Share link via Whatsapp</Small>
              </FlexRow>
            </WhatsappShareButton>
          </Flex>
        }
      </Flex>
    </Page>
  )
}

export default PortalShare
