/** @jsxImportSource @emotion/react */

import { forwardRef, Ref } from "react"
import Flex from "../../../../../shared/components/IFSuites/Flex"

const GradingAvatar = forwardRef(({
  onClick,
  selected,
  title,
  label,
  isGraded,
}: {
  onClick: () => void
  selected: boolean
  title: string
  label: string
  isGraded?: boolean
}, ref: Ref<HTMLDivElement>) => {
  return (
    <Flex
      ref={ref}
      jc='center'
      ai='center'
      onClick={onClick}
      css={t => ({
        color: selected ? t.palette?.gray?.[50] : t.palette?.gray?.[700],
        width: t.spacing(4),
        height: t.spacing(4),
        borderRadius: t.spacing(2),
        background: selected ? t.palette?.secondary?.main : "auto", 
        // border: selected ? `3px solid ${t.palette?.primary?.main}` : `3px solid ${t.palette?.error?.main}`,
        border: `3px solid ${isGraded !== undefined ? isGraded === true ? t.palette?.success?.main : t.palette?.error?.main : t.palette?.gray?.[400]}`,
        '&:hover': {
          cursor: "pointer",
          // border: `3px solid ${t.palette?.primary?.main}`,
          background: t.palette?.secondary?.main,
          color: t.palette?.gray?.[50],
          // background: t.palette?.gray?.[700], 
        }
      })}
      title={title}
    >
      {label}
    </Flex>
  )
})

export default GradingAvatar
