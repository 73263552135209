import { gql } from "@apollo/client";

export const GET_UPCOMING_DEFENSES = gql`
query GetUpcomingDefenses($player_id: uuid = "") {
  defense_defense(where: {is_done: {_eq: "0"}, evaluators: {evaluator_player_id: {_eq: $player_id}}}) {
    defense_id
    datetime_start
    title
    place {
      place_id
      title
    }
    category {
      title
    }
    student {
      full_name
      student_id
    }
    evaluators {
      type {
        title
        evaluator_type_id
      }
      player {
        as_staff {
          full_name
        }
      }
    }
  }
}
`

export interface GetUpcomingDefensesQ {
  defense_defense: {
    defense_id: string
    datetime_start: string
    title: string
    place: {
      place_id: string
      title: string
    }
    category: {
      title: string
    }
    student: {
      full_name: string
      student_id: string
    }
    evaluators: {
      type: {
        title: string
        evaluator_type_id: string
      }
      player: {
        as_staff: {
          full_name: string
        }
      }
    }[]
  }[]
} 
