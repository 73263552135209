/** @jsxImportSource @emotion/react */

import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import IconLoading from '../../../../shared/components/IconLoading'
import Flex from '../../../../shared/components/IFSuites/Flex'
import Paper from '../../../../shared/components/IFSuites/Paper'
import List from '../../../../shared/components/List'
import { ClassroomDetailQ } from '../../Home/models'
import ClassroomMeetingListItem from './Item'

const ClassroomMeetingList = ({data}: {data?: ClassroomDetailQ}) => {
  const { classroomID } = useParams()

  const date = dayjs(data?._.cys.year_semester.teaching_weeks_start_date)
  const dayNumber = data?._.schedules[0].day_number

  return (
    <Flex gap={2}>
      <Paper br={0}>
        {!data && 
          <Flex css={{width: `100%`}} ai="center" pt={4} pb={4}>
            <IconLoading size={48} />
          </Flex>
        }
        {data &&
          <List
            title={`Pilih Pertemuan`}
            collapsible
          >
            {data?._.items.filter(item => item.classroom_item_type_id === 1).map((item, key) => (
              <ClassroomMeetingListItem
                key={`classroom-session-list-item-${key}`}
                // title={key == 7 ? `${item.title} - UTS` : key == 15 ? `${item.title} - UAS` : item.title}
                title={item.title}
                totalMaterial={item.total_materials.aggregate.count}
                totalAssessment={item.total_assessments.aggregate.count}
                isAssessmentCreated={false}
                isPresenceCreated={false}
                isToday={false} 
                classroomItemID={item.classroom_item_id}
                classroomID={data._.classroom_id}
                date={(date.add(((item.item_order - 1)), 'w')).add(dayNumber ? dayNumber - 1 : 0, 'd')}
              />
            ))}
          </List>
        }
      </Paper>
    </Flex>
  )
}

export default ClassroomMeetingList
