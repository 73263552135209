/** @jsxImportSource @emotion/react */

import { useLazyQuery, useMutation } from "@apollo/client"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Button } from "../../../shared/components/IFSuites/Button"
import DateTimePicker from "../../../shared/components/IFSuites/DateTimePicker"
import Flex from "../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import InputText from "../../../shared/components/IFSuites/forms/InputText"
import H3 from "../../../shared/components/IFSuites/typography/H3"
import Page from "../../../shared/components/Page"
import useLayout from "../../../shared/hooks/use-layout"
import PlaceSelector from "../../Place/Selector"
import PlayerSelector from "../../Player/Selector"
import { getDefenseByIDQ, GET_DEFENSE_BY_ID } from "../get-defense-by-id.gql"
import { EditDefenseByIDQ, EDIT_DEFENSE_BY_ID } from "./edit-defense-by-id.gql"

type EvaluatorsKV = {
  [key: number]: {
    evaluator_player_id: string,
    evaluator_type_id: number
  }
}

const DefenseEdit = () => {

  // programme admin & student

  const { defenseID } = useParams<{defenseID: string}>()
  const layout = useLayout()

  const [get, getOps] = useLazyQuery<getDefenseByIDQ>(
    GET_DEFENSE_BY_ID,
  )

  const [edit,] = useMutation(
    EDIT_DEFENSE_BY_ID, 
  )

  const [editPayload, setEditPayload] = useState<EditDefenseByIDQ>({
    title: "", 
    datetime_start: dayjs().format("YYYY-MM-DDTHH:[00]:[00]+00"), 
    place_id: "", 
  })

  const [evaluatorsKV, setEvaluatorsKV] = useState<EvaluatorsKV>({}) 

  const refreshData = () => {
    layout.loadingShow("Mengakses data ...")
    get({
      variables: {
        defense_id: defenseID,
      },
      fetchPolicy: "network-only",
    }).then(response => {

      const initEvaluatorsKV: EvaluatorsKV = {}

      const responseData = response.data

      if(responseData) {

        responseData.defense_defense_by_pk.evaluators.map(v => {
          initEvaluatorsKV[v.type.evaluator_type_id] = {
            evaluator_type_id: v.type.evaluator_type_id,
            evaluator_player_id: v.player.player_id,
          }
        })

        setEvaluatorsKV(initEvaluatorsKV)

        setEditPayload(v => ({
          ...v,
          title: responseData.defense_defense_by_pk.title,
          datetime_start: responseData.defense_defense_by_pk.datetime_start,
          place_id: responseData.defense_defense_by_pk.place.place_id, 
        }))
      }

    }).finally(() => {
      layout.loadingHide()
    })
  } 

  useEffect(() => {
    console.log("evaluatorsKV", evaluatorsKV)
  }, [evaluatorsKV])

  useEffect(() => {
    refreshData()
  }, [])

  return (
    <Page
      title={`Perbarui Data Sidang`}
    >
      {getOps.data &&
        <Flex
          pt={2}
          pb={0}
          px={2}
        >
          <Flex>
            <H3>{getOps.data.defense_defense_by_pk.student.full_name}</H3> 
            {getOps.data.defense_defense_by_pk.student.nim} 
            
          </Flex>
          <Flex>
            <Flex
              css={t => ({
                height: t.spacing(6),
              })}
            >
              <H3>Perbarui Jadwal</H3>
            </Flex>
            <Flex>
              <InputText
                label="Judul"
                value={editPayload.title}
                fullWidth
                onChange={e => {
                  setEditPayload(v => ({...v, title: e.target.value}))
                }}
              />
            </Flex>
            {getOps.data && getOps.data.defense_defense_by_pk.category.types.map((item, key) => {

              const selectedEvaluator = getOps.data?.defense_defense_by_pk.evaluators.find(w => w.type.evaluator_type_id === item.evaluator_type_id) 
              
              return (
                <Flex key={`evaluator-item-${key}`}>
                  <PlayerSelector
                    target="lecturer"
                    label={item.title}
                    initialTitle={selectedEvaluator?.player.as_staff.full_name}
                    initialDescription={selectedEvaluator?.player.as_staff.nip}
                    initialKeyword="teknik"
                    onChange={selected => {
                      setEvaluatorsKV(v => ({...v, [item.evaluator_type_id]: {
                          evaluator_player_id: selected.playerID,
                          evaluator_type_id: item.evaluator_type_id,
                      }}))
                    }}
                  />
                </Flex>
              )
            })}
            <Flex>
              <DateTimePicker
                label="Waktu pelaksanaan"
                initialDatetime={getOps.data.defense_defense_by_pk.datetime_start}
                onChange={newDateTime => {
                  console.log(newDateTime)
                  setEditPayload(v => ({...v, datetime_start: newDateTime.format("YYYY-MM-DDTHH:[00]:[00]+07")}))
                }}
              />
            </Flex>
            <Flex>
              <PlaceSelector
                initialTags={["705"]}
                initialTitle={getOps.data.defense_defense_by_pk.place.title}
                initialDescription={''}
                label={`Tempat pelaksanaan`}
                onChange={(selected) => {
                  setEditPayload(v => ({
                    ...v,
                    place_id: selected.placeID,
                  }))
                }}
              />
            </Flex>
            <FlexRow jc="flex-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {

                  layout.confirmCancelable({
                    content: "Perbarui data ?",
                    onClick: () => {

                      layout.loadingShow("Mengirim data ke server...")

                      const evaluators = Object.keys(evaluatorsKV).map(w => {
                        const evaluator = evaluatorsKV[Number(w)]
                        return {
                          evaluator_type_id: evaluator.evaluator_type_id,
                          evaluator_player_id: evaluator.evaluator_player_id, 
                        }
                      })

                      const defenseUpdateObject = {
                        ...editPayload,
                      }

                      const variables = {
                        defense_id: defenseID,
                        defense_object: defenseUpdateObject,
                        defense_evaluator_objects: evaluators.map(v => ({...v, defense_id: defenseID})),
                      }

                      console.log("variables", variables)

                      edit({
                        variables: variables 
                      }).then(response => {
                        layout.confirm({
                          content: <Flex>Jadwal berhasil diperbarui</Flex>,
                          onClick: () => {
                            getOps.refetch()
                          }
                        })

                      }).finally(() => {
                        layout.loadingHide()
                      })

                    }
                  })

                  
                }}
              >
                Perbarui
              </Button>
            </FlexRow>
          </Flex>
        </Flex>
      }
    </Page>
  )
} 

export default DefenseEdit
