import { gql } from "@apollo/client";

export const GET_PLO_SCORE = gql`
query GetPLOScore($year_academic_input: Int = 2023) {
  scores: academic__plo_score(args: {year_academic_input: $year_academic_input}, order_by: {code: asc}) {
    code
    description
    details
  }
}
` 

export interface GetPLOScore {
  scores: {
    code: string
    description: string
    details: {
      plo_score: null | number
    }
  }[]
}
