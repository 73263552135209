/** @jsxImportSource @emotion/react */

import { BiCycling } from "react-icons/bi"
import { motion } from "framer-motion"

interface Props {
  size?: number
}

export default ({size}: Props) => {
  return (
    <motion.div
      animate={{
        y: [0, 3, 0, -3, 0],
        color: ["#B9C3D3", "#627798", "#627798", "#627798", "#B9C3D3"],
      }}
      transition={{
        repeat: Infinity,
        duration: 2,
        // repeatDelay: 2,
      }}
    >
      <BiCycling size={size !== undefined ? size : 24} /> 
    </motion.div>
  )
}
