/** @jsxImportSource @emotion/react */

import { ReactNode } from "react"
import mq from "../../../../utils/media-query"
import Flex from "../../../IFSuites/Flex"
import Small from "../../../IFSuites/typography/Small"

interface GridIconLayoutProps {
  title?: ReactNode
  items: {
    title: ReactNode 
    icon: ReactNode
    onClick: () => void
  }[]
}

const GridIconLayout = ({title, items}: GridIconLayoutProps) => {

  return (
    <div
      css={t => ({
        width: `100%`,
        display : "grid",
        gridTemplateColumns: `1fr 1fr 1fr`, 
        justifyContent: "center",
        alignItems: "center",
        gridGap: t.spacing(1),
        padding: t.spacing(1),
        boxSizing: "border-box",
        [mq[0]]: {
          gridTemplateColumns: `1fr 1fr 1fr 1fr`, 
        },
        // [mq[1]]: {
        //   gridTemplateColumns: `1fr 1fr 1fr 1fr 1fr 1fr`, 
        // },
        [mq[2]]: {
          gridTemplateColumns: `1fr 1fr 1fr 1fr 1fr 1fr`, 
        },
      })}
    >
      {items.map((item, index) => {
        return (
          <Flex
            className={`MenuItem`}
            key={`menu-item-${index}`}
            py={2}
            onClick={item.onClick}
            gap={1}
            ai={`center`}
            jc={`center`}
            css={t => ({
              borderRadius: t.spacing(1),
              border: `1px solid ${t.palette?.blueGray?.[100]}`,
              background: t.palette?.gray?.[50], 
              color: t.palette?.blueGray?.[600],
              transition: `.4s`,
              '&:hover': {
                background: t.palette?.yellow?.[400], 
                border: `1px solid ${t.palette?.yellow?.[100]}`,
                cursor: "pointer",
              },
            })}
          >
            {item.icon}
            <Small>{item.title}</Small> 
          </Flex>
        )
      })}
    </div>
  )
}

export default GridIconLayout
