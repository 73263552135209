import { gql } from "@apollo/client";

export interface PlacesByTagsQ {
  academic_place: {
    place_id: string
    tags: string[]
    title: string
  }[]
}

export const GET_PLACES_BY_TAGS = gql`
  query GetPlaceByTags($_contains: jsonb = "") {
    academic_place(where: {tags: {_contains: $_contains}}) {
      place_id
      tags
      title
    }
  }
`

// GET DEFENSE EVALUATOR BY KEYWORD
