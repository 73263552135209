import { gql } from "@apollo/client";

export const UPSERT_DEFENSE_GRADING = gql`
mutation UpsertDefenseGrading($grading_objects: [defense_grading_insert_input!] = {}, $evaluator_object: defense_defense_evaluator_set_input = {}, $defense_evaluator_id: uuid = "") {
  insert_defense_grading(objects: $grading_objects, on_conflict: {constraint: grading_un, update_columns: [score, score_components, comments]}) {
    returning {
      grading_id
    }
  }
  update_defense_defense_evaluator_by_pk(_set: $evaluator_object, pk_columns: {defense_evaluator_id: $defense_evaluator_id}) {
    defense_evaluator_id
  }
}
`
export interface UpsertDefenseGradingQ {
  grading_objects: {
    defense_evaluator_id: string
    grading_comp_id: string
    score: number 
    score_components?: object[]
    comments?: string
  }[]
  evaluator_object: {
    comments?: string
    comments_url?: string
    decree_number?: string
    decree_url?: string
  }
  defense_evaluator_id: string
}
