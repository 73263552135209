/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react"
import { FaTimes } from "react-icons/fa"
import { Button } from "../../../../../../shared/components/IFSuites/Button"
import Dialog from "../../../../../../shared/components/IFSuites/Dialog"
import DividerGrow from "../../../../../../shared/components/IFSuites/Divider/Grow"
import Flex from "../../../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../../../shared/components/IFSuites/Flex/Row"
import InputText from "../../../../../../shared/components/IFSuites/forms/InputText"
import Paper from "../../../../../../shared/components/IFSuites/Paper"
import { LearningAttachmentWS } from "../../../../models/learning"

interface Props {
  open: boolean
  onSave: (attachment: LearningAttachmentWS) => void
  initialValue?: LearningAttachmentWS 
  onClose: () => void
}

const ClassroomMaterialAddDialogLink = (
  { open, onSave, initialValue, onClose } : Props
) => {

  const [attachment, setAttachment] = useState<LearningAttachmentWS>({
    title: "",
    url: "",
  })

  useEffect(() => {
    if(open && initialValue) {
      setAttachment(initialValue)
    }
  }, [open])

  return (
    <Dialog
      open={open}
    >
      <Paper>
        <Flex p={2}>
          <FlexRow>
            <DividerGrow />
            <Button
              size="small"
              variant="icon"
              color="info"
              onClick={() => {
                onClose()
              }}
            >
              <FaTimes />
            </Button>
          </FlexRow>
          <InputText
            label="Judul link"
            value={attachment.title}
            onChange={e => {
              setAttachment(v => ({
                ...v,
                title: e.target.value,
              }))
            }}
          />
          <InputText
            label="Alamat URL"
            value={attachment.url}
            onChange={e => {
              setAttachment(v => ({
                ...v,
                url: e.target.value,
              }))
            }}
          />
          <Button
            size="small"
            color="success"
            variant="contained"
            fullWidth
            onClick={() => {
              onSave(attachment)
            }}
          >
            Simpan
          </Button>
        </Flex>
      </Paper>
    </Dialog>
  )
}

export default ClassroomMaterialAddDialogLink 
