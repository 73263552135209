/** @jsxImportSource @emotion/react */

import Flex from "../../Flex"
import { CommonProps } from "../../interfaces"

const H3 = ({children, ...other}: CommonProps) => {
  return (
    <Flex
      css={t => ({
        'h3': {
          marginTop: 0,
          marginBottom: 0,
          fontSize: `1rem`,
          color: t.palette?.gray?.[900]
        },
      })}
      w={"auto"}
      {...other}
    >
      <h3>{children}</h3>
    </Flex>
  )
}

export default H3
