/** @jsxImportSource @emotion/react */

import { gql, useMutation, useQuery } from "@apollo/client"
import { useAtom } from "jotai"
import { forwardRef, Ref, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import NormalEditor, { sampleEditorState } from "../../../../../shared/components/Editor/MGLexical/NormalEditor"
import { Button } from "../../../../../shared/components/IFSuites/Button"
import Flex from "../../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../../shared/components/IFSuites/Flex/Row"
import Paper from "../../../../../shared/components/IFSuites/Paper"
import Small from "../../../../../shared/components/IFSuites/typography/Small"
import Page from "../../../../../shared/components/Page"
import useAuth from "../../../../../shared/hooks/use-auth"
import useBreakpoint from "../../../../../shared/hooks/use-breakpoint"
import layoutAtom from "../../../../../shared/states/layout"
import { questionTypeByID } from "../../utils"

const QuestionAvatar = forwardRef(({
  onClick,
  selected,
  title,
  label,
  answered,
}: {
  onClick: () => void
  selected: boolean
  title: string
  label: string
  answered: boolean,
}, ref: Ref<HTMLDivElement>) => {
  return (
    <Flex
      ref={ref}
      jc='center'
      ai='center'
      onClick={onClick}
      css={t => ({
        color: selected ? t.palette?.gray?.[50] : t.palette?.gray?.[700],
        width: t.spacing(4),
        height: t.spacing(4),
        borderRadius: t.spacing(2),
        background: selected ? t.palette?.secondary?.main : "auto", 
        border: `3px solid ${answered ? t.palette?.success?.main : t.palette?.error?.main}`,
        '&:hover': {
          cursor: "pointer",
          background: t.palette?.secondary?.main,
          color: t.palette?.gray?.[50],
        }
      })}
      title={title}
    >
      {label}
    </Flex>
  )
})

interface QuestionAnswerQ {
  _: {
    learning_question_id: string
    description: object
    attachments: object[]
    answer_choices: object[]
    question_type_id: number 
    type: {
      title: string
    }
    participation_answers: {
      learning_participation_answer_id: string
      answer_choices: object[]
      answer_text: object
      datetime_created: string | null
      datetime_graded: string | null
      datetime_submit: string | null
      feedback: object
      grader: {
        lecturer: {
          staff: {
            full_name: string
          }
        }
      }
      score: number
    }[]
    learning: {
      questions: {
        learning_question_id: string
        item_order: number
        participation_answers: {
          datetime_submit: string
        }[]
      }[]
      datetime_end: string | null
      datetime_start: string | null
      title: string
      participations: {
        learning_participation_id: string
      }[]
    }
    clo: {
      clo: {
        code: string
        description: string
      }
    }
  }
}

const ClassroomAssessmentGameAnswer = () => {

  const {learningID, questionID} = useParams()
  const [layout, setLayout] = useAtom(layoutAtom)
  const navigate = useNavigate()
  const location = useLocation()
  const bp = useBreakpoint()
  const [answerText, setAnswerText] = useState<object | undefined>(undefined)
  const [pageInfo, setPageInfo] = useState<{
    nextQuestionID: undefined | string
    prevQuestionID: undefined | string
  }>({
    nextQuestionID: undefined,
    prevQuestionID: undefined,
  })
  const auth = useAuth()

  const GET_QUESTION_ANSWER = gql`
    query GetQuestionAnswer($learning_question_id: uuid = "") {
      _: academic_learning_question_by_pk(learning_question_id: $learning_question_id) {
        learning_question_id
        description
        attachments
        answer_choices
        question_type_id
        type {
          title
        }
        participation_answers {
          learning_participation_answer_id
          answer_choices
          answer_text
          datetime_created
          datetime_graded
          datetime_submit
          feedback
          grader {
            lecturer {
              staff {
                full_name
              }
            }
          }
          score
        }
        learning {
          questions(order_by: {item_order: asc}) {
            learning_question_id
            item_order
            participation_answers {
              datetime_submit
            }
          }
          datetime_end
          datetime_start
          title
          participations {
            learning_participation_id
          }
        }
        clo {
          clo {
            code
            description
          }
        }
      }
    }
  `

  const {data, refetch} = useQuery<QuestionAnswerQ>(
    GET_QUESTION_ANSWER,
    {
      variables: {
        learning_question_id: questionID,
      }
    }
  )

  const INSERT_UPDATE_ANSWER = gql`
    mutation InsertUpdateAnswer(
      $answer_choices: jsonb = [],
      $answer_text: jsonb = null,
      $learning_participation_id: uuid = "",
      $learning_question_id: uuid = ""
    ) {
      insert_academic_learning_participation_answer_one(
        object: {
          answer_choices: $answer_choices,
          answer_text: $answer_text,
          learning_participation_id: $learning_participation_id,
          learning_question_id: $learning_question_id
        },
        on_conflict: {
          constraint: learning_participation_answer_un,
          update_columns: [answer_text, answer_choices]
        }
      ) {
        answer_text
      }
    }
  `
  const [upsert, upsertOps] = useMutation(INSERT_UPDATE_ANSWER)

  const SUBMIT_ASSESSMENT = gql`
    mutation SubmitAssessment($learning_participation_id: uuid = "") {
      update_academic_learning_participation_by_pk(pk_columns: {learning_participation_id: $learning_participation_id}) {
        datetime_submit
      }
    }
  `
  const [submitAssessment, submitAssessmentOps] = useMutation(SUBMIT_ASSESSMENT)

  const currentNumber = questionID && data ? data._.learning.questions.map(v => v.learning_question_id).indexOf(questionID) + 1 : null 
  const questionType = questionTypeByID(data ? data._.question_type_id : 0)

  useEffect(() => {
    if(data && data._.learning.questions) {
      const questions = data._.learning.questions
      const currentQuestionIndex = questions.findIndex(v => v.learning_question_id === questionID)
      const totalQuestion = questions.length 
      if(currentQuestionIndex !== undefined) {
        const nextQuestion = questions.at(currentQuestionIndex + 1)
        const prevQuestion = questions.at(currentQuestionIndex - 1)
        const newPageInfo = {
          nextQuestionID: nextQuestion ? nextQuestion.learning_question_id : undefined,
          prevQuestionID: prevQuestion ? prevQuestion.learning_question_id : undefined,
        }
        setPageInfo(newPageInfo)
      }
    }
  }, [location.pathname, data])

  return (
    <Page
      title={data?._.learning.title}
    >
      <Flex
        pb={17}
        gap={2}
      >
        <Paper
          br={0}
        >
          <FlexRow
            px={2}
            pt={2}
            pb={1}
          >
            <Button
              color="info"
              variant="contained"
              size="small"
            >
              No. {currentNumber}
            </Button>
            <Button
              color="info"
              size="small"
            >
              {data?._.clo.clo.code}
            </Button>
            <Button
              color="info"
              size="small"
              css={t => ({
                'svg': {
                }
              })}
            >
              {questionType.icon}
              {questionType.title}
            </Button>
          </FlexRow>
          <FlexRow
            css={t => ({
              '.ContentEditable__root': {
                paddingLeft: 16,
              }
            })}
          >
            {data !== undefined &&
              <NormalEditor
                initEditorStateString={
                 JSON.stringify(data._.description)
                }
                trackInitEditorStateChange={true}
                isEditable={false}
                onEditorStateChange={newEditorState => {}}
              />
            }
          </FlexRow>
          {data?._.participation_answers.length == 1 &&
            <FlexRow
              px={2}
              pt={1}
              pb={2}
            >
              <Small>
                {data?._.participation_answers[0].datetime_graded === null ?
                  `Belum dinilai` : 
                  `Nilai ${data?._.participation_answers[0].score}` 
                }
              </Small> 
            </FlexRow>
          }
        </Paper>

        {data && 
          <Paper
            br={0}
          >
            <FlexRow
              px={2}
              pt={2}
            >
              {![1, 2].includes(data._.question_type_id) &&
                <Small>Jawaban</Small> 
              }
            </FlexRow>
            <FlexRow
              px={1}
            >
              <NormalEditor
                initEditorStateString={
                  data._.participation_answers.length == 0 ? 
                  sampleEditorState : data._.participation_answers[0].answer_text === null ?
                  sampleEditorState : JSON.stringify(data._.participation_answers[0].answer_text)
                }
                trackInitEditorStateChange={true}
                isEditable={true}
                onEditorStateChange={newEditorState => {
                  setAnswerText(newEditorState)
                }}
              />
            </FlexRow>
            <Flex
              px={2}
              pb={2}
              css={t => ({
                flexDirection: bp === "mobile" ? "column" : "row",
              })}
            >
              <Button
                fullWidth={bp === "mobile" ? true : undefined}
                variant="contained"
                color="success"
                size="small"
                onClick={() => {
                  const upsertPayload = {
                    variables: {
                      answer_choices: [],
                      answer_text: answerText,
                      learning_participation_id: data._.learning.participations[0].learning_participation_id,
                      learning_question_id: data._.learning_question_id 
                    }
                  }
                  upsert(upsertPayload).then(res => {
                    refetch().then(res => {
                      console.log("pageInfo", pageInfo)
                      if(pageInfo.nextQuestionID) {
                        navigate(`/classroom/assessment/${learningID}/game/question/${pageInfo.nextQuestionID}`)
                      }
                    })
                  })
                }}
              >
                Perbarui Jawaban
              </Button>
            </Flex>
          </Paper>
        }
      </Flex>
      <Flex
        className={"ClassroomAssessmentGameAnswer_Navigation"}
        gap={0}
        jc={"center"}
        css={t => ({
          position: "absolute",
          bottom: 0,
          background: t.palette?.gray?.[50], 
        })}
      >
        <FlexRow
          jc='center'
          css={t => ({
          })}
        >
          <Small>
            Soal
          </Small>
        </FlexRow> 
        <FlexRow
          css={t => ({
            height: 48,
            background: t.palette?.gray?.[50], 
            overflowX: "auto",
          })}
        >
          <FlexRow
            pb={1}
            pl={1}
            pr={10}
            css={t => ({
              width: "auto",
            })}
          >
            {data?._.learning.questions.map((item, key) => {

              const answered = item.participation_answers.length == 1 && item.participation_answers[0].datetime_submit !== null

              return (
                <QuestionAvatar
                  answered={answered}
                  key={`question-list-item-${key}`}
                  selected={item.learning_question_id === questionID}
                  onClick={() => {
                    navigate(
                      `/classroom/assessment/${learningID}/game/question/${item.learning_question_id}`,
                      {
                        replace: true,
                      }
                    )
                  }}
                  label={`${key + 1}`}
                  title={`Soal ${key + 1}`}
                />
              )
            })}
          </FlexRow>
        </FlexRow>
        {data && data._.learning.participations[0].learning_participation_id &&
          <FlexRow
            px={2}
            pb={2}
          >
            <Button
              fullWidth={bp === "mobile" ? true : undefined}
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                setLayout(v => ({
                  ...v,
                  confirmDialog: {
                    ...v.confirmDialog,
                    open: true,
                    status: "info",
                    content: <Flex>
                      Kumpulkan Asesmen ?
                    </Flex>,
                    onClick: () => {
                      submitAssessment({
                        variables: {
                          learning_participation_id: data?._.learning.participations[0].learning_participation_id
                        }
                      }).then(res => {
                        navigate(`/classroom/assessment/${learningID}/game/intro`)
                      })
                    },
                    isCancelable: true, 
                  },
                }))
              }}
            >
              Kumpulkan Asesmen 
            </Button>
          </FlexRow>
        }
      </Flex>  
    </Page>
  )
}

export default ClassroomAssessmentGameAnswer
