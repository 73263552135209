/** @jsxImportSource @emotion/react */

import Backdrop from "../Backdrop"
import { CommonProps, FlexProps } from "../interfaces"
import Paper from "../Paper"

interface DialogProps {
  open: boolean
}

const Dialog = ({children, open, onClick, ...other}: CommonProps & DialogProps & FlexProps) => {

  return (
    <Backdrop 
      onClick={() => {}}
      open={open}
      {...other}
    >
      <Paper
        css={_ => ({
          width: `auto`,
        })}
      >
        {children}
      </Paper>
    </Backdrop>
  )
}

export default Dialog 
