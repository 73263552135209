/** @jsxImportSource @emotion/react */

import axios from "axios"
import { useAtom } from "jotai"
import { useEffect, useState } from "react"
import { useKey, useKeyPress } from "react-use"
import useAuth from "../../../shared/hooks/use-auth"
import layoutAtom from "../../../shared/states/layout"
import { API_URL } from "../../../shared/variables/config"
import { Grading, GradingParticipantAnswer, GradingQuestion, GradingUpdateScore } from "../models/grading"

const useAssessmentGrading = (learningID?: string) => {
  const [, setLayout] = useAtom(layoutAtom)
  const auth = useAuth()
  const [data, setData] = useState<Grading | null>(null)
  const [selectedLearningParticipationID, setSelectedLearningParticipationID] = useState('')
  const [selectedLearningQuestion, setSelectedLearningQuestion] = useState<GradingQuestion | null>(null)
  const [selectedLearningQuestionDescription, setSelectedLearningQuestionDescription] = useState<object | null>(null)
  const [selectedAnswer, setSelectedAnswer] = useState<object | null>(null)
  const [selectedParticipantAnswer, setSelectedParticipantAnswer] = useState<GradingParticipantAnswer | null>(null)
  const [selectedScore, setSelectedScore] = useState<string>('')

  const [hit, setHit] = useState<{
    left: number | null
    right: number | null
    up: number | null
    down: number | null
  }>({
    left: null, right: null, up: null, down: null,
  })

  const goToQuestion = (to: number, d: Grading | null) => {
    if(d === null) return false

    const idx = selectedLearningQuestion ? d.questions.map(v => v.learning_question_id).indexOf(selectedLearningQuestion.learning_question_id) : 0
    if(idx === undefined) return false

    const question = d.questions[idx+to]
    if(question === undefined) return false

    setSelectedLearningQuestion(question)
    if(question.description) {
      setSelectedLearningQuestionDescription(question.description)
    }
  }

  const goToParticipation = (to: number, d: Grading | null) => {
    if(d === null) return false

    const idx = d.participants.map(v => v.learning_participation_id).indexOf(selectedLearningParticipationID)
    if(idx === undefined) return false

    const participant = d.participants[idx+to]
    if(participant === undefined) return false

    setSelectedLearningParticipationID(participant.learning_participation_id)
  }

  useKey('ArrowLeft', () => setHit(v => ({...v, left: new Date().getTime()})))
  useEffect(() => {if(hit.left) goToQuestion(-1, data)}, [hit.left])

  useKey('ArrowRight', () => setHit(v => ({...v, right: new Date().getTime()})))
  useEffect(() => {if(hit.right) goToQuestion(1, data)}, [hit.right])

  useKey('ArrowUp', () => setHit(v => ({...v, up: new Date().getTime()})))
  useEffect(() => {if(hit.up) goToParticipation(-1, data)}, [hit.up])

  useKey('ArrowDown', () => setHit(v => ({...v, down: new Date().getTime()})))
  useEffect(() => {if(hit.down) goToParticipation(1, data)}, [hit.down])

  useEffect(() => {

    const description = data?.questions.find(v => v.learning_question_id === selectedLearningQuestion?.learning_question_id)?.description
    if(description) {
      setSelectedLearningQuestionDescription(description)
    }

    const answer = data?.participant_answers.find(v => {
      return v.learning_question_id === selectedLearningQuestion?.learning_question_id &&
        v.learning_participation_id === selectedLearningParticipationID
    })

    if(answer) {
      setSelectedParticipantAnswer(answer)
      if(answer.score) {
        setSelectedScore(answer.score.toString())
      }
    } else {
      setSelectedParticipantAnswer(null)
      setSelectedScore('')
    }

  }, [selectedLearningParticipationID, selectedLearningQuestion])

  const get = () => {
    if(!learningID) return 

    setLayout(v => ({
      ...v,
      loading: {
        ...v.loading,
        open: true,
      },
    }))

    const authorizationBearer = `Bearer ${auth.getToken()}`
    const url = `${API_URL}/academic/assessment/${learningID}/grading?timestamp=${
            new Date().getTime()}`

    axios.get(
      url,
      {
        headers: {
          "Authorization": authorizationBearer,
        },
        withCredentials: false,
      },
    ).then(response => {

      const grading: Grading = response.data["data"]

      setSelectedLearningParticipationID(grading.participants[0].learning_participation_id)
      setSelectedLearningQuestion(grading.questions[0])

      setData(grading)

    }).catch(e => {
      console.log("error", e)
    }).finally(() => {

      setLayout(v => ({
        ...v,
        loading: {
          ...v.loading,
          open: false,
          message: "",
        },
      }))

    })
  } 

  const put = (score: number) => {
    if(selectedParticipantAnswer && learningID) {
      const payload: GradingUpdateScore = {
        learning_participation_answer_id: selectedParticipantAnswer?.learning_participation_answer_id,
        score: score,
        learning_id: learningID,
        feedback: {},
      }
      console.log("payload", payload)
      setLayout(v => ({
        ...v,
        loading: {
          ...v.loading,
          open: true,
        },
      }))

      const authorizationBearer = `Bearer ${auth.getToken()}`
      const url = `${API_URL}/academic/assessment/${learningID}/grading?timestamp=${
              new Date().getTime()}`

      axios.put(
        url,
        payload,
        {
          headers: {
            "Authorization": authorizationBearer,
          },
          withCredentials: false,
        },
      ).then(response => {

        const grading: Grading = response.data["data"]

        setSelectedLearningParticipationID(grading.participants[0].learning_participation_id)
        setSelectedLearningQuestion(grading.questions[0])

        setData(grading)

      }).catch(e => {
        console.log("error", e)
      }).finally(() => {

        setLayout(v => ({
          ...v,
          loading: {
            ...v.loading,
            open: false,
            message: "",
          },
        }))

      })
    }
  }

  useEffect(() => {
    get()
  }, [learningID])

  return {
    data,
    selectedLearningParticipationID,
    setSelectedLearningParticipationID,
    selectedLearningQuestion,
    setSelectedLearningQuestion,
    selectedLearningQuestionDescription,
    selectedParticipantAnswer,
    setHit,
    hit,
    put,
    selectedScore,
    setSelectedScore,
  }
}

export default useAssessmentGrading
