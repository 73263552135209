/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState } from "react"
import { useMouse } from "react-use"
import ContextMenu from "../ContextMenu"
import Flex from "../IFSuites/Flex"

interface Props {
  menus: JSX.Element[] 
  trigger: (setOpen: (open: boolean) => void) => JSX.Element 
  width?: number
  y?: "top" | "bottom"
  x?: "left" | "right"
  top?: number
  left?: number
}

const ContextMenuWithTrigger = ({trigger, menus, width, top, left}: Props) => {

  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [offsetTop, setOffsetTop] = useState(0)
  // const offsetTop = ref.current ? ref.current.offsetTop : 0
  const offsetRight = ref.current ? ref.current.offsetLeft + ref.current.offsetWidth : 0
  const offsetWidth = width ? width : 300
  const {docY} = useMouse(ref)

  useEffect(() => {
    if(open) {
      setOffsetTop(docY)
    }
  }, [open])

  return (
    <Flex
      w="auto"
      ref={ref}
    >
      {trigger(setOpen)}
      <ContextMenu
        menus={menus}
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        top={top ? top : offsetTop}
        left={left ? left : (offsetRight - offsetWidth)}
        width={offsetWidth}
      />
    </Flex>
  )
}

export default ContextMenuWithTrigger
