/** @jsxImportSource @emotion/react */

import { FaBuilding, FaClock, FaUser } from "react-icons/fa"
import { useNavigate } from "react-router"
import DividerGrow from "../../../../shared/components/IFSuites/Divider/Grow"
import Flex from "../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../shared/components/IFSuites/Flex/Row"
import H3 from "../../../../shared/components/IFSuites/typography/H3"
import H4 from "../../../../shared/components/IFSuites/typography/H4"
import Small from "../../../../shared/components/IFSuites/typography/Small"
import ListItem from "../../../../shared/components/List/Item"
import useAuth from "../../../../shared/hooks/use-auth"
import useBreakpoint from "../../../../shared/hooks/use-breakpoint"

interface ClassroomListItemProps {
  title: string
  classCode: string
  classroomID: string
  facilitator: string 
  date: string
  location: string
  isToday?: boolean
}

const ClassroomListItem = ({
  title,
  classCode,
  facilitator,
  date,
  location,
  isToday,
  classroomID,
}: ClassroomListItemProps) => {
  const navigate = useNavigate()

  const bp = useBreakpoint()

  return (
    <ListItem
      glow={isToday}
      onClick={(_) => navigate(`/classroom/${classroomID}/sessions`)}
    >
        <Flex
          css={t => ({
            flexGrow: 1,
            'small, svg': {
              color: isToday ? t.palette?.blueGray?.[700] : t.palette?.gray?.[600],
            },
            flexDirection: bp === "mobile" ? "column" : "row",
          })}
        >
          <FlexRow ai="center" pr={2}>
            <b>{title}</b>
            <DividerGrow />
            <b>{classCode}</b>
          </FlexRow>
          <FlexRow ai="center">
            {bp === "mobile" ?
              <Small><FaUser /> {facilitator}</Small> :  
              <><FaUser /> {facilitator}</>
            }
          </FlexRow>
          <FlexRow ai="center">
            {bp === "mobile" ?
              <Small><FaClock /> {isToday ? <b>{date}</b> : date}</Small> :  
              <><FaClock /> {isToday ? <b>{date}</b> : date}</>
            }
          </FlexRow>
          <FlexRow ai="center">
            {bp === "mobile" ?
              <Small><FaBuilding /> {location}</Small> :  
              <><FaBuilding /> {location}</>
            }
          </FlexRow>
        </Flex>
        {/* <Flex */}
        {/*   css={t => ({ */}
        {/*     width: 20, */}
        {/*     fontWeight: 700, */}
        {/*     color: `rgba(0, 0, 0, 0.2)`, */}
        {/*     fontSize: `1.5rem`, */}
        {/*   })} */}
        {/* > */}
        {/*   {classCode} */} 
        {/* </Flex> */}
    </ListItem>
  )
}

export default ClassroomListItem
