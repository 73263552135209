export type DefenseCategoryID = (1 | 2 | 3 | 4 | 5)

export const defenseCategoryByID = (id: DefenseCategoryID): {
  label: string 
  abbr: string
  id: number
} => ({
  1: {
    label: 'Munaqasyah',
    abbr: 'Muna.',
    id: 1,
  },
  2: {
    label: 'Kolokium',
    abbr: 'Kolo.',
    id: 2,
  },
  3: {
    label: 'Proposal',
    abbr: 'Prop.',
    id: 3,
  },
  4: {
    label: 'Komprehensif',
    abbr: 'Kompre.',
    id: 4,
  },
  5: {
    label: 'Kerja Praktik',
    abbr: 'K.P.',
    id: 5,
  },
}[id]) 
