/** @jsxImportSource @emotion/react */

import { forwardRef, Ref } from "react";
import FlexRow from "../Flex/Row";
import { CommonProps, FlexProps } from "../interfaces";

export interface ButtonProps {
  variant?: "outlined" | "soft" | "contained" | "text" | "icon",
  // onClick?: (e: MouseEvent) => void,
  fullWidth?: true
  size?: "small" | "medium" | "large"
  color?: "primary" | "secondary" | "error" | "warning" | "info" | "success"
  br?: number
}

export const Button = forwardRef(({children, br, variant, fullWidth, size, color, px, ...other}: CommonProps & ButtonProps & FlexProps, ref: Ref<HTMLDivElement>) => {

  const selectedColor = color !== undefined ? color : "primary" 

  return (
    <FlexRow
      ref={ref}
      jc="center"
      className="Button"
      // onClick={(e) => {
      //   if(onClick) {
      //     onClick(e) 
      //   }
      // }}
      css={t => ({
        width: fullWidth ? `100%` : `auto`,
        border: {
          "icon": `none`,
          "soft": `none`,
          "text": `none`,
          "contained": `none`,
          "outlined": `1px solid ${t.palette?.[selectedColor]?.main}`,
        }[variant !== undefined ? variant : "soft"],
        padding: variant === "icon" ? 4 : t.spacing(1) + (size && size === "large" ? 12 : size && size === "small" ? 0 : 2),
        paddingLeft: variant === "icon" ? 4 : t.spacing(2),
        paddingRight: variant === "icon" ? 4 : t.spacing(2),
        background: {
          "icon": `${t.palette?.[selectedColor]?.light}`,
          "soft": `${t.palette?.[selectedColor]?.light}`,
          "text": t.palette?.[selectedColor]?.main,
          "contained": t.palette?.[selectedColor]?.main,
          "outlined": `${t.palette?.[selectedColor]?.light}`,
        }[variant !== undefined ? variant : "soft"],
        color: {
          "icon": t.palette?.[selectedColor]?.main,
          "soft": t.palette?.[selectedColor]?.main,
          "text": t.palette?.[selectedColor]?.main,
          "contained": t.palette?.[selectedColor]?.contrastText,
          "outlined": t.palette?.[selectedColor]?.main,
        }[variant !== undefined ? variant : "soft"],
        'svg': {
          fill: {
            "icon": t.palette?.[selectedColor]?.main,
            "soft": t.palette?.[selectedColor]?.main,
            "text": t.palette?.[selectedColor]?.main,
            "contained": t.palette?.[selectedColor]?.contrastText,
            "outlined": t.palette?.[selectedColor]?.main,
          }[variant !== undefined ? variant : "soft"],
        },
        borderRadius: br !== undefined ? t.spacing(br) : t.spacing(0.5),
        '&:hover': {
          background: {
            "icon": t.palette?.[selectedColor]?.main,
            "soft": t.palette?.[selectedColor]?.main,
            "text": t.palette?.[selectedColor]?.main,
            "contained": t.palette?.[selectedColor]?.dark,
            "outlined": t.palette?.[selectedColor]?.main,
          }[variant !== undefined ? variant : "soft"],
          '*': {
            color: {
              "icon": t.palette?.[selectedColor]?.contrastText,
              "soft": t.palette?.[selectedColor]?.contrastText,
              "text": t.palette?.[selectedColor]?.main,
              "contained": t.palette?.[selectedColor]?.contrastText,
              "outlined": t.palette?.[selectedColor]?.contrastText,
            }[variant !== undefined ? variant : "soft"],
            'svg': {
              fill: {
                "icon": t.palette?.[selectedColor]?.contrastText,
                "soft": t.palette?.[selectedColor]?.contrastText,
                "text": t.palette?.[selectedColor]?.main,
                "contained": t.palette?.[selectedColor]?.contrastText,
                "outlined": t.palette?.[selectedColor]?.contrastText,
              }[variant !== undefined ? variant : "soft"],
            }
          },
          color: {
            "icon": t.palette?.[selectedColor]?.contrastText,
            "soft": t.palette?.[selectedColor]?.contrastText,
            "text": t.palette?.[selectedColor]?.main,
            "contained": t.palette?.[selectedColor]?.contrastText,
            "outlined": t.palette?.[selectedColor]?.contrastText,
          }[variant !== undefined ? variant : "soft"],
          'svg': {
            fill: {
              "icon": t.palette?.[selectedColor]?.contrastText,
              "soft": t.palette?.[selectedColor]?.contrastText,
              "text": t.palette?.[selectedColor]?.main,
              "contained": t.palette?.[selectedColor]?.contrastText,
              "outlined": t.palette?.[selectedColor]?.contrastText,
            }[variant !== undefined ? variant : "soft"],
          }
        },
        transition: `.4s`,
        fontWeight: 700,
        cursor: `pointer`,
        fontSize: size === "small" ? `0.75rem` : "medium" ? `1rem` : `1rem`,
        gap: t.spacing(1),
        whiteSpace: "nowrap",
      })}      
      {...other}
    >
      {children}
    </FlexRow>
  )
})
