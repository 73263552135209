/** @jsxImportSource @emotion/react */

import { ReactNode, } from "react"
import { FaTimes } from "react-icons/fa"
import Dialog from ".."
import { Button } from "../../Button"
import Flex from "../../Flex"
import FlexRow from "../../Flex/Row"
import Paper from "../../Paper"

interface Props {
  open: boolean
  onClose: () => void
  children: ReactNode
  title: ReactNode
}

const DialogSimpleContent = ({
  title,
  open,
  onClose,
  children,
}: Props) => {

  return (
    <Dialog
      open={open}
    >
      <Paper>
        <FlexRow
          p={2}
          jc="space-between"
          css={t => ({
            borderBottom: `1px solid ${t.palette?.gray?.[200]}`
          })}
        >
          {title}
          <Button
            color="info"
            variant="icon"
            onClick={() => {
              onClose()
            }}
          >
            <FaTimes
            />
          </Button>
        </FlexRow>
        <Flex>
          {children} 
        </Flex>
      </Paper> 
    </Dialog>
  )
}

export default DialogSimpleContent
