/** @jsxImportSource @emotion/react */

import { forwardRef, Ref } from "react"
import Flex from ".."
import { CommonProps, FlexProps } from "../../interfaces"

const FlexRow = forwardRef(({jc, ai, children, gap, w, m, mx, my, mb, mt, ml, mr, p, px, py, pl, pr, pt, pb, ...other}: CommonProps & FlexProps, ref: Ref<HTMLDivElement>) =>{
  return (
    <Flex
      ref={ref}
      className="FlexRow"
      css={t => ({
        width: w,
        flexDirection: `row`,
        alignItems: ai === undefined ? "center" : ai,
        justifyContent: jc === undefined ? "flex-start" : jc,
        gap: gap === undefined ? t.spacing(1) : t.spacing(gap),
        paddingTop: pt ? t.spacing(pt) : py ? t.spacing(py) : p ? t.spacing(p) : 0, 
        paddingBottom: pb ? t.spacing(pb) : py ? t.spacing(py) : p ? t.spacing(p) : 0, 
        paddingLeft: pl ? t.spacing(pl) : px ? t.spacing(px) : p ? t.spacing(p) : 0, 
        paddingRight: pr ? t.spacing(pr) : px ? t.spacing(px) : p ? t.spacing(p) : 0, 
        marginTop: mt ? t.spacing(mt) : my ? t.spacing(my) : m ? t.spacing(m) : 0, 
        marginBottom: mb ? t.spacing(mb) : my ? t.spacing(my) : m ? t.spacing(m) : 0, 
        marginLeft: ml ? t.spacing(ml) : mx ? t.spacing(mx) : m ? t.spacing(m) : 0, 
        marginRight: mr ? t.spacing(mr) : mx ? t.spacing(mx) : m ? t.spacing(m) : 0, 
      })}
      {...other}
    >
      {children}
    </Flex>
  )
})

export default FlexRow
