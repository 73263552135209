/** @jsxImportSource @emotion/react */

import { FaCalendar, FaGoogleDrive, FaHammer } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import Flex from "../../../shared/components/IFSuites/Flex"
import Small from "../../../shared/components/IFSuites/typography/Small"
import List from "../../../shared/components/List"
import ListItem from "../../../shared/components/List/Item"
import Page from "../../../shared/components/Page"
import useAuth from "../../../shared/hooks/use-auth"

const DefenseHome = () => {

  const auth = useAuth()
  const authInfo = auth.getInfo() 
  const navigate = useNavigate()

  return (
    <Page
      title="Sidang"
    >
      <List>
        {[
          {
            title: "Persyaratan Seminar dan Sidang",
            description: null,
            onClick: () => {
              // window.open('http://www.example.com?ReportID=1', '_blank');
              window.open("https://docs.google.com/presentation/d/1Uef6nc0Sy-ytT1t_cNZwi0f0OFnyVxmPMoKjIryCIQ8/edit#slide=id.g1ac4387964e_0_0");
            },
            displayed: auth.isStudent(), 
            icon: <FaGoogleDrive />,
          },
          {
            title: "Form Seminar dan Sidang",
            description: "Proposal, Kolokium, Munaqasyah, Komprehensif, Kerja Praktik, Ibtitah",
            onClick: () => {
              window.open("https://docs.google.com/forms/d/e/1FAIpQLSebSnudTMdTkBr3FZikhStweB5f7WQnGpUaZMYn3oJ_2F8BBg/viewform");
            },
            displayed: auth.isStudent(), 
            icon: <FaGoogleDrive />,
          },
          {
            title: "Form Plagiarism Checker untuk Kolokium dan Munaqasyah",
            description: "Pengumpulan nilai seminar dan sidang untuk dosen penguji atau pembimbing",
            onClick: () => {
              window.open("https://docs.google.com/forms/d/e/1FAIpQLSebSnudTMdTkBr3FZikhStweB5f7WQnGpUaZMYn3oJ_2F8BBg/viewform");
            },
            displayed: auth.isStudent(), 
            icon: <FaGoogleDrive />,
          },
          {
            title: "Jadwal Sidang",
            description: "Jadwal sidang yang akan datang dan arsip sidang yang telah selesai",
            onClick: () => {
              navigate(`/defense/schedule`)
            },
            displayed: auth.isLecturer(), 
            icon: <FaCalendar />,
          },
          {
            title: "Pengumpulan Nilai Seminar dan Sidang",
            description: "Pengumpulan nilai seminar dan sidang untuk dosen penguji atau pembimbing",
            onClick: () => {
              window.open("https://docs.google.com/forms/d/e/1FAIpQLScFIU7-92zVGCi3Z2ZY06bHkw8LhfwJUEbFcP4m_Hrzk-E3ew/viewform");
            },
            displayed: auth.isLecturer(), 
            icon: <FaGoogleDrive />,
          },
          {
            title: "Kelola Pelaksanaan Sidang",
            description: "Pengelolaan pelaksanaan sidang. Penentuan dan perubahan penguji, jadwal, dan tempat.",
            onClick: () => {
              navigate('/defense/manage/1', {replace: false})
            },
            displayed: authInfo && authInfo.roles.includes("programmeadmin"), 
            icon: <FaHammer />,
          },
        ].filter(v => v.displayed).map((item, key) => (

          <ListItem
            key={`list-item-${key}`}
            onClick={item.onClick}
          >
            {item.icon}
            <Flex>
              {item.title}
              {item.description !== null && <Small>{item.description}</Small>}
            </Flex>
          </ListItem>
        ))} 
      </List>
    </Page>
  )
} 

export default DefenseHome
