/** @jsxImportSource @emotion/react */

import { keyframes } from "@emotion/react"
import { useAtom } from "jotai"
import layoutAtom from "../../states/layout"
import Flex from "../IFSuites/Flex"
import FlexRow from "../IFSuites/Flex/Row"

const glowKeyframe = keyframes`
  from {
    background: #FFDA4FDD;
  }

  50% {
    background: #FFEEB4DD;
  }

  to {
    background: #FFDA4FDD;
  }
`

const SimpleLoading = () => {

  const [layout,] = useAtom(layoutAtom)

  return (
    layout.loading.open ? <Flex
      ai="start"
      jc="flex-end"
      css={t => ({
        width: `100%`,
        height: `100%`,
        position: "fixed",
        zIndex: 9,
        top: 0,
        pointerEvents: "none",
      })}
    >
      <FlexRow
        p={1}
        css={t => ({
          animation: `${glowKeyframe} 3s ease infinite`,
          transition: ".3s",
          background: t.palette?.gray?.[400],
          color: `rgba(0, 0, 0, 0.4)`,
        })}
      >
        {layout.loading.message ? layout.loading.message : "Komunikasi ke server ..."}
      </FlexRow>
    </Flex> : null
  )
}

export default SimpleLoading
