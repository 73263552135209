/** @jsxImportSource @emotion/react */

import axios from "axios"
import { useAtom } from "jotai"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Flex from "../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import useAuth from "../../../shared/hooks/use-auth"
import layoutAtom from "../../../shared/states/layout"
import { API_URL } from "../../../shared/variables/config"
import { Player, PlayerWS } from "../models"

const usePlayerLogAs = () => {

  const navigate = useNavigate()

  const auth = useAuth()
  const authorizationBearer = `Bearer ${auth.getAdminToken()}`

  const [layout, setLayout] = useAtom(layoutAtom)
  const [playerList, setPlayerList] = useState<PlayerWS[]>([])

  // Find player by keywords
  const find = (keywords: string) => {

    if(keywords.length < 3) {

      setPlayerList([])

      setLayout(v => ({
        ...v,
        confirmDialog: {
          ...v.confirmDialog,        
          open: true,
          content: "Masukkan kata kunci terlebih dahulu (minimal 3 huruf)",
        },
      }))

      return 
    }

    setLayout(v => ({
      ...v,
      loading: {
        ...v.loading,
        open: true,
      },
    }))

    const url = `${API_URL}/player?q=${keywords}`
    axios.get(
      url,
      {
        headers: {
          "Authorization": authorizationBearer,
        },
        withCredentials: false,
      },
    ).then(response => {
      const data: PlayerWS[] = response.data["data"]

      console.log("find player")
      setPlayerList(data)
    }).catch(e => {
      console.log("error", e)
    }).finally(() => {

      setLayout(v => ({
        ...v,
        loading: {
          ...v.loading,
          open: false,
          message: "",
        },
      }))

    })
  }

  // Log as
  const logAs = (playerID: string) => {
    setLayout(v => ({
      ...v,
      loading: {
        ...v.loading,
        open: true,
      },
    }))

    const url = `${API_URL}/player/auth/login-as-token`

    axios.post(
      url,
      {
        player_id: playerID,
      },
      {
        headers: {
          "Authorization": authorizationBearer,
        },
        withCredentials: false,
      },
    ).then(response => {

      if(response.status === 200) {
        const data = response.data["data"]
        console.log("login as token issuer result", data)

        const accessToken = response.data["access_token"]
        const refreshToken = response.data["refresh_token"]
        const tokenExp = response.data["exp"]

        const parsedToken = auth.parseJwt(accessToken) 
        const claims = parsedToken["claims"] 
        const hasuraClaims = parsedToken["https://hasura.io/jwt/claims"]

        auth.setInfo(
          accessToken,
          refreshToken,
          tokenExp,
          {
            fullName: claims["fullname"],
            roles: claims["roles"],
            playerUUID: claims["player_uuid"],
            username: claims['username'],
            nickname: claims['nickname'],

            nip: claims['nip'],
            staffID: claims['staff_id'],
            lecturerID: claims['lecturer_id'],
            nim: claims['nim'],
            studentID: claims['student_id'],
            programmeID: claims['programme_id'],
          },
        )

        if(claims["roles"].includes("lecturer")) {
          localStorage.setItem("hasuraDefaultRole", "lecturer")
          localStorage.setItem("hasuraCurrentRole", "lecturer")
        }

        if(claims["roles"].includes("student")) {
          localStorage.setItem("hasuraDefaultRole", "student")
          localStorage.setItem("hasuraCurrentRole", "student")
        }

        setLayout(v => ({
          ...v,
          confirmDialog: {
            ...v.confirmDialog,        
            open: true,
            content: <Flex>
              Login sebagai <b>{claims["fullname"]}</b>
            </Flex>,
            onClick: () => {
              navigate("/player/dashboard")
            },
          },
        }))
      }
    }).catch(e => {
      console.log("error", e)
    }).finally(() => {

      setLayout(v => ({
        ...v,
        loading: {
          ...v.loading,
          open: false,
          message: "",
        },
      }))

    })

  }

  return { players: playerList, find, logAs }
}

export default usePlayerLogAs
