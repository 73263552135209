/** @jsxImportSource @emotion/react */
import { gql, useQuery } from "@apollo/client"
import { FaShareAlt } from "react-icons/fa"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import IconLoading from "../../../shared/components/IconLoading"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import Small from "../../../shared/components/IFSuites/typography/Small"
import ListItem from "../../../shared/components/List/Item"
import Page from "../../../shared/components/Page"
import useAuth from "../../../shared/hooks/use-auth"
import ClassroomAssessmentHome from "../Assessment/Home"
import ClassroomDetail from "../Detail"
import ClassroomGrade from "../Grade/v2"
import ClassroomMeetingList from "../Meeting/List"
import ClassroomTab, { useClassroomTab } from "../Tab"
import { ClassroomDetailQ } from "./models"

const ClassroomHome = () => {

  // navigate(`/classroom/meeting/${classroomItemNavigation.next}`, {replace: true})

  const { classroomID } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const { tab } = useClassroomTab()

  const auth = useAuth()

  const GET_CLASSROOM_DETAIL = gql`
    query GetClassroomByPK($classroom_id: uuid = "") {
      _: academic_classroom_by_pk(classroom_id: $classroom_id) {
        classroom_id
        code
        schedules {
          day_number
          time_start
          time_end
          place {
            title
            place_id
          }
        }
        cys {
          details
          course {
            code
            title
            credit
            description
          }
          clos {
            clo {
              code
              description
              plo {
                code
                description
                plo_id
              }
              clo_id
            }
            questions: learning_questions_aggregate(where: {learning: {classroom_item: {classroom_id: {_eq: $classroom_id}}}, question_type_id: {_in: [2, 3, 4]}}) {
              aggregate {
                count
              }
            }
          }
          year_semester {
            teaching_weeks_start_date
          }
        }
        facilitator_enrollments {
          lecturer {
            staff {
              full_name
            }
          }
        }
        items(order_by: {item_order: asc}) {
          classroom_item_id
          classroom_item_type_id
          title
          item_order
          total_assessments: learnings_aggregate(where: {learning_template_id: {_eq: "2"}}) {
            aggregate {
              count
            }
          }
          total_materials: learnings_aggregate(where: {learning_template_id: {_eq: "3"}}) {
            aggregate {
              count
            }
          }
        }
      }
    }
  `

  const classroomDetailQ = useQuery<ClassroomDetailQ>(
    GET_CLASSROOM_DETAIL, {
    variables: {
      classroom_id: classroomID,
    }
  })

  return (
    <Page
      title={
        <FlexRow
          jc="center"
        >
          {classroomDetailQ.data ?
            `${classroomDetailQ.data?._.cys.course.title} - ${classroomDetailQ.data?._.code}`
            : <IconLoading />
          }
        </FlexRow>
      }
      menus={[
        <ListItem
          key={`nav-menu-0`}
          onClick={() => {
            console.log("location", location)
            navigate(`/share?path=${location.pathname}`, {state: {path: location.pathname}}) 
          }}
        >
          <FaShareAlt /><Small>Share</Small> 
        </ListItem>
      ]}
    >
      <ClassroomTab />
      {tab === "sessions" &&
        <ClassroomMeetingList data={classroomDetailQ.data} /> 
      }
      {tab === "assessments" &&
        <ClassroomAssessmentHome data={classroomDetailQ.data} /> 
      }
      {tab === "detail" &&
        <ClassroomDetail data={classroomDetailQ.data} /> 
      }
      {auth.isLecturer() && tab === "grade" &&
        <ClassroomGrade data={classroomDetailQ.data} /> 
      }
    </Page>
  )
}

export default ClassroomHome
