/** @jsxImportSource @emotion/react */

import { ReactNode } from "react"
import { BsTerminalFill } from "react-icons/bs"
import { FaChartLine, FaCode, FaLanguage, FaMobileAlt, FaRobot, FaUnity } from "react-icons/fa"
import { MdAdsClick, MdDesignServices, MdPsychology, MdSecurity } from "react-icons/md"
import { SiDrone, SiKubernetes, SiTypescript } from "react-icons/si"
import { useNavigate } from "react-router-dom"
import GridIconLayout from "../../../shared/components/Grid/Icon/Layout"
import Page from "../../../shared/components/Page"

const CareerSkill = () => {

  const navigate = useNavigate()

  return (
    <Page
      title={`Skill Dunia Kerja & Usaha`}
    >
      <GridIconLayout
        items={[
          {
            title: "Coding",
            icon: <FaCode size={24} />, 
            onClick: () => {},
          },
          {
            title: "Frontend",
            icon: <SiTypescript size={24} />, 
            onClick: () => {},
          },
          {
            title: "Backend",
            icon: <BsTerminalFill size={24} />, 
            onClick: () => {},
          },
          {
            title: "Data",
            icon: <FaChartLine size={24} />, 
            onClick: () => {},
          },
          {
            title: "Mobile",
            icon: <FaMobileAlt size={24} />, 
            onClick: () => {},
          },
          {
            title: "AI",
            icon: <FaRobot size={24} />, 
            onClick: () => {},
          },
          {
            title: "DevOps",
            icon: <SiKubernetes size={24} />, 
            onClick: () => {},
          },
          {
            title: "Security",
            icon: <MdSecurity size={24} />, 
            onClick: () => {},
          },
          {
            title: "UX / UI",
            icon: <MdDesignServices size={24} />, 
            onClick: () => {},
          },
          {
            title: "Multimedia",
            icon: <FaUnity size={24} />, 
            onClick: () => {},
          },
          {
            title: "IoT",
            icon: <SiDrone size={24} />, 
            onClick: () => {},
          },
          {
            title: "Marketing",
            icon: <MdAdsClick size={24} />, 
            onClick: () => {},
          },
          {
            title: "Bahasa",
            icon: <FaLanguage size={24} />, 
            onClick: () => {},
          },
          {
            title: "SoftSkill",
            icon: <MdPsychology size={24} />, 
            onClick: () => {},
          },
        ]}
      />
    </Page>
  )
}

export default CareerSkill 
