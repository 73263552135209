/** @jsxImportSource @emotion/react */

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import dayjs from "dayjs"
import { useAtom } from "jotai"
import _ from "lodash"
import { useEffect, useState } from "react"
import { FaPlus } from "react-icons/fa"
import { GiBowman } from "react-icons/gi"
import { useNavigate, useParams } from "react-router-dom"
import { useList } from "react-use"
import { simpleTextEditorState } from "../../../../shared/components/Editor/MGLexical/App"
import NormalEditor from "../../../../shared/components/Editor/MGLexical/NormalEditor"
import { Button } from "../../../../shared/components/IFSuites/Button"
import Dialog from "../../../../shared/components/IFSuites/Dialog"
import DividerGrow from "../../../../shared/components/IFSuites/Divider/Grow"
import Flex from "../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../shared/components/IFSuites/Flex/Row"
import InputText from "../../../../shared/components/IFSuites/forms/InputText"
import Select from "../../../../shared/components/IFSuites/forms/Select"
import Paper from "../../../../shared/components/IFSuites/Paper"
import Small from "../../../../shared/components/IFSuites/typography/Small"
import List from "../../../../shared/components/List"
import ListItem from "../../../../shared/components/List/Item"
import Page from "../../../../shared/components/Page"
import useBreakpoint from "../../../../shared/hooks/use-breakpoint"
import useLayout from "../../../../shared/hooks/use-layout"
import { LearningQuestionWS } from "../../models/learning"
import { learningCreateAtom } from "../../states/learning-create"
import { initQuestionCreate } from "../Add/states"
import { questionTypeByID } from "../utils"
import DialogDuplicateAssessment from "./Dialog/DuplicateAssessment"

interface CreationConfigQ {
  _: {
    classroom_item_id: string 
    title: string
    classroom_id: string
    classroom: {
      code: string
      cys: {
        clos: {
          clo: {
            clo_id: string
            code: string
            description: string
            plo: {
              code: string
              plo_id: string
              description: string
            }
          }
          course_year_sem_clo_id: string
        }[]
        course: {
          code: string
          title: string
        }
        year_semester: {
          academic_year: number
          academic_semester: number
        }
      }
      student_enrollments: {
        student_enrollment_id: string
        student: {
          nim: string
          full_name: string
        }
      }[]
    }
  }[]
  grading_comp: {
    grading_comp_id: number 
    title: string
  }[]
  question_type: {
    question_type_id: number 
    title: string
  }[]
  academic_learning_by_pk?: {
    title: string
    datetime_end: string
    datetime_start: string
    grading_comp_id: number
    learning_id: string
    learning_template_id: number
    questions: {
      course_year_sem_clo_id: string
      description: object 
      item_order: number
      clo: {
        clo_id: string
        clo: {
          code: string
          description: string
        }
      }
      learning_question_id: string
      score_weight: number
      question_type_id: number 
    }[]
  }
}

const initWizardDialog = {
  selectCLO: {
    open: false,
  },
  selectQuestionType: {
    open: false,
  },
  duplicateAssessment: {
    open: false,
  },
}

const currentTime = dayjs()
const currentTimePlus = dayjs().add(15, 'minute')

const hourOptions = ((new Array(24).fill(0)) as number[]).map((_, a) => ({
  label: (a).toString().padStart(2, '0'),
  value: (a).toString(),
}))

const minuteOptions = ((new Array(60).fill(0)) as number[]).map((_, a) => ({
  label: (a).toString().padStart(2, '0'),
  value: (a).toString(),
}))

const initTimeRange = {
  start: {
    date: currentTime.format("YYYY-MM-DD"),
    hour: currentTime.hour(),
    minute: currentTime.minute(),
    datetimeString: `${currentTime.format("YYYY-MM-DD")}T${String(currentTime.hour()).padStart(2, '0')}:${String(currentTime.minute()).padStart(2, '0')}:00`,
  },
  end: {
    date: dayjs().add(15, 'minute').format("YYYY-MM-DD"),
    hour: dayjs().add(15, 'minute').hour(),
    minute: dayjs().add(15, 'minute').minute(),
    datetimeString: `${currentTimePlus.format("YYYY-MM-DD")}T${String(currentTimePlus.hour()).padStart(2, '0')}:${String(currentTimePlus.minute()).padStart(2, '0')}:00`,
  },
}

const ClassroomAssessmentCreate = () => {

  const {classroomItemID, learningID} = useParams()
  const mode: "create" | "edit" | null = classroomItemID ? "create" : learningID ? "edit" : null 
  const [learningCreate, setLearningCreate] = useAtom(learningCreateAtom)
  const [questionList, questionOps] = useList<LearningQuestionWS>([])
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number | null>(null)
  const layout = useLayout()
  const navigate = useNavigate()

  const bp = useBreakpoint()
  const [dialogConfig, setDialogConfig] = useState<{
    selectCLO: {
      open: boolean
    }
    selectQuestionType: {
      open: boolean
    }
    duplicateAssessment: {
      open: boolean
    }
  }>(initWizardDialog)

  const [ timeRange, setTimeRange ] = useState(initTimeRange)

  const datetimeStringStart = () => `${timeRange.start.date}T${String(timeRange.start.hour).padStart(2, '0')}:${String(timeRange.start.minute).padStart(2, '0')}:00`
  const datetimeStringEnd = () => `${timeRange.end.date}T${String(timeRange.end.hour).padStart(2, '0')}:${String(timeRange.end.minute).padStart(2, '0')}:00`

  useEffect(() => {

    console.log("time range changed", timeRange.start.date, datetimeStringStart(), timeRange.end.date, datetimeStringEnd())
    
    setLearningCreate(v => ({
      ...v,
      datetime_start: datetimeStringStart(), 
      datetime_end: datetimeStringEnd(),
    }))

    // console.log("datetime_start", datetimeStringStart())
    // console.log("datetime_end", datetimeStringEnd())

  }, [timeRange])

  const GET_CREATION_CONFIG = gql`
  query GetAssessmentCreateConfigs($classroom_item_id: uuid = "") {
    _: academic_classroom_item(where: {classroom_item_id: {_eq: $classroom_item_id}}) {
      classroom_item_id
      title
      classroom_id
      classroom {
        cys {
          clos {
            clo {
              code
              description
              plo {
                code
                plo_id
                description
              }
              clo_id
            }
            course_year_sem_clo_id
          }
          course {
            code
            title
          }
          year_semester {
            academic_year
            academic_semester
          }
        }
        code
        student_enrollments(where: {is_active: {_eq: "1"}}) {
          student_enrollment_id
          student {
            nim
            full_name
          }
        }
      }
    }
    grading_comp: academic_ref_grading_comp(where: {grading_comp_id: {_gte: "1"}}) {
      grading_comp_id
      title
    }
    question_type: academic_ref_question_type {
      question_type_id
      title
    }
  }
  `

  const GET_UPDATE_CONFIG = gql`
query GetAssessmentUpdateConfigs($learning_id: uuid = "") {
  _: academic_classroom_item(where: {learnings: {learning_id: {_eq: $learning_id}}}) {
    classroom_item_id
    title
    classroom_id
    classroom {
      cys {
        clos {
          clo {
            code
            description
            plo {
              code
              plo_id
              description
            }
            clo_id
          }
          course_year_sem_clo_id
        }
        course {
          code
          title
        }
        year_semester {
          academic_year
          academic_semester
        }
      }
      code
      student_enrollments(where: {is_active: {_eq: "1"}}) {
        student_enrollment_id
        student {
          nim
          full_name
        }
      }
    }
  }
  grading_comp: academic_ref_grading_comp(where: {grading_comp_id: {_gte: "1"}}) {
    grading_comp_id
    title
  }
  question_type: academic_ref_question_type {
    question_type_id
    title
  }
  academic_learning_by_pk(learning_id: $learning_id) {
    title
    datetime_end
    datetime_start
    grading_comp_id
    learning_id
    learning_template_id
    questions {
      course_year_sem_clo_id
      description
      item_order
      clo {
        clo_id
        clo {
          code
          description
        }
      }
      learning_question_id
      score_weight
      question_type_id
    }
  }
}
  `

  const getDataVariable = mode === "create" ? {
    classroom_item_id: classroomItemID,
  } : {
    learning_id: learningID,
  }

  const [getData, queryResult] = useLazyQuery<CreationConfigQ>(
    mode === "create" ? GET_CREATION_CONFIG : GET_UPDATE_CONFIG,
    {
      fetchPolicy: "network-only",
      variables: getDataVariable, 
    }
  )

  const INSERT_ASSESSMENT = gql`
    mutation InsertLearning(
      $attachments: jsonb = [],
      $classroom_item_id: uuid!,
      $datetime_end: timestamp!,
      $datetime_start: timestamp!,
      $description: jsonb,
      $grading_comp_id: smallint!,
      $learning_template_id: smallint!,
      $title: String!,
      $questions: [academic_learning_question_insert_input!]!,
      $participations: [academic_learning_participation_insert_input!]!,
    ) {
      insert_academic_learning_one(
        object: {
          attachments: $attachments,
          classroom_item_id: $classroom_item_id,
          datetime_end: $datetime_end,
          datetime_start: $datetime_start,
          description: $description,
          grading_comp_id: $grading_comp_id,
          learning_template_id: $learning_template_id,
          title: $title,
          questions: {data: $questions},
          participations: {data: $participations}
        }
      ) {
        learning_id
        participations {
          learning_participation_id
          student_enrollment {
            student {
              nim
              full_name
            }
          }
        }
        questions {
          learning_id
          learning_question_id
          question_type_id
        }
      }
    }
  `

  const [insert, insertOps] = useMutation(
    INSERT_ASSESSMENT, 
  )

  const UPDATE_ASSESSMENT = gql`
    mutation UpdateLearning(
      $learning_id: uuid = "",
      $attachments: jsonb = [],
      $datetime_end: timestamp!,
      $datetime_start: timestamp!,
      $description: jsonb,
      $grading_comp_id: smallint!,
      $title: String!,
    ) {
      update_academic_learning_by_pk(
        pk_columns: {learning_id: $learning_id}, 
        _set: {
          attachments: $attachments,
          datetime_end: $datetime_end,
          datetime_start: $datetime_start,
          description: $description,
          grading_comp_id: $grading_comp_id,
          title: $title,
        }) {
        learning_id
      }
    }
  `

  const [update, updateOps] = useMutation(
    UPDATE_ASSESSMENT, 
  )

  // const GET_ASSESSMENT = gql`
  // `
  useEffect(() => {

    getData()

  }, [mode])

  useEffect(() => {

    if(queryResult.data && queryResult.data._.length > 0) {

      const classroomData = queryResult.data._[0]
      const learningData = queryResult.data?.academic_learning_by_pk
      const gradingComponent = queryResult.data?.grading_comp.find(v => v.grading_comp_id === learningCreate.grading_comp_id)
      const gradingComponentName = gradingComponent ? gradingComponent.title : "Asesmen"

      const titleForUpdate = queryResult.data.academic_learning_by_pk ? queryResult.data.academic_learning_by_pk?.title : ''

      console.log("learningData", learningData?.datetime_end, dayjs(learningData?.datetime_end).format())

      if(mode === "edit" && learningData && learningData.questions) {
        learningData?.questions.forEach(w => {
          questionOps.push({
            description: w.description,
            attachments: [], 
            score_weight: w.score_weight,
            question_type_id: w.question_type_id,
            answer_choices: [],
            true_values: [],
            course_year_sem_clo_id: w.course_year_sem_clo_id, 
            clo_code: w.clo.clo.code,
            item_order: w.item_order,
          }) 
        })

        const startTime = dayjs(learningData.datetime_start)
        const endTime = dayjs(learningData.datetime_end)

        console.log("Edit setTimeRange start", startTime, startTime.format(), startTime.get("hours"), startTime.get("minute"))
        console.log("Edit setTimeRange end", endTime, endTime.format(), endTime.get("hours"), endTime.get("minute"))

        setTimeRange(v => {
          const newTimeRange = {
            ...v,
            start: {...v.start, datetimeString: startTime.format(), date: startTime.format("YYYY-MM-DD"), hour: startTime.get("hours"), minute: startTime.get("minute")},
            end: {...v.end, datetimeString: endTime.format(), date: endTime.format("YYYY-MM-DD"), hour: endTime.get("hours"), minute: endTime.get("minute")},
          }
          console.log("newTimeRange", endTime.format(), newTimeRange)
          return newTimeRange
        })
        
        // setTimeRange(v => ({...v, start: {...v.start, datetimeString: startTime.format(), hour: startTime.get("hours"), minute: startTime.get("minute")}}))
        // setTimeRange(v => ({...v, end: {...v.end, endimeString: endTime.format(), hour: endTime.get("hours"), minute: endTime.get("minute")}}))
      }

      if((classroomData.classroom_item_id !== null) || classroomItemID !== null) {
        setLearningCreate(v => ({
          ...v,
          title: mode === "create" ? 
            `${gradingComponentName} ${classroomData.classroom.cys.course.title} - ${classroomData.classroom.code} - ${classroomData.title}` :
            titleForUpdate,
          grading_comp_id: mode === "create" ? v.grading_comp_id : (learningData?.grading_comp_id || 0),  
          classroom_item_id: mode === "create" ? v.classroom_item_id : classroomData.classroom_item_id, 
          datetime_start: mode === "create" ? v.datetime_start : (learningData?.datetime_start || null),
          datetime_end: mode === "create" ? v.datetime_end : (learningData?.datetime_end || null),
          questions: mode === "create" ? v.questions : (mode === "edit" && learningData && learningData.questions) ? learningData?.questions.map(w => {
            return {
              description: w.description,
              attachments: [], 
              score_weight: w.score_weight,
              question_type_id: w.question_type_id,
              answer_choices: [],
              true_values: [],
              course_year_sem_clo_id: w.course_year_sem_clo_id, 
              clo_code: w.clo.clo.code,
              item_order: w.item_order,
            } 
          }) : v.questions, 
        }))
      }
    }

  }, [queryResult.data])

  return (
    <Page
      title={<FlexRow jc="center" ai="center">
        <GiBowman /> {mode === "create" ? `Buat` : mode === "edit" ? `Perbarui` : null} Asesmen
      </FlexRow>}
      rightElement={
        <Flex
          w={"auto"}
          pr={2}
        >
          <Button
            br={0.5}
            variant={"contained"}
            color={"secondary"}
            size="small"
            onClick={() => {

              if(queryResult.data && queryResult.data._.length > 0) {
                if(mode === "create") {
                  layout.confirmCancelable({
                    content: `Publikasikan asesmen ini ?`,
                    onClick: () => {

                      layout.loadingShow("Mempublikasikan asesmen . . .")

                      //const datetimeStartUTCString = dayjs(learningCreate.datetime_start).utc().format()
                      //const datetimeEndUTCString = dayjs(learningCreate.datetime_end).utc().format()
                      const datetimeStartUTCString = dayjs(learningCreate.datetime_start).format()
                      const datetimeEndUTCString = dayjs(learningCreate.datetime_end).format()

                      const insertPayload = {
                        variables: {
                          learning_template_id: 2,
                          title: learningCreate.title,
                          datetime_start: datetimeStartUTCString,
                          datetime_end: datetimeEndUTCString,
                          classroom_item_id: classroomItemID,
                          grading_comp_id: learningCreate.grading_comp_id,
                          questions: questionList.map((v, k) => ({
                            item_order: k,
                            description: v.description,
                            score_weight: v.score_weight,
                            question_type_id: v.question_type_id,
                            course_year_sem_clo_id: queryResult.data?._[0].classroom.cys.clos.find(w => w.clo.code === v.clo_code)?.course_year_sem_clo_id,
                          })),
                          participations: queryResult.data?._[0].classroom.student_enrollments.map(w => ({
                            student_enrollment_id: w.student_enrollment_id,
                          })),
                        }
                      }

                      insert(insertPayload).then(_ => {
                        layout.loadingHide()
                        layout.confirm({
                          content: `Asesmen berhasil dibuat`,
                          onClick: () => {
                            navigate(-1)
                          }
                        })
                      })
                    }
                  })
                }

                if(mode === "edit") {
                  layout.confirmCancelable({
                    content: `Perbarui asesmen ini ?`,
                    onClick: () => {

                      layout.loadingShow("Memperbarui asesmen . . .")

                      //const datetimeStartUTCString = dayjs(learningCreate.datetime_start).utc().format()
                      //const datetimeEndUTCString = dayjs(learningCreate.datetime_end).utc().format()
                      const datetimeStartUTCString = dayjs(learningCreate.datetime_start).format()
                      const datetimeEndUTCString = dayjs(learningCreate.datetime_end).format()

                      const updatePayload = {
                        variables: {
                          learning_id: learningID,
                          title: learningCreate.title,
                          datetime_start: datetimeStartUTCString,
                          datetime_end: datetimeEndUTCString,
                          grading_comp_id: learningCreate.grading_comp_id,
                        }
                      }

                      layout.loadingHide()
                      update(updatePayload).then(_ => {
                        layout.loadingHide()
                        layout.confirm({
                          content: `Asesmen berhasil diperbarui`,
                          onClick: () => {
                            navigate(-1)
                          }
                        })
                      })
                    }
                  })
                }

              }


            }}
          >
            {mode === "edit" ? "Simpan" : "Buat"}
          </Button>
        </Flex>
      }
    >
      <Flex
        gap={2}
        pb={2}
      >
        <Paper
          br={0}
        >
          <Flex 
            p={2}
            css={t => ({
              flexDirection: bp === "mobile" ? "column" : "row",
            })}
          >
            <InputText
              label="Judul asesmen"
              value={learningCreate.title}
              onChange={e => {
                setLearningCreate(v => ({...v, title: e.target.value}))
              }}
              fullWidth
            />
            <Select
              label="Tipe Asesmen"
              fullWidth
              value={learningCreate.grading_comp_id.toString()}
              onChange={(newValue) => {
                setLearningCreate(v => ({...v, grading_comp_id: Number(newValue)}))
              }}
              items={queryResult.data ? queryResult.data.grading_comp.map(v => ({
                label: v.title,
                value: v.grading_comp_id.toString(),
              })): []}
            />
          </Flex>

          <Flex
            px={2}
            pb={2}
            pt={2}
            css={t => ({
              flexDirection: bp === "mobile" ? "column" : "row",
            })}
          >
            <Flex
              gap={1}
            >
              <Small>Waktu mulai</Small>
              <FlexRow>
                <InputText
                  fullWidth
                  value={timeRange.start.date}
                  slim
                  type="date"
                  onChange={e => {
                    console.log(timeRange)
                    setTimeRange(v => ({
                      ...v,
                      start: {
                        ...v.start,
                        date: e.target.value,
                      },
                    }))
                  }}
                />
                <select
                  value={timeRange.start.hour}
                  onChange={e => {
                    setTimeRange(v => ({...v, start: {...v.start, hour: parseInt(e.target.value)}}))
                  }}
                >
                  {hourOptions.map((v, k) => <option key={`option-hour-start-${k}`} value={v.value}>{v.label}</option>)} 
                </select>
                <select
                  value={timeRange.start.minute}
                  onChange={e => {
                    setTimeRange(v => ({...v, start: {...v.start, minute: parseInt(e.target.value)}}))
                  }}
                >
                  {minuteOptions.map((v, k) => <option key={`option-minute-start-${k}`} value={v.value}>{v.label}</option>)} 
                </select>
              </FlexRow>
            </Flex>
            <Flex
              gap={1}
            >
              <Small>Waktu selesai</Small>
              <FlexRow>
                <InputText
                  fullWidth
                  value={timeRange.end.date}
                  slim
                  type="date"
                  onChange={e => {
                    console.log(timeRange)
                    setTimeRange(v => ({
                      ...v,
                      end: {
                        ...v.end,
                        date: e.target.value,
                      },
                    }))
                  }}
                />
                <select
                  value={timeRange.end.hour}
                  onChange={e => {
                    setTimeRange(v => ({...v, end: {...v.end, hour: parseInt(e.target.value)}}))
                  }}
                >
                  {hourOptions.map((v, k) => <option key={`option-hour-end-${k}`} value={v.value}>{v.label}</option>)} 
                </select>
                <select
                  value={timeRange.end.minute}
                  onChange={e => {
                    setTimeRange(v => ({...v, end: {...v.end, minute: parseInt(e.target.value)}}))
                  }}
                >
                  {minuteOptions.map((v, k) => <option key={`option-minute-end-${k}`} value={v.value}>{v.label}</option>)} 
                </select>
              </FlexRow>
            </Flex>
          </Flex>
          <FlexRow
            px={2}
            pb={2}
            pt={2}
            jc={"space-between"}
          >
            <FlexRow>
              <b>{questionList.length}</b> Soal 
            </FlexRow>
            {(() => {
              const totalScoreWeight = questionList.map(v => v.score_weight).reduce((b, a) => b + a, 0)
              return (
                <FlexRow
                  jc="flex-end"
                  css={t => ({
                    color: totalScoreWeight != 100 ? t.palette?.error?.main : "inherit",
                    '*': {
                      color: totalScoreWeight != 100 ? t.palette?.error?.main : "inherit",
                    }
                  })}
                >
                  <b>{
                  questionList.length > 0 ?
                  totalScoreWeight 
                  : 0
                  }</b> / 100 Poin 
                </FlexRow>
              )
            })()}
          </FlexRow>
        </Paper>
      
        <Flex
          py={1}
          px={2}
          jc="center"
          css={t => ({
            flexDirection: bp === "mobile" ? "column" : "row",
          })}
        >
          <Button
            br={0.5}
            size="small"
            color="secondary"
            fullWidth={bp === "mobile" ? true : undefined}
            onClick={() => {
              const currentIndex = questionList.length
              setSelectedQuestionIndex(currentIndex)
              setDialogConfig(v => ({
                ...v,
                selectCLO: {
                  ...v.selectCLO,
                  open: true,
                }
              }))
            }}
          >
            <FaPlus /> Tambah Soal
          </Button>
          <Button
            br={0.5}
            size="small"
            color="secondary"
            fullWidth={bp === "mobile" ? true : undefined}
            onClick={() => {
              console.log(dialogConfig)
              const currentIndex = questionList.length
              setSelectedQuestionIndex(currentIndex)
              setDialogConfig(v => ({
                ...v,
                duplicateAssessment: {
                  open: true,
                },
              })) 
            }}
          >
            <FaPlus /> Duplikasi Soal dari Kelas Lain
          </Button>
        </Flex>
        
        {questionList.map((item, idx) => {

          const questionType = questionTypeByID(item.question_type_id, 20, "#133C6B")

          return (
            <Paper
              br={0}
              key={`question-list-item-${idx}`}
              css={t => ({
                
              })}
            >
              <FlexRow
                px={2}
                pt={2}
              >
                <Button
                  color="success"
                  size="small"
                >
                  Soal {idx + 1}
                </Button>
                <DividerGrow />
                <Small>Poin</Small>
                <InputText
                  slim
                  label="Poin"
                  size={2}
                  value={item.score_weight.toString()}
                  onChange={e => {

                    const newValue = Number(e.target.value)

                    const totalScoreWeight = _.sum(questionList.map(v => v.score_weight)) + newValue

                    questionOps.updateAt(
                      idx,
                      {
                        ...questionList[idx],
                        score_weight: newValue,
                      }
                    )
                  }}
                />
              </FlexRow>
              <FlexRow
                px={2}
                pt={1}
                pb={2}
                css={t => ({
                  flexDirection: bp === "mobile" ? "column" : "row" 
                })}
              >
                <Select
                  fullWidth
                  value={item.clo_code}
                  onChange={newValue => {
                    questionOps.updateAt(idx, {
                      ...questionList[idx],
                      clo_code: newValue,
                    })         
                  }}
                  label="CPMK"
                  items={queryResult.data ? queryResult.data?._[0].classroom.cys.clos.map(v => ({
                    label: <Flex
                      css={t => ({
                        'span': {
                          fontWeight: 400,
                          fontSize: 12,
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                        }
                      })}
                    >
                      {v.clo.code}
                      <span>{v.clo.description}</span>
                    </Flex>,
                    value: v.clo.code,
                  })): []}
                />
                <Select
                  fullWidth
                  value={item.question_type_id.toString()}
                  onChange={newValue => {
                    questionOps.updateAt(idx, {
                      ...questionList[idx],
                      question_type_id: Number(newValue),
                    })         
                  }}
                  label="Tipe soal"
                    items={queryResult.data ? queryResult.data?.question_type.filter(v => [1, 2, 3].includes(v.question_type_id)).map(v => ({
                      label: <Flex
                        css={t => ({
                          'span': {
                            fontWeight: 400,
                            fontSize: 12,
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                          }
                        })}
                      >
                        <FlexRow>
                          {questionTypeByID(v.question_type_id).icon}
                          {questionTypeByID(v.question_type_id).title}
                        </FlexRow>
                        <span>{questionTypeByID(v.question_type_id).description}</span>
                    </Flex>,
                    value: v.question_type_id.toString(),
                  })): []}
                />
              </FlexRow>
              <FlexRow
                px={1}
              >
                <NormalEditor
                  isEditable={true}

                  // Initial text editor state
                  initEditorStateString={JSON.stringify(questionList[idx].description)}
                  trackInitEditorStateChange={false}

                  // Current text editor state
                  onEditorStateChange={value => {

                    // Current edited question at idx
                    questionOps.updateAt(idx, {
                      ...questionList[idx],
                      description: value,
                    })         
                  }}
                />
              </FlexRow>
            </Paper>
          )
        })}
      </Flex>
      
      <Dialog
        open={dialogConfig.selectCLO.open}
      >
        <Paper
        >
          <Flex>
            <List
              title="CPMK"
            >
              {queryResult.data?._[0].classroom.cys.clos.map((item, key) => (
                <ListItem
                  key={`clo-list-item-${key}`}
                  onClick={() => {
                    if(questionList.length == 0) {
                      questionOps.push({
                        ...initQuestionCreate,
                        clo_code: item.clo.code,
                        description: simpleTextEditorState("Tuliskan apa saja yang Kamu dapat dari pertemuan hari ini !"),
                      })
                    } else {
                      if(selectedQuestionIndex) {
                        questionOps.insertAt(selectedQuestionIndex, {
                          ...initQuestionCreate,
                          clo_code: item.clo.code,
                        })
                      }
                    }
                    setDialogConfig(v => ({
                      ...v,
                      selectCLO: {open: false,},
                      selectQuestionType: {open: true,},
                    }))
                  }}
                >
                  <Flex>
                    {item.clo.code}
                    <Small>{item.clo.description}</Small>
                  </Flex>
                </ListItem>
              ))}
            </List>
          </Flex> 
        </Paper>
      </Dialog>

      <Dialog
        open={dialogConfig.selectQuestionType.open}
      >
        <Paper
        >
          <Flex>
            <List
              title="Tipe Soal"
              css={t => ({
                '.children-container': {
                  '.ListItem': {
                    // paddingTop: t.spacing(1),
                    // paddingBottom: t.spacing(1),
                  },
                }
              })}
            >
              {queryResult.data?.question_type.filter(v => [1, 2, 3].includes(v.question_type_id)).map((item, key) => {
                const questionType = questionTypeByID(item.question_type_id, 20, "#133C6B")

                return (
                  <ListItem
                    key={`question-type-list-item-${key}`}
                    onClick={() => {

                      if(selectedQuestionIndex !== null) {
                        const updated = {
                          ...questionList[selectedQuestionIndex],
                          question_type_id: item.question_type_id,
                        }
                        console.log("onClick question type list", selectedQuestionIndex, updated)
                        questionOps.updateAt(selectedQuestionIndex, updated)
                      }


                      setDialogConfig(v => ({
                        ...v,
                        selectQuestionType: {open: false,},
                      }))
                    }}
                  >
                    <FlexRow gap={2}>
                      {questionType.icon}
                      <Flex
                        gap={0}
                      >
                        {questionType.title}
                        <Small>{questionType.description}</Small>
                      </Flex>
                    </FlexRow>
                  </ListItem>
                )
              })}
            </List>
          </Flex> 
        </Paper>
      </Dialog>

      {classroomItemID &&
        <DialogDuplicateAssessment
          classroomItemID={classroomItemID}
          open={dialogConfig.duplicateAssessment.open}
          onClose={() => {
            setDialogConfig(v => ({
              ...v,
              duplicateAssessment: {
                open: false,
              },
            })) 
          }}
          onSelect={learningQuestions => {
            // questionOps.reset()
            learningQuestions.forEach(v => {
              questionOps.push(v)
            })
          }}
        />
      }
    </Page>
  )
}

export default ClassroomAssessmentCreate
