/** @jsxImportSource @emotion/react */

import { useLazyQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { FaSearch } from "react-icons/fa"
import { useDebounce } from "react-use"
import ContextMenu from "../../../shared/components/ContextMenu"
import Flex from "../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import InputText from "../../../shared/components/IFSuites/forms/InputText"
import Small from "../../../shared/components/IFSuites/typography/Small"
import ListItem from "../../../shared/components/List/Item"
import { GET_LECTURERS_BY_KEYWORD, GET_STUDENTS_BY_KEYWORD, PlayersByKeywordQ } from "./get-players-by-keywords.gql"

interface Props {
  label: string
  onChange: (selected: Selected) => void 
  target?: "all" | "student" | "lecturer"
  initialKeyword?: string
  fetchPolicy?: ""
  initialTitle?: string 
  initialDescription?: string 
}

type Selected = {
  title: string 
  description: string
  playerID: string
  studentID: string | null
  staffID: string | null
  lecturerID: string | null
}

const initContextMenuConfig = {
  open: false,
  x: 0,
  y:0,
}

const PlayerSelector = ({ label, onChange, target, initialKeyword, initialTitle, initialDescription, }: Props) => {

  const [searchKeywords, setSearchKeywords] = useState("")
  const [contextMenuConfig, setContextMenuConfig] = useState(initContextMenuConfig)
  const [selected, setSelected] = useState<Selected | null>(null)

  const selectedTarget = target || "student"
  const selectedQuery = selectedTarget === "student" ? GET_STUDENTS_BY_KEYWORD :
    selectedTarget === "lecturer" ? GET_LECTURERS_BY_KEYWORD : GET_STUDENTS_BY_KEYWORD

  const [getPlayersByKeyword, getPlayersByKeywordOps] = useLazyQuery<PlayersByKeywordQ>(
    selectedQuery,
  )

  const queryData = getPlayersByKeywordOps.data ? getPlayersByKeywordOps.data.player_player : []

  const openInitKeywordDropdown = () => {
    if(initialKeyword && initialKeyword.length >= 3) {
      setContextMenuConfig(v => ({...v, open: true}))
      getPlayersByKeyword({
        variables: {
          _ilike: `%${initialKeyword}%`
        }
      })
    }
  }

  const [, cancel] = useDebounce(
    () => {
      // console.log("typing stopped", searchKeywords)
      if(searchKeywords.length >= 3) {
        getPlayersByKeyword({
          variables: {
            _ilike: `%${searchKeywords}%`
          },
          fetchPolicy: "network-only"
        })

        if(!contextMenuConfig.open) {
          setContextMenuConfig(v => ({...v, open: true,}))
        }
      }

      if(searchKeywords.length < 3 && contextMenuConfig.open) {
        if(contextMenuConfig.open) {
          setContextMenuConfig(v => ({...v, open: false,}))
        }
      }
    },
    1000,
    [searchKeywords] 
  )

  useEffect(() => {

  }, [searchKeywords])

  return (
    <Flex
      css={t => ({
        position: `relative`,
      })}
    >
      {((selected !== null) || (initialTitle !== undefined && initialDescription !== undefined)) &&
        <FlexRow
          css={t => ({
            // color: `rgba(0, 0, 0, 0.7)`,
            color: t.palette?.secondary?.dark,
            background: t.palette?.secondary?.light,
            fontWeight: 700,
            borderRadius: 4,
            padding: 4,
            paddingLeft: 8,
            paddingRight: 8,
            ':focus': {
              border: `1px solid ${t.palette?.secondary?.main}`,
            },
            transition: `.4s`,
            border: `1px solid ${t.palette?.secondary?.main}`,
            outline: `none`,
            ':hover': {
              cursor: "pointer",
              border: `1px solid ${t.palette?.secondary?.main}`,
              boxShadow: `0 0 0 0.2rem ${t.palette?.secondary?.main}33`,
            },
          })}
          onClick={() => {
            setSelected(null)
            openInitKeywordDropdown()
          }}
        >
          <Flex
            gap={0}
          >
            <Small>
              {label}:
            </Small>
              {selected?.title || initialTitle}
            <Small
              css={{fontWeight: 400}}
            >
              {selected?.description || initialDescription}
            </Small>
          </Flex>
          <FlexRow
            css={t => ({
              justifyContent: `end`,
              position: `absolute`,
              right: t.spacing(1),
              color: `${t.palette?.primary?.main}`,
              width: `auto`,
            })}
          >
            <FaSearch color="#00000066" />
          </FlexRow>
        </FlexRow>
      }
      {(selected === null && (initialTitle === undefined || initialDescription === undefined)) &&
        <InputText
          label={label}
          fullWidth
          placeholder=""
          value={searchKeywords}
          onClick={e => {
            openInitKeywordDropdown()
          }}
          onChange={e => {
            setSearchKeywords(e.target.value) 
          }}
          endicon={<FaSearch color="#00000066" />}
        />
      }
      <ContextMenu
        open={contextMenuConfig.open}
        width={`100%`}
        top={48}
        left={0}
        onClose={() => {
          setContextMenuConfig(v => ({
            ...v,
            open: false,
          }))
        }}
        menus={getPlayersByKeywordOps.loading ? [<ListItem key={`list-item-1`}>Mengambil data...</ListItem>] : queryData.map((item, key) => {

          const title = selectedTarget === "student" ? `${item.as_student?.full_name}` : 
            selectedTarget === "lecturer" ? `${item.as_staff?.full_name}` : '' 

          const description = selectedTarget === "student" ? `${item.as_student?.nim} - ${item.as_student?.programme.title}` : 
            selectedTarget === "lecturer" ? `${item.as_staff?.nip} - ${item.as_staff?.programme.title}` : '' 

          const selectedItem: Selected = {
            title,
            description,  
            playerID: item.player_id,
            studentID: item.as_student?.student_id || null,
            staffID: item.as_staff?.staff_id || null,
            lecturerID: item.as_staff?.as_lecturer.lecturer_id || null,
          }

          return (
            <ListItem  
              onClick={() => {
                onChange(selectedItem) 
                setSearchKeywords("")
                setSelected(selectedItem)
                setContextMenuConfig(v => ({
                  ...v,
                  open: false,
                }))
              }}
              key={`select-${label?.replaceAll(" ", "")}-item-${key}`}
              css={t => ({
                transition: ".4s",
              })}
            >
              <Flex
                gap={0}
              >
                <b>
                  {title}
                </b>
                <Small>
                  {description}
                </Small>
              </Flex>
              
            </ListItem>
          )
        })}  
      />
    </Flex>
  )
} 

export default PlayerSelector 
