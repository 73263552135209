/** @jsxImportSource @emotion/react */

import { useLazyQuery, useMutation } from "@apollo/client"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDebounce } from "react-use"
import { Button } from "../../../shared/components/IFSuites/Button"
import Flex from "../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import InputText from "../../../shared/components/IFSuites/forms/InputText"
import H3 from "../../../shared/components/IFSuites/typography/H3"
import Small from "../../../shared/components/IFSuites/typography/Small"
import List from "../../../shared/components/List"
import ListItem from "../../../shared/components/List/Item"
import Page from "../../../shared/components/Page"
import useAuth from "../../../shared/hooks/use-auth"
import useLayout from "../../../shared/hooks/use-layout"
import { getDefenseEvaluationFormQ, GET_DEFENSE_EVALUATION_FORM } from "./get-defense-evaluation-form.gql"
import { UpsertDefenseGradingQ, UPSERT_DEFENSE_GRADING } from "./upsert-defense-grading.qgl"

const DefenseEvaluation = () => {

  const { defenseID } = useParams<{defenseID: string}>()
  const layout = useLayout()
  const auth = useAuth()
  const authInfo = auth.getInfo()

  const [gradingKV, setGradingKV] = useState<{[key: string]: {
    defense_evaluator_id: string
    grading_comp_id: string
    score: number 
    comments?: string
  }}>({})

  const [upsertGradePayload, setUpsertGradePayload] = useState<UpsertDefenseGradingQ>({
    grading_objects: [],
    evaluator_object: {},
    defense_evaluator_id: "",
  })

  const [get, getOps] = useLazyQuery<getDefenseEvaluationFormQ>(
    GET_DEFENSE_EVALUATION_FORM,
  )

  const [upsert,] = useMutation(UPSERT_DEFENSE_GRADING)

  useEffect(() => {
    get({
      variables: {
        defense_id: defenseID,
        player_id: authInfo?.playerUUID,
      }
    }).then(response => {
      const defenseEvaluatorID = response.data?.defense_defense_evaluator[0].defense_evaluator_id

      if(defenseEvaluatorID && defenseEvaluatorID !== '') {
        setUpsertGradePayload(v => ({
          ...v,
          defense_evaluator_id: defenseEvaluatorID,
          evaluator_object: {
            ...v.evaluator_object,
            comments: response.data?.defense_defense_by_pk.evaluators.find(x => x.defense_evaluator_id === defenseEvaluatorID)?.comments,
          },
        }))

        response.data?.defense_defense_by_pk.evaluators.forEach(v => {
          if(v.defense_evaluator_id === defenseEvaluatorID) {
            v.gradings.forEach(w => {
              setGradingKV(x => ({
                ...x,
                [w.grading_comp_id]: {
                  defense_evaluator_id: defenseEvaluatorID,
                  grading_comp_id: w.grading_comp_id, 
                  score: w.score, 
                  comments: w.comments, 
                }
              }))
              
            })
          }
        })

      }

    })
  }, [])

  // useEffect(() => {
  //   console.log("gradingKV", gradingKV)

  // }, [gradingKV])

  const GradeTotal = () => {

    let total = 0

    getOps.data?.defense_grading_comp.map(v => {
      if(gradingKV[v.grading_comp_id] !== undefined) {
        total += (v.percentage / 100 * gradingKV[v.grading_comp_id].score) 
      }
    })
    return (
      <FlexRow>
        Total nilai
        <h1>

          {total.toFixed(2)}
        </h1>
      </FlexRow>
    )
  }

  useDebounce(
    () => {
      runUpsert()
    }, 
    1000,
    [upsertGradePayload, gradingKV]
  )

  const runUpsert = () => {

    layout.loadingShow("Menyimpan nilai...")

    const variables = {
      ...upsertGradePayload,
      grading_objects: Object.keys(gradingKV).map(v => ({
        defense_evaluator_id: upsertGradePayload.defense_evaluator_id, 
        grading_comp_id: gradingKV[v].grading_comp_id, 
        score: gradingKV[v].score, 
      }))
    }

    upsert({
      variables: variables 
    }).then(response => {
      getOps.refetch() 
    }).finally(() => {
      layout.loadingHide()
    })
  }

  return (
    <Page
      title={`Penilaian Sidang`}
    >
      {getOps.data &&
        <Flex
          pt={2}
          pb={0}
        >
          <Flex ai="center" px={2} pb={4} gap={2}>
            <h1 css={{textAlign: "center"}}>Sidang {getOps.data.defense_defense_by_pk.category.title}</h1>
            <Flex gap={0} ai="center">
              <H3>{getOps.data.defense_defense_by_pk.student.full_name}</H3> 
              {getOps.data.defense_defense_by_pk.student.nim} 
            </Flex>
            <Flex gap={0} ai="center">
              <Small>Judul:</Small>
              <H3>{getOps.data.defense_defense_by_pk.title}</H3>
            </Flex>
          </Flex>
          <Flex gap={4}>
            <List
              title={`Form Penilaian`}
            >
              {upsertGradePayload.defense_evaluator_id != "" && getOps.data.defense_grading_comp.map((item, key) => {

                const selectedGradingKV = gradingKV[item.grading_comp_id]

                return (
                  <ListItem key={`defense-grading-item-${key}`}>
                    <Flex>
                      {item.title}
                      <FlexRow>
                        <InputText
                          size={4}
                          label="Nilai 0 - 100"
                          value={selectedGradingKV !== undefined ? selectedGradingKV.score.toString() : ''}
                          onChange={e => {
                            if(Number(e.target.value) <= 100) {

                              if(upsertGradePayload.defense_evaluator_id) {
                                setGradingKV(v => ({
                                  ...v,
                                  [item.grading_comp_id]: {
                                    defense_evaluator_id: upsertGradePayload.defense_evaluator_id, 
                                    grading_comp_id: item.grading_comp_id,
                                    score: Number(e.target.value), 
                                  }
                                }))
                              }

                            }

                          }}
                        />
                        x {item.percentage}% = <b>{selectedGradingKV !== undefined ? Number(selectedGradingKV.score) * item.percentage / 100 : ''}</b> 
                      </FlexRow>
                    </Flex>
                  </ListItem>
                )
              })}
              <ListItem disableHover>
                <GradeTotal />
              </ListItem> 
              <ListItem disableHover>
                <Flex>
                  Catatan sidang
                  <Flex
                    p={2}
                    css={t => ({
                      borderRadius: 4,
                      background: t.palette?.gray?.[50],
                      border: `1px solid ${t.palette?.gray?.[400]}`,
                    })}
                    jc="stretch"
                  >

                    <textarea
                      value={upsertGradePayload.evaluator_object.comments}
                      onChange={e => {
                        if(e.target !== null && e.target.value !== null) {

                          setUpsertGradePayload(v => ({
                            ...v,
                            evaluator_object: {
                              ...v.evaluator_object,
                              comments: e.target.value,
                            }
                          }))
                        }
                      }}
                      css={t => ({
                        padding: 0,
                        border: 0,
                        width: `100%`,
                        outline: `none`,
                        ':active': {
                          border: 0,
                          outline: `none`,
                        },
                      })}
                    >
                      
                    </textarea>
                  </Flex>
                </Flex>
              </ListItem> 
              <ListItem disableHover jc="flex-end">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {

                    runUpsert()

                  }}
                >
                  Perbarui Nilai 
                </Button>
              </ListItem>
            </List>
            <List 
              title={`Nilai dari tiap pengevaluasi`}
            >

            </List>
          </Flex>
        </Flex>
      }
    </Page>
  )
} 

export default DefenseEvaluation
