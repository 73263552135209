/** @jsxImportSource @emotion/react */

import { useMutation } from "@apollo/client"
import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { getAnalytics } from "firebase/analytics"
import { useEffect, useState } from "react"
import Button from "../../../shared/components/Editor/MGLexical/ui/Button"
import Dialog from "../../../shared/components/IFSuites/Dialog"
import { UPDATE_PLAYER_FCM } from "./update-player-fcm.gql"

const firebaseConfig = {
  apiKey: "AIzaSyCJMCHJQFB7FYfTpl68L-Fpd5Zd_-QYk7U",
  authDomain: "informatika-digital.firebaseapp.com",
  projectId: "informatika-digital",
  storageBucket: "informatika-digital.appspot.com",
  messagingSenderId: "728058938848",
  appId: "1:728058938848:web:05e66f4dd3be594f785785",
  measurementId: "G-Q9D9VW2TQS"
}

const vapidKey = 'BLYmcSNIcyVXUWHenCZoBbrV07PEh2JzB7FHYHF6zjzZta1qA4IFj0o3gm74GUoPZeY48NIe3Us2hYrStLWNmQI'
// Initialize Firebase
const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)
const analytics = getAnalytics(app)

const NotificationManager = () => {

  const [permission, setPermission] = useState<"default" | "denied" | "granted">(Notification.permission)
  const [registrationToken, setRegistrationToken] = useState("")
  const [incomingMessage, setIncomingMessage] = useState<{
    title: string
    body: string
  }>({
    title: "",
    body: "",
  })

  const [update, updateOps] = useMutation(
    UPDATE_PLAYER_FCM, 
  )

  useEffect(() => {
    setPermission(Notification.permission)

  }, [Notification.permission])

  useEffect(() => {
    navigator.serviceWorker.getRegistration("/service-worker.js").then((registration) => {
      if(registration) {
        getToken(messaging, {
          vapidKey,
          serviceWorkerRegistration: registration 
        }).then((currentToken) => {
          if (currentToken) {
            console.log("Set registration token")
            setRegistrationToken(currentToken) 
            update({
              variables: {
                fcm_latest_registration_token: currentToken, 
              }
            }).then(data => {
              console.log("FCM registration token updated", data.data)
            })
          } else {
            console.log("No registration token available")
          }
        })
      }
    })

    onMessage(messaging, (payload) => {
      console.log("message received, ", payload.notification?.title)
      setIncomingMessage({
        title: payload.notification && payload.notification.title ? payload.notification.title : "",
        body: payload.notification && payload.notification.body ? payload.notification.body : "",
      })
    })
  }, [])

  return (
    <div>
      <Dialog 
        open={permission === "denied" || permission === "default"}
      >
        <div>
          <Button onClick={() => {
            Notification.requestPermission().then((permission: string) => {
              console.log("permission", permission)
            })
          }}>
            Nyalakan notifikasi
          </Button>
        </div>
      </Dialog>
    </div>
  )
}

export default NotificationManager 
