/** @jsxImportSource @emotion/react */

import { useNavigate } from "react-router-dom"

import Page from "../../../shared/components/Page"
import ListLayout from "../../../shared/components/List/Layout"
import Flex from "../../../shared/components/IFSuites/Flex"
import { SiTypescript } from "react-icons/si"
import { FaDatabase, FaMicroscope, FaVideo, FaWhatsapp } from "react-icons/fa"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import { Button } from "../../../shared/components/IFSuites/Button"
import { MdDraw, MdVolunteerActivism } from "react-icons/md"

const PortalDigiteam = () => {

  const navigate = useNavigate()

  return (
    <Page
      title={`Tim Digital Informatika`}
    >
      <Flex px={2} pt={2}>
          Gabung ke tim digital informatika, aplikasikan skill digitalmu untuk berbagi manfaat ke seluruh civitas IF. Dapatkan berbagai manfaat dan pengalaman. 
        <Button 
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => {
            // https://stackoverflow.com/questions/30344476/web-link-to-specific-whatsapp-contact
            window.location.href = "https://api.whatsapp.com/send?phone=+6282119389338&phone=Gabung%2C%20Digiteam"
          }}
        >
          <FaWhatsapp size={16} /> Gabung
        </Button> 
      </Flex>
      <ListLayout
        title={`Posisi Tersedia`}
        items={[
          {
            title: <Flex pb={1}>
              Frontend Developer ⭐
              <FlexRow>
                <Button size="small" color="secondary">KP / Magang</Button>
                <Button size="small" color="secondary"><MdVolunteerActivism size={16} /> Volunteer</Button>
              </FlexRow>
            </Flex>,
            icon: <SiTypescript size={24} />,
            content: "Mengembangkan web client IF Digital Service menggunakan TypeScript React dan GraphQL",
            onClick: () => {},
          },
          {
            title: <Flex pb={1}>
              Content Curator ⭐
              <FlexRow>
                <Button size="small" color="secondary"><MdVolunteerActivism size={16} /> Volunteer</Button>
              </FlexRow>
            </Flex>,
            icon: <FaDatabase size={24} />,
            content: "Mengelola konten IF Digital Service. Pilih satu atau lebih: skill dunia kerja / event / beasiswa / konferensi / magang / berita teknologi",
            onClick: () => {},
          },
          {
            title: <Flex pb={1}>
              Educational Illustrator ⭐
              <FlexRow>
                <Button size="small" color="secondary"><MdVolunteerActivism size={16} /> Volunteer</Button>
              </FlexRow>
            </Flex>,
            icon: <MdDraw size={24} />,
            content: "Membuat ilustrasi-ilustrasi sederhana untuk menjelaskan konsep dan teknologi keinformatikaan",
            onClick: () => {},
          },
          {
            title: <Flex pb={1}>
              Video Editor ⭐
              <FlexRow>
                <Button size="small" color="secondary"><MdVolunteerActivism size={16} /> Volunteer</Button>
              </FlexRow>
            </Flex>,
            icon: <FaVideo size={24} />,
            content: "Melakukan editing video untuk publikasi konten keinformatikaan",
            onClick: () => {},
          },
          {
            title: <Flex pb={1}>
              Smart Village Fullstack Developer
              <FlexRow>
                <Button size="small" color="secondary">KP / Magang</Button>
                <Button size="small" color="secondary"><MdVolunteerActivism size={16} /> Volunteer</Button>
              </FlexRow>
            </Flex>,
            icon: <SiTypescript size={24} />,
            content: "Mengembangkan web Smart Village menggunakan TypeScript Next.js",
            onClick: () => {},
          },
          {
            title: <Flex pb={1}>
              Researcher
              <FlexRow>
                <Button size="small" color="secondary">TA / KP</Button>
                <Button size="small" color="secondary"><MdVolunteerActivism size={16} /> Volunteer</Button>
              </FlexRow>
            </Flex>,
            icon: <FaMicroscope size={24} />,
            content: "Mengeksplorasi berbagai metode dan teknologi AI ataupun Distributed Computing untuk fitur-fitur produk Jurusan Informatika",
            onClick: () => {},
          },
        ]}
      />
    </Page>
  )
}

export default PortalDigiteam
