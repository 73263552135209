/** @jsxImportSource @emotion/react */

import Flex from "../../../shared/components/IFSuites/Flex"
import Page from "../../../shared/components/Page"
import { QrReader } from 'react-qr-reader'
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { Button } from "../../../shared/components/IFSuites/Button"
import { MdCameraswitch } from "react-icons/md"
import { useWindowSize } from "react-use"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"

const PortalScan = () => {
  const [data, setData] = useState('-')
  const navigate = useNavigate()
  const [facingMode, setFacingMode] = useState<{ exact: "user"} | { exact: "environment"} | "environment" | "user">("environment")
  const windowSize = useWindowSize()

  return (
    <Page
      title={`Scan QR Code`}
    >
      <Flex
        css={t => ({
        })}
      >
        <Flex
          css={t => ({
            width: windowSize.width,
            maxWidth: 480,
            height: windowSize.height * 0.6,
            maxHeight: 480,
            display: "block",
            margin: "auto",
          })}
        >
          <QrReader
            onResult={(result, error) => {
              if (!!result) {
                const textFromResult = result.getText() 
                setData(textFromResult)
                navigate(textFromResult)
              }
              if (!!error) {
                // console.log(error)
              }
            }}
            constraints={{
              facingMode: facingMode,
            }}
          />
        </Flex>
        <FlexRow
          jc="center"
        >
          <Button
            onClick={() => setFacingMode(facingMode === 'environment' ? 'user' : 'environment')}
          >
            <MdCameraswitch />
          </Button>
        </FlexRow>

        <p>{data}</p>
      </Flex>
    </Page>
  )
}

export default PortalScan
