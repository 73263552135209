/** @jsxImportSource @emotion/react */

import { Theme } from "@emotion/react"

const defaultTheme = (): Theme => ({
  palette: {
    primary: {
      dark: `#FF9500`,
      main: `#FFB900`,
      light: `#FFEEB4`,
      contrastText: `#FFFFFF`,
    },
    // secondary: {
    //   dark: `#006430`,
    //   main: `#16A75C`,
    //   light: `#E6F6EC`,
    //   contrastText: `#FFFFFF`,
    // },
    success: {
      dark: `#006430`,
      main: `#16A75C`,
      light: `#E6F6EC`,
      contrastText: `#FFFFFF`,
    },
    info: {
      dark: `#424242`,
      main: `#616161`,
      light: `#FAFAFA`,
      contrastText: `#FFFFFF`,
    },
    secondary: {
      dark: `#182833`,
      main: `#133C6B`,
      light: `#E3E7ED`,
      contrastText: `#FFFFFF`,
    },
    error: {
      dark: `#B71B1C`,
      main: `#E53935`,
      light: `#FFEBEE`,
      contrastText: `#FFFFFF`,
    },
    yellow: {
      50: `#FFF9E1`,
      100: `#FFEEB4`,
      200: `#FFE483`,
      300: `#FFDA4F`,
      400: `#FFD026`,
      500: `#FFC800`,
      600: `#FFB900`,
      700: `#FFA600`,
      800: `#FF9500`,
      900: `#FF9500`,
    },
    blueGray: {
      50: `#E3E7ED`,
      100: `#B9C3D3`,
      200: `#8D9DB5`,
      300: `#627798`,
      400: `#415C84`,
      500: `#1A4374`,
      600: `#133C6B`,
      700: `#1F4762`,
      800: `#153040`,
      900: `#182833`,
    },
    gray: {
      50: `#FAFAFA`,
      100: `#F5F5F5`,
      200: `#EEEEEE`,
      300: `#E0E0E0`,
      400: `#BDBDBD`,
      500: `#9E9E9E`,
      600: `#757575`,
      700: `#616161`,
      800: `#424242`,
      900: `#212121`,
    },
  },
  spacing: (v: number) => v * 8,
  w12: (n: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12) => `${n / 12 * 100}%`,
}) 

export default defaultTheme
