/** @jsxImportSource @emotion/react */

import { gql, useLazyQuery, useMutation } from "@apollo/client"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useWindowSize } from "react-use"
import Flex from "../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../shared/components/IFSuites/Flex/Row"
import Page from "../../../../shared/components/Page"
import useAuth from "../../../../shared/hooks/use-auth"
import useLayout from "../../../../shared/hooks/use-layout"
import GradingContent from "./Content"
import GradingGrade from "./Grade"
import GradingParticipants from "./Participants"
import GradingQuestions from "./Questions"

const ClassroomAssessmentGrading = () => {

  const auth = useAuth()
  const authInfo = auth.getInfo()
  const layout = useLayout()
  const { learningID } = useParams()
  const size = useWindowSize()
  const [selected, setSelected] = useState<{
    learningQuestionID: string | null 
    learningParticipationID: string | null
    learningQuestionDescription: object | undefined 
    participantAnswer: null | object
    score: number
    feedback: null | object
  }>({
    learningQuestionID: null,
    learningParticipationID: null,
    learningQuestionDescription: undefined, 
    participantAnswer: null,
    score: 0,
    feedback: null
  })

  const GET_GRADING_COMPONENTS = gql`
    query GetGradingComponents($learning_id: uuid = "", $player_uuid: uuid = "") {
      assessment: academic_learning_by_pk(learning_id: $learning_id) {
        classroom_item {
          classroom {
            facilitator_enrollments(where: {lecturer: {staff: {player_id: {_eq: $player_uuid}}}}) {
              facilitator_enrollment_id
            }
          }
        }
        participations(order_by: {student_enrollment: {student: {full_name: asc}}}) {
          learning_participation_id
          student_enrollment {
            student {
              full_name
              nim
            }
          }
          answers(where: {learning_participation: {learning_id: {_eq: $learning_id}}}) {
            datetime_graded
            datetime_submit
            learning_question_id
            learning_participation_id
          }
        }
        questions(order_by: {item_order: asc}) {
          learning_question_id
          description
          attachments
          question_type_id
          participation_answers {
            learning_participation_id
            graded_by
            datetime_submit
            datetime_graded
          }
        }
      }
    }
  `

  const [getGradingComponents, gradingComponents] = useLazyQuery<{
    assessment: {
      classroom_item: {
        classroom: {
          facilitator_enrollments: {
            facilitator_enrollment_id: string
          }[]
        }
      }
      participations: {
        learning_participation_id: string
        student_enrollment: {
          student: {
            full_name: string
            nim: string
          }
        }
        answers: {
          datetime_graded: string | null
          datetime_submit: string | null
          learning_question_id: string
          learning_participation_id: string
        }[]
      }[]
      questions: {
        learning_question_id: string
        description: object
        attachments: {

        }[]
        question_type_id: string
        participation_answers: {
          learning_participation_id: string
          graded_by: string
          datetime_submit: string
          datetime_graded: string
        }[]
      }[]
    }
  }>(
    GET_GRADING_COMPONENTS,
    {
      fetchPolicy: "network-only",
      variables: {
        learning_id: learningID,
        player_uuid: authInfo?.playerUUID,
      }
    }
  )

  const GET_PARTICIPATION_ANSWER = gql`
    query ParticipationAnswer($learning_question_id: uuid = "", $learning_participation_id: uuid = "") {
      answer: academic_learning_participation_answer(where: {learning_question_id: {_eq: $learning_question_id}, learning_participation_id: {_eq: $learning_participation_id}}) {
        answer_text
        datetime_graded
        datetime_submit
        datetime_created
        feedback
        score
        learning_participation_answer_id
        graded_by
        learning_participation_id
        learning_question_id
        answer_choices
      }
    }
  `

  const [getParticipantAnswer, participantAnswer] = useLazyQuery<{
    answer: {
      answer_text: object
      datetime_graded: string
      datetime_submit: string
      datetime_created: string
      feedback: object
      score: number
      learning_participation_answer_id: string
      graded_by: string
      learning_participation_id: string
      learning_question_id: string
      answer_choices: object[]
    }[]
  }>(
    GET_PARTICIPATION_ANSWER
  )
  // const INSERT_UPDATE_GRADE = gql`
  //   mutation InsertUpdateGrade($object: academic_learning_participation_answer_insert_input!) {
  //     insert_academic_learning_participation_answer_one(on_conflict: {constraint: learning_participation_answer_un, update_columns: [score, feedback]}, object: $object) {
  //       graded_by
  //       score
  //     }
  //   }
  // `

  const INSERT_UPDATE_GRADE = gql`
    mutation InsertUpdateGrade($object: academic_learning_participation_answer_insert_input!) {
      insert_academic_learning_participation_answer_one(on_conflict: {constraint: learning_participation_answer_un, update_columns: [score, feedback]}, object: $object) {
        graded_by
        score
        graded_by
      }
    }
  `

  const [insertUpdateGrade, ] = useMutation(
    INSERT_UPDATE_GRADE,
  )

  const updateGradingComponents = (resetSelected: boolean = true) => {
    getGradingComponents().then(res => {
      if(res.data) {
        const assessment = res.data.assessment

        if(resetSelected) {
          setSelected(v => ({
            ...v,
            learningQuestionDescription: assessment.questions[0].description,
            learningParticipationID: assessment.participations[0].learning_participation_id,
            learningQuestionID: assessment.questions[0].learning_question_id,
          }))
        }
      }
    })
  }

  useEffect(() => {
    updateGradingComponents()
  }, [])

  /**
    * Update selected values
    */

  const reloadParticipantAnswer = () => {
    if(selected.learningQuestionID && selected.learningParticipationID) {
      getParticipantAnswer({
        fetchPolicy: "network-only",
        variables: {
          learning_question_id: selected.learningQuestionID,
          learning_participation_id: selected.learningParticipationID,
        }
      }).then(res => {
        const data = res.data

        console.log("participantAnswer", data)

        if(data !== undefined && data.answer.length > 0) {
          setSelected(v => ({
            ...v,
            participantAnswer: data.answer[0].answer_text,
            score: data.answer[0].score,
            feedback: data.answer[0].feedback,
          }))
        } else {
          setSelected(v => ({
            ...v,
            participantAnswer: null,
            score: 0,
            feedback: null,
          }))
        }
      })
    }
  }

  useEffect(() => {
    // setSelected(v => ({
    //   ...v,
    //   participantAnswer: null,
    // }))
    reloadParticipantAnswer()

  }, [
    selected.learningQuestionID,
    selected.learningParticipationID
  ])

  return (
    <Page
      title="Periksa Asesmen"
    >
      <FlexRow
        ai="start"
        css={t => ({
          height: "100%",
        })}
      >
        <Flex
          pr={5}
          gap={0}
          jc="flex-start"
          css={t => ({
            height: size.height - t.spacing(7),
          })}
        >
          {gradingComponents.data && selected.learningQuestionID && selected.learningQuestionDescription &&
            <GradingContent
            participant={`${gradingComponents.data.assessment.participations.find(w => w.learning_participation_id === selected.learningParticipationID)?.student_enrollment.student.full_name} (${gradingComponents.data.assessment.participations.find(w => w.learning_participation_id === selected.learningParticipationID)?.student_enrollment.student.nim})`}
              question={selected.learningQuestionDescription}
              answer={selected.participantAnswer}
            />
          }

          <Flex
            gap={0}
            pr={5}
            css={t => ({
              position: "absolute",
              bottom: 0,
            })}
          >
            {participantAnswer.data &&
              <GradingGrade
                onSave={(score, feedback) => {
                  if(gradingComponents.data && gradingComponents.data.assessment.classroom_item.classroom.facilitator_enrollments.length > 0) {

                    layout.loadingShow("Menyimpan nilai...")

                    insertUpdateGrade({
                      variables: {
                        object: participantAnswer.data?.answer.length === 0 ? {
                          answer_text: null,
                          feedback: feedback,
                          score: score,
                          learning_participation_id: selected.learningParticipationID,
                          learning_question_id: selected.learningQuestionID,
                          graded_by: gradingComponents.data.assessment.classroom_item.classroom.facilitator_enrollments[0].facilitator_enrollment_id,
                        }: {
                          feedback: feedback,
                          score: score,
                          learning_participation_id: selected.learningParticipationID,
                          learning_question_id: selected.learningQuestionID,
                          graded_by: gradingComponents.data.assessment.classroom_item.classroom.facilitator_enrollments[0].facilitator_enrollment_id,
                        }
                      }
                    }).then(res => {
                      reloadParticipantAnswer()
                      updateGradingComponents(false)
                    }).finally(() => {
                      layout.loadingHide()
                    })

                  }
                }}
                answer={
                  participantAnswer.data.answer.length === 0 ?
                  null : {
                    score: participantAnswer.data.answer[0].score,
                    feedback: participantAnswer.data.answer[0].feedback,
                  }
                }
              />
            }
            {gradingComponents.data && selected.learningQuestionID &&
              <GradingQuestions
                selectedLearningQuestionID={selected.learningQuestionID}
                questions={gradingComponents.data.assessment.questions.map(v => {

                  let isGraded = undefined 
                  if(gradingComponents.data) {
                    const participant = gradingComponents.data.assessment.participations.find(w => w.learning_participation_id === selected.learningParticipationID)
                    if(participant) {
                      const participantAnswer = participant.answers.find(w => w.learning_question_id === v.learning_question_id)

                      if(participantAnswer) {
                        if(participantAnswer.datetime_graded !== null) {
                          isGraded = true
                        } else {
                          isGraded = false
                        }
                      } 

                    }
                  }

                  return {
                    learningQuestionID: v.learning_question_id,
                    isGraded: isGraded,
                  }
                })}
                onChange={id => {

                  // const description = gradingComponents.data?.assessment.questions.find(v => v.learning_question_id === selected.learningQuestionID)?.description
                  const description = gradingComponents.data?.assessment.questions.find(v => v.learning_question_id === id)?.description
                  setSelected(v => ({
                    ...v,
                    learningQuestionID: id,
                    learningQuestionDescription: description 
                  }))
                }}
              />
            }
          </Flex>

          {gradingComponents.data && selected.learningParticipationID &&
            <GradingParticipants
              onChange={id => {
                setSelected(v => ({...v, learningParticipationID: id}))
              }}
              selectedLearningParticipationID={selected.learningParticipationID}
              participants={gradingComponents.data.assessment.participations.map(v => {

                const totalAnswersGraded = v.answers.filter(v => v.datetime_graded !== null).length
                const totalQuestions = gradingComponents.data?.assessment.questions.length

                return {
                  fullName: v.student_enrollment.student.full_name,
                  nim: v.student_enrollment.student.nim,
                  learningParticipationID: v.learning_participation_id,
                  isGraded: totalAnswersGraded === totalQuestions ? true : v.answers.length === 0 ? undefined : false,
                }
              })}
            />
          }
        </Flex>
      </FlexRow>
    </Page>
  )
}

export default ClassroomAssessmentGrading

// References drag to scroll https://htmldom.dev/drag-to-scroll/
