/** @jsxImportSource @emotion/react */

import { keyframes } from "@emotion/react"
import FlexRow from "../../IFSuites/Flex/Row"
import { CommonProps, FlexProps } from "../../IFSuites/interfaces"

interface ListItemProps {
  glow?: boolean
  disableHover?: true
}

const glowKeyframe = keyframes`
  from {
    background: #E3E7ED;
  }

  50% {
    background: #E6F6EC;
  }

  to {
    background: #E3E7ED;
  }
`

const ListItem = ({glow, children, disableHover, ...other}: ListItemProps & CommonProps & FlexProps) => {
  return (
    <FlexRow
      className="ListItem"
      gap={2}
      p={2}
      css={t => ({
        animation: glow ? `${glowKeyframe} 4s ease infinite` : ``,
        transition: ".4s",
        borderTop: `1px solid ${t.palette?.gray?.[300]}`,
        ':hover': disableHover ? {} : {
          background: `#00000011`,
          cursor: "pointer",
        },
      })}
      {...other}
    >
      {children}
    </FlexRow>
  )
}

export default ListItem
