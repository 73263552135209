/** @jsxImportSource @emotion/react */

import { motion } from "framer-motion"
import { ReactNode } from "react"

interface Props {
  children: ReactNode,
  disable?: true,
}

export default ({children, disable}: Props) => {
  return (
    <motion.div
      animate={disable ? {} : {
        scale: [1, 1, 1, 1.2, 1],
        // y: [0, 3, 0, -3, 0],
      }}
      transition={{
        repeat: Infinity,
        duration: 1,
        // repeatDelay: 2,
      }}
    >
      {children}
    </motion.div>
  )
}
