import { gql } from "@apollo/client";

export interface LecturerSchedulesTodayQ {
  defense_defense_evaluator: {
    defense: {
      defense_id: string
      datetime_start: string
      title: string
      place: {
        place_id: string
        title: string
      }
      category: {
        title: string
        defense_category_id: string
      }
      student: {
        full_name: string
        student_id: string
      }
    }
    type: {
      title: string
    }
  }[]
  academic_classroom_schedule: {
    time_end: string
    time_start: string
    place: {
      title: string
    }
    day_number: number
    classroom_id: string
    classroom: {
      code: string
      cys: {
        course: {
          code: string
          title: string
        }
      }
    }
  }[]
}

export const GET_LECTURER_SCHEDULES_TODAY = gql`
query GetLecturerSchedulesToday(
  $player_id: uuid = "",
  $datetime_from: timestamp = "",
  $datetime_to: timestamp = "",
  $day_number: smallint = "",
  $current_year: smallint = "",
  $current_semester: smallint = ""
) {
  defense_defense_evaluator(where: {
    evaluator_player_id: {_eq: $player_id},
    defense: {
      is_done: {_eq: 0},
      _and: [
        {datetime_start: {_gte: $datetime_from}},
        {datetime_start: {_lte: $datetime_to}}
      ]
    }
  }) {
    defense {
      defense_id
      datetime_start
      title
      place {
        place_id
        title
      }
      category {
        title
        defense_category_id
      }
      student {
        full_name
        student_id
      }
    }
    type {
      title
    }
  }
  academic_classroom_schedule(where: {
    day_number: {_eq: $day_number},
    classroom: {
      facilitator_enrollments: {lecturer: {staff: {player_id: {_eq: $player_id}}}},
      cys: {current_semester: {_eq: $current_semester}, current_year: {_eq: $current_year}}
    }}) {
    time_end
    time_start
    place {
      title
    }
    day_number
    classroom_id
    classroom {
      code
      cys {
        course {
          code
          title
        }
      }
    }
  }
}
`
