/** @jsxImportSource @emotion/react */

import { ReactNode } from "react"
import { FaDocker, FaFileDownload, FaFilePdf, FaFilePowerpoint, FaGithub, FaGitlab, FaGoogleDrive, FaYoutube } from "react-icons/fa"
import { SiDiagramsdotnet } from "react-icons/si"
import DividerGrow from "../../IFSuites/Divider/Grow"
import Flex from "../../IFSuites/Flex"
import FlexRow from "../../IFSuites/Flex/Row"
import { FlexProps } from "../../IFSuites/interfaces"
import Small from "../../IFSuites/typography/Small"

interface LinkFilesProps {
  links: {
    label: string
    url: string
    rightElement?: ReactNode
  }[]
}

const LinkFiles = ({links, ...other}: FlexProps & LinkFilesProps) => {
  return (
    <Flex gap={0}>
      {links.filter(v => v.url !== undefined).map((item, key) => (
        <FlexRow
          key={`attachment-${key}`}
          css={t => ({
            borderTop: `1px solid ${t.palette?.gray?.[300]}`,
            cursor: "pointer",
            ":hover": {
              background: `${t.palette?.gray?.[200]}`
            },
          })}
          px={2}
          py={1}
        >
          <FlexRow
            onClick={() => {
              window.open(item.url, '_blank')
            }}
          >
            <Small css={{width: 16,}}>{key + 1}</Small>
            {
              item.url.includes("docs.google.com") ? <FaGoogleDrive color="#26a768" size={24} /> :
              item.url.includes("youtube.com") ? <FaYoutube color="#f70000" size={24} /> :
              item.url.includes("gitlab.com") ? <FaGitlab color="#db4128" size={24} /> :
              item.url.includes("github.com") ? <FaGithub size={24} /> :
              item.url.includes(".ppt") ? <FaFilePowerpoint color="#cb4524" size={24} /> :
              item.url.includes(".pdf") ? <FaFilePdf size={24} /> :
              item.url.includes("docker.com") ? <FaDocker color="#2496ed" size={24} /> :
              item.url.includes("diagrams.net") ? <SiDiagramsdotnet color="#e48005" size={24} /> :
              <FaFileDownload color="#333333" size={24} />
            }
            <Small>
              {item.label}
            </Small> 
          </FlexRow>
          <DividerGrow />
          {item.rightElement}
        </FlexRow>
      ))}
    </Flex>
  )
}

export default LinkFiles
