/** @jsxImportSource @emotion/react */

import { useMutation } from "@apollo/client"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useWindowSize } from "react-use"
import { Button } from "../../../../shared/components/IFSuites/Button"
import Flex from "../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../shared/components/IFSuites/Flex/Row"
import InputText from "../../../../shared/components/IFSuites/forms/InputText"
import Select from "../../../../shared/components/IFSuites/forms/Select"
import Paper from "../../../../shared/components/IFSuites/Paper"
import Small from "../../../../shared/components/IFSuites/typography/Small"
import Page from "../../../../shared/components/Page"
import useBreakpoint from "../../../../shared/hooks/use-breakpoint"
import useLayout from "../../../../shared/hooks/use-layout"
import { INSERT_TICKET } from "../insert-ticket.qgl"
import { TicketAdd } from "../models"

// List of tickets generated by the user, or by all of the users
const HelpdeskTicketAdd = () => {

  const bp = useBreakpoint()
  const windowSize = useWindowSize()
  const [ticketAdd, setTicketAdd] = useState<TicketAdd>({
    ticket_category_id: 0,
    subject: ``,
    message: ``,
    attachments: [],
  })

  const navigate = useNavigate()
  const layout = useLayout()

  const [insert, insertOps] = useMutation(
    INSERT_TICKET, 
  )

  return (
    <Page
      title={`Buat Tiket Bantuan`}
    >
      <Paper
        br={0}
        mt={2}
      >
        <Flex
          p={2}
        >
          <InputText
            label="Judul pertanyaan / keluhan / aspirasi"
            value={ticketAdd.subject}
            onChange={(e) => {setTicketAdd(v => ({...v, subject: e.target.value}))}}
            fullWidth={true}
          />
        </Flex>
        <Flex
          pt={0}
          pb={2}
          px={2} 
        >
          <Select
            fullWidth
            value={ticketAdd.ticket_category_id.toString()}
            onChange={newValue => {
              setTicketAdd(v => ({...v, ticket_category_id: Number(newValue)}))
            }}
            label="Kategori Tiket"
            items={[
              {
                label: `Aplikasi`,
                value: 1, 
              },
              {
                label: `Perkuliahan`,
                value: 2, 
              },
              {
                label: `Fasilitas`,
                value: 3, 
              },
              {
                label: `Lain-lain`,
                value: 4, 
              },
            ].map(v => ({
              label: <Flex
                css={t => ({
                  'span': {
                    fontWeight: 400,
                    fontSize: 12,
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                  }
                })}
              >
                {v.label}
              </Flex>,
              value: v.value.toString(),
            }))}
          />
        </Flex>
        <Flex
          pt={0}
          pb={2}
          px={2}
        >
          <Small>Deskripsi</Small>
          <textarea
            value={ticketAdd.message}
            onChange={e => {
              setTicketAdd(v => ({...v, message: e.target.value}))
            }}
            css={t => ({
              padding: t.spacing(2),
              outline: 'none',
              width: windowSize.width - t.spacing(9),
              minHeight: t.spacing(20),
            })}
          >
          </textarea>
        </Flex>
      </Paper>
      <Paper
        br={0}
      >
        <FlexRow
          p={2}
          jc={`flex-end`}
        >
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => {
              layout.loadingShow("Mengirim data ke server")
              insert({
                variables: {
                  objects: {
                    subject: ticketAdd.subject,
                    message: ticketAdd.message,
                    ticket_category_id: ticketAdd.ticket_category_id,
                    attachments: ticketAdd.attachments,
                  }
                }
              }).then(_ => {
                layout.confirm({
                  content: `Tiket berhasil dibuat`,
                  onClick: () => {
                    navigate(-1)
                  }
                })
              }).catch(e => {
                layout.confirm({
                  content: `Tiket gagal dibuat !`,
                  onClick: () => {}
                })
              }).finally(() => {
                layout.loadingHide()
              })
            }}
          >
            Buat 
          </Button>
        </FlexRow>
      </Paper>
    </Page>
  )
}

export default HelpdeskTicketAdd
