/** @jsxImportSource @emotion/react */

import { forwardRef, Ref, useEffect, useRef } from "react"
import Flex from "../Flex"
import { CommonProps, FlexProps } from "../interfaces"

interface PaperProps {
  shadow?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
  br?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
}

const Paper = ({shadow, br, children, ...other}: CommonProps & PaperProps & FlexProps, ref: Ref<HTMLDivElement>) => {

  const selectedShadow = shadow ? shadow : 1 
  const shadowArray = [0, 0.06, 0.12, 0.18, 0.24, 0.30, 0.36, 0.42, 0.48]

  return (
    <Flex
      ref={ref}
      css={t => ({
        background: `#ffffff`,
        boxShadow: `rgba(0, 0, 0, ${shadowArray[selectedShadow]}) 0px 2px 4px 0px, rgba(0, 0, 0, ${shadowArray[selectedShadow]}) 0px 0px 0px 0px`,
        borderRadius: br === undefined ? t.spacing(2) : t.spacing(br),
        gap: 0,
      })}
      {...other}
    >
      {children}
    </Flex>
  )
}

export default forwardRef(Paper) 
