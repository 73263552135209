/** @jsxImportSource @emotion/react */

import dayjs, { Dayjs } from "dayjs"
import { useEffect, useState } from "react"
import Flex from "../Flex"
import FlexRow from "../Flex/Row"
import InputText from "../forms/InputText"
import Small from "../typography/Small"

const currentTime = dayjs()
const currentTimePlus = dayjs().add(60, 'minute')

const hourOptions = ((new Array(24).fill(0)) as number[]).map((_, a) => ({
  label: (a).toString().padStart(2, '0'),
  value: (a).toString(),
}))

const minuteOptions = ((new Array(12).fill(0)) as number[]).map((_, a) => ({
  label: (a * 5).toString().padStart(2, '0'),
  value: (a * 5).toString(),
}))

const initTime = {
    date: currentTime.format("YYYY-MM-DD"),
    hour: currentTime.hour(),
    minute: currentTime.minute(),
    datetimeString: `${currentTime.format("YYYY-MM-DD")}T${String(currentTime.hour()).padStart(2, '0')}:${String(currentTime.minute()).padStart(2, '0')}:00`,
}

interface Props {
  label: string
  onChange: (newDateTime: Dayjs) => void
  initialDatetime?: string
}

const DateTimePicker = ({ label, onChange, initialDatetime }: Props) => {

  const [ time, setTime ] = useState(initTime)

  useEffect(() => {
    const newDatetimeString = `${time.date}T${String(time.hour).padStart(2, '0')}:${String(time.minute).padStart(2, '0')}:00`
    // console.log("new time", newDatetimeString)
    setTime(v => ({...v, datetimeString: newDatetimeString}))
    onChange(dayjs(newDatetimeString))

  }, [time.date, time.hour, time.minute])

  useEffect(() => {

    if(initialDatetime) {
      const dayjsObject = dayjs(initialDatetime) 
      setTime(v => ({
        ...v,
        datetimeString: initialDatetime,
        date: dayjsObject.format("YYYY-MM-DD"),
        hour: dayjsObject.hour(),
        minute: dayjsObject.minute(),
      }))
    }

  }, [initialDatetime])

  return (
    <Flex
      gap={1}
      p={2}
      css={t => ({
        border: `1px solid ${t.palette?.gray?.[500]}`,
        outline: `none`,
        ':hover': {
          border: `1px solid ${t.palette?.secondary?.main}`,
          outline: `4px solid ${t.palette?.secondary?.main}33`,
        },
        borderRadius: 4,
      })}
    >
      <FlexRow>
        <Flex gap={1}>
          <Small>{label}</Small>
          <InputText
            fullWidth
            value={time.date}
            slim
            type="date"
            onChange={e => {
              setTime(v => ({
                ...v,
                date: e.target.value,
              }))
            }}
          />
        </Flex>
        <Flex gap={1} w="auto">
          <Small>Jam</Small>
          <select
            value={time.hour}
            onChange={e => {
              setTime(v => ({...v, hour: parseInt(e.target.value)}))
            }}
          >
            {hourOptions.map((v, k) => <option key={`option-hour-start-${k}`} value={v.value}>{v.label}</option>)} 
          </select>
        </Flex>
        <Flex gap={1} w="auto">
          <Small>Menit</Small>
          <select
            value={time.minute}
            onChange={e => {
              setTime(v => ({...v, minute: parseInt(e.target.value)}))
            }}
          >
            {minuteOptions.map((v, k) => <option key={`option-minute-start-${k}`} value={v.value}>{v.label}</option>)} 
          </select>
        </Flex>
      </FlexRow>
    </Flex>
  )
} 

export default DateTimePicker 
