/** @jsxImportSource @emotion/react */
 
import { GiGraduateCap, } from "react-icons/gi"
import { BsMegaphoneFill } from "react-icons/bs"
import DividerGrow from "../../../shared/components/IFSuites/Divider/Grow"
import Flex from "../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import Paper from "../../../shared/components/IFSuites/Paper"
import H3 from "../../../shared/components/IFSuites/typography/H3"
import Small from "../../../shared/components/IFSuites/typography/Small"
import {} from "react-icons/gi"
import BottomNavigation from "../../../shared/components/BottomNavigation"
import { useNavigate } from "react-router-dom"
import useAuth from "../../../shared/hooks/use-auth"
import List from "../../../shared/components/List"
import appConfig, { APP_VERSION } from "../../../shared/variables/config"
import { useEffect, useState, } from "react"
import useBreakpoint from "../../../shared/hooks/use-breakpoint"
import dayjs from "dayjs"
import { MdGridView, MdMenuBook } from "react-icons/md"
import { useLazyQuery } from "@apollo/client"
import { GET_LECTURER_SCHEDULES_TODAY, LecturerSchedulesTodayQ } from "./get-lecturer-schedules-today.gql"
import ScheduleListItem from "./ScheduleListItem"
import { GET_STUDENT_SCHEDULES_TODAY, StudentSchedulesTodayQ } from "./get-student-schedules-today.gql"
import { FaChartLine, FaLaptopCode, FaMedal, FaNewspaper, FaQuran, FaSearch, FaShoppingBag, FaShoppingBasket, FaTrophy } from "react-icons/fa"
import mq from "../../../shared/utils/media-query"
import InputText from "../../../shared/components/IFSuites/forms/InputText"
import { useWindowSize } from "react-use"
import GridIconLayout from "../../../shared/components/Grid/Icon/Layout"
import { Button } from "../../../shared/components/IFSuites/Button"

const SearchWidget = () => {

  const url = `https://beta-sequran.vercel.app/api/ai-search?total=10&query=`

  const [keyword, setKeyword] = useState<string>("")

  return (
    <FlexRow>
      <InputText
        label="Cari"
        value={keyword}
        onChange={(e) => {
          setKeyword(e.target.value)
        }}
        fullWidth
        slim
        placeholder={`materi, info, produk, dsb.`}
      />
      <Button
        variant="icon"
        size="large"
        onClick={() => {

          const searchURL = `${url}${keyword}`

          fetch(searchURL).then(rawData => {
            return rawData
          }).then(json => {

            console.log("result", json)
          })
        }}
      >
        <FaSearch/>
      </Button>
    </FlexRow>
  )
}

const ActivityScheduleStudent = () => {

  const [get, getOps] = useLazyQuery<StudentSchedulesTodayQ>(GET_STUDENT_SCHEDULES_TODAY) 
  const auth = useAuth()
  const authInfo = auth.getInfo()

  const bp = useBreakpoint()
  const now = dayjs()

  useEffect(() => {
    get({
      variables: {
        player_id: authInfo?.playerUUID,
        datetime_from: `${now.format("YYYY-MM-DD[T]")}00:00:01`,
        datetime_to: `${now.format("YYYY-MM-DD[T]")}23:59:59`,
        day_number: now.format("d"),
        current_year: appConfig.defaultYear,
        current_semester: appConfig.defaultSemester, 
      }
    })
  }, [])

  return (
    <Paper>
      <FlexRow
        gap={0}
        css={t => ({
          padding: t.spacing(2),
          borderBottom: `1px solid ${t.palette?.gray?.[300]}`
        })}
      >
        <H3>Jadwal hari ini{bp !== "tablet" ? ` - ${now.format("dddd D MMMM YYYY")}` : ` - ${now.format("dddd D MMM 'YY")}`}</H3>
        <DividerGrow />
        {/* <Button */}
        {/*   size="small" */}
        {/*   // variant="outlined" */}
        {/* > */}
        {/*   Detail */}
        {/* </Button> */}
      </FlexRow>
      {getOps.data && (getOps.data.defense_defense.length + getOps.data.academic_classroom_schedule.length) > 0 ?
      <List>
      {
        [
          ...getOps.data.defense_defense.map((item, key) => {
            return {
              title: <Flex gap={0}>
                {item.category.title} {item.student.full_name} 
                <Small>{item.category.title}</Small> 
              </Flex>,
              dateFrom: dayjs(item.datetime_start),
              dateTo: dayjs(item.datetime_start).add(2, "hour"),
              location: item.place.title,
              url: `/defense/detail/${item.defense_id}`, 
            } 
          }),
          ...getOps.data.academic_classroom_schedule.map((item, key) => {
            return {
              title: <Flex gap={0}>
                {item.classroom.cys.course.title} - {item.classroom.code} 
              </Flex>,
              dateFrom: dayjs(`${now.format(`YYYY-MM-DDT`)}${item.time_start}:00`),
              dateTo: dayjs(`${now.format(`YYYY-MM-DDT`)}${item.time_end}:00`),
              location: item.place.title,
              url: `/classroom/${item.classroom_id}/sessions`, 
            } 
          }),
        ].sort((a, b) => {
          const aTS = a.dateFrom.format("HH:mm")
          const bTS = b.dateFrom.format("HH:mm")
          return (aTS && bTS && aTS < bTS) ? -1 : (aTS && bTS && aTS > bTS) ? 1 : 0
        }).map((item, key) => {
          return (
            <ScheduleListItem
              key={`course-list-item-${key}`}
              title={item.title}
              date={`${item.dateFrom.format("HH:mm")} - ${item.dateTo.format("HH:mm")}`}
              location={item.location}
              url={item.url}
            />
          )
        })
      }
      </List>
      : 
      <Flex p={2}>
        Tidak ada jadwal
      </Flex>
      }
    </Paper>
  )

}
const ActivityScheduleLecturer = () => {

  const [get, getOps] = useLazyQuery<LecturerSchedulesTodayQ>(GET_LECTURER_SCHEDULES_TODAY) 
  const auth = useAuth()
  const authInfo = auth.getInfo()

  const bp = useBreakpoint()
  const now = dayjs()

  useEffect(() => {
    get({
      variables: {
        player_id: authInfo?.playerUUID,
        datetime_from: `${now.format("YYYY-MM-DD[T]")}00:00:01`,
        datetime_to: `${now.format("YYYY-MM-DD[T]")}23:59:59`,
        day_number: now.format("d"),
        current_year: appConfig.defaultYear,
        current_semester: appConfig.defaultSemester, 
      }
    })
  }, [])

  return (
    <Paper>
      <FlexRow
        gap={0}
        css={t => ({
          padding: t.spacing(2),
          borderBottom: `1px solid ${t.palette?.gray?.[300]}`
        })}
      >
        <H3>Jadwal hari ini{bp !== "tablet" ? ` - ${now.format("dddd D MMMM YYYY")}` : ` - ${now.format("dddd D MMM 'YY")}`}</H3>
        <DividerGrow />
        {/* <Button */}
        {/*   size="small" */}
        {/*   // variant="outlined" */}
        {/* > */}
        {/*   Detail */}
        {/* </Button> */}
      </FlexRow>
      {getOps.data && (getOps.data.defense_defense_evaluator.length + getOps.data.academic_classroom_schedule.length) > 0 ?
      <List>
      {
        [
          ...getOps.data.defense_defense_evaluator.map((item, key) => {
            return {
              title: <Flex gap={0}>
                {item.defense.category.title} {item.defense.student.full_name} 
                <Small>{item.type.title}</Small> 
              </Flex>,
              dateFrom: dayjs(item.defense.datetime_start),
              dateTo: dayjs(item.defense.datetime_start).add(2, "hour"),
              location: item.defense.place.title,
              url: `/defense/evaluation/${item.defense.defense_id}`, 
            } 
          }),
          ...getOps.data.academic_classroom_schedule.map((item, key) => {
            return {
              title: <Flex gap={0}>
                {item.classroom.cys.course.title} - {item.classroom.code} 
              </Flex>,
              dateFrom: dayjs(`${now.format(`YYYY-MM-DDT`)}${item.time_start}:00`),
              dateTo: dayjs(`${now.format(`YYYY-MM-DDT`)}${item.time_end}:00`),
              location: item.place.title,
              url: `/classroom/${item.classroom_id}/sessions`, 
            } 
          }),
        ].sort((a, b) => {
          const aTS = a.dateFrom.format("HH:mm")
          const bTS = b.dateFrom.format("HH:mm")
          return (aTS && bTS && aTS < bTS) ? -1 : (aTS && bTS && aTS > bTS) ? 1 : 0
        }).map((item, key) => {
          return (
            <ScheduleListItem
              key={`course-list-item-${key}`}
              title={item.title}
              date={`${item.dateFrom.format("HH:mm")} - ${item.dateTo.format("HH:mm")}`}
              location={item.location}
              url={item.url}
            />
          )
        })
      }
      </List>
      : 
      <Flex p={2}>
        Tidak ada jadwal
      </Flex>
      }
    </Paper>
  )

}

const PlayerDashboard = () => {

  const navigate = useNavigate()
  const bp = useBreakpoint()

  const auth = useAuth()
  const authInfo = auth.getInfo()

  const size = useWindowSize()

  useEffect(() => {
    const currentRole = localStorage.getItem('hasuraCurrentRole');
    if(currentRole === null) {
      auth.purgeInfo()
      auth.purgeAdminInfo()
      navigate("/")
    }

    if(authInfo?.roles) {
      authInfo.roles.forEach(v => {
        if(["super_admin", "mahasiswa", "dosen"].includes(v)) {
          auth.purgeInfo()
          auth.purgeAdminInfo()
          navigate("/")
        }
      })
    }

  }, [])

  return (
    <Flex
      className="PlayerDashboard"
      pb={12}
      jc="flex-start"
      css={t => ({
        minHeight: window.innerHeight,
        gap: t.spacing(2),
        background: t.palette?.gray?.[100],
      })}
    >
      <Flex px={2} pt={2}>
        <Paper
          css={t => ({
            padding: t.spacing(2),
            background: t.palette?.primary?.main,
          })}
        >
          <FlexRow>
            Selamat datang, <b>{authInfo?.nickname}</b> 
          </FlexRow>
        </Paper>
      </Flex>

      <FlexRow px={2}>
        <SearchWidget />
      </FlexRow>

      <Flex px={1}>
        <GridIconLayout
          items={[
            {
              title: 'Info', 
              icon: <BsMegaphoneFill size={24} />,
              onClick: () => {
                navigate("/info")
              }
            },
            ...[{
              title: 'Kuliah', 
              icon: <MdMenuBook size={24} />,
              onClick: () => {
                navigate("/classroom")
              }
            }].filter(() => (auth.isStudent() || auth.isLecturer())),
            {
              title: 'IbTiTah',
              icon: <FaQuran size={24} />,
              onClick: () => {
                navigate("/mentorship")
              }
            },
            {
              title: 'TA & Sidang',
              icon: <GiGraduateCap size={24} />,
              onClick: () => {
                navigate("/defense")
              }
            },
            {
              title: 'Karir',
              icon: <FaTrophy size={24} />,
              onClick: () => {
                navigate("/career")
              }
            },
            {
              title: 'Market',
              icon: <FaShoppingBasket size={24} />,
              onClick: () => {
                navigate("/marketplace")
              }
            },
            ...[{
              title: 'Monitoring',
              icon: <FaChartLine size={24} />,
              onClick: () => {
                navigate("/indicator")
              },
            }].filter(() => (auth.isProgrammeAdmin() || auth.isLecturer())),
            {
              title: 'Digiteam',
              icon: <FaLaptopCode size={24} />,
              onClick: () => {
                navigate("/portal/digiteam")
              }
            },
            {
              title: 'Lainnya',
              icon: <MdGridView size={24} />,
              onClick: () => {
                navigate("/portal/other-menus")
              }
            },
          ]}
        />
      </Flex>

      <Flex px={2}>
        {(auth.isLecturer() && <ActivityScheduleLecturer />)}
        {(auth.isStudent() && <ActivityScheduleStudent />)}
      </Flex>

      <Flex ai="center" mt={2}>
        <Small>{APP_VERSION}</Small>
      </Flex>

      {/*
      <Paper>
        <FlexRow
          gap={0}
          py={1}
          px={2}
          css={t => ({
            borderBottom: `1px solid ${t.palette?.gray?.[300]}`
          })}
        >
          <Small>Update Aplikasi 8 Des 2023</Small>
        </FlexRow>
        <Flex>
          <ul>
            <li><Small>Add: Penilaian sidang</Small></li>
            <li><Small>Add: Jadwal Sidang akan datang</Small></li>
            <li><Small>Add: Jadwal Sidang hari ini</Small></li>
            <li><Small>Add: Pengelolaan Sidang</Small></li>
            <li><Small>Add: Aktivasi Push Notification</Small></li>
          </ul>
        </Flex>
      </Paper>
      */}

      <BottomNavigation />
    </Flex>
  )
}

export default PlayerDashboard
