/** @jsxImportSource @emotion/react */

import { useLazyQuery } from "@apollo/client"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import List from "../../../shared/components/List"
import ListItem from "../../../shared/components/List/Item"
import Page from "../../../shared/components/Page"
import { GetPLOScore, GET_PLO_SCORE } from "./gql"


import * as d3 from "d3"


// https://blog.logrocket.com/getting-started-d3-js-react/
const BarChart = (props: {
  data?: {
    label: string
    value: number
  }[]
}) => {

  console.log("props", props.data)

  const d3Container = useRef<SVGSVGElement | null>(null)

  useEffect(() => {


    if(props.data && d3Container.current) {

      const normalizedValue = props.data.map(v => {
        const value = v.value ? v.value : 0.1 
        return value
      })

      const svg = d3.select(d3Container.current)

      console.log("d3Container", d3Container, props.data, normalizedValue)


      const update = svg.selectAll("rect")
        .data(normalizedValue)
        .enter()
        .append("rect")
        .attr("x", (d, i) => i * 70)
        .attr("y", (d, i) => 200 - 10 * d)
        .attr("width", 65)
        .attr("height", (d, i) => d * 10)
        .attr("fill", "green")

      update.exit().remove()


    }

  }, [d3Container.current, props.data]) 

  return (
    <svg
      css={{zIndex: 99}}
      className="d3-bar-chart"
      width={700}
      height={200}
      ref={d3Container}
    />
  )
}

const IndicatorPLO = () => {

  const [academicYear, setAcademicYear] = useState(2023)
  const navigate = useNavigate()

  const [getPLOScore, getPLOScoreOps] = useLazyQuery<GetPLOScore>(
    GET_PLO_SCORE,
    {
      variables: {
        year_academic_input: academicYear,
      }
    }
  )

  useEffect(() => {
    getPLOScore() 
  }, [academicYear])

  return (
    <Page
      title={`Capaian Pembelajaran Lulusan ${academicYear}/${academicYear+1}`}
    >
      <List>
        {getPLOScoreOps.data &&
          <ListItem>
            <BarChart data={getPLOScoreOps.data.scores.map(v => ({
              label: v.code,
              value: v.details.plo_score ? v.details.plo_score : 0,
            }))} />
          </ListItem>
        }
        <ListItem>
          <table css={t => ({
            borderCollapse: "collapse",
            th: {
              textAlign: "left",
              '&:nth-last-of-type': {
                textAlign: "right",
              },
              padding: 4,
            },
            td: {
              textAlign: "left",
              '&:nth-last-of-type': {
                textAlign: "right",
              },
              padding: 8,
              borderBottom: `1px solid ${t.palette?.gray?.[300]}`
            },
          })}>
            <thead>
              <tr>
                <th>Kode PLO</th>
                <th>Deskripsi</th>
                <th>Nilai</th>
              </tr> 
            </thead>
            <tbody>
              {getPLOScoreOps.data?.scores.map((row, index) => {

                const score = row.details.plo_score?.toFixed(2)
                return (
                  <tr key={`table-item-${index}`}>
                    <td>{row.code}</td>
                    <td>{row.description}</td>
                    <td>{score ? score : '-'} / 100</td>
                  </tr>
                )
              })} 
            </tbody>
          </table>
        </ListItem>
      </List>
    </Page>
  )
}

export default IndicatorPLO
