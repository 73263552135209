/** @jsxImportSource @emotion/react */

import _ from "lodash"
import { gql, useQuery } from "@apollo/client"
import dayjs from "dayjs"
import { FaCheck, FaPlus, FaUpload, FaUser } from "react-icons/fa"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Button } from "../../../../shared/components/IFSuites/Button"
import DividerGrow from "../../../../shared/components/IFSuites/Divider/Grow"
import Flex from "../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../shared/components/IFSuites/Flex/Row"
import Paper from "../../../../shared/components/IFSuites/Paper"
import Small from "../../../../shared/components/IFSuites/typography/Small"
import List from "../../../../shared/components/List"
import ListItem from "../../../../shared/components/List/Item"
import useAuth from "../../../../shared/hooks/use-auth"
import { ClassroomDetailQ } from "../../Home/models"
import AssessmentListItem from "../List/Item"
import { learningCreateAtom } from "../../states/learning-create"
import { useAtom } from "jotai"
import { useEffect } from "react"
import ContextMenuWithTrigger from "../../../../shared/components/ContextMenuWithTrigger"
import AniWrapperBeating from "../../../../shared/components/AniWrapper/AniWrapperBeating"
import IconLoading from "../../../../shared/components/IconLoading"

interface AssessmentQ {
  _: {
    learning_id: string
    learning_template_id: number 
    datetime_start: string
    datetime_end: string
    title: string
    grading_comp_id: number
    participations: {
      not_graded_answers: {
        aggregate: {
          count: number
        }
      }
      graded_answers: {
        aggregate: {
          count: number
        }
      }
      datetime_submit: string | null
    }[]
    questions: {
      clo: {
        clo_id: string
        clo: {
          code: string
        }
      }
    }[]
  }[]
  classroom: {
    items: {
      classroom_item_id: string
      classroom_item_type_id: string
    }[]
  }
}

const ClassroomAssessmentHome = ({data}: {data?: ClassroomDetailQ}) => {

  const { classroomID } = useParams()
  const auth = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const [learningCreate, setLearningCreate] = useAtom(learningCreateAtom)

  const GET_ASSESSMENTS = gql`
    query GetAssessments($classroom_id: uuid = "") {
      _: academic_learning(where: {classroom_item: {classroom_id: {_eq: $classroom_id}}, learning_template_id: {_eq: "2"}}) {
        learning_id
        learning_template_id
        datetime_start
        datetime_end
        title
        grading_comp_id
        participations {
          answers_aggregate(where: {graded_by: {_is_null: true}}) {
            aggregate {
              count
            }
          }
        }
        participations {
          not_graded_answers: answers_aggregate(where: {graded_by: {_is_null: true}}) {
            aggregate {
              count
            }
          }
          graded_answers: answers_aggregate(where: {graded_by: {_is_null: false}}) {
            aggregate {
              count
            }
          }
          datetime_submit
        }
        questions {
          clo {
            clo_id
            clo {
              code
            }
          }
        }
      }
      classroom: academic_classroom_by_pk(classroom_id: $classroom_id) {
        items(where: {classroom_item_type_id: {_eq: "1"}}) {
          classroom_item_id
          classroom_item_type_id
        }
      }
    }
  `

  const {data: assessments, refetch, loading} = useQuery<AssessmentQ>(
    GET_ASSESSMENTS, {
      nextFetchPolicy: "network-only",
      variables: {
        classroom_id: classroomID,
      }
  })

  useEffect(() => {

    refetch()

  }, [location.pathname])

  return (
    <Flex gap={2}>
      <Paper br={0}>
        {loading && 
          <Flex css={{width: `100%`}} ai="center" pt={4} pb={4}>
            <IconLoading size={48} />
          </Flex>
        }
        {!loading &&
          <List
            title={`Jumlah Soal per CPMK`}
            collapsible
            css={t => ({
              '.children-container': {
                '.ListItem': {
                  paddingTop: t.spacing(1),
                  paddingBottom: t.spacing(1),
                }
              }
            })}
          >
              {data?._.cys.clos.map((item, key) => (
                <ListItem
                  key={`clo-list-item-${key}`}
                  title={item.clo.description}
                >
                  <Flex
                    w={120}
                  >
                    {item.clo.code}
                  </Flex>
                  <Flex
                    css={t => ({
                      color: item.questions.aggregate.count > 0 ? "auto" : t.palette?.error?.main,
                    })}
                  >
                    {item.questions.aggregate.count}
                  </Flex>
                </ListItem>
              ))}
          </List>
        }
      </Paper>

      {!loading && [
        {
          title: `Tugas Mandiri`,
          id: 2,
        },
        {
          title: `Tugas Terstruktur`,
          id: 3,
        },
        {
          title: `Ujian Tengah Semester`,
          id: 4,
        },
        {
          title: `Ujian Akhir Semester`,
          id: 5,
        },
        {
          title: `Softskill / Absensi`,
          id: 1,
        },
      ].map((item, key) => {

        const filteredAssessment = assessments?._.filter(v => v.grading_comp_id == item.id)

        return (
          <Paper
            key={`assessment-grade-comps-list-item-${key}`}
            br={0}
          >
            {loading}
            <List
              title={
                <FlexRow>
                  {`${item.title} (${filteredAssessment?.length})`}
                  <DividerGrow />
                  {auth.isLecturer() // && assessments && assessments.classroom && assessments.classroom.items.length > 0 
                      && filteredAssessment && data &&
                    <ContextMenuWithTrigger 
                      trigger={(setOpen) => {
                        return (
                          <AniWrapperBeating disable={filteredAssessment.length > 0 ? true : undefined}>
                            <Button 
                              px={0}
                              variant={"icon"}
                              size="medium"
                              color={filteredAssessment.length === 0 ? `error` : `secondary`}
                              onClick={(e) => {
                                setOpen(true)
                              }}
                            >
                              <FaPlus />
                            </Button> 
                          </AniWrapperBeating>
                        )
                      }}
                      menus={data?._.items.map((classroomItem, classroomItemKey) => (
                        <ListItem
                          key={`grading-component-${item.id}-classroom-item-${key}`}
                          onClick={() => {
                            if(classroomItem.classroom_item_id) {
                              setLearningCreate(v => ({
                                ...v,
                                grading_comp_id: item.id,
                              }))
                              navigate(`/classroom/meeting/${classroomItem.classroom_item_id}/assessment/create`)
                            }
                          }}
                        >
                          {classroomItem.title}
                        </ListItem>
                      ))}
                    />
                  }
                </FlexRow>
              }
            >
              {filteredAssessment?.map(v => {
                const now = dayjs()
                const startDatetime = dayjs.utc(v.datetime_start, "YYYY-MM-DD HH:mm:ss")
                const elapsed = {
                  day: now.diff(startDatetime, 'd'),
                  hour: now.diff(startDatetime, 'h'),
                  minutes: now.diff(startDatetime, 'm'),
                }
                const hourElapsed = now.diff(startDatetime, 'h')

                return {
                  ...v,
                  elapsed: elapsed, 
                  progress: {
                    totalParticipant: v.participations.length,
                    totalNotSubmittedParticipant: v.participations.filter(v2 => v2.datetime_submit === null).length,
                    totalNotYetGraded: _.sum(v.participations.map(v2 => v2.not_graded_answers.aggregate.count)),
                    totalGraded: _.sum(v.participations.map(v2 => v2.graded_answers.aggregate.count)),
                  },
                }
                
              }).map((item, key) => (
                <AssessmentListItem
                  key={`assessment-list-item-${key}`}
                  playerType={auth.isLecturer() ? "lecturer" : "student"}
                  title={item.title} 
                  elapsed={
                    <Flex>
                      {auth.isLecturer() &&
                        <FlexRow jc={"flex-start"} ai={"start"}>
                          <FlexRow w={80}>
                            <FaUser /> {item.progress.totalParticipant}
                          </FlexRow>
                          <FlexRow w={80}>
                            <FaUpload /> {item.progress.totalParticipant - item.progress.totalNotSubmittedParticipant} 
                          </FlexRow>
                          <FlexRow w={80}>
                            <FaCheck /> {item.progress.totalGraded} / {item.progress.totalNotYetGraded + item.progress.totalGraded}
                          </FlexRow>
                        </FlexRow>
                      }
                      <Small>
                        {`Telah berjalan ${item.elapsed.day > 0 ? ` ${item.elapsed.day} hari` : ""} ${(item.elapsed.hour % 24)} jam ${(item.elapsed.minutes % 60)} menit`}
                      </Small>
                    </Flex>
                  }
                  learningID={item.learning_id}
                />
              ))}
            </List>
          </Paper>
        )
      })}

    </Flex>
  )
}

export default ClassroomAssessmentHome
