import { gql } from "@apollo/client";

export const GET_UPCOMING_DEFENSES = gql`
query GetUpcomingDefense($defense_category_id: smallint = "") {
  defense_defense(where: {is_done: {_eq: 0}, defense_category_id: {_eq: $defense_category_id}}, order_by: {datetime_start: asc}) {
    defense_id
    datetime_start
    student_id
    student {
      full_name
    }
    title
    place {
      place_id
      title
    }
    evaluators {
      player {
        as_staff {
          full_name
          nip
        }
        player_id
      }
      type {
        title
      }
    }
  }
  defense_evaluator_type(where: {defense_category_id: {_eq: $defense_category_id}}) {
    evaluator_type_id
    title
  }
}
`

export interface GetDefensesQ {
  defense_defense: {
    defense_id: string
    datetime_start: string
    student_id: string
    student: {
      full_name: string
    }
    title: string
    place: {
      place_id: string
      title: string
    }
    evaluators: {
      player: {
        as_staff: {
          full_name: string
          nip: string
        }
        player_id: string
      }
      type: {
        title: string
      }
    }[]
  }[]
  defense_evaluator_type: {
    evaluator_type_id: number
    title: string
  }[]
} 
