/** @jsxImportSource @emotion/react */

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { useAtom } from "jotai"
import { ReactNode, useEffect, useState } from "react"
import { FaDotCircle, FaEdit, FaEllipsisV, FaPlus, FaSearch, FaSpider, FaTimes, FaUserTie, } from "react-icons/fa"
import { GiRat, GiSpiderWeb } from "react-icons/gi"
import { TbTargetArrow } from "react-icons/tb"
import { useLocation, useNavigate } from "react-router-dom"
import { useWindowSize } from "react-use"
import AniWrapperBeating from "../../../../shared/components/AniWrapper/AniWrapperBeating"
import ContextMenu from "../../../../shared/components/ContextMenu"
import ContextMenuWithTrigger from "../../../../shared/components/ContextMenuWithTrigger"
import { Button } from "../../../../shared/components/IFSuites/Button"
import Flex from "../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../shared/components/IFSuites/Flex/Row"
import Paper from "../../../../shared/components/IFSuites/Paper"
import Small from "../../../../shared/components/IFSuites/typography/Small"
import ListItem from "../../../../shared/components/List/Item"
import useLayout from "../../../../shared/hooks/use-layout"
import useLearningAdd from "../../hooks/use-learning-add"
import ClassroomEnrollmentDialogAdd, { enrollConfigAtom } from "../../Enrollment/Dialog/Add"
import { INSERT_STUDENT_ENROLLMENT } from "../../Enrollment/insert-student-enrollment"
import { ClassroomDetailQ } from "../../Home/models"
import { GET_CLASSROOM_GRADE, IClassroomGrade } from "../get-classroom-grade"
import IconLoading from "../../../../shared/components/IconLoading"


const ClassroomGrade = ({data: propsData}: {data?: ClassroomDetailQ}) => {

  const [, setEnrollConfig] = useAtom(enrollConfigAtom)
  const size = useWindowSize()
  const cellHeight = 128
  const location = useLocation()
  const navigate = useNavigate()
  const layout = useLayout()
  const [mouseClick, setMouseClick] = useState({
    x: 0,
    y: 0,
  })
  const { setLearningCreate, } = useLearningAdd() 

  const initStudentContextMenu = {
    x: 0,
    y: 0,
    studentID: null,
    open: false, 
    studentNIM: null,
    studentFullName: null,
  }

  const [studentContextMenu, setStudentContextMenu] = useState<{
    x: number
    y: number
    studentID: string | null
    studentNIM: string | null
    studentFullName: string | null
    open: boolean
  }>(initStudentContextMenu)

  const [getClassroomGrade, classroomGradeQuery] = useLazyQuery<IClassroomGrade>(GET_CLASSROOM_GRADE, {
    variables: {
      classroom_id: propsData?._.classroom_id,
    }
  })

  useEffect(() => {
    console.log("change location", location.pathname)
    getClassroomGrade({
      nextFetchPolicy: "network-only",
    })
  }, [])

  useEffect(() => {
    classroomGradeQuery.refetch()
  }, [location.pathname])

  const [insertStudent, {data: insertStudentData}] = useMutation(
    INSERT_STUDENT_ENROLLMENT,
  )

  const buildTable = () => {

    if(!classroomGradeQuery.data) return (<td></td>)

    const data = classroomGradeQuery.data

    const cloList = data.academic_v_clo_by_classroom_id
    const gradingCompList = data.academic_v_clo_grading_comp_by_classroom_id
    const assessmentList = data.academic_v_assessments_cys_clo_by_classroom_id 
    const assessmentQuestionList = data.academic_v_learning_questions_by_classroom_id
    const participantList = data.academic_v_students_by_classroom_id
    const participantAnswerList = data.academic_v_learning_participation_answer_by_student_enrollment_id

    const participantRowTemplate: {
      type: "name" | "finalScore" | "cloScore" | "questionAnswerScore" | "empty"
      cysCLOID?: string 
      learningID?: string 
      gradingCompID?: string
      questionID?: string
      questionWeight?: number
    }[] = [
      {
        type: "name",
      },
      {
        type: "finalScore",
      },
    ]

    let colNumber = 2 
    let trHeadList: ReactNode[] = []
    let trBodyList: ReactNode[] = []
    const cloRow: ReactNode[] = []
    const gradingCompRow: ReactNode[] = []
    const assessmentRow: ReactNode[] = []
    const assessmentQuestionRow: ReactNode[] = []

    const buildCLORow = (
      elements: ReactNode[]
    ) => {
      return (
        <tr className="header">
          <th className="first-column">
            <FlexRow px={1} jc="flex-end" css={{height: cellHeight}}>
              CPMK & Bobot
            </FlexRow>
          </th>
          <td className="cloHeader1" rowSpan={4}>
            <Flex px={1} css={{height: cellHeight, fontWeight: 700, fontSize: 24,}}>
              Nilai Akhir<br />Mata Kuliah 
            </Flex>
          </td>
          {elements}
        </tr>
      )
    }

    const buildGradingCompRow = (
      elements: ReactNode[]
    ) => {
      return (
        <tr className="header">
          <th className="first-column">
            <FlexRow px={1} jc="flex-end" css={{height: cellHeight}}>
              Komponen Penilaian & Bobot
            </FlexRow>
          </th>
          {elements}
        </tr>
      )
    }

    const buildAssessmentRow = (
      elements: ReactNode[]
    ) => {
      return (
        <tr className="header">
          <th className="first-column">
            <FlexRow px={1} jc="flex-end" css={{height: cellHeight}}>
              Pelaksanaan Asesmen
            </FlexRow>
          </th>
          {elements}
        </tr>
      )
    }

    const buildAssessmentQuestionRow = (
      elements: ReactNode[]
    ) => {
      colNumber += (elements.length + cloList.length) 

      return (
        <tr className="header">
          <th className="first-column">
            <FlexRow px={1} jc="flex-end" css={{height: cellHeight}}>
              No. Soal & Bobot
            </FlexRow>
          </th>
          {elements}
        </tr>
      )
    }

    cloList.forEach((item, key) => {

      participantRowTemplate.push({
        type: "cloScore",
        cysCLOID: item.course_year_sem_clo_id,
      })

      const thisGradingCompList = gradingCompList.filter(
        v => v.course_year_sem_clo_id === item.course_year_sem_clo_id
        && v.weight > 0
      )

      let cloRowspan = 1 

      // Build grading component row
      gradingCompRow.push(
        <td className={`cloHeader${key % 2 == 0 ? '2' : '1'}`} rowSpan={3}>
          <Flex px={1} css={t => ({height: cellHeight, fontWeight: 700})}>
            Nilai Akhir<br />{item.code}
          </Flex>
        </td>,
      )

      thisGradingCompList.forEach((item2, key2) => {

        let gradingCompRowspan = 0 
        let totalQuestion = 0

        // Build assessment row
        const thisAssessmentList = assessmentList.filter(
          v => v.course_year_sem_clo_id === item.course_year_sem_clo_id
          && v.grading_comp_id === item2.grading_comp_id
        )

        if(thisAssessmentList.length === 0) {
          cloRowspan += 1
          gradingCompRowspan += 1

          assessmentRow.push(
            <td className="long">
              <Flex px={1} css={{height: cellHeight}} title={`Belum ada ${item2.title}`} ai="center">
                <GiRat color="#E53935" size={24} />
              </Flex>
            </td>
          )

          assessmentQuestionRow.push(
            <td className="long">
              <Flex px={1} css={{height: cellHeight}} title={`Belum ada soal ${item2.title}`} ai="center">
                <GiRat color="#E53935" size={24} />
              </Flex>
            </td>
          )

          participantRowTemplate.push({
            type: "empty",
          })
        }

        thisAssessmentList.forEach((item3, key3) => {
          let assessmentRowspan = 0

          // Build assessment question row
          const thisAssessmentQuestionList = assessmentQuestionList.filter(
            v => v.learning_id === item3.learning_id 
            && v.course_year_sem_clo_id === item.course_year_sem_clo_id
          )

          const defaultQuestionWeight = Number(((item2.weight / thisAssessmentList.length) / thisAssessmentQuestionList.length).toFixed(2)) 

          thisAssessmentQuestionList.forEach((item4, key4) => {

            assessmentRowspan += 1
            cloRowspan += 1
            gradingCompRowspan += 1

            participantRowTemplate.push({
              type: "questionAnswerScore",
              cysCLOID: item.course_year_sem_clo_id,
              learningID: item3.learning_id,
              questionID: item4.learning_question_id,
              questionWeight: defaultQuestionWeight,
            })

            totalQuestion += 1
            assessmentQuestionRow.push(
              <td className="long">
                <Flex px={1} css={{height: cellHeight}} title={`Soal no. ${item4.item_order + 1} - ${item3.title}`} ai="center">
                  <Button size="small" color="info" variant="icon" br={1}>#{item4.item_order + 1}</Button>
                  <Button br={1} px={0} variant={"icon"} size="small" color="success">{defaultQuestionWeight.toFixed(2)} % <FaEdit /></Button> 
                </Flex>
              </td>
            )
          })

          assessmentRow.push(
            <td className="long" colSpan={assessmentRowspan}>
              <Flex px={1} css={{height: cellHeight}} title={item3.title} ai="center">
                <Button br={1} px={0} variant={"icon"} size="small" color="secondary" onClick={() => {navigate(`/classroom/assessment/${item3.learning_id}/grading`)}}>
                  {item2.abbr} {key3 + 1} <FaSearch />
                </Button> 
                <Button br={1} px={0} variant={"icon"} size="small" color="success">{(item2.weight / thisAssessmentList.length).toFixed(2)} % <FaEdit /></Button> 
              </Flex>
            </td>
          )
        })

        // Build grading component row
        gradingCompRow.push(
          <td className="long" colSpan={gradingCompRowspan}>
            <Flex px={1} css={{height: cellHeight}} title={item2.title} ai="center">
              
              <Button br={1} px={0} variant={"icon"} size="large" color="info">{item2.abbr}</Button> 
              <Button br={1} px={0} variant={"icon"} size="large" color="success">{item2.weight}%</Button> 
              
              {classroomGradeQuery.data && classroomGradeQuery.data.academic_classroom_item &&
              <ContextMenuWithTrigger 
                top={mouseClick.y}
                left={mouseClick.x}
                trigger={(setOpen) => {
                  return (
                    <AniWrapperBeating disable={totalQuestion > 0 ? true : undefined}>
                      <Button 
                        px={0}
                        variant={"icon"}
                        size="medium"
                        color={totalQuestion === 0 ? `error` : `secondary`}
                        onClick={(e) => {
                          setMouseClick({
                            x: e.clientX,
                            y: e.clientY,
                          })
                          setOpen(true)
                        }}
                      >
                        <FaPlus />
                      </Button> 
                    </AniWrapperBeating>
                  )
                }}
                menus={classroomGradeQuery.data?.academic_classroom_item.map((item, key) => (
                  <ListItem
                    key={`item-${key}`}
                    onClick={() => {
                      if(item.classroom_item_id) {
                        setLearningCreate(v => ({
                          ...v,
                          grading_comp_id: item2.grading_comp_id,
                        }))
                        navigate(`/classroom/meeting/${item.classroom_item_id}/assessment/create`)
                      }
                    }}
                  >
                    {item.title}
                  </ListItem>
                ))}
              />
              }
            </Flex>
          </td>
        )

      })

      // Build CLO row
      cloRow.push(
        <td className={`cloHeader${key % 2 == 0 ? '2' : '1'}`} colSpan={cloRowspan} key={`clo-header-${key}`}>
          <Flex px={1} css={{height: cellHeight, fontWeight: 700}} title={item.description}>
            <FlexRow ai="center">
              <TbTargetArrow size={24} /> {item.code} 
            </FlexRow>
            <Button br={1} px={0} variant={"icon"} size="large" color="success">{item.weight}%</Button>
          </Flex>
        </td>
      )

    })

    trHeadList.push(buildCLORow(cloRow)) 
    trHeadList.push(buildGradingCompRow(gradingCompRow)) 
    trHeadList.push(buildAssessmentRow(assessmentRow))
    trHeadList.push(buildAssessmentQuestionRow(assessmentQuestionRow))

    participantList.forEach((item, key) => {

      trBodyList.push(<tr>
        {participantRowTemplate.map((item2, key2) => {

          // Get participant answer list for this participant
          const thisParticipantAnswerList = participantAnswerList.filter(v => v.student_enrollment_id === item.student_enrollment_id)

          switch(item2.type) {
            case "name":
              return (
                <td>
                  <FlexRow px={1} gap={2}>
                    <Flex w={`auto`}>
                      <Small>{key + 1}</Small> 
                    </Flex>
                    <Flex gap={0}>
                      <Flex>
                        <b>{item.full_name}</b> 
                      </Flex>
                      <Flex>
                        {item.nim} 
                      </Flex>
                    </Flex>
                    <Button
                      color="secondary"
                      variant="icon"
                        onClick={(e) => {
                          // console.log(item.student.full_name)
                          // console.log("windowsSize", windowSize.height, e.clientY)
                          setStudentContextMenu(v => ({
                            open: true,
                            x: e.clientX - 16,
                            y:  e.clientY - (e.clientY > (size.height/2) ? 160 : 16),
                            studentID: item.student_id,
                            studentNIM: item.nim,
                            studentFullName: item.full_name,
                          }))
                        }}
                    >
                      <FaEllipsisV />
                    </Button>
                  </FlexRow>
                </td>
              )
            case "finalScore":
              let finalScore = 0

              // Get participant answer list for this participant

              // Calculate CLO score for this CLO with this participant
              participantRowTemplate
              // Filter participant row template for this CLO
              .filter(v => v.type === "questionAnswerScore")
              // Iterate each question in this CLO
              .forEach(v => {

                // Get participant answer for this  
                const thisQuestionAnswer = thisParticipantAnswerList.find(
                  w =>  w.learning_question_id === v.questionID 
                )

                const questionAnswerScore = thisQuestionAnswer && thisQuestionAnswer.datetime_graded !== null ? thisQuestionAnswer.score : 0 
                const questionAnswerScoreXWeight = v.questionWeight ? questionAnswerScore * v.questionWeight / 100 : 0

                finalScore += questionAnswerScoreXWeight 
              })

              return (
                <td>
                  <Flex px={1} gap={0} css={{fontWeight: 700}}>
                    {finalScore.toFixed(3)}
                  </Flex>
                </td>
              )
            case "cloScore":
              let cloScore = 0

              // Calculate CLO score for this CLO with this participant
              participantRowTemplate
              // Filter participant row template for this CLO
              .filter(v => v.cysCLOID === item2.cysCLOID && v.type === "questionAnswerScore")
              // Iterate each question in this CLO
              .forEach(v => {

                // Get participant answer for this  
                const thisQuestionAnswer = thisParticipantAnswerList.find(
                  w =>  w.learning_question_id === v.questionID 
                )

                const questionAnswerScore = thisQuestionAnswer && thisQuestionAnswer.datetime_graded !== null ? thisQuestionAnswer.score : 0 
                const questionAnswerScoreXWeight = v.questionWeight ? questionAnswerScore * v.questionWeight / 100 : 0

                cloScore += questionAnswerScoreXWeight 
              })

              return (
                <td>
                  <Flex px={1} gap={0} css={{fontWeight: 700}}>
                    {cloScore.toFixed(3)}
                  </Flex>
                </td>
              )
            case "questionAnswerScore":

              const thisQuestionAnswer = participantAnswerList.find(v => v.student_enrollment_id === item.student_enrollment_id && v.learning_question_id === item2.questionID)
              const questionAnswerScore = thisQuestionAnswer?.datetime_graded !== null ? thisQuestionAnswer?.score : null 
              const questionAnswerScoreTimesWeight = (questionAnswerScore && questionAnswerScore && item2.questionWeight) ?
                (questionAnswerScore * item2.questionWeight / 100) : null 

              return (
                <td className="long">
                  <Flex px={1} css={t => ({})} gap={0}>
                    <Button br={1} px={0} variant={"icon"} size="small" color="info">{questionAnswerScoreTimesWeight}</Button> 
                    <Small>{questionAnswerScore}</Small> 
                  </Flex>
                </td>
              )
            default:
              return (
                <td></td>
              )
          }

        })}
      </tr>)
    })

    return <table>
      <thead>
        {trHeadList}
      </thead>
      <tbody>
        <tr>
          <td>
            <FlexRow
              jc="space-between"
              p={1}
            >
              Anggota Kelas
              <Button
                // size="small"
                color="secondary"
                variant="icon"
                onClick={() => {
                  setEnrollConfig(v => ({
                    ...v,
                    open: true,
                    redClassroomCode: propsData?._.code,
                    classroomID: propsData?._.classroom_id,
                    refetch: classroomGradeQuery.refetch,
                  }))
                }}
              >
                <FaPlus />{/* bp !== "tablet" && "Tambah" */} 
              </Button>    
            </FlexRow>
          </td> 
          {
            ([...Array(colNumber - 1)]).map((item, key) => {
              return (
                <td></td>
              )
            })
          }
        </tr>
        {trBodyList}
      </tbody>
    </table>  
  }

  return (
    <Paper br={0}>
      <Flex
        jc="flex-start"
        css={t => ({
          height: size.height - t.spacing(20),
          width: size.width - t.spacing(1),
          overflowY: "scroll",
          overflowX: "scroll",
          "table": {
            borderCollapse: "collapse",
            borderSpacing: 10,
            ".first-column": {
              background: t.palette?.gray?.[300],
            }, 
            ".cloHeader1": {
              background: t.palette?.gray?.[200], 
            },
            ".cloHeader2": {
              background: t.palette?.gray?.[300], 
            },
            "th, td": {
              margin: 0,
              border: `1px solid green`,
              whiteSpace: "nowrap",
              borderTopWidth: 0,
            },
          }
        })}
      >
        {classroomGradeQuery.loading ? <Flex css={{width: `100%`}} ai="center" pt={4}>
            <IconLoading size={48} />
          </Flex> : buildTable()}
      </Flex>
      <ContextMenu
        menus={[
          <ListItem
            key={`page-context-menu-item-0`}
            onClick={() => {

            }}
          >
            <Small>{studentContextMenu.studentFullName}</Small>
          </ListItem>,
          <ListItem
            key={`page-context-menu-item-1`}
            onClick={() => {

            }}
          >
            <FaUserTie /> 
            <Small>Profil</Small>
          </ListItem>,
          <ListItem
            key={`page-context-menu-item-2`}
            onClick={() => {

              layout.confirmCancelable({
                content: <FlexRow>Nonaktifkan <b>{studentContextMenu.studentFullName}</b> dari kelas ini ?</FlexRow>,
                onClick: () => {
                  insertStudent({
                    variables: {
                      object: {
                        classroom_id: propsData?._.classroom_id,
                        student_id: studentContextMenu.studentID,
                        is_active: 0,
                        red_classroom_code: propsData?._.code,
                        red_student_nim: studentContextMenu.studentNIM,
                      } 
                    }
                  }).then(res => {

                    layout.confirm({
                      content: <FlexRow><b>{studentContextMenu.studentFullName}</b> berhasil dinonaktifkan</FlexRow>,
                      onClick: () => {
                        classroomGradeQuery.refetch()
                      },
                    })

                  })
                },
              })
            }}
            css={t => ({
              color: t.palette?.error?.main,
            })}
          >
            <FaTimes /> 
            <Small>Nonaktifkan</Small>
          </ListItem>, 
        ]}
        open={studentContextMenu.open}
        onClose={() => setStudentContextMenu(v => (initStudentContextMenu))}
        top={studentContextMenu.y}
        left={studentContextMenu.x}
        width={160}
      />
      <ClassroomEnrollmentDialogAdd />
    </Paper>
  )

} 

export default ClassroomGrade
