import { gql } from "@apollo/client";

export interface TicketQ {_: {
  ticket_id: string
  ticket_status_id: number
  ticket_category_id: number
  subject: string
  message: string
  datetime_resolved: string | null
  datetime_created: string
  creator: {
    username: string
    player_id: string
  }
}[]}

export const GET_TICKETS = gql`
  query GetTickets {
    _: helpdesk_ticket(order_by: {datetime_created: desc}) {
      ticket_id
      ticket_status_id
      ticket_category_id
      subject
      message
      datetime_resolved
      datetime_created
      creator {
        username
        player_id
      }
    }
  }
`
