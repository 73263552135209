/** @jsxImportSource @emotion/react */

import { css, Global, ThemeProvider } from "@emotion/react"
import defaultTheme from "./shared/styles/default-theme"

import emotionNormalize from "emotion-normalize"
import { HashRouter, Route, Routes, useLocation } from "react-router-dom"
import PortalSplash from "./modules/Portal/Splash"
import PortalLogin from "./modules/Portal/Login"
import PlayerDashboard from "./modules/Player/Dashboard"
import SimpleLoading from "./shared/components/SimpleLoading"
import ConfirmationDialog from "./shared/components/ConfirmationDialog"
import ClassroomList from "./modules/Classroom/List"
import PlayerMenu from "./modules/Player/Menu"
import PlayerLogAs from "./modules/Player/LogAs"
import ClassroomHome from "./modules/Classroom/Home"
import ClassroomMeetingHome from "./modules/Classroom/Meeting/Home"
import ClassroomMaterialAdd from "./modules/Classroom/Material/Add"

import "dayjs/locale/id"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import useBreakpoint from "./shared/hooks/use-breakpoint"
import ClassroomMeetingHomeLaptop from "./modules/Classroom/Meeting/Home/Laptop"
import { ApolloProvider } from "@apollo/client"
import { apolloClient } from "./shared/components/Hasura/client"

import ReactGA from 'react-ga4'
import ClassroomAssessmentGame from "./modules/Classroom/Assessment/Game"
import ClassroomAssessmentGameAnswer from "./modules/Classroom/Assessment/Game/Answer"
import { useEffect } from "react"
import ClassroomAssessmentCreate from "./modules/Classroom/Assessment/Create"
import GameNest from "./modules/Misc/Game/Nest"
import ClassroomAssessmentGradingV1 from "./modules/Classroom/Assessment/Grading/V1"
import ClassroomAssessmentGrading from "./modules/Classroom/Assessment/Grading"
import PortalScan from "./modules/Portal/Scan"
import PortalShare from "./modules/Portal/Share"
import HelpdeskTicketList from "./modules/Helpdesk/Ticket/List"
import HelpdeskTicketAdd from "./modules/Helpdesk/Ticket/Add"
import HelpdeskTicketDetail from "./modules/Helpdesk/Ticket/Detail"
import NotificationManager from "./modules/Player/NotificationManager"
import DefenseHome from "./modules/Defense/Home"
import DefenseManage from "./modules/Defense/Manage"
import DefenseDetail from "./modules/Defense/Detail"
import DefenseEdit from "./modules/Defense/Edit"
import DefenseSchedule from "./modules/Defense/Schedule"
import DefenseChecklist from "./modules/Defense/Checklist"
import DefenseEvaluation from "./modules/Defense/Evaluation"
import Indicator from "./modules/Indicator"
import IndicatorPLO from "./modules/Indicator/PLO"
import Info from "./modules/Info"
import InfoAcademic from "./modules/Info/Academic"
import InfoNews from "./modules/Info/News"
import Career from "./modules/Career"
import Marketplace from "./modules/Marketplace"
import Mentorship from "./modules/Mentorship"
import CareerSkill from "./modules/Career/Skill"
import PortalOtherMenus from "./modules/Portal/OtherMenus"
import PortalDigiteam from "./modules/Portal/Digiteam"

ReactGA.initialize([
  {
    trackingId: "G-K91RTNY4M8",
  }
])

dayjs.extend(utc)
dayjs.locale("id")

const GaTracker = () => {

  const loc = useLocation()
  useEffect(() => {
    if(!["localhost", "127.0.0.1"].includes(window.location.hostname)) {
      ReactGA.send({
        hitType: "pageview",
        page: loc.pathname,
      })
    }
  }, [loc])

  return null
}

const App = () => {

  const bp = useBreakpoint()

  return (
    <ApolloProvider
      client={apolloClient}
    >
      <ThemeProvider
        theme={defaultTheme}
      >
        <Global
          styles={css`
            ${emotionNormalize} 
            html, body, #root {
              height: ${window.innerHeight}px;
              font-family: 'Poppins', sans-serif;
              line-height: 1.5;
            }
          `}
        />
        <NotificationManager />
        <HashRouter>
          <GaTracker />
          <Routes>
            <Route path="/">
              <Route index element={<PortalSplash />} />
              <Route path="login" element={<PortalLogin />} />

              <Route path="portal" >
                <Route path="other-menus" element={<PortalOtherMenus />} />
                <Route path="digiteam" element={<PortalDigiteam />} />
              </Route>

              <Route path="player">
                <Route path="dashboard" element={<PlayerDashboard />} />
                <Route path="menu" element={<PlayerMenu />} />
                <Route path="log-as" element={<PlayerLogAs />} />
              </Route>

              <Route path="info" >
                <Route index element={<Info />} />
                <Route path="academic" element={<InfoAcademic />} />
                <Route path="news" element={<InfoNews />} />
              </Route>

              <Route path="classroom" >
                <Route index element={<ClassroomList />} />
                <Route path=":classroomID/:tab" element={<ClassroomHome />} />

                <Route path="material" >
                  <Route path=":learningID/edit" element={<ClassroomMaterialAdd />} />
                </Route>

                <Route path="meeting" >
                  <Route path=":classroomItemID" element={
                    ["mobile", "tablet"].includes(bp) ?
                    <ClassroomMeetingHome /> :
                    <ClassroomMeetingHomeLaptop />
                  } />
                  <Route path=":classroomItemID/material/add" element={<ClassroomMaterialAdd />} />
                  {/* <Route path=":classroomItemID/assessment/add/step/:stepNumber" element={<ClassroomAssessmentAdd />} /> */}
                  <Route path=":classroomItemID/assessment/create" element={<ClassroomAssessmentCreate />} />
                </Route>

                <Route path="assessment" >
                  <Route path=":learningID/edit" element={<ClassroomAssessmentCreate />} />
                  <Route path=":learningID/grading" element={<ClassroomAssessmentGrading />} />
                  <Route path=":learningID/gradingv1" element={<ClassroomAssessmentGradingV1 />} />
                  <Route path=":learningID/game/intro" element={<ClassroomAssessmentGame />} />
                  <Route path=":learningID/game/question/:questionID" element={<ClassroomAssessmentGameAnswer />} />
                </Route>

                <Route path="assessments-container" >
                  <Route path=":classroomItemID" element={<ClassroomMeetingHome />} />
                  <Route path=":classroomItemID/material/add" element={<ClassroomMeetingHome />} />
                </Route>

                <Route path="materials-container" >
                  <Route path=":classroomItemID" element={<ClassroomMeetingHome />} />
                  <Route path=":classroomItemID/material/add" element={<ClassroomMeetingHome />} />
                </Route>
              </Route>
            </Route>

            <Route path="mentorship" >
              <Route index element={<Mentorship />} />
            </Route>

            <Route path="defense" >
              <Route index element={<DefenseHome />} />
              <Route path="manage/:defenseCategoryID" element={<DefenseManage />} />
              <Route path="detail/:defenseID" element={<DefenseDetail />} />
              <Route path="edit/:defenseID" element={<DefenseEdit />} />
              <Route path="evaluation/:defenseID" element={<DefenseEvaluation />} />
              <Route path="schedule" element={<DefenseSchedule />} />
              <Route path="checklist" element={<DefenseChecklist />} />
            </Route>

            <Route path="career" >
              <Route index element={<Career />} />
              <Route path="skill" element={<CareerSkill />} />
            </Route>

            <Route path="marketplace" >
              <Route index element={<Marketplace />} />
            </Route>

            <Route path="helpdesk" >
              <Route path="ticket" element={<HelpdeskTicketList />} />
              <Route path="ticket/add" element={<HelpdeskTicketAdd />} />
              <Route path="ticket/detail/:ticketID" element={<HelpdeskTicketDetail />} />
            </Route>

            <Route path="indicator" >
              <Route index element={<Indicator />} />
              <Route path="plo" element={<IndicatorPLO />} />
            </Route>

            <Route path="scan" element={<PortalScan />} />
            <Route path="share" element={<PortalShare />} />

            <Route path="misc" >
              <Route path="game">
                <Route path="nest">
                  <Route index element={<GameNest />} />
                </Route>
              </Route>
            </Route>
          </Routes>
        </HashRouter>
        <SimpleLoading />
        <ConfirmationDialog />
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default App;
