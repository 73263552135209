/** @jsxImportSource @emotion/react */

import { BsTerminalFill } from "react-icons/bs"
import { FaBook, FaChartBar, FaGraduationCap, FaIndustry, FaMicroscope, FaNewspaper, FaQuran } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import List from "../../shared/components/List"
import ListItem from "../../shared/components/List/Item"
import Page from "../../shared/components/Page"

const Mentorship = () => {

  const navigate = useNavigate()

  return (
    <Page
      title={`IbTiTah`}
    >
      <List
        title={`Coming soon`}
      >
        <ListItem
          onClick={() => {
            navigate('/mentorship/ibadah')
          }} 
        >
          <FaQuran /> Praktik Ibadah 
        </ListItem>
        <ListItem
          onClick={() => {
            navigate('/mentorship/tilawah')
          }} 
        >
          <FaQuran /> Praktik Tilawah 
        </ListItem>
        <ListItem
          onClick={() => {
            navigate('/career/scholarship')
          }} 
        >
          <FaQuran /> Praktik Tahfidz
        </ListItem>
      </List>
    </Page>
  )
}

export default Mentorship 
