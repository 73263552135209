/** @jsxImportSource @emotion/react */

import { useLazyQuery } from "@apollo/client"
import dayjs from "dayjs"
import { useEffect } from "react"
import Flex from "../../../shared/components/IFSuites/Flex"
import Small from "../../../shared/components/IFSuites/typography/Small"
import List from "../../../shared/components/List"
import Page from "../../../shared/components/Page"
import useAuth from "../../../shared/hooks/use-auth"
import useLayout from "../../../shared/hooks/use-layout"
import ScheduleListItem from "../../Player/Dashboard/ScheduleListItem"
import { GetUpcomingDefensesQ, GET_UPCOMING_DEFENSES } from "./get-upcoming-defenses.gql"

const DefenseSchedule = () => {

  const [get, getOps] = useLazyQuery<GetUpcomingDefensesQ>(GET_UPCOMING_DEFENSES)
  const auth = useAuth()
  const authInfo = auth.getInfo()
  const layout = useLayout()

  useEffect(() => {

    layout.loadingShow("Memuat data...")

    get({
      variables: {
        player_id: authInfo?.playerUUID,
      }
    }).then(response => {
    }).finally(() => {
      layout.loadingHide()
    })

  }, [])


  return (
    <Page
      title={`Jadwal Sidang`}
    >
      <List>
        {getOps.data && getOps.data.defense_defense && getOps.data.defense_defense.map((item, key) => {

          console.log("item sidang", item)

          const title = <Flex gap={0}>
            {item.student.full_name} 
            <Small>{item.category.title}</Small> 
          </Flex>
          const dateFrom = dayjs(item.datetime_start)
          const dateTo = dayjs(item.datetime_start).add(2, "hour")
          const location = item.place.title
          const url = `/defense/evaluation/${item.defense_id}` 
          return (
            <ScheduleListItem
              title={title}     
              date={`${dateFrom.format("dddd[,] D MMMM [,] HH:mm")} - ${dateTo.format("HH:mm")}`}
              url={url}
              location={location}
            />
          )
        })}
      </List> 
    </Page>
  )
} 

export default DefenseSchedule
