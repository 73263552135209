/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react"
import { FaCaretLeft, FaCaretRight } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { Button } from "../../../../../shared/components/IFSuites/Button"
import FlexRow from "../../../../../shared/components/IFSuites/Flex/Row"
import { ClassroomMeetingQ } from "../Laptop/q-get-classroom-meeting"

interface Props {
  data: ClassroomMeetingQ
}

const ClassroomMeetingHomeTitle  = ({data}: Props) => {
  const [ classroomItemNavigation, setClassroomItemNavigation] = useState<{
    prev: null | string
    next: null | string
  }>({
    prev: null,
    next: null,
  })

  const navigate = useNavigate()

  useEffect(() => {
    if(data) {
      const classroomItemIDList = data._.classroom.items.map(v => v.classroom_item_id)
      const currentIndex = classroomItemIDList.indexOf(data._.classroom_item_id)
      setClassroomItemNavigation({
        prev: classroomItemIDList[currentIndex - 1] ? classroomItemIDList[currentIndex - 1] : null,
        next: classroomItemIDList[currentIndex + 1] ? classroomItemIDList[currentIndex + 1] : null,
      })
    }
  }, [data._.classroom_item_id])

  return (
    <FlexRow
      jc="center"
      gap={2}
    >
      {classroomItemNavigation.prev &&
        <Button
          variant="icon"
          color="secondary"
          onClick={() => {
            navigate(`/classroom/meeting/${classroomItemNavigation.prev}`, {replace: true})
          }}
        >
          <FaCaretLeft/>
        </Button>
      }
      {data?._.title} 
      {classroomItemNavigation.next &&
        <Button
          variant="icon"
          color="secondary"
          onClick={() => {
            navigate(`/classroom/meeting/${classroomItemNavigation.next}`, {replace: true})
          }}
        >
          <FaCaretRight/>
        </Button>
      }
    </FlexRow>
  )
}

export default ClassroomMeetingHomeTitle
