import { gql } from "@apollo/client";

export const EDIT_DEFENSE_BY_ID = gql`
mutation EditDefenseByID(
  $defense_id: uuid = "", 
  $defense_object: defense_defense_set_input = {}, 
  $defense_evaluator_objects: [defense_defense_evaluator_insert_input!] = {}
) {
  update_defense_defense_by_pk(pk_columns: {defense_id: $defense_id}, _set: $defense_object) {
    defense_id
  }
  delete_defense_defense_evaluator(where: {defense_id: {_eq: $defense_id}}) {
    returning {
      defense_id
    }
  }
  insert_defense_defense_evaluator(objects: $defense_evaluator_objects) {
    returning {
      defense_id
    }
  }
}
`

export interface EditDefenseByIDQ {
  datetime_start: string 
  place_id: string 
  title: string 
} 


