/** @jsxImportSource @emotion/react */

import { useAtom } from "jotai"
import layoutAtom, { initLayoutAtom } from "../../states/layout"
import { Button } from "../IFSuites/Button"
import Dialog from "../IFSuites/Dialog"
import Flex from "../IFSuites/Flex"
import FlexRow from "../IFSuites/Flex/Row"

const ConfirmationDialog = () => {

  const [layout, setLayout] = useAtom(layoutAtom)

  return (
    <Dialog
      open={layout.confirmDialog.open}
    >
      <Flex
        p={2}
        gap={2}
      >
        <FlexRow
          jc="center"
          ai="center"
        >
          {layout.confirmDialog.content}
        </FlexRow>
        <FlexRow
          jc="center"
          ai="center"
        >
          <Button
            br={1}
            onClick={() => {
              layout.confirmDialog.onClick()
              setLayout(v => (initLayoutAtom))
            }}  
            color={layout.confirmDialog.isCancelable ? "success" : "info"}
          >
            Ok
          </Button>
          {layout.confirmDialog.isCancelable &&
            <Button
              br={1}
              onClick={() => {
                setLayout(v => (initLayoutAtom))
              }}  
              color="error"
            >
              Batal
            </Button>
          }
        </FlexRow>
      </Flex>
    </Dialog>
  )
}

export default ConfirmationDialog
