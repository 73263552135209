/** @jsxImportSource @emotion/react */

import { useQuery } from "@apollo/client"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { FaEdit, FaPlus, FaShareAlt } from "react-icons/fa"
import { IoGameController } from "react-icons/io5"
import { MdMenuBook } from "react-icons/md"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ContextMenuWithTrigger from "../../../../shared/components/ContextMenuWithTrigger"
import NormalEditor from "../../../../shared/components/Editor/MGLexical/NormalEditor"
import { Button } from "../../../../shared/components/IFSuites/Button"
import DividerGrow from "../../../../shared/components/IFSuites/Divider/Grow"
import Flex from "../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../shared/components/IFSuites/Flex/Row"
import Paper from "../../../../shared/components/IFSuites/Paper"
import Small from "../../../../shared/components/IFSuites/typography/Small"
import LinkFiles from "../../../../shared/components/links/Files"
import List from "../../../../shared/components/List"
import ListItem from "../../../../shared/components/List/Item"
import Page from "../../../../shared/components/Page"
import useAuth from "../../../../shared/hooks/use-auth"
import AssessmentListItem from "../../Assessment/List/Item"
import useLearningAdd from "../../hooks/use-learning-add"
import { initLearningCreate } from "../../states/learning-create"
import { ClassroomMeetingQ, GET_CLASSROOM_MEETING_BY_ID } from "./Laptop/q-get-classroom-meeting"
import ClassroomMeetingHomeTitle from "./Title"

const ClassroomMeetingHome = () => {

  const location = useLocation()
  const { classroomItemID } = useParams()
  // const { classroomItemDetail } = useClassroomItemDetail(classroomItemID)
  // const { cloList } = useCLOGet(classroomItemDetail.classroom_id)
  const { setLearningCreate, } = useLearningAdd()
  // const { learningList, attachments } = useLearningGet(classroomItemDetail.classroom_id)
  // const [, setClassroomDialogs] = useAtom(classroomDialogsAtom)

  const navigate = useNavigate()
  const auth = useAuth()

  const [ classroomItemNavigation, setClassroomItemNavigation] = useState<{
    prev: null | string
    next: null | string
  }>({
    prev: null,
    next: null,
  })

  const { data, refetch } = useQuery<ClassroomMeetingQ>(GET_CLASSROOM_MEETING_BY_ID, {
    variables: {
      classroom_item_id: classroomItemID,
    }
  })

  const currentMaterial = data?._.learnings.filter(v => v.learning_template_id === 3)[0]

  const [selectedTab, setSelectedTab] = useState<"attendance" | "activity" | "outcome">(auth.isLecturer() ? "activity" : "activity")
  const iconSize = 24 
  const iconColor = '#1A3647'

  useEffect(() => {
    if(data && classroomItemID) {
      const classroomItemIDList = data._.classroom.items.map(v => v.classroom_item_id)
      const currentIndex = classroomItemIDList.indexOf(classroomItemID)
      setClassroomItemNavigation({
        prev: classroomItemIDList[currentIndex - 1] ? classroomItemIDList[currentIndex - 1] : null,
        next: classroomItemIDList[currentIndex + 1] ? classroomItemIDList[currentIndex + 1] : null,
      })
    }
  }, [data, classroomItemID])

  useEffect(() => {
    refetch()
  }, [location])

  return (
    <Page
      title={
        data ? <ClassroomMeetingHomeTitle data={data} /> : 'Pertemuan'
      }
      menus={[
        <ListItem
          onClick={() => {
            console.log("location", location)
            navigate(`/share?path=${location.pathname}`, {state: {path: location.pathname}}) 
          }}
        >
          <FaShareAlt /><Small>Share</Small> 
        </ListItem>,
      ]}
    >
      <FlexRow
        jc='stretch'
        px={0}
        gap={0}
        css={t => ({
          background: t.palette?.gray?.[100],
          paddingBottom: t.spacing(1),
          'small': {
            color: t.palette?.blueGray?.[700],
            fontWeight: 400,
          }
        })}
      >
        <Button
          br={0}
          fullWidth
          variant={`soft`}
          color={selectedTab === "activity" ? 'primary' : 'secondary'}
          onClick={() => {
            setSelectedTab('activity')
          }}
        >
          <Flex ai='center'>
            <IoGameController  color={iconColor} size={iconSize} />
            <Small
            >
              Aktivitas 
            </Small>
          </Flex>
        </Button>

        <Button
          br={0}
          fullWidth
          variant={`soft`}
          color={selectedTab === "outcome" ? 'primary' : 'secondary'}
          onClick={() => {
            setSelectedTab('outcome')
          }}
        >
          <Flex ai='center'>
            <MdMenuBook color={iconColor} size={iconSize} />
            <Small
              css={t => ({
              })}
            >
              RPS
            </Small>
          </Flex>
        </Button>
      
      </FlexRow>

      {selectedTab === "outcome" &&
        <Paper
          br={0}
        >
          <Flex
            p={1}
            px={2}
            gap={0}
          >
            <FlexRow>
              <Small>Deskripsi</Small>
              <DividerGrow />
              {auth.isLecturer() &&
                <Button
                  size="small"
                  color="success"
                  variant="soft"
                >
                  <FaEdit /> Ubah 
                </Button>
              }
              {/* <Button */}
              {/*   size="small" */}
              {/*   color="success" */}
              {/* > */}
              {/*   <FaChevronDown /> */} 
              {/* </Button> */}
            </FlexRow>
            {data && data._.details &&
              <Flex>
                {data._.details.description 
                && data._.details.description !== ""
                  && <FlexRow pt={2}>
                    <Small>{data._.details.description}</Small>
                  </FlexRow> 
                }
                {data._.details.indicator 
                && data._.details.indicator !== ""
                && <Small>{data._.details.indicator}</Small> 
                }
                {data._.details.duration
                && data._.details.duration !== null 
                && <Small>{data._.details.duration} menit</Small> 
                }
              </Flex>
            }
          </Flex>
        </Paper>
      }

      {selectedTab === "outcome" &&
        <Paper br={0}>
          <Flex
            gap={0}
          >
            <List collapsible title={
              <FlexRow>
                <Small>Capaian</Small>
                <Small><b>({data?._.classroom.cys.clos.length})</b></Small>
              </FlexRow>
            }>
              {data?._.classroom.cys.clos.map((item, key) => (
                <ListItem key={`clo-list-item-${key}`}>
                  <Flex>
                    <Small>{item.clo.code}</Small> 
                    <Small>{item.clo.description}</Small> 
                  </Flex>
                </ListItem>
              ))}
            </List>
          </Flex>
        </Paper>
      }

      {selectedTab === "activity" &&
        <Paper br={0}>
          <Flex
            gap={0}
          >
            <List title={
              <FlexRow>
                <Small>Materi</Small>
                {/* <Small> */}
                {/*   {data && */}
                {/*     <b> ({ */}
                {/*       ((data._.details && data._.details.materials) ? */} 
                {/*       data._.details.materials.length : 0) + */}
                {/*       Number(data._.learnings[0] ? data._.learnings[0].attachments.length : 0) */} 
                {/*     })</b> */}
                {/*   } */}
                {/* </Small> */}
                <DividerGrow />
                {auth.isLecturer() && data &&
                  <Button
                    size="small"
                    color="success"
                    variant="soft"
                    onClick={() => {
                      const newLearningCreate = {
                        ...initLearningCreate,
                        classroom_id: data._.classroom_id,
                        classroom_item_id: data._.classroom_item_id,
                        learning_template_id: 3,
                        title: `Materi ${data._.title}`,  
                      }
                      setLearningCreate(newLearningCreate)
                      if(currentMaterial === undefined) {
                        navigate(`/classroom/meeting/${classroomItemID}/material/add`, {state: {previousPage: ""}, replace: false})
                      } else {
                        navigate(`/classroom/material/${currentMaterial.learning_id}/edit`, {state: {previousPage: ""}, replace: false})
                      }
                    }}
                  >
                    <FaEdit /> Perbarui 
                  </Button>
                }
              </FlexRow>
            }>
            </List>
            <Flex gap={0}>
              {data?._.learnings.filter(v => v.learning_template_id === 3).map((item, key) => (
                <Flex
                  key={`learning-material-item-editor-${key}`}
                  px={0}
                >
                  <Flex px={2}>{item.title}</Flex>
                  <NormalEditor 
                    isEditable={false}
                    onEditorStateChange={() => {}}
                    initEditorStateString={JSON.stringify(item.description)}
                    trackInitEditorStateChange={true}
                  />
                </Flex>
              ))}
              {data && data._.details.materials &&
                <LinkFiles
                  links={[
                    ...data._.details.materials.map(v => ({
                      url: v.url,
                      label: v.nama_file,
                    })),
                    ...(currentMaterial ? currentMaterial.attachments.map(v => ({
                      url: v.url,
                      label: v.title,
                    })): [])
                  ]}
                />
              }
              {data && data._.learnings.filter(v => v.learning_template_id === 3).map((item, key) => (
                <LinkFiles
                  key={`link-file-${key}`}
                  links={item.attachments.map(v => ({
                    url: v.url,
                    label: v.title,
                  }))}
                />
              ))}
            </Flex>
          </Flex>
        </Paper>
      }

      {selectedTab === "activity" &&
        <Paper br={0}>
          <List
            title={
              <FlexRow>
                <Small>Asesmen</Small>
                <Small><b>({data?._.learnings.filter(v => v.learning_template_id === 2).length})</b></Small>
                <DividerGrow />
                  {auth.isLecturer() &&
                    <ContextMenuWithTrigger 
                      trigger={(setOpen) => {
                        return (
                          <Button
                            size="small"
                            color="success"
                            variant="soft"
                            onClick={() => {
                              setOpen(true)
                            }}
                          >
                            <FaPlus /> Tambah
                          </Button>
                        )
                      }}
                      menus={[
                        {
                          title: `Tugas Mandiri`,
                          id: 2,
                        },
                        {
                          title: `Tugas Terstruktur`,
                          id: 3,
                        },
                        {
                          title: "Ujian tengah semester (UTS)",
                          id: 4,
                        },
                        {
                          title: "Ujian akhir semester (UAS)",
                          id: 5,
                        },
                      ].map((item, key) => (
                        <ListItem
                            key={`item-${key}`}
                            onClick={() => {
                              if(classroomItemID) {
                              setLearningCreate(v => ({
                                ...v,
                                grading_comp_id: item.id,
                              }))
                              navigate(`/classroom/meeting/${classroomItemID}/assessment/create`)
                            }
                          }}
                        >
                          {item.title}
                        </ListItem>
                      ))}
                    />
                  }
              </FlexRow>
            }
          >
            {data && data._.learnings.filter(v => v.learning_template_id === 2).map(v => {
              const now = dayjs()
              const startDatetime = dayjs(v.datetime_start, "YYYY-MM-DD HH:mm:ss")
              const elapsed = {
                day: now.diff(startDatetime, 'd'),
                hour: now.diff(startDatetime, 'h'),
                minutes: now.diff(startDatetime, 'm'),
              }
              const hourElapsed = now.diff(startDatetime, 'h')

              return {
                ...v,
                elapsed: elapsed, 
              }
              
            }).map((item, key) => (
              <AssessmentListItem
                key={`assessment-list-item-${key}`}
                playerType={auth.isLecturer() ? "lecturer" : "student"}
                title={item.title} 
                elapsed={
                  <Small>
                    {`Telah berjalan ${item.elapsed.day > 0 ? ` ${item.elapsed.day} hari` : ""} ${(item.elapsed.hour % 24)} jam ${(item.elapsed.minutes % 60)} menit`}
                  </Small>
                }
                learningID={item.learning_id}
              />
            ))}
          </List>
        </Paper>
      }

      {/* <Paper br={0}> */}
      {/*   <Flex */}
      {/*     p={1} */}
      {/*     px={2} */}
      {/*   > */}
      {/*     <FlexRow> */}
      {/*       <Small>Absensi</Small> */}
      {/*       <Small><b>(0 / 1)</b></Small> */}
      {/*       <DividerGrow /> */}
      {/*       {auth.isLecturer() && */}
      {/*         <Button */}
      {/*           size="small" */}
      {/*           color="success" */}
      {/*           variant="soft" */}
      {/*         > */}
      {/*           <FaEye /> Cek */} 
      {/*         </Button> */}
      {/*       } */}
      {/*     </FlexRow> */}
      {/*   </Flex> */}
      {/* </Paper> */}

    </Page>
  )
}

export default ClassroomMeetingHome
