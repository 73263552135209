/** @jsxImportSource @emotion/react */

import { useState } from "react"
import { Button } from "../../../shared/components/IFSuites/Button"
import Flex from "../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import InputText from "../../../shared/components/IFSuites/forms/InputText"
import useAuth from "../../../shared/hooks/use-auth"
import mq from "../../../shared/utils/media-query"

interface LoginForm {
  username: string
  password: string
}

const PortalLogin = () => {

  const auth = useAuth()

  const [loginForm, setLoginForm] = useState<LoginForm>({
    username: "",
    password: "",
  })

  return (
    <Flex
      className="PortalSplash"
      p={2}
      jc="center"
      ai="center"
      css={t => ({
        height: window.innerHeight,
        gap: t.spacing(2),
      })}
    >
      <Flex ai="center">
        Layanan Digital Informatika
      </Flex>      
      <InputText
        label="username"
        value={loginForm.username}
        onChange={e => setLoginForm(v => ({...v, username: e.target.value}))} // ... spread operator
        css={t => ({
          width: `100%`,
          [mq[1]]: {
            width: t.spacing(40),
          },
        })}
      />
      <InputText
        label="password"
        type="password"
        value={loginForm.password}
        onChange={e => setLoginForm(v => ({...v, password: e.target.value}))}
        css={t => ({
          width: `100%`,
          [mq[1]]: {
            width: t.spacing(40),
          },
        })}
      />
      <FlexRow jc="center">
        <Button
          variant="contained"
          onClick={() => {
            auth.getTokenFromAPI(loginForm.username, loginForm.password) 
          }} 
        >
          Login
        </Button>
      </FlexRow>
    </Flex>
  )
}

export default PortalLogin
