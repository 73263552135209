/** @jsxImportSource @emotion/react */

import { createBreakpoint } from "react-use";

const breakpoints = [576, 768, 992, 1200]

export const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

export const w12 = (n: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12) => `${n / 12 * 100}%`

const useBreakpoint = createBreakpoint({ laptopL: 1440, laptop: 1280, tablet: 768, mobile: 0 });

export default useBreakpoint
