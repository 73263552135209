/** @jsxImportSource @emotion/react */

import { useAtom } from "jotai"
import { ReactNode } from "react"
import layoutAtom from "../states/layout"

const useLayout = () => {
  const [layout, setLayout] = useAtom(layoutAtom)
  
  const confirm = (args: {
    content: ReactNode,
    onClick: () => void,
  }) => {
    setLayout(v => ({
      ...v,
      confirmDialog: {
        ...v.confirmDialog,
        open: true,
        content: args.content,
        status: "info",
        onClick: args.onClick,
      }
    }))
  }

  const confirmCancelable = (args: {
    content: ReactNode,
    onClick: () => void,
  }) => {
    setLayout(v => ({
      ...v,
      confirmDialog: {
        ...v.confirmDialog,
        open: true,
        content: args.content,
        status: "info",
        onClick: args.onClick,
        isCancelable: true,
      }
    }))
  }

  const loadingShow = (message: string) => {
    setLayout(v => ({
      ...v,
      loading: {
        ...v.loading,
        message: message,
        open: true
      }
    }))
  }
  
  const loadingHide = () => {
    setLayout(v => ({
      ...v,
      loading: {
        open: false,
        status: "info",
        message: "Komunikasi ke server ..."
      }
    }))
  }

  return {confirm, confirmCancelable, loadingShow, loadingHide}
}

export default useLayout
