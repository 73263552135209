/** @jsxImportSource @emotion/react */

import { useQuery } from "@apollo/client"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { FaBookOpen, FaCaretLeft, FaCaretRight, FaCheck, FaClock, FaEdit, FaPlus, FaRegGem, FaSave, FaShareAlt, FaSitemap, FaTimes, FaUser, FaUsers } from "react-icons/fa"
import { GiBowman, GiGraduateCap, GiSpellBook } from "react-icons/gi"
import { MdChat, MdQuiz } from "react-icons/md"
import { TbTargetArrow } from "react-icons/tb"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ContextMenuWithTrigger from "../../../../../shared/components/ContextMenuWithTrigger"
import NormalEditor from "../../../../../shared/components/Editor/MGLexical/NormalEditor"
import { Button } from "../../../../../shared/components/IFSuites/Button"
import Flex from "../../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../../shared/components/IFSuites/Flex/Row"
import Paper from "../../../../../shared/components/IFSuites/Paper"
import H3 from "../../../../../shared/components/IFSuites/typography/H3"
import Small from "../../../../../shared/components/IFSuites/typography/Small"
import LinkFiles from "../../../../../shared/components/links/Files"
import List from "../../../../../shared/components/List"
import ListItem from "../../../../../shared/components/List/Item"
import Page from "../../../../../shared/components/Page"
import useAuth from "../../../../../shared/hooks/use-auth"
import { dayName } from "../../../../../shared/utils/datetime"
import AssessmentListItem from "../../../Assessment/List/Item"
import useLearningAdd from "../../../hooks/use-learning-add"
import { initLearningCreate } from "../../../states/learning-create"
import { ClassroomMeetingQ, GET_CLASSROOM_MEETING_BY_ID } from "./q-get-classroom-meeting"

const ClassroomMeetingHomeLaptop  = () => {

  const location = useLocation()
  const { classroomItemID } = useParams()
  // const { classroomItemDetail } = useClassroomItemDetail(classroomItemID)
  const { setLearningCreate, } = useLearningAdd()
  // const { learningList, attachments } = useLearningGet(classroomItemDetail.classroom_id)
  const [ classroomItemNavigation, setClassroomItemNavigation] = useState<{
    prev: null | string
    next: null | string
  }>({
    prev: null,
    next: null,
  })

  const { data, refetch } = useQuery<ClassroomMeetingQ>(GET_CLASSROOM_MEETING_BY_ID, {
    variables: {
      classroom_item_id: classroomItemID,
    }
  })

  const currentMaterial = data?._.learnings.filter(v => v.learning_template_id === 3)[0]

  const navigate = useNavigate()
  const auth = useAuth()

  useEffect(() => {
    if(data && classroomItemID) {
      const classroomItemIDList = data._.classroom.items.map(v => v.classroom_item_id)
      const currentIndex = classroomItemIDList.indexOf(classroomItemID)
      setClassroomItemNavigation({
        prev: classroomItemIDList[currentIndex - 1] ? classroomItemIDList[currentIndex - 1] : null,
        next: classroomItemIDList[currentIndex + 1] ? classroomItemIDList[currentIndex + 1] : null,
      })
    }
  }, [data, classroomItemID])

  useEffect(() => {
    refetch()
  }, [location])

  return (
    <Page title={
      <FlexRow
        jc="center"
        gap={2}
      >
        {classroomItemNavigation.prev &&
          <Button
            variant="icon"
            color="secondary"
            onClick={() => {
              navigate(`/classroom/meeting/${classroomItemNavigation.prev}`, {replace: true})
            }}
          >
            <FaCaretLeft/>
          </Button>
        }
        {data?._.title} 
        {classroomItemNavigation.next &&
          <Button
            variant="icon"
            color="secondary"
            onClick={() => {
              navigate(`/classroom/meeting/${classroomItemNavigation.next}`, {replace: true})
            }}
          >
            <FaCaretRight/>
          </Button>
        }
      </FlexRow>
    }
    menus={[
      <ListItem
        key={`page-context-menu-item-2`}
        onClick={() => {
          console.log("location", location)
          navigate(`/share?path=${location.pathname}`, {state: {path: location.pathname}}) 
        }}
      >
        <FaShareAlt /><Small>Share</Small> 
      </ListItem>,
      <ListItem
        key={`page-context-menu-item-3`}
        onClick={() => {navigate(`/classroom/${data?._.classroom_id}/sessions`)}}
      >
        <GiGraduateCap /> 
        <Small>Kelas</Small>
      </ListItem>, 
      <ListItem
        key={`page-context-menu-item-4`}
        onClick={() => {navigate(`/classroom/${data?._.classroom_id}/assessments`)}}
      >
        <MdQuiz /> 
        <Small>Asesmen</Small>
      </ListItem>, 
      <ListItem
        key={`page-context-menu-item-4`}
        onClick={() => {navigate(`/classroom/${data?._.classroom_id}/grade`)}}
      >
        <FaUsers /> 
        <Small>Nilai</Small>
      </ListItem>, 
      <ListItem
        key={`page-context-menu-item-4`}
        onClick={() => {navigate(`/classroom/${data?._.classroom_id}/detail`)}}
      >
        <FaSitemap /> 
        <Small>RPS</Small>
      </ListItem>
    ]}
    >
      <FlexRow
        gap={2}
        p={2}
        ai={`start`}
        // jc={`stretch`}
        css={t => ({
          background: t.palette?.gray?.[200],
        })}
      >
        {/*
        {data !== undefined && auth.isLecturer() &&
          <Attendance data={data} />
        }
        */}
        <Flex
          gap={2}
          jc="flex-start"
          css={t => ({
            flexGrow: 1,
          })}
        >
          <FlexRow
            gap={2}
            ai="stretch"
          >
            <Paper
              p={2}
              jc="flex-start"
              css={t => ({
                background: t.palette?.gray?.[50],
              })}
            >
              <H3>Deskripsi Pertemuan (RPS)</H3>
              <FlexRow pb={2} /> 
              <table
                css={t => ({

                })}
              >
                <tbody>
                  <tr>
                    <td>Mata Kuliah</td>              
                    <td>:</td>              
                    <td>{data?._.classroom.cys.course.title} ({data?._.classroom.cys.course.code})</td>              
                  </tr>
                  <tr>
                    <td>Kelas</td>              
                    <td>:</td>              
                    <td>{data?._.classroom.code}</td>              
                  </tr>
                </tbody>
              </table>

              {data?._.details &&
                <Flex>
                  {data?._.details['description'] 
                  && data?._.details['description'] !== ""
                    && <Flex pt={2}>
                      Detail
                      <Small>{data?._.details['description']}</Small>
                    </Flex> 
                  }
                  {data?._.details['indicator'] 
                  && data?._.details['indicator'] !== ""
                  && <Flex mt={2}>Indikator<Small>{data?._.details['indicator']}</Small></Flex> 
 
                  }
                  {data?._.details['duration']
                  && data?._.details['duration'] !== null 
                  && <span>Durasi: {data?._.details['duration']} menit</span> 
                  }
                </Flex>
              }
            </Paper>
            <Paper
              p={0}
              jc="flex-start"
              css={t => ({
                background: t.palette?.gray?.[50],
              })}
            >
              <Flex p={2}>
                <FlexRow ai="center">
                  <TbTargetArrow size={24} /> <H3>Capaian Pembelajaran</H3>
                </FlexRow>
              </Flex>
              <Flex>
                <List>
                  {data?._.classroom.cys.clos.map((item, key) => (
                    <ListItem key={`clo-list-item-${key}`}>
                      <Flex>
                        {item.clo.code} 
                        <Small>{item.clo.description}</Small> 
                        <Small>{item.clo.plo.code} - {item.clo.plo.description}</Small> 
                      </Flex>
                    </ListItem>
                  ))}
                </List>
              </Flex>
            </Paper>
          </FlexRow>

          <FlexRow
            gap={2}
            ai="start"
          >
            <Paper
              p={2}
              jc="flex-start"
              css={t => ({
                background: t.palette?.gray?.[50],
              })}
            >
              <FlexRow jc="space-between">
                <FlexRow ai="center">
                  <FaRegGem size={24} /> <H3>Materi</H3>
                </FlexRow>
                  {/* {data && */}
                  {/*   <b> ({ */}
                  {/*     ((data._.details && data._.details.materials) ? */} 
                  {/*                           data._.details.materials.length : 0) + */}
                  {/*     Number(data._.learnings[0] ? data._.learnings[0].attachments.length : 0) */} 
                  {/*   })</b> */}
                  {/* } */}
                {auth.isLecturer() && data &&
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      const newLearningCreate = {
                        ...initLearningCreate,
                        classroom_id: data._.classroom_id,
                        classroom_item_id: data._.classroom_item_id,
                        learning_template_id: 3,
                        title: `Materi ${data._.title}`,  
                      }
                      setLearningCreate(newLearningCreate)
                      if(currentMaterial === undefined) {
                        navigate(`/classroom/meeting/${classroomItemID}/material/add`, {state: {previousPage: ""}, replace: false})
                      } else {
                        navigate(`/classroom/material/${currentMaterial.learning_id}/edit`, {state: {previousPage: ""}, replace: false})
                      }
                    }}
                  >
                    <FaEdit /> Perbarui 
                  </Button>
                }
              </FlexRow>
              <Flex gap={0} pt={2}>
                {data?._.learnings.filter(v => v.learning_template_id === 3).map((item, key) => (
                  <Flex
                    key={`learning-material-item-editor-${key}`}
                    px={0}
                  >
                    {item.title}
                    <NormalEditor 
                      isEditable={false}
                      onEditorStateChange={() => {}}
                      initEditorStateString={JSON.stringify(item.description)}
                      trackInitEditorStateChange={true}
                    />
                  </Flex>
                ))}
                {data && data._.details.materials &&
                  <LinkFiles
                    links={[
                      ...data._.details.materials.map(v => ({
                        url: v.url,
                        label: v.nama_file,
                      })),
                      ...(currentMaterial ? currentMaterial.attachments.map(v => ({
                        url: v.url,
                        label: v.title,
                      })): [])
                    ]}
                  />
                }
                {data && data._.learnings.filter(v => v.learning_template_id === 3).map((item, key) => (
                  <LinkFiles
                    key={`link-file-${key}`}
                    links={item.attachments.map(v => ({
                      url: v.url,
                      label: v.title,
                    }))}
                  />
                ))}
              </Flex>
            </Paper>
            <Paper
              p={0}
              jc="flex-start"
              css={t => ({
                background: t.palette?.gray?.[50],
              })}
            >
              <Flex p={2}>
                <FlexRow jc="space-between">
                  <FlexRow ai="center">
                    <GiBowman size={24} /><H3>Asesmen ({data?._.learnings.filter(v => v.learning_template_id === 2).length})</H3>
                  </FlexRow>
                  
                  {auth.isLecturer() &&
                    <ContextMenuWithTrigger 
                      trigger={(setOpen) => {
                        return (
                          <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                              setOpen(true)
                            }}
                          >
                            <FaPlus /> Tambah
                          </Button>
                        )
                      }}
                      menus={[
                        {
                          title: `Softskill / Sikap / Absensi (SS)`,
                          id: 1,
                        },
                        {
                          title: `Tugas Mandiri (TM)`,
                          id: 2,
                        },
                        {
                          title: `Tugas Terstruktur (TS)`,
                          id: 3,
                        },
                        {
                          title: "Ujian tengah semester (UTS)",
                          id: 4,
                        },
                        {
                          title: "Ujian akhir semester (UAS)",
                          id: 5,
                        },
                      ].map((item, key) => (
                        <ListItem
                          key={`item-${key}`}
                          onClick={() => {
                            if(classroomItemID) {
                              setLearningCreate(v => ({
                                ...v,
                                grading_comp_id: item.id,
                              }))
                              navigate(`/classroom/meeting/${classroomItemID}/assessment/create`)
                            }
                          }}
                        >
                          {item.title}
                        </ListItem>
                      ))}
                    />
                  }
                </FlexRow>
              </Flex>
              <Flex>
                {data &&
                  <List>
                    {data._.learnings.filter(v => v.learning_template_id === 2).map(v => {
                      const now = dayjs()
                      const startDatetime = dayjs(v.datetime_start, "YYYY-MM-DD HH:mm:ss")
                      const elapsed = {
                        day: now.diff(startDatetime, 'd'),
                        hour: now.diff(startDatetime, 'h'),
                        minutes: now.diff(startDatetime, 'm'),
                      }
                      const hourElapsed = now.diff(startDatetime, 'h')

                      return {
                        ...v,
                        elapsed: elapsed, 
                      }
                      
                    }).map((item, key) => (
                      <AssessmentListItem
                        key={`assessment-list-item-${key}`}
                        playerType={auth.isLecturer() ? "lecturer" : "student"}
                        title={item.title} 
                        elapsed={
                          <Small>
                            {`Telah berjalan ${item.elapsed.day > 0 ? ` ${item.elapsed.day} hari` : ""} ${(item.elapsed.hour % 24)} jam ${(item.elapsed.minutes % 60)} menit`}
                          </Small>
                        }
                        learningID={item.learning_id}
                      />
                    ))}
                  </List>
                }
              </Flex>
            </Paper>
          </FlexRow>

        </Flex>
      </FlexRow>
    </Page>
  )
}

const Attendance = ({data} : {data: ClassroomMeetingQ}) => {
  return (
    <Paper
      jc="flex-start"
      ai="stretch"
      py={2}
      css={t => ({
        background: t.palette?.blueGray?.[900],
        width: 400,
        '.attendance-h3 h3': {
          color: t.palette?.blueGray?.[50],
        },
        color: t.palette?.blueGray?.[50], 
        'small': {
          color: t.palette?.blueGray?.[200], 
        },
      })}
    >
      <Flex gap={4}>
        <Flex gap={1} px={2}>
          <H3 className='attendance-h3'>Kehadiran</H3>
          <FlexRow pt={2}>
            <FaBookOpen size={16} /> 
            <Flex gap={0}>
              <Small>
                {data?._.classroom.cys.course.code}
              </Small>
              <Small>
                {data?._.classroom.cys.course.title} - {data?._.classroom.code}
              </Small>
            </Flex>
          </FlexRow>
          <FlexRow>
            <FaUser /> 
            <Small>
              {data?._.classroom.facilitator_enrollments[0]?.lecturer.staff.full_name}
            </Small>
          </FlexRow>
          <FlexRow>
            {data?._.classroom.schedules.map((v, k) => (
              <FlexRow key={`classroom-schedules-item-${k}`}>
                <FaClock /> 
                <Small>
                  {dayName(v.day_number)}, {v.time_start} s/d {v.time_end}
                </Small>
              </FlexRow>
            ))}
          </FlexRow>
        </Flex>
        <Flex
          ai="stretch"
          px={2}
        >
          <FlexRow
            jc="space-between" 
          >
            <FlexRow
              css={t => ({
                color: t.palette?.primary?.main,
              })}
            >
              Presensi Dosen 
            </FlexRow>
            <FlexRow
              jc="flex-end"
              css={t => ({
              })}
            >
              <FaCheck size={16} />
              <Small>2023-03-29 14:44:05</Small> 
                
            </FlexRow>
          </FlexRow>
        </Flex>

        <Flex
          ai="stretch"
          px={2}
        >
          <FlexRow
            jc="space-between" 
          >
            <FlexRow
              css={t => ({
                color: t.palette?.primary?.main,
              })}
            >
              Berita Acara
            </FlexRow>
            <Button
              size="small"
              color="success"
              // css={t => ({
              //   color: t.palette?.secondary?.main,
              // })}
              onClick={() => {

              }}
            >
              <FaSave /> Simpan
            </Button>
          </FlexRow>
          <textarea
            rows={4}
            css={t => ({
              padding: t.spacing(1),
              borderRadius: t.spacing(1),
              fontSize: 12,
              border: `none`,
            })}
          >
          </textarea>
        </Flex>

        <Flex
          ai="stretch"
        >

          <List
            title={
              <FlexRow
                jc="space-between" 
                css={t => ({
                  color: t.palette?.primary?.main,
                  'small': {
                    whiteSpace: "nowrap",
                  },
                })}
              >
                <FlexRow
                >
                  Presensi Mahasiswa
                </FlexRow>
                <FlexRow
                  jc="flex-end"
                  css={t => ({
                    width: "auto",
                  })}
                >
                  <Small>
                    {data?._.classroom.student_enrollments.length} / {data?._.classroom.student_enrollments.length} 
                  </Small> 
                    
                </FlexRow>
              </FlexRow>
            }
          >

            {data?._.classroom.student_enrollments.map(v => ({
              name: v.student.full_name,
              nim: v.student.nim,
              isAttended: true,
              comment: null,
            })).map((item, key) => (
              <ListItem
                className="Student_ListItem"
                key={`student-${key}`}
                css={t =>({
                  borderTopColor: t.palette?.blueGray?.[800],
                })}
              >
                <Flex
                  css={t => ({
                    width: t.spacing(2), 
                    'small': {
                      color: t.palette?.blueGray?.[200],
                    }
                  })}
                >
                  <Small>{key + 1}</Small>
                </Flex>
                <Flex
                  gap={0}
                  css={t => ({
                    'small': {
                      textTransform: "capitalize",
                      color: t.palette?.blueGray?.[50],
                    },
                  })}
                >
                  <Small>
                    {item.name.toLowerCase()}
                  </Small>
                  <FlexRow>
                    <Small>{item.nim}</Small>
                  </FlexRow>
                </Flex>
                <Button
                  color={item.isAttended ? "success" : "error"}
                  variant="icon"
                >
                  {item.isAttended ? <FaCheck /> : <FaTimes />} 
                </Button>
                <Button
                  color="info"
                  variant="soft"
                  size="small"
                  css={t => ({
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingLeft: 8,
                    paddingRight: 8,
                    gap: 4,
                    fontWeight: 400,
                    width: t.spacing(5),
                  })}
                >
                  {item.comment === null ? 0 : 1}
                  <MdChat size={16} /> 
                </Button>
              </ListItem>
            ))}
          </List>
            
        </Flex>

      </Flex>
    </Paper>
  )
}

export default ClassroomMeetingHomeLaptop
