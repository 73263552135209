/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react"
import { FaCheck, FaComment } from "react-icons/fa"
import { Button } from "../../../../../shared/components/IFSuites/Button"
import FlexRow from "../../../../../shared/components/IFSuites/Flex/Row"
import InputText from "../../../../../shared/components/IFSuites/forms/InputText"
import useLayout from "../../../../../shared/hooks/use-layout"

interface Props {
  onSave: (
    score: number,
    feedback: object | null
  ) => void
  answer: {
    score: number
    feedback: object | null
  } | null
}

const GradingGrade = ({answer, onSave}: Props) => {

  const [score, setScore] = useState(0)
  const [feedback, setFeedback] = useState({})
  const layout = useLayout()

  useEffect(() => {

    if(answer) {
      setScore(answer.score)
    }

  }, [answer])

  return (
    <FlexRow
      px={2}
      css={t => ({
        height: 64,
        background: t.palette?.success?.light,
      })}
    >
      Nilai
      <InputText
        slim
        value={score.toString()}
        onChange={(e) => {
          const inputValue = Number(e.target.value) 
          if(inputValue <= 100 && inputValue >= 0) {
            setScore(inputValue)
          }
        }}
        fullWidth
      />
      <Button
        onClick={() => {
        }}
        size='small'
        variant='contained'
        color='info'
      >
        <FaComment /> 0
      </Button>
      <Button
        onClick={() => {
          onSave(score, feedback)
        }}
        variant='contained'
        color='success'
      >
        <FaCheck />
      </Button>
    </FlexRow>
  )
}

export default GradingGrade
