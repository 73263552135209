import { gql } from "@apollo/client";

export const INSERT_MATERIAL = gql`
  mutation InsertMaterial(
    $attachments: jsonb = [],
    $classroom_item_id: uuid!,
    $description: jsonb,
    $title: String!,
  ) {
    insert_academic_learning_one(
      object: {
        attachments: $attachments,
        classroom_item_id: $classroom_item_id,
        description: $description,
        grading_comp_id: 0,
        learning_template_id: 3,
        title: $title,
      }
    ) {
      learning_id
      datetime_updated
    }
  }
`
