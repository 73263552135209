/** @jsxImportSource @emotion/react */

import { useLazyQuery } from "@apollo/client"
import { useState } from "react"
import { FaSearch } from "react-icons/fa"
import ContextMenu from "../../../shared/components/ContextMenu"
import Flex from "../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import InputText from "../../../shared/components/IFSuites/forms/InputText"
import Small from "../../../shared/components/IFSuites/typography/Small"
import ListItem from "../../../shared/components/List/Item"
import { GET_PLACES_BY_TAGS, PlacesByTagsQ } from "./get-places-by-tags.gql"

interface Props {
  label: string
  onChange: (selected: Selected) => void 
  initialTags?: string[]
  initialTitle?: string
  initialDescription?: string
  initialPlaceID?: string
}

type Selected = {
  title: string 
  description: string
  placeID: string
}

const initContextMenuConfig = {
  open: false,
  x: 0,
  y:0,
}

const PlaceSelector = ({ label, onChange, initialTags, initialTitle, initialDescription, initialPlaceID }: Props) => {

  const [searchTags, setSearchTags] = useState<string[]>([])
  const [contextMenuConfig, setContextMenuConfig] = useState(initContextMenuConfig)
  const [selected, setSelected] = useState<Selected | null>(null)

  const currentInitialTags = initialTags || []

  const [getPlaceByTags, getPlaceByTagsOps] = useLazyQuery<PlacesByTagsQ>(GET_PLACES_BY_TAGS)

  const queryData = getPlaceByTagsOps.data ? getPlaceByTagsOps.data.academic_place : getPlaceByTagsOps.loading ? [] : []

  const openInitTagsDropdown = () => {
    console.log("openInitTagsDropdown", currentInitialTags)
    if(currentInitialTags.length > 0) {
      setContextMenuConfig(v => ({...v, open: true}))
      getPlaceByTags({
        variables: {
          _contains: `${initialTags}`
        }
      })
    }
  }

  return (
    <Flex
      css={t => ({
        position: `relative`,
      })}
    >
      {((selected !== null) || (initialTitle !== undefined && initialDescription !== undefined)) &&
        <FlexRow
          css={t => ({
            // color: `rgba(0, 0, 0, 0.7)`,
            color: t.palette?.secondary?.dark,
            background: t.palette?.secondary?.light,
            fontWeight: 700,
            borderRadius: 4,
            padding: 4,
            paddingLeft: 8,
            paddingRight: 8,
            ':focus': {
              border: `1px solid ${t.palette?.secondary?.main}`,
            },
            transition: `.4s`,
            border: `1px solid ${t.palette?.secondary?.main}`,
            outline: `none`,
            ':hover': {
              cursor: "pointer",
              border: `1px solid ${t.palette?.secondary?.main}`,
              boxShadow: `0 0 0 0.2rem ${t.palette?.secondary?.main}33`,
            },
          })}
          onClick={() => {
            setSelected(null)
            openInitTagsDropdown()
          }}
        >
          <Flex
            gap={0}
          >
            <Small>
              {label}:
            </Small>
              {selected?.title || initialTitle}
            <Small
              css={{fontWeight: 400}}
            >
              {selected?.description || initialDescription}
            </Small>
          </Flex>
          <FlexRow
            css={t => ({
              justifyContent: `end`,
              position: `absolute`,
              right: t.spacing(1),
              color: `${t.palette?.primary?.main}`,
              width: `auto`,
            })}
          >
            <FaSearch color="#00000066" />
          </FlexRow>
        </FlexRow>
      }
      {(selected === null && (initialTitle === undefined || initialDescription === undefined)) &&
        <InputText
          label={label}
          fullWidth
          placeholder=""
          value={""}
          onClick={e => {
            openInitTagsDropdown()
          }}
          onChange={e => {
          }}
          endicon={<FaSearch color="#00000066" />}
        />
      }
      <ContextMenu
        open={contextMenuConfig.open}
        width={`100%`}
        top={48}
        left={0}
        onClose={() => {
          setContextMenuConfig(v => ({
            ...v,
            open: false,
          }))
        }}
          menus={getPlaceByTagsOps.loading ? [<ListItem key={`list-item-1`}>Mengambil data...</ListItem>] : queryData.map((item, key) => {

          const title = item.title 

          const description = '' 

          const selectedItem: Selected = {
            title,
            description,  
            placeID: item.place_id
          }

          return (
            <ListItem  
              onClick={() => {
                onChange(selectedItem) 
                setSelected(selectedItem)
                setContextMenuConfig(v => ({
                  ...v,
                  open: false,
                }))
              }}
              key={`select-${label?.replaceAll(" ", "")}-item-${key}`}
              css={t => ({
                transition: ".4s",
              })}
            >
              <Flex
                gap={0}
              >
                <b>
                  {title}
                </b>
                {/* <Small> */}
                {/*   {description} */}
                {/* </Small> */}
              </Flex>
              
            </ListItem>
          )
        })}  
      />
    </Flex>
  )
} 

export default PlaceSelector 
