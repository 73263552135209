/** @jsxImportSource @emotion/react */

import { BsMegaphoneFill } from "react-icons/bs"
import { FaBook, FaChartBar, FaDiscord, FaLink, FaNewspaper } from "react-icons/fa"
import { MdEventNote, MdNewspaper, MdOutlineMenu, MdOutlineMenuBook, MdWeb } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import Flex from "../../shared/components/IFSuites/Flex"
import FlexRow from "../../shared/components/IFSuites/Flex/Row"
import Small from "../../shared/components/IFSuites/typography/Small"
import List from "../../shared/components/List"
import ListItem from "../../shared/components/List/Item"
import Page from "../../shared/components/Page"

import { motion } from "framer-motion"
import ListLayout from "../../shared/components/List/Layout"

const Info = () => {

  const navigate = useNavigate()

  return (
    <Page
      title={`Informasi dan Pengumuman`}
    >
      <ListLayout
        items={[
          {
            title: "Pedoman Akademik",
            icon: <MdOutlineMenuBook size={24} /* color={`#ca4a47`} */ />,
            onClick: () => {
              navigate('/info/academic')
            },
            content: "Tata cara KP, MBKM, Sempro, TA (artikel / skripsi)",
          },
          {
            title: "Discord Channel Informatika",
            icon: <motion.div
              animate={{
                scale: [1, 1, 1.5, 1.5, 1],
                rotate: [0, 0, 360, 360, 0],
              }}
              transition={{
                repeat: Infinity,
                repeatDelay: 2,
              }}
            >
              <FaDiscord size={24} color="#5166f7" /> 
            </motion.div>,
            onClick: () => {
              window.location.href = "https://discord.gg/gJqtfrFgpG"
            },
            content: `Diskusi skill, pelajaran, alumni, karir, ide, tukar kabar, dsb.`,
          },
          {
            title: "Link Penting",
            icon: <FaLink size={24} />,
            onClick: () => {
            },
          },
          {
            title: "Berita Terbaru",
            icon: <MdNewspaper size={24} />,
            onClick: () => {
              navigate('/info/news')
            },
          },
          {
            title: "Event",
            icon: <MdEventNote size={24} />,
            onClick: () => {
              navigate('/event')
            },
          },
        ]}
      />
    </Page>
  )
}

export default Info
