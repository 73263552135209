/** @jsxImportSource @emotion/react */

import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import { Button } from "../../../../../shared/components/IFSuites/Button"
import Flex from "../../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../../shared/components/IFSuites/Flex/Row"
import Small from "../../../../../shared/components/IFSuites/typography/Small"
import GradingAvatar from "../Avatar"

export interface GradingQuestionsProps {
  onChange: (id: string) => void
  questions: {
    learningQuestionID: string
    isGraded?: boolean
  }[]
  selectedLearningQuestionID: string
}

const GradingQuestions = ({
  questions,
  selectedLearningQuestionID,
  onChange,
}: GradingQuestionsProps) => {

  return (
    <Flex
      gap={0}
    >
      <FlexRow
        jc='center'
        css={t => ({
          background: t.palette?.gray?.[50], 
        })}
      >
        <Small>
          Pertanyaan
        </Small>
      </FlexRow> 
      <FlexRow
        css={t => ({
          height: 48,
          background: t.palette?.gray?.[50], 
          overflowX: "auto",
        })}
      >
        <FlexRow
          pb={1}
          pl={1}
          pr={10}
          css={t => ({
            width: "auto",
          })}
        >
          {questions.map((item, key) => {
            const selected = item.learningQuestionID === selectedLearningQuestionID

            return (
              <GradingAvatar
                key={`question-avatar-item-${key}`}
                selected={selected}
                isGraded={item.isGraded}
                onClick={() => {
                  onChange(item.learningQuestionID)
                }}
                label={`${key + 1}`}
                title={`Pertanyaan ${key + 1}`}
              />
            )
          })}
        </FlexRow>
        <FlexRow
          py={2}
          ai='end'
          jc='flex-end'
          css={t => ({
            position: "fixed",
            bottom: t.spacing(0),
            right: t.spacing(6),
            background: t.palette?.gray?.[50], 
            width: t.spacing(8), 
          })}
        >
          <Button
            variant='icon'
            color='secondary'
            onClick={() => {}}
          >
            <FaAngleLeft />
          </Button>
          <Button
            variant='icon'
            color='secondary'
            onClick={() => {}}
          >
            <FaAngleRight />
          </Button>
        </FlexRow>
      </FlexRow>
    </Flex>
  )
}

export default GradingQuestions
