/** @jsxImportSource @emotion/react */

import { MdAccountCircle, MdLogout, MdManageAccounts } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import Flex from "../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import Small from "../../../shared/components/IFSuites/typography/Small"
import List from "../../../shared/components/List"
import ListItem from "../../../shared/components/List/Item"
import Page from "../../../shared/components/Page"
import useAuth from "../../../shared/hooks/use-auth"

const PlayerMenu = () => {

  const auth = useAuth()
  const authInfo = auth.getInfo()
  const adminAuthInfo = auth.getAdminInfo()

  // console.log(authInfo?.roles)
  const navigate = useNavigate()

  return (
    <Page
      title="Akun Saya"
      bottomNavigation
    >
      <List
      title={<Flex>
        <FlexRow>
          {authInfo?.nickname}
        </FlexRow>
        <FlexRow>
          <Small>{authInfo?.nip || authInfo?.nim}</Small>
        </FlexRow>
      </Flex>}
      >
        {[
          {
            label: "Login sebagai",
            icon: <MdManageAccounts />, 
            onClick: () => {
              navigate("/player/log-as")
            },
            roles: ["superadmin"],
          },
          // {
          //   label: "Perbarui profil",
          //   icon: <MdAccountCircle />, 
          //   onClick: () => {
          //     navigate("/player/me/edit")
          //   },
          //   roles: ["superadmin", "lecturer", "student"],
          // },
          {
            label: "Log out",
            icon: <MdLogout />, 
            onClick: () => {
              auth.purgeInfo()
              auth.purgeAdminInfo()
              navigate("/login")
            },
          },
        ].filter(item => {

          let displayed = false 

          // console.log("authInfo", authInfo?.roles)

          if(item.roles) {
            item.roles.forEach(role => {
              if(authInfo?.roles !== undefined) {
                if(authInfo?.roles.includes(role)) {
                  displayed = true
                }
                if(adminAuthInfo?.roles.includes(role)) {
                  displayed = true
                }
              }
            })

            return displayed
          } else {
            return true
          }


        }).map((item, key) => (
          <ListItem
            key={`player-menu-item-${key}`}
            onClick={item.onClick}
          >
          {item.icon} {item.label}
          </ListItem>
        ))}
      </List>
    </Page>
  )
}

export default PlayerMenu
