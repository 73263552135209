/** @jsxImportSource @emotion/react */

const DividerGrow = () => {
  return (
    <div
      css={t => ({
        flexGrow: 1,
      })}
    />
  ) 
}

export default DividerGrow
