/** @jsxImportSource @emotion/react */

import { forwardRef, Ref, useEffect, useRef, useState } from 'react';
import { FaAngleDown, FaAngleLeft, FaAngleRight, FaAngleUp, FaCheck, FaComment, FaPlay } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import EditorApp from '../../../../../shared/components/Editor/MGLexical/App';
import { Button } from '../../../../../shared/components/IFSuites/Button';
import Flex from '../../../../../shared/components/IFSuites/Flex';
import FlexRow from '../../../../../shared/components/IFSuites/Flex/Row';
import InputText from '../../../../../shared/components/IFSuites/forms/InputText';
import Small from '../../../../../shared/components/IFSuites/typography/Small';

import Page from "../../../../../shared/components/Page"
import { nowTime } from '../../../../../shared/utils/datetime';
import useAssessmentGrading from '../../../hooks/use-assessment-grading';
import { questionTypeByID } from '../../utils';

const Avatar = forwardRef(({
  onClick,
  selected,
  title,
  label,
}: {
  onClick: () => void
  selected: boolean
  title: string
  label: string
}, ref: Ref<HTMLDivElement>) => {
  return (
    <Flex
      ref={ref}
      jc='center'
      ai='center'
      onClick={onClick}
      css={t => ({
        color: selected ? t.palette?.gray?.[50] : t.palette?.gray?.[700],
        width: t.spacing(4),
        height: t.spacing(4),
        borderRadius: t.spacing(2),
        background: selected ? t.palette?.secondary?.main : "auto", 
        // border: selected ? `3px solid ${t.palette?.primary?.main}` : `3px solid ${t.palette?.error?.main}`,
        border: `3px solid ${t.palette?.error?.main}`,
        '&:hover': {
          cursor: "pointer",
          // border: `3px solid ${t.palette?.primary?.main}`,
          background: t.palette?.secondary?.main,
          color: t.palette?.gray?.[50],
          // background: t.palette?.gray?.[700], 
        }
      })}
      title={title}
    >
      {label}
    </Flex>
  )
})

const ParticipantAvatar = ({
  onClick,
  selected,
  title,
  label,
  hitUp,
  hitDown,
  parentElement,
}: {
  onClick: () => void
  selected: boolean
  title: string
  label: string
  hitUp: number | null
  hitDown: number | null
  parentElement: HTMLDivElement | null
}) => {

  const ref = useRef<HTMLDivElement>(null)
  const size = useWindowSize()

  useEffect(() => {

    if(selected && ref.current !== null && parentElement) {
      const y = ref.current.getBoundingClientRect().y
      if((size.height - y <= 168)) {
        parentElement.scrollBy(0, 40)
      }
      // console.log(`${title} terpilih hitDown`, y, size.height, hitDown, parentElement, ref)
    }

  }, [ref, selected, hitDown, parentElement])

  useEffect(() => {

    if(selected && ref.current !== null && parentElement) {
      const y = ref.current.getBoundingClientRect().y
      if((size.height - y >= 152)) {
        parentElement.scrollBy(0, -40)
      }
      // console.log(`${title} terpilih hitUp`, y, size.height, hitUp, parentElement, ref)
    }

  }, [ref, selected, hitUp, parentElement])
  
  return (
    <Avatar
      ref={ref}
      onClick={onClick}
      selected={selected}
      title={title}
      label={label}
    />
  )
} 

const ClassroomAssessmentGradingV1 = () => {

  const { learningID } = useParams()
  const size = useWindowSize()
  const [value, setValue] = useState("");
  const grading = useAssessmentGrading(learningID)

  const participantScrollRef = useRef<HTMLDivElement>(null)

  const GradingHeader = () => {

    const selectedLearningQuestionID = grading.selectedLearningQuestion?.learning_question_id
    const questionNumber = selectedLearningQuestionID ? Number(grading.data?.questions.map(v => v.learning_question_id).indexOf(selectedLearningQuestionID)) + 1 : 0 
    const questionTypeID =  grading.selectedLearningQuestion?.question_type_id || 0
    
    return (
      <Flex
        px={2}
        gap={0}
        pr={5}
        css={t => ({
        })}
      >
        <span>
          {grading.data?.participants.find(v => v.learning_participation_id === grading.selectedLearningParticipationID)?.full_name}
        </span>
        <FlexRow>
          {questionTypeByID(questionTypeID).icon} {questionTypeID === 2 ? `Aspek Penilaian` : `Pertanyaan`} {questionNumber} 
        </FlexRow>
      </Flex>
    )
  }

  const setNormalizedScore = (inputValue: string) => {
    const numberValue = Number(inputValue)

    if((!isNaN(numberValue) && numberValue <= 100) || inputValue === '') {
      grading.setSelectedScore(inputValue !== '' ? numberValue.toString() : '')
    }
  }

  return (
    <Page
      title="Periksa Asesmen"
    >
      <FlexRow
        ai="start"
        css={t => ({
          // background: "yellow",
          height: "100%",
        })}
      >
        <Flex
          pr={5}
          gap={0}
          jc="flex-start"
          css={t => ({
            height: size.height - t.spacing(6),
            // background: "pink",
          })}
        >
          <GradingHeader />
          <Flex
            jc='flex-start'
            px={2}
            pb={5}
            mt={1}
            css={t => ({
              // background: "turquoise",
              overflowY: "scroll",
              height: size.height - t.spacing(23),
            })}
          >
            {grading.selectedLearningQuestionDescription !== null &&
              <EditorApp
                editable={false}
                onChange={() => {}}
                value={grading.selectedLearningQuestionDescription} 
              />
            }
            {[3].includes(Number(grading.selectedLearningQuestion?.question_type_id)) && grading.selectedParticipantAnswer?.learning_participation_answer_id !== null &&
              <>
                Jawaban
                {/* {grading.selectedParticipantAnswer?.answer_text !== undefined && */}
                {/* <EditorApp */}
                {/*   editable={false} */}
                {/*   onChange={() => {}} */}
                {/*   value={grading.selectedParticipantAnswer?.answer_text} */} 
                {/* /> */}
                {/* } */}
              </>
            }
          </Flex>
          <Flex
            gap={0}
            pr={5}
            css={t => ({
              position: "absolute",
              bottom: 0,
            })}
          >
            <FlexRow
              px={2}
              css={t => ({
                height: 64,
                background: t.palette?.success?.light,
              })}
            >
              Nilai
              <InputText
                slim
                value={grading.selectedScore}
                onChange={(e) => {
                  const inputValue = e.target.value

                  setNormalizedScore(inputValue)
                  
                }}
                fullWidth
              />
              <Button
                onClick={() => {
                }}
                size='small'
                variant='contained'
                color='secondary'
              >
                <FaComment /> 0
              </Button>
              <Button
                onClick={() => {
                  grading.put(Number(grading.selectedScore)) 
                }}
                variant='contained'
                color='success'
              >
                <FaCheck />
              </Button>
            </FlexRow>
            <FlexRow
              jc='center'
              css={t => ({
                background: t.palette?.gray?.[50], 
              })}
            >
              <Small>
                Pertanyaan
              </Small>
            </FlexRow> 
            <FlexRow
              css={t => ({
                height: 48,
                background: t.palette?.gray?.[50], 
                overflowX: "auto",
              })}
            >
              <FlexRow
                pb={1}
                pl={1}
                pr={10}
                css={t => ({
                  width: "auto",
                })}
              >
              {grading.data?.questions.map((item, key) => {
                const selected = item.learning_question_id === grading.selectedLearningQuestion?.learning_question_id


                return (
                  <Avatar
                    key={`question-avatar-item-${key}`}
                    selected={selected}
                    onClick={() => {grading.setSelectedLearningQuestion(item)}}
                    label={`${key + 1}`}
                    title={`Pertanyaan ${key + 1}`}
                  />
                  // <Flex
                  //   onClick={() => {grading.setSelectedLearningQuestion(item)}}
                  //   jc='center'
                  //   ai='center'
                  //   key={`participant-item-${key}`}
                  //   css={t => ({
                  //     color: t.palette?.gray?.[700],
                  //     width: t.spacing(4),
                  //     height: t.spacing(4),
                  //     borderRadius: t.spacing(2),
                  //     // background: selected ? t.palette?.gray?.[50] : t.palette?.gray?.[400], 
                  //     border: selected ? `3px solid ${t.palette?.primary?.main}` : `3px solid ${t.palette?.error?.main}`,
                  //     '&:hover': {
                  //       cursor: "pointer",
                  //       border: `3px solid ${t.palette?.primary?.main}`,
                  //       // background: t.palette?.gray?.[50], 
                  //     }
                  //   })}
                  // >
                  //   {key + 1}
                  // </Flex>
                )
              })}
              </FlexRow>
              <FlexRow
                py={2}
                ai='end'
                jc='flex-end'
                css={t => ({
                  position: "fixed",
                  bottom: t.spacing(0),
                  right: t.spacing(7),
                  background: t.palette?.gray?.[50], 
                  width: t.spacing(8), 
                })}
              >
                <Button
                  variant='icon'
                  color='secondary'
                  onClick={() => grading.setHit(v => ({...v, left: nowTime()}))}
                >
                  <FaAngleLeft />
                </Button>
                <Button
                  variant='icon'
                  color='secondary'
                  onClick={() => grading.setHit(v => ({...v, right: nowTime()}))}
                >
                  <FaAngleRight />
                </Button>
              </FlexRow>
            </FlexRow>
          </Flex>
        </Flex>
        <Flex
          jc='flex-start'
          ref={participantScrollRef}
          css={t => ({
            position: "fixed",
            background: t.palette?.gray?.[50], 
            width: t.spacing(6),
            top: 0,
            right: 0,
            height: size.height,
            overflowY: "auto",
          })}
        >
          <Flex
            ai='center'
            pt={9}
            pb={12}
            css={t => ({
              // background: t.palette?.yellow?.[700],
            })}
          >
            <Flex
              ai='center'
              css={t => ({
                position: "fixed",
                top: t.spacing(6),
                left: size.width - t.spacing(6),
                background: t.palette?.gray?.[50], 
                width: t.spacing(6), 
              })}
            >
              <Small>
                Mhsw.
              </Small>
            </Flex>
            <Flex
              py={2}
              ai='center'
              css={t => ({
                position: "fixed",
                bottom: t.spacing(0),
                left: size.width - t.spacing(6),
                background: t.palette?.gray?.[50], 
                width: t.spacing(6), 
              })}
            >
              <Button
                variant='icon'
                color='secondary'
                onClick={() => grading.setHit(v => ({...v, up: new Date().getTime()}))}
              >
                <FaAngleUp />
              </Button>
              <Button
                variant='icon'
                color='secondary'
                onClick={() => grading.setHit(v => ({...v, down: new Date().getTime()}))}
              >
                <FaAngleDown />
              </Button>
            </Flex>

            {grading.data?.participants.filter(v => v.full_name !== null).map((item, key) => {

              const splittedFullName = item.full_name.split(' ')
              const initial = `${splittedFullName[0][0]}${splittedFullName[1] ? splittedFullName[1][0] : ''}`
              const selected = item.learning_participation_id === grading.selectedLearningParticipationID

              return (
                <ParticipantAvatar 
                  selected={selected}
                  onClick={() => {grading.setSelectedLearningParticipationID(item.learning_participation_id)}}
                  key={`participant-item-${key}`}
                  label={initial}
                  title={`${item.full_name} (${item.nim})`}
                  hitUp={grading.hit.up}
                  hitDown={grading.hit.down}
                  parentElement={participantScrollRef.current}
                />
              )
            })}
          </Flex>
        </Flex>
      </FlexRow>
    </Page>
  )
}

export default ClassroomAssessmentGradingV1

// References drag to scroll https://htmldom.dev/drag-to-scroll/
