/** @jsxImportSource @emotion/react */

import { gql, useQuery } from "@apollo/client"
import Paper from "../../../../shared/components/IFSuites/Paper"
import FlexRow from "../../../../shared/components/IFSuites/Flex/Row"
import Page from "../../../../shared/components/Page"
import Flex from "../../../../shared/components/IFSuites/Flex"
import { Button } from "../../../../shared/components/IFSuites/Button"
import { } from "react-icons/io"
import useBreakpoint from "../../../../shared/hooks/use-breakpoint"
import Small from "../../../../shared/components/IFSuites/typography/Small"
import { useNavigate, useParams } from "react-router-dom"
import dayjs from "dayjs"
import { useEffect } from "react"

interface AssessmentQ {
  _: {
    attachments: object[]
    datetime_end: string
    datetime_start: string
    description: object
    is_group_learning: number
    learning_id: string
    learning_template_id: string
    participations: {
      score: number
      datetime_submit: null | string
    }[]
    questions: {
      learning_question_id: string
      answer_choices: object[]
      attachments: object[]
      description: object
      question_type_id: number
      clo: {
        clo: {
          code: string
          description: string 
          plo: {
            code: string
            description: string
          }
        }
        weight: number
      }
    }[]
    title: string
    classroom_item: {
      classroom: {
        code: string
        cys: {
          year_semester: {
            academic_semester: number
            academic_year: number
          }
          course: {
            code: string
            title: string
            programme: {
              title: string
            }
            credit: number
            lab_credit: number
          }
        }
        facilitator_enrollments: {
          lecturer: {
            staff: {
              full_name: string
            }
          }
        }[]
      }
    }
    grading_comp: {
      title: string
      grading_comp_id: number
    }
  }
}

const ClassroomAssessmentGame = () => {

  const bp = useBreakpoint()
  const { learningID } = useParams()

  const navigate = useNavigate()

  const GET_ASSESSMENT_BY_PK = gql`
    query GetAssessmentByPK($learning_id: uuid = "") {
      _: academic_learning_by_pk(learning_id: $learning_id) {
        attachments
        datetime_end
        datetime_start
        description
        is_group_learning
        learning_id
        learning_template_id
        participations {
          score
          datetime_submit
        }
        questions {
          learning_question_id
          answer_choices
          attachments
          clo {
            clo {
              code
              description
              plo {
                code
                description
              }
            }
            weight
          }
          description
          question_type_id
        }
        title
        classroom_item {
          classroom {
            code
            cys {
              year_semester {
                academic_semester
                academic_year
              }
              course {
                code
                title
                programme {
                  title
                }
                credit
                lab_credit
              }
            }
            facilitator_enrollments {
              lecturer {
                staff {
                  full_name
                }
              }
            }
          }
        }
        grading_comp {
          title
          grading_comp_id
        }
      }
    }
  `

  const {data, refetch} = useQuery<AssessmentQ>(
    GET_ASSESSMENT_BY_PK,
    {
      variables: {
        learning_id: learningID,
      }
    }
  )

  const course = data?._.classroom_item.classroom.cys.course
  const datetimeStart = dayjs(data?._.datetime_start)
  const datetimeEnd = dayjs(data?._.datetime_end)
  const datetimeNow = dayjs()
  const isLate = datetimeNow.isAfter(datetimeEnd)
  const isTooEarly = datetimeNow.isBefore(datetimeStart)
  const submitTime = data?._.participations[0].datetime_submit ? dayjs(data?._.participations[0].datetime_submit) : null

  useEffect(() => {
    refetch()
  }, [])


  return (
    <Page
      title={data?._.title}
    >
      <Paper
        br={0}
      >
        <Flex
          p={2}
          ai="center"
        >
          <FlexRow
            jc="center"
            css={t => ({
              fontSize: 36,
            })}
          >
            {data?._.title}
          </FlexRow>
          {isLate &&
            <FlexRow
              css={t => ({
                textAlign: "center"
              })}
            >
              Periode pengerjaan telah ditutup {datetimeEnd.add(7, 'h').format("dddd D MMMM YYYY")} jam {datetimeEnd.add(7, 'h').format("HH:mm")}
            </FlexRow>
          }
          {submitTime !== null &&
          <Small>
            Kamu telah mengumpulkan asesmen ini pada {submitTime.add(7, 'h').format("DD MMMM YYYY")} jam {submitTime.add(7, 'h').format("HH:mm")}
          </Small>
          }
          <Flex
            jc="center"
            py={4}
            w={240}
            css={t => ({
              textAlign: "center",
            })}
          >
            {isLate !== undefined && !isLate && !isTooEarly &&
              <Button
                br={3}
                variant={"contained"}
                color="success"
                fullWidth
                onClick={() => {
                  navigate(`/classroom/assessment/${learningID}/game/question/${data?._.questions[0].learning_question_id}`) 
                }}
              >
                {submitTime !== null ?
                  `Review` : `Mulai`
                } 
              </Button>
            }

            {isLate &&
              <Button
                br={3}
                size={"small"}
                variant={"contained"}
                color="info"
                fullWidth
                onClick={() => {
                  navigate(`/classroom/assessment/${learningID}/game/question/${data?._.questions[0].learning_question_id}`) 
                }}
              >
                Review
              </Button>
            }
          </Flex>
          <FlexRow 
            ai="start"
            p={2}
            css={t => ({
              background: t.palette?.gray?.[200],
              flexDirection: ["tablet", "mobile"].includes(bp) ? "column" : "row",
              '& > .Flex > .FlexRow': {
                flexDirection: ["mobile"].includes(bp) ? "column" : "row",
                gap: ["mobile"].includes(bp) ? 1 : 2,
              }
            })}
          >
            <Flex
              
            >
              {[
                {
                  label: "Jurusan / Prodi",
                  value: course?.programme.title,
                },
                {
                  label: "Mata Kuliah",
                  value: course?.title,
                },
                {
                  label: "Kode Mata Kuliah",
                  value: course?.code,
                },
                {
                  label: "Bobot SKS",
                  value: `${course?.credit} SKS`,
                },
                {
                  label: "Semester / Kelas",
                  value: `${data?._.classroom_item.classroom.cys.year_semester.academic_semester} / ${data?._.classroom_item.classroom.code}`,
                },
                {
                  label: "Gedung / Ruang",
                  value: "",
                },
              ].map((item, key) => (
                <FlexRow
                  key={`assessment-description-item-2-${key}`}
                >
                  <Flex 
                    w={bp === "mobile" ? undefined : 240}
                  >
                    {bp === "mobile" ? 
                      <Small>
                        {item.label} 
                      </Small>
                      :
                      <>{item.label}</> 
                    }
                  </Flex>
                  {bp !== "mobile" &&
                    <Flex w={"auto"}>:</Flex>
                  }
                  <Flex pl={bp === "mobile" ? 0 : 1}>
                    {item.value} 
                  </Flex>
                </FlexRow> 
              ))}
            </Flex>
            <Flex
            >
              {[
                {
                  label: "Hari / Tanggal",
                  value: data?._.datetime_start,
                },
                {
                  label: "Waktu",
                  value: data?._.datetime_start,
                },
                {
                  label: "Jenis Ujian",
                  value: data?._.grading_comp.title,
                },
                {
                  label: "Tahun Akademik",
                  value: `${data?._.classroom_item.classroom.cys.year_semester.academic_year}/${Number(data?._.classroom_item.classroom.cys.year_semester.academic_year) + 1}`,
                },
                {
                  label: "Dosen",
                  value: <Flex>
                  {data?._.classroom_item.classroom.facilitator_enrollments.map((item, key) => (
                    `${key+1}. ${item.lecturer.staff.full_name}` 
                  ))}
                  </Flex>,
                },
                {
                  label: "CPMK",
                  value: <Flex>
                  {data?._.questions.reduce((arr, item) => {
                    const currentItem = arr.find(v => v.cloCode === item.clo.clo.code)
                    if(currentItem) {
                      currentItem.count += 1
                    } else {
                      arr.push({
                        cloCode: item.clo.clo.code,
                        count: 1,
                      })
                    }

                    return arr
                  }, [] as {cloCode: string, count: number}[]).map((item, key) => (
                    <FlexRow key={`clo-list-item-${key}`}>
                      {item.cloCode} - {item.count} Soal
                    </FlexRow>
                  ))}
                  </Flex>,
                },
                {
                  label: "Nilai",
                  value: data?._.participations[0].datetime_submit === null ? '-' : data?._.participations[0].score,
                },
              ].map((item, key) => (
                <FlexRow
                  key={`assessment-description-item-1-${key}`}
                >
                  <Flex 
                    w={bp === "mobile" ? undefined : 240}
                  >
                    {bp === "mobile" ? 
                      <Small>
                        {item.label} 
                      </Small>
                      :
                      <>{item.label}</> 
                    }
                  </Flex>
                  {bp !== "mobile" &&
                    <Flex w={"auto"}>:</Flex>
                  }
                  <Flex pl={bp === "mobile" ? 0 : 1}>
                    {item.value} 
                  </Flex>
                </FlexRow> 
              ))}
            </Flex>
          </FlexRow>
        </Flex>
      </Paper>
    </Page>
  )
}

export default ClassroomAssessmentGame
