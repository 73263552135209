/** @jsxImportSource @emotion/react */

import { ReactNode, useState } from "react"
import { FaArrowLeft, FaEllipsisV, FaHome } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import useBreakpoint from "../../hooks/use-breakpoint"
import BottomNavigation from "../BottomNavigation"
import ContextMenu from "../ContextMenu"
import { Button } from "../IFSuites/Button"
import Flex from "../IFSuites/Flex"
import FlexRow from "../IFSuites/Flex/Row"
import { CommonProps } from "../IFSuites/interfaces"
import Small from "../IFSuites/typography/Small"
import ListItem from "../List/Item"

interface PageProps {
  title: ReactNode 
  menus?: JSX.Element[], 
  backOnClick?: () => void
  bottomNavigation?: true
  rightElement?: ReactNode
}


// https://stackoverflow.com/questions/45209760/react-how-to-determine-if-a-specific-child-component-exists
// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component

const Page = ({title, children, bottomNavigation, menus, rightElement, ...other}: CommonProps & PageProps) => {

  const navigate = useNavigate()
  const [contextMenuOpen, setContextMenuOpen] = useState(false)

  const bp = useBreakpoint()

  const defaultMenus: JSX.Element[] = [
    <ListItem key={`page-context-menu-item-1`} onClick={() => {navigate("/player/dashboard")}}>
      <FaHome /> 
      <Small>Home</Small>
    </ListItem> 
  ]

  return (
    <Flex
      className="Page"
      gap={0}
      jc="flex-start"
      css={t => ({
        background: t.palette?.gray?.[100],
        height: window.innerHeight,
        overflowX: "hidden",
      })}
    >
      <FlexRow
        className="PageHeader"
        gap={0}
        css={t => ({
          height: t.spacing(7),
          position: "fixed",
          background: t.palette?.gray?.[100],
          zIndex: 7,
          borderBottom: `1px solid ${t.palette?.gray?.[300]}`
        })}
      >
        <Flex
          ai="center"
          css={t => ({
            width: t.spacing(5),
          })}
        >
          <Button
            variant="icon"
            color="info"
            onClick={() => {
              navigate(-1)
            }}
          >
            <FaArrowLeft />
          </Button>
        </Flex>
        <Flex
          ai="center"
          css={t => ({
            flexGrow: 1,
            textAlign: "center",
            fontSize: ["tablet", "mobile"].includes(bp) ? "initial" : "x-large",
            fontWeight: ["tablet", "mobile"].includes(bp) ? 400 : 700,
          })}
        >
          {title}
        </Flex>
        {rightElement} 
        {menus &&
          <Flex
            ai="center"
            css={t => ({
              width: t.spacing(5),
            })}
          >
            <div>
              <Button
                color="info"
                variant="icon"
                onClick={() => {
                  setContextMenuOpen(!contextMenuOpen)
                }}
              >
                <FaEllipsisV />
              </Button>
              <ContextMenu
                menus={menus ? [...menus, ...defaultMenus] : []}
                open={contextMenuOpen}
                onClose={() => setContextMenuOpen(false)}
                top={8}
                left={window.innerWidth - 160 - 8}
                width={160}
              />
            </div>
          </Flex>
        }
      </FlexRow>
      <Flex
        className="hundred"
        pt={7}
        jc="stretch"
        ai="stretch"
        css={t => ({
          minHeight: `100%`,
        })} 
      >
        {children}
      </Flex>
      {bottomNavigation &&
        <BottomNavigation />
      }
    </Flex>
  )
}

export default Page
