import { gql } from "@apollo/client";

export const INSERT_STUDENT_ENROLLMENT = gql`
  mutation InsertStudentEnrollment($object: academic_student_enrollment_insert_input!) {
    insert_academic_student_enrollment_one(object: $object, on_conflict: {constraint: student_enrollment_un, update_columns: [is_active]}) {
      student_enrollment_id
      is_active
      student {
        full_name
        nim
      }
    }
  }
`
