/** @jsxImportSource @emotion/react */

import { atom } from "jotai";
import { LearningCreateWS } from "../models/learning";

export const emptyEditorState = JSON.parse(
  `{"root":{"children":[{"children":[],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`
)

export const initLearningCreate = {
  classroom_id: "",
  classroom_item_id: "",
  learning_template_id: 2,
  title: "", 
  description: emptyEditorState,
  attachments: [],
  is_group_learning: 0, 
  grading_comp_id: 0, 
  datetime_start: null,
  datetime_end: null,
  questions: [],
  is_draft: false,
}

export const learningCreateAtom = atom<LearningCreateWS>(initLearningCreate)
