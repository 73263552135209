/** @jsxImportSource @emotion/react */

import axios from "axios"
import { useAtom } from "jotai"
import { useNavigate } from "react-router-dom"
import layoutAtom from "../states/layout"
import { API_URL } from "../variables/config"
import ReactGA from 'react-ga4'

export interface LoginInfo {
  fullName: string,
  roles: Array<string>,
  username: string,
  playerUUID: string, // UUID dari player (dosen, mahasiswa, eksternal alumni / industri)
  nickname: string,

  nip?: string,
  staffID?: string,
  lecturerID?: string,
  nim?: string,
  studentID?: string,
  programmeID?: string,
}

const useAuth = () => {

  const [, setLayout] = useAtom(layoutAtom)
  const navigate = useNavigate()

  // Get Token
  const getTokenFromAPI = (username: string, password: string) => {

    setLayout(v => ({
      ...v,
      loading: {
        ...v.loading,
        open: true,
      },
    }))

    // const url = `/academic/v1/player/auth/token`
    const url = `${API_URL}/player/auth/token`
    const payload = {
      username: username,
      password: password,
    }

    axios.post(url, payload).then(response => {

      if(response.status === 200) {

        const accessToken = response.data["access_token"]
        const refreshToken = response.data["refresh_token"]
        const tokenExp = response.data["exp"]

        const parsedToken = parseJwt(accessToken) 
        const claims = parsedToken["claims"] 
        // const hasuraClaims = parsedToken["https://hasura.io/jwt/claims"]

        setInfo(
          accessToken,
          refreshToken,
          tokenExp,
          {
            fullName: claims["fullname"],
            roles: claims["roles"],
            playerUUID: claims["player_uuid"],
            username: claims['username'],
            nickname: claims['nickname'],

            nip: claims['nip'],
            staffID: claims['staff_id'],
            lecturerID: claims['lecturer_id'],
            nim: claims['nim'],
            studentID: claims['student_id'],
            programmeID: claims['programme_id'],
          },
        )

        if(claims["roles"].includes("lecturer")) {
          localStorage.setItem("hasuraDefaultRole", "lecturer")
          localStorage.setItem("hasuraCurrentRole", "lecturer")
          localStorage.setItem("lecturerID", claims['lecturer_id'])
        }

        if(claims["roles"].includes("student")) {
          localStorage.setItem("hasuraDefaultRole", "student")
          localStorage.setItem("hasuraCurrentRole", "student")
          localStorage.setItem("studentID", claims['student_id'])
        }

        if(claims["roles"].includes("programmeadmin") && !claims["roles"].includes("student") && !claims["roles"].includes("lecturer")) {
          localStorage.setItem("hasuraDefaultRole", "programmeadmin")
          localStorage.setItem("hasuraCurrentRole", "programmeadmin")
        }

        if(claims["roles"].includes("superadmin")) {
          setAdminInfo(
            accessToken,
            refreshToken,
            tokenExp,
            {
              fullName: claims["fullname"],
              roles: claims["roles"],
              playerUUID: claims["player_uuid"],
              username: claims['username'],
              nickname: claims['nickname'],

              nip: claims['nip'],
              staffID: claims['staff_id'],
              lecturerID: claims['lecturer_id'],
              nim: claims['nim'],
              studentID: claims['student_id'],
              programmeID: claims['programme_id'],
            },
          )
        }

        setLayout(v => ({
          ...v,
          confirmDialog: {
            ...v.confirmDialog,
            open: true,
            content: "Login berhasil", 
            onClick: () => {
              navigate("/player/dashboard")
            }
          }
        }))

        ReactGA.event({
          category: "PLAYER",
          action: "LOGIN_SUCCESS",
        })

      } else {

        setLayout(v => ({
          ...v,
          confirmDialog: {
            ...v.confirmDialog,
            open: true,
            content: "Terjadi kesalahan login", 
            onClick: () => {},
          }
        }))

        ReactGA.event({
          category: "PLAYER",
          action: "LOGIN_FAILED",
        })
      }

    }).catch(e => {

      console.log("error", e)

      setLayout(v => ({
        ...v,
        confirmDialog: {
          ...v.confirmDialog,
          open: true,
          content: "Terjadi kesalahan login", 
          onClick: () => {},
        }
      }))
    }).finally(() => {

      setLayout(v => ({
        ...v,
        loading: {
          ...v.loading,
          open: false,
        },
      }))
    })
  }

  /**
    * Parse JWT
    */
  const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  /**
  * Is user logged in ?
  */
  const isLoggedIn = () => {
    return (
      localStorage.getItem('accessToken') !== null
      && localStorage.getItem('loginInfo') !== null
    )
  }

  /**
  * Is Admin user logged in ?
  */
  const isAdminLoggedIn = () => {
    return (
      localStorage.getItem('adminAccessToken') !== null
      && localStorage.getItem('adminLoginInfo') !== null
    )
  }

  /**
  * Get auth info 
  */
  const getInfo = () : LoginInfo | undefined => {
    const loginInfo = localStorage.getItem('loginInfo');

    if (loginInfo == null) {
      return undefined;
    } else {

      const parsedLoginInfo = JSON.parse(loginInfo);
      return {
        fullName: parsedLoginInfo['fullName'],
        roles: parsedLoginInfo['roles'],
        playerUUID: parsedLoginInfo['playerUUID'],
        username: parsedLoginInfo['username'],
        nickname: parsedLoginInfo['nickname'],

        nip: parsedLoginInfo['nip'],
        staffID: parsedLoginInfo['staffID'],
        lecturerID: parsedLoginInfo['lecturerID'],
        nim: parsedLoginInfo['nim'],
        studentID: parsedLoginInfo['studentID'],
        programmeID: parsedLoginInfo['programmeID'],
      }
    }
  }

  const isProgrammeAdmin = () : boolean => {
    const loginInfo = getInfo()
    return loginInfo?.roles.includes('programmeadmin') ? true : false
  }

  const isLecturer = () : boolean => {
    const loginInfo = getInfo()
    return loginInfo?.roles.includes('lecturer') ? true : false
  }

  const isStudent = () : boolean => {
    const loginInfo = getInfo()
    return loginInfo?.roles.includes('student') ? true : false
  }

  const getAdminInfo = () : LoginInfo | undefined => {
    const loginInfo = localStorage.getItem('adminLoginInfo');

    if (loginInfo == null) {
      return undefined;
    } else {

      const parsedLoginInfo = JSON.parse(loginInfo);
      return {
        fullName: parsedLoginInfo['fullName'],
        roles: parsedLoginInfo['roles'],
        playerUUID: parsedLoginInfo['playerUUID'],
        username: parsedLoginInfo['username'],
        nickname: parsedLoginInfo['nickname'],

        nip: parsedLoginInfo['nip'],
        staffID: parsedLoginInfo['staffID'],
        lecturerID: parsedLoginInfo['lecturerID'],
        nim: parsedLoginInfo['nim'],
        studentID: parsedLoginInfo['studentID'],
        programmeID: parsedLoginInfo['programmeID'],
      }
    }
  }

  /**
  * Get token 
  */
  const getToken = () : string | undefined => {
    const token = localStorage.getItem('accessToken');

    if (token == null) {
      return undefined;
    } else {
      return token;
    }
  }

  const getAdminToken = () : string | undefined => {
    const token = localStorage.getItem('adminAccessToken');

    if (token == null) {
      return undefined;
    } else {
      return token;
    }
  }

  /**
  * Set info 
  */
  const setInfo = (accessToken: string, refreshToken: string, tokenExp: string, loginInfo: LoginInfo) => {
    console.log(["setAuthInfo", loginInfo])
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('tokenExp', tokenExp);
    localStorage.setItem('loginInfo', JSON.stringify(loginInfo));
  }

  const setAdminInfo = (accessToken: string, refreshToken: string, tokenExp: string, loginInfo: LoginInfo) => {
    console.log(["setAdminAuthInfo", loginInfo])
    localStorage.setItem('adminAccessToken', accessToken);
    localStorage.setItem('adminRefreshToken', refreshToken);
    localStorage.setItem('adminTokenExp', tokenExp);
    localStorage.setItem('adminLoginInfo', JSON.stringify(loginInfo));
  }

  /**
  * Purge info 
  */
  const purgeInfo = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('tokenExp')
    localStorage.removeItem('loginInfo')
    localStorage.removeItem("hasuraDefaultRole")
    localStorage.removeItem("hasuraCurrentRole")
    localStorage.removeItem("lecturerID")
    localStorage.removeItem("studentID")
  }

  const purgeAdminInfo = () => {
    localStorage.removeItem('adminAccessToken');
    localStorage.removeItem('adminRefreshToken');
    localStorage.removeItem('adminTokenExp');
    localStorage.removeItem('adminLoginInfo');
  }

  return { 
    getTokenFromAPI,
    getToken,
    getAdminToken,
    isLoggedIn,
    isAdminLoggedIn,
    isLecturer,
    isStudent,
    isProgrammeAdmin,
    getInfo,
    getAdminInfo,
    purgeInfo,
    purgeAdminInfo,
    setInfo,
    parseJwt,
  }
}





export default useAuth
