import { gql } from "@apollo/client";

export interface MaterialQ {_: {
  title: string
  learning_template_id: number 
  learning_id: string
  attachments: {
    title: string
    url: string
  }[]
  description: object
  classroom_item_id: string
}}

export const GET_MATERIAL_BY_ID = gql`
query GetMaterialByID($learning_id: uuid = "") {
  _: academic_learning_by_pk(learning_id: $learning_id) {
    attachments
    description
    learning_id
    learning_template_id
    title
    classroom_item_id
  }
}
`
