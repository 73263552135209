/** @jsxImportSource @emotion/react */

import { gql, useLazyQuery, useMutation } from "@apollo/client"
import { atom, useAtom } from "jotai"
import { useState } from "react"
import { FaPlus, FaSearch, FaTimes } from "react-icons/fa"
import { useWindowSize } from "react-use"
import { Button } from "../../../../../shared/components/IFSuites/Button"
import Dialog from "../../../../../shared/components/IFSuites/Dialog"
import Flex from "../../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../../shared/components/IFSuites/Flex/Row"
import InputText from "../../../../../shared/components/IFSuites/forms/InputText"
import Paper from "../../../../../shared/components/IFSuites/Paper"
import H3 from "../../../../../shared/components/IFSuites/typography/H3"
import Small from "../../../../../shared/components/IFSuites/typography/Small"
import List from "../../../../../shared/components/List"
import ListItem from "../../../../../shared/components/List/Item"
import useBreakpoint from "../../../../../shared/hooks/use-breakpoint"
import layoutAtom, { initLayoutAtom } from "../../../../../shared/states/layout"

interface EnrollConfig {
  open: boolean
  searchKeywords: string
  searchResults: {
    full_name: string
    nim: string
    student_id: string
  }[],
  redClassroomCode?: string
  classroomID?: string
  refetch?: () => void
}

export const initEnrollConfig: EnrollConfig = {
  open: false,
  searchKeywords: "",
  searchResults: [], 
  redClassroomCode: undefined,
  classroomID: undefined,
  refetch: undefined,
}

export const enrollConfigAtom = atom<EnrollConfig>(initEnrollConfig)

const ClassroomEnrollmentDialogAdd = () => {

  const bp = useBreakpoint()
  const windowSize = useWindowSize()
  const [searchKeywords, setSearchKeywords] = useState("")

  const [layout, setLayout] = useAtom(layoutAtom)
  const [enrollConfig, setEnrollConfig] = useAtom(enrollConfigAtom)

  const FIND_STUDENTS_BY_KEYWORDS = gql`
    query FindStudentByKeywords($keywords: String!) {
      _: academic_student(where: {_or: [{full_name: {_ilike: $keywords}}, {nim: {_ilike: $keywords}}]}) {
        full_name
        nim
        student_id
        programme {
          title
          code
        }
      }
    }
  `

  const [findStudents, findStudentsResult] = useLazyQuery<
    {
      _: {
        full_name: string
        nim: string
        student_id: string
        programme: {
          title: string
          code: string
        }
      }[]
    }
  >(
    FIND_STUDENTS_BY_KEYWORDS 
  )

  const INSERT_STUDENT_ENROLLMENT = gql`
    mutation InsertStudentEnrollment($object: academic_student_enrollment_insert_input!) {
      insert_academic_student_enrollment_one(object: $object, on_conflict: {constraint: student_enrollment_un, update_columns: [is_active]}) {
        student_enrollment_id
        is_active
        student {
          full_name
          nim
        }
      }
    }
  `

  const [insertStudent, {data: insertStudentData}] = useMutation(
    INSERT_STUDENT_ENROLLMENT,
  )

  return (
    <Dialog
      open={enrollConfig.open}
    >
      <Paper
        css={t =>({
          width: bp === "tablet" ? windowSize.width - t.spacing(4) : 640, 
        })}
      >
        <Flex
          css={t =>({
          })}
        >
          <FlexRow
            p={2}
            jc={"space-between"}
          >
            <H3>Tambahkan anggota kelas</H3>
            <Button
              variant="icon"
              color="error"
              onClick={() => {
                console.log("reset dialog")
                setEnrollConfig(v => (initEnrollConfig))
              }}
            >
              <FaTimes />
            </Button>
          </FlexRow>          
          <Flex px={2}>
            <InputText
              fullWidth
              label="Cari nim / nama mahasiswa"
              value={searchKeywords} 
              onChange={e => {
                setSearchKeywords(e.target.value)
              }}
              endicon={
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    if(searchKeywords.length > 2){
                      findStudents({
                        variables: {
                          keywords: `%${searchKeywords}%`
                        }
                      })
                    } 
                  }}
                >
                  <FaSearch /> Cari
                </Button>
              }
            />
          </Flex>
          <Flex
            mb={3}
          >
            <List
              title={`Hasil Pencarian (${findStudentsResult.data ? findStudentsResult.data?._.length : 0})`}
              css={t => ({
                '.children-container': {
                  maxHeight: 300,
                  overflowY: "auto",
                }
              })}
            >
              {findStudentsResult.data?._.map((item, key) => (
                <ListItem
                  key={`search-result-item-${key}`}
                >
                  <FlexRow
                    jc="space-between"
                  >
                    <Flex>
                      <Small>{item.full_name} ({item.nim})</Small> 
                      <Small>{item.programme.title}</Small> 
                    </Flex>
                    <Button
                      color="secondary"
                      size="small"
                      onClick={() => {
                        setLayout(v => ({
                          ...v,
                          confirmDialog: {
                            ...v.confirmDialog, 
                            open: true,
                            isCancelable: true,
                            content: <FlexRow>Tambahkan <b>{item.full_name}</b> ke kelas ini ?</FlexRow>,
                            onClick: () => {
                              setLayout(initLayoutAtom)
                              insertStudent({
                                variables: {
                                  object: {
                                    classroom_id: enrollConfig.classroomID,
                                    student_id: item.student_id,
                                    red_student_nim: item.nim,
                                    red_classroom_code: enrollConfig.redClassroomCode,
                                    is_active: 1,
                                  } 
                                }
                              }).then(res => {
                                setLayout(v2 => ({
                                  ...v2,
                                  confirmDialog: {
                                    ...v2.confirmDialog,
                                    open: true,
                                    content: <FlexRow><b>{item.full_name}</b> berhasil ditambahkan</FlexRow>,
                                    onClick: () => {
                                      if(enrollConfig.refetch) enrollConfig.refetch()
                                      setEnrollConfig(initEnrollConfig)
                                    }
                                  }
                                  
                                }))
                              })
                              console.log("student added")
                            }
                          }
                        }))
                      }}
                    >
                      <FaPlus /> Tambah
                    </Button>
                  </FlexRow>
                </ListItem>
              ))} 
            </List>
          </Flex>
        </Flex>
      </Paper>
    </Dialog>
  )
}

export default ClassroomEnrollmentDialogAdd
