import { gql } from "@apollo/client";

export const INSERT_TICKET = gql`
  mutation InsertTicket($objects: [helpdesk_ticket_insert_input!] = {}) {
    insert_helpdesk_ticket(objects: $objects) {
      returning {
        ticket_id
      }
    }
  }
`
