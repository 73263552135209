import { BsMegaphoneFill } from "react-icons/bs"
import { FaBook } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import List from "../../../shared/components/List"
import ListItem from "../../../shared/components/List/Item"
import Page from "../../../shared/components/Page"

const InfoAcademic = () => {

  const navigate = useNavigate()

  return (
    <Page
      title={`Pedoman Akademik`}
    >
      <List>
        {[
          {
            title: "Pedoman Akademik",
            url: "https://if.uinsgd.ac.id/stream/pdf/eyJpdiI6IklzWWtWRkpBektObnpydzR0WmZwdHc9PSIsInZhbHVlIjoiUGpMMVBicElSZGEvb0ZwSVYzdG10TDVOSWVzSWJRVnBkakhiVG9WV01ncXAwRHBFeE1Kekh3TitpcUVNS0puLzN1S2p6TWVZYzFybTEyT25oZlp4MWc9PSIsIm1hYyI6IjkxZmMxOTZmNGZiNGNjZTAzZDQ3NTVlOGQ3NDk1Yzg2NzVhOTQ4YTZkNGMzZWEyOWRhMzRjNWIzY2ZkMjVjMDUiLCJ0YWciOiIifQ==",
          },
          {
            title: "Petunjuk Teknis KP",
            url: "https://if.uinsgd.ac.id/stream/pdf/eyJpdiI6IkxjYUFGWTFxL2d3a3NrRDZMVWowSEE9PSIsInZhbHVlIjoiWXRYOHk4K3c5aVdWVGJySUJQVHFqbzZOM0djMm1DdTJlVnpxM201VFpXTDhFSGpieHFQLytYNWhFUEh0QXlma1BOOXk5M3I5UmY5U1VRQlNVR1VmQVE9PSIsIm1hYyI6ImQ5NDRiMGJiYTQzOWZjYzVmZmU3YjgxOWMxMzJiNWU2ODNkMWUzNDNiZTUyNjkzY2ZjMWYyYzkzMTU4ODExOWUiLCJ0YWciOiIifQ==",
          },
          {
            title: "Petunjuk Teknis MBKM",
            url: "https://if.uinsgd.ac.id/stream/pdf/eyJpdiI6ImFSSWlkaTdMUzBtTmJsR2VQelRWUXc9PSIsInZhbHVlIjoiVzJTRFdXTlorM1E3R2xRalR2eHQ0SUZKQlZZdWJXVlpHc29kVm9ndW1qOUVnb1MzRElodkU1NS9EY2ZIV1BPOWFrNTQ1MFlFUWFiUlVNSEJVOUhWYWc9PSIsIm1hYyI6ImY2NmU1ZmRjZjNlNGFiMmNiZDdmOGY4Nzk0YjhkNGY2NWFmYzIxYWIyMDNhODRlZGY0ODc2OTE4YWQ1ZDcxMzYiLCJ0YWciOiIifQ==",
          },
          {
            title: "Petunjuk Teknis Sempro",
            url: "https://if.uinsgd.ac.id/stream/pdf/eyJpdiI6IkpFRlBnV0h1d05FWGVldWxZdC9kZEE9PSIsInZhbHVlIjoiS0Nwa1JJTGhqM0hFMFg1MEkxNm15ZkxZeU1ONmtkMiszK1kwdzlLNEYzMW4wUzdKK1FGckNMUDhvRUVqWnlvYSswclRVelpDeDlLa21Vbmh3eE4zTEE9PSIsIm1hYyI6ImE4Y2I3YzRlZTg0NTU4ZmI2NTI5YzY2ZjlmOTQwNTg4NGMyNWJhMDU3Y2MzNWRmZTg1MzQ0MDdkODlhODQxYmIiLCJ0YWciOiIifQ==",
          },
          {
            title: "Petunjuk Teknis TA (Artikel Ilmiah)",
            url: "https://if.uinsgd.ac.id/stream/pdf/eyJpdiI6ImlSOCtUalErYWZtbHIrck1QYStIMUE9PSIsInZhbHVlIjoiUXI5dVM4MU10bW90eDNJQkFEVHM2S0l0NWdEeEZDUFFzQlpGSkh6c2p2eU1uOUpwSHZXcTZ6OStWRGxpdGNnVE1KUmF2UFBQbjlCMTh5NTlBMnhjYkE9PSIsIm1hYyI6ImZkOWIxMTYwZjYxN2QwNjRjOTBjNDQ3ZTI3NzcxMmQyZGJjMTQ2MjBmZjI1NDc5ODg4OWMwZDZiMGJkYmY5YmIiLCJ0YWciOiIifQ==",
          },
          {
            title: "Petunjuk Teknis TA (Skripsi)",
            url: "https://if.uinsgd.ac.id/stream/pdf/eyJpdiI6IllVa2FZOWVXckZqbi8wUXdRWUxXUGc9PSIsInZhbHVlIjoiRTBIcVFUY3hHYmx6VUVhWnR6bWtMUlFXV25FSDhteHYvT1lGUFFXMmVFeVhPS1ZNT2JNc2pyTlRpRmI5TUtFODFJZnpGYkROd3NNREZEMzFObE4zTmc9PSIsIm1hYyI6IjFmMmZjMTFhOTU1NTRmMmEwNWU5MjllMmViNmQ5ZWUyZjAyYjdiNjhlMzUzZmU5YTJhMjEyZGJhMTQ4ODUyN2UiLCJ0YWciOiIifQ==",
          },
        ].map((item, index) => (
          <ListItem
            key={`item-${index}`}
            onClick={() => {
              window.location.href = item.url
            }} 
          >
            <FaBook /> {item.title} 
          </ListItem>
        ))} 
      </List>
    </Page>
  )
}

export default InfoAcademic
