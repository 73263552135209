import { gql } from "@apollo/client";

export interface IClassroomGrade {
      academic_v_clo_by_classroom_id: {
        course_year_sem_clo_id: string
        code: string
        description: string
        weight: number
      }[]
      academic_v_clo_grading_comp_by_classroom_id: {
        course_year_sem_clo_id: string
        grading_comp_id: number
        weight: number 
        abbr: string
        title: string
      }[]
      academic_v_learning_questions_by_classroom_id: {
        course_year_sem_clo_id: string
        grading_comp_id: number
        item_order: number
        learning_id: string
        learning_question_id: string
        question_type_id: number
        title: string
      }[]
      academic_v_students_by_classroom_id: {
        full_name: string
        nim: string
        student_enrollment_id: string
        student_id: string
        is_active: number 
      }[]
      academic_v_learning_participation_answer_by_student_enrollment_id: {
        score: number
        student_enrollment_id: string
        learning_question_id: string
        learning_id: string
        datetime_submit: string | null
        datetime_graded: string | null
      }[]
      academic_v_assessments_cys_clo_by_classroom_id: {
        course_year_sem_clo_id: string
        grading_comp_id: number 
        learning_id: string
        title: string
      }[]
      academic_classroom_item: {
        classroom_item_id: string
        classroom_item_type_id: number
        title: string
        item_order: number
      }[]
  }

export const GET_CLASSROOM_GRADE = gql`
  query GetClassroomGradeUsingView($classroom_id: uuid = "") {
    academic_v_clo_by_classroom_id(where: {classroom_id: {_eq: $classroom_id}}, order_by: {code: asc}) {
      course_year_sem_clo_id
      code
      description
      weight
    }
    academic_v_clo_grading_comp_by_classroom_id(where: {classroom_id: {_eq: $classroom_id}}, order_by: [{code: asc}, {grading_comp_id: asc}]) {
      course_year_sem_clo_id
      grading_comp_id
      weight
      abbr
      title
    }
    academic_v_learning_questions_by_classroom_id(where: {classroom_id: {_eq: $classroom_id}}, order_by: [{item_order: asc}]) {
      course_year_sem_clo_id
      grading_comp_id
      item_order
      learning_id
      learning_question_id
      question_type_id
      title
    }
    academic_v_students_by_classroom_id(where: {classroom_id: {_eq: $classroom_id}, is_active: {_neq: "0"}}, order_by: {full_name: asc}) {
      full_name
      nim
      student_enrollment_id
      student_id
      is_active
    }
    academic_v_learning_participation_answer_by_student_enrollment_id(where: {classroom_id: {_eq: $classroom_id}}) {
      score
      student_enrollment_id
      learning_question_id
      learning_id
      datetime_submit
      datetime_graded
    }
    academic_v_assessments_cys_clo_by_classroom_id(where: {classroom_id: {_eq: $classroom_id}}, order_by: {course_year_sem_clo_id: asc, grading_comp_id: asc}) {
      course_year_sem_clo_id
      grading_comp_id
      learning_id
      title
    }
    academic_classroom_item(where: {_and: [{classroom_id: {_eq: $classroom_id}}, {classroom_item_type_id: {_eq: 1}}]}, order_by: {item_order: asc}) {
      classroom_item_id
      classroom_item_type_id
      title
      item_order
    }
  }
`
