/** @jsxImportSource @emotion/react */

import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "../../../shared/components/IFSuites/Button"
import Flex from "../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import useAuth from "../../../shared/hooks/use-auth"

const PortalSplash = () => {

  const navigate = useNavigate()
  const auth = useAuth()

  useEffect(() => {
    if(auth.isLoggedIn()){
      navigate("/player/dashboard")
    } 
  }, [])

  return (
    <Flex
      className="PortalSplash"
      p={2}
      css={t => ({
        gap: t.spacing(2),
        height: window.innerHeight,
        '.PortalSplash_littleCircle': {
          background: t.palette?.gray?.[600],
          width: 8,
          height: 8,
          borderRadius: 4,
        },
        '.Flex': {
          textAlign: "center",
        },
      })}
    >
      <Flex ai="center">
        Selamat datang
      </Flex>      
      <Flex ai="center">
        Aplikasi Layanan Digital Informatika merupakan sistem satu pintu digitalisasi seluruh layanan akademik di Jurusan Teknik Informatika 
      </Flex>
      {/* <FlexRow */}
      {/*   jc="center" */}
      {/* > */}
      {/*   {[0, 0, 0].map((item, key) => ( */}
      {/*     <Flex */}
      {/*       key={`circle-${key}`} */}
      {/*       className="PortalSplash_littleCircle" */}
      {/*     /> */}
      {/*   ))} */}
      {/* </FlexRow> */}
      <FlexRow jc="center" pt={2}>
        <Button
          variant="contained"
          onClick={() => {
            navigate("/login")
          }}
        >
          Yuk, masuk !
        </Button>
      </FlexRow>
    </Flex>
  )
}

export default PortalSplash
