/** @jsxImportSource @emotion/react */

import '../index.css' 
import { useEffect, useState } from "react"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { SharedHistoryContext, useSharedHistoryContext } from "../context/SharedHistoryContext"
import Placeholder from "../ui/Placeholder"
import ToolbarPlugin from "../plugins/ToolbarPlugin"
import TableCellActionMenuPlugin from '../plugins/TableActionMenuPlugin'
import PlaygroundEditorTheme from "../themes/PlaygroundEditorTheme"
import PlaygroundNodes from "../nodes/PlaygroundNodes"
import DragDropPaste from "../plugins/DragDropPastePlugin"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin"
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin"
import ComponentPickerPlugin from "../plugins/ComponentPickerPlugin"
import EmojiPickerPlugin from "../plugins/EmojiPickerPlugin"
import AutoEmbedPlugin from "../plugins/AutoEmbedPlugin"
import EmojisPlugin from "../plugins/EmojisPlugin"
import { HashtagPlugin } from "@lexical/react/LexicalHashtagPlugin"
import KeywordsPlugin from "../plugins/KeywordsPlugin"
import SpeechToTextPlugin from "../plugins/SpeechToTextPlugin"
import AutoLinkPlugin from "../plugins/AutoLinkPlugin"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import MarkdownShortcutPlugin from "../plugins/MarkdownShortcutPlugin"
import CodeHighlightPlugin from "../plugins/CodeHighlightPlugin"
import { ListPlugin } from "@lexical/react/LexicalListPlugin"
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin"
import ListMaxIndentLevelPlugin from "../plugins/ListMaxIndentLevelPlugin"
import TableCellResizer from "../plugins/TableCellResizer"
import {TablePlugin as NewTablePlugin} from '../plugins/TablePlugin'
import { TablePlugin } from "@lexical/react/LexicalTablePlugin"
import ImagesPlugin from "../plugins/ImagesPlugin"
import LinkPlugin from "../plugins/LinkPlugin"
import ClickableLinkPlugin from "../plugins/ClickableLinkPlugin"
import FloatingTextFormatToolbarPlugin from "../plugins/FloatingTextFormatToolbarPlugin"
import TwitterPlugin from "../plugins/TwitterPlugin"
import YouTubePlugin from "../plugins/YouTubePlugin"
import FigmaPlugin from "../plugins/FigmaPlugin"
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin"
import EquationsPlugin from "../plugins/EquationsPlugin"
import ExcalidrawPlugin from "../plugins/ExcalidrawPlugin"
import TabFocusPlugin from "../plugins/TabFocusPlugin"
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin"
import CollapsiblePlugin from "../plugins/CollapsiblePlugin"
import TableCellNodes from "../nodes/TableCellNodes"
import ContentEditable from "../ui/ContentEditable"
import DraggableBlockPlugin from "../plugins/DraggableBlockPlugin"
import CodeActionMenuPlugin from "../plugins/CodeActionMenuPlugin"
import FloatingLinkEditorPlugin from "../plugins/FloatingLinkEditorPlugin"

export const sampleEditorState = (JSON.stringify(JSON.parse(`{"root": {"type": "root", "format": "", "indent": 0, "version": 1, "children": [{"type": "paragraph", "format": "", "indent": 0, "version": 1, "children": [{"mode": "normal", "text": "", "type": "text", "style": "", "detail": 0, "format": 0, "version": 1}], "direction": "ltr"}], "direction": "ltr"}}`)))

const NormalEditorInnerContainer = (props: {
  isEditable: boolean
  initEditorStateString: string 
  trackInitEditorStateChange: boolean 
  onEditorStateChange: (value: object) => void
}) => {
  const [editor] = useLexicalComposerContext()
  const {historyState} = useSharedHistoryContext()
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null)

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem)
    }
  }

  const cellEditorConfig = {
    namespace: 'Playground',
    nodes: [...TableCellNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  }

  const placeholder = <Placeholder>Tulis di sini...</Placeholder>

  useEffect(() => {
    if(props.trackInitEditorStateChange) {
      const newEditorState = editor.parseEditorState(props.initEditorStateString)
      editor.setEditorState(newEditorState)
    } 
  }, [props.initEditorStateString, props.trackInitEditorStateChange])

  useEffect(() => {
      editor.setEditable(props.isEditable)
  }, [props.isEditable])
  return (
    <div className="editor-shell">
      <>
        {editor.isEditable() && <ToolbarPlugin />}
        <div className="editor-container">
          {editor.isEditable() &&
            <>
              <DragDropPaste />
              <OnChangePlugin onChange={editorState => {
                props.onEditorStateChange(editorState.toJSON())
              }} />
              <AutoFocusPlugin />
              <ClearEditorPlugin />
              <ComponentPickerPlugin />
              <EmojiPickerPlugin />
              <AutoEmbedPlugin />
              <EmojisPlugin />
              <HashtagPlugin />
              <KeywordsPlugin />
              <SpeechToTextPlugin />
              <AutoLinkPlugin />
            </>
          }
          <>
            <div
              css={{
                '.ContentEditable__root': {
                  paddingBottom: props.isEditable === false ? 0 : 40, 
                },
                '.PlaygroundEditorTheme__link': {
                  pointerEvents: props.isEditable === false ? "auto" : "none",
                },
              }}
            >
              <RichTextPlugin
                contentEditable={
                  <div className="editor-scroller">
                    <div className="editor" ref={onRef}>
                      <ContentEditable />
                    </div>
                  </div>
                }
                placeholder={placeholder}
                ErrorBoundary={LexicalErrorBoundary}
              />
            </div>
            <HistoryPlugin externalHistoryState={historyState} />
            <MarkdownShortcutPlugin />
            <CodeHighlightPlugin />
            <ListPlugin />
            <CheckListPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <TablePlugin />
            <TableCellResizer />
            <NewTablePlugin cellEditorConfig={cellEditorConfig}>
              <AutoFocusPlugin />
              <RichTextPlugin
                contentEditable={
                  <ContentEditable className="TableNode__contentEditable" />
                }
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <HistoryPlugin />
              <ImagesPlugin captionsEnabled={false} />
              <LinkPlugin />
              <ClickableLinkPlugin />
              <FloatingTextFormatToolbarPlugin />
            </NewTablePlugin>
            <ImagesPlugin />
            <LinkPlugin />
            <TwitterPlugin />
            <YouTubePlugin />
            <FigmaPlugin />
            <ClickableLinkPlugin />
            <HorizontalRulePlugin />
            <EquationsPlugin />
            <ExcalidrawPlugin />
            <TabFocusPlugin />
            <TabIndentationPlugin />
            <CollapsiblePlugin />
            {floatingAnchorElem /*&& !isSmallWidthViewport*/ && (
              <>
                <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
                <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
                <TableCellActionMenuPlugin
                  anchorElem={floatingAnchorElem}
                  cellMerge={true}
                />
                <FloatingTextFormatToolbarPlugin
                  anchorElem={floatingAnchorElem}
                />
              </>
            )}
          </>
        </div>
      </>
    </div>
  )
}

const NormalEditor = (props: {
  isEditable: boolean
  initEditorStateString: string 
  onEditorStateChange: (value: object) => void
  trackInitEditorStateChange: boolean 
}) => {

  const initialConfig = {
    editorState: props.initEditorStateString,
    namespace: 'IFDS Editor',
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        <NormalEditorInnerContainer
          isEditable={props.isEditable}
          initEditorStateString={props.initEditorStateString}
          trackInitEditorStateChange={props.trackInitEditorStateChange}
          onEditorStateChange={props.onEditorStateChange}
        />
      </SharedHistoryContext>
    </LexicalComposer>
  )
}

export default NormalEditor
