/** @jsxImportSource @emotion/react */

import { FaFilePdf } from "react-icons/fa"
import EditorApp from "../../../shared/components/Editor/MGLexical/App"
import { Button } from "../../../shared/components/IFSuites/Button"
import Flex from "../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import Paper from "../../../shared/components/IFSuites/Paper"
import Small from "../../../shared/components/IFSuites/typography/Small"
import List from "../../../shared/components/List"
import ListItem from "../../../shared/components/List/Item"
import { dayName } from "../../../shared/utils/datetime"
import { ClassroomDetailQ } from "../Home/models"

const ClassroomDetail = ({data}: {data?: ClassroomDetailQ}) => {
  const cloList = data?._.cys.clos
  const cysDetails = data?._.cys.details
  return (
    <Flex gap={2}>
      <Paper br={0}>
        <Flex
          gap={0}
        >
          <List
            title={
              <FlexRow>
                Deskripsi Mata Kuliah
              </FlexRow>
            }
            css={t => ({
              gap: 0,
            })}
          >
            <ListItem>
              <Flex>
                <Flex gap={0}>
                  <Small><b>Mata Kuliah</b></Small> 
                  <Small>{data?._.cys.course.code} - {data?._.cys.course.title} - Kelas {data?._.code}</Small> 
                </Flex>
                <Flex gap={0}>
                  <Small><b>Dosen pengampu</b></Small>
                  {data?._.facilitator_enrollments.map((item, key) => (
                    <Small key={`facilitator-list-item-${key}`}>{item.lecturer.staff.full_name}</Small>
                  ))}
                </Flex>
                <Flex gap={0}>
                  <Small><b>Jadwal</b></Small>
                  {data?._.schedules.map((item, key) => (
                    <Small key={`schedules-item-${key}`}>{dayName(item.day_number)}, {item.time_start} - {item.time_end} {item.place.title}</Small>
                  ))}
                </Flex>
                <Flex>
                  <Button size="small" variant="contained" color="secondary">
                    Cetak RPS Format Fakultas
                  </Button>
                </Flex>
              </Flex>
            </ListItem>
          </List>
        </Flex>
      </Paper>

      <Paper br={0}>
        <Flex
          gap={0}
        >
          <List collapsible title={
            <FlexRow>
              Capaian
              ({cloList?.length})
            </FlexRow>
          }>
            {cloList?.map((item, key) => (
              <ListItem key={`clo-list-item-${key}`}>
                <Flex>
                  <Small><b>{item.clo.code} - {item.clo.plo.code}</b></Small> 
                  <Small>{item.clo.description}</Small> 
                </Flex>
              </ListItem>
            ))}
          </List>
        </Flex>
      </Paper>

      <Paper br={0}>
        <Flex
          gap={0}
        >
          <List collapsible title={
            <FlexRow>
              Informasi
            </FlexRow>
          }>
            <ListItem>
              <Flex>
                <Flex>
                  <Small><b>Referensi</b></Small> 
                  <Small>{cysDetails?.references?.split("-").join(', ')}</Small>
                </Flex>
                <Flex>
                  <Small><b>Media</b></Small> 
                  <Small>{cysDetails?.learning_media?.split("-").join(', ')}</Small>
                </Flex>
                <Flex>
                  <Small><b>Kelulusan</b></Small> 
                  <Small>{cysDetails?.pass_condition?.split("-").join(', ')}</Small>
                </Flex>
              </Flex> 
            </ListItem>            
          </List>
        </Flex>
      </Paper>

      <Paper br={0}>
        <Flex
          gap={0}
        >
          <List collapsible title={
            <FlexRow>
              Pertemuan
            </FlexRow>
          }>
            {cysDetails?.meeting_plan_list?.map((item, key) => (
              <ListItem key={`meeting-plan-list-item-${key}`}>
                <Flex>
                  Pertemuan {item.meeting_number}
                  <Small><b>Indikator</b></Small>
                  <Small>{item.indicator?.split("-").join(', ')}</Small>
                  <Small><b>Deskripsi</b></Small>
                  <Small>{item.description?.split("-").join(', ')}</Small>
                </Flex>
              </ListItem>            
            ))}
          </List>
        </Flex>
      </Paper>
    </Flex>
  )
} 

export default ClassroomDetail
