/** @jsxImportSource @emotion/react */

import { Dayjs } from "dayjs"
import { FaBook, FaRegGem, FaRegQuestionCircle, FaTasks,  FaUserCheck } from "react-icons/fa"
import { GiBowman, GiSecretBook, GiSpellBook } from "react-icons/gi"
import { IoGameController } from "react-icons/io5"
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdQuiz } from "react-icons/md"
import { useNavigate, useParams } from "react-router-dom"
import Flex from "../../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../../shared/components/IFSuites/Flex/Row"
import H4 from "../../../../../shared/components/IFSuites/typography/H4"
import Small from "../../../../../shared/components/IFSuites/typography/Small"
import ListItem from "../../../../../shared/components/List/Item"

interface ClassroomSessionListItemProps {
  title: string
  totalAssessment: number
  totalMaterial: number
  isAssessmentCreated: boolean
  isPresenceCreated: boolean
  isToday: boolean
  classroomItemID: string
  classroomID: string
  date: Dayjs
}

const ClassroomMeetingListItem = ({
  title,
  isAssessmentCreated,
  isPresenceCreated,
  isToday,
  classroomItemID,
  classroomID,
  totalMaterial,
  totalAssessment,
  date,
}: ClassroomSessionListItemProps) => {

  const navigate = useNavigate()
  return (
    <ListItem
      glow={isToday}
      onClick={(_) => navigate(`/classroom/meeting/${classroomItemID}`)}
    >
      <FlexRow
      >
        <Flex css={t => ({
          flexGrow:1,
        })}>
          <H4>{title}</H4>
          <Small>{date.format('dddd, D MMM YYYY')}</Small> 
        </Flex>
        <FlexRow gap={2} css={t => ({
          width: "fit-content",
          'svg': {
            color: t.palette?.blueGray?.[700],
          }
        })}>
          <FlexRow>
            <FaRegGem size={24} />
            <span title="Total materi">{totalMaterial}</span>
          </FlexRow>
          <FlexRow>
            <GiBowman size={24} />
            <span title="Total asesmen">{totalAssessment}</span>
          </FlexRow>
        </FlexRow>
      </FlexRow>
    </ListItem>
  )
}

export default ClassroomMeetingListItem
