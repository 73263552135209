/** @jsxImportSource @emotion/react */

import Page from "../../../../shared/components/Page"

// List of tickets generated by the user, or by all of the users
const HelpdeskTicketDetail = () => {
  return (
    <Page
      title={`Tiket Bantuan`}
    >

    </Page>
  )
}

export default HelpdeskTicketDetail
