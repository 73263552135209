/** @jsxImportSource @emotion/react */

import { BsTerminalFill } from "react-icons/bs"
import { FaBook, FaChartBar, FaGraduationCap, FaIndustry, FaMicroscope, FaNewspaper } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import Flex from "../../shared/components/IFSuites/Flex"
import FlexRow from "../../shared/components/IFSuites/Flex/Row"
import Small from "../../shared/components/IFSuites/typography/Small"
import List from "../../shared/components/List"
import ListItem from "../../shared/components/List/Item"
import Page from "../../shared/components/Page"

import { motion } from "framer-motion"
import H4 from "../../shared/components/IFSuites/typography/H4"

const Career = () => {

  const navigate = useNavigate()

  return (
    <Page
      title={`Karir Profesi & Akademik`}
    >
      <List
        title={`Coming soon`}
        css={t => ({
          color: t.palette?.blueGray?.[700],
        })}
      >
        <ListItem
          onClick={() => {
            navigate('/career/skill')
          }} 
        >
          <FlexRow gap={2}>
            <motion.div
              animate={{
                // rotate: [0, 0, 360, 360, 0],
              }}
              transition={{
                // repeat: Infinity,
                // repeatDelay: 2,
              }}
            >
              <BsTerminalFill size={24}/*  color={`#ca4a47`} */ /> 
            </motion.div>
            <Flex gap={0}>
              <H4>Skill Dunia Kerja & Usaha</H4> 
              <Small>Developer ? Scientist ? Analyst ? CEO ?, Direktori pembelajaran aneka skill industri</Small>
            </Flex>
          </FlexRow>
        </ListItem>
        <ListItem
          onClick={() => {
            navigate('/career/research')
          }} 
        >
          <FlexRow gap={2}>
            <motion.div
              animate={{
                // rotate: [0, 0, 360, 360, 0],
              }}
              transition={{
                // repeat: Infinity,
                // repeatDelay: 2,
              }}
            >
              <FaMicroscope size={24}/*  color={`#fe8508`} */ />
            </motion.div>
            <Flex gap={0}>
              <H4>Penelitian & Publikasi Ilmiah</H4> 
              <Small>Tips penelitian dan penulisan artikel, direktori jurnal & event konferensi terbaru</Small>
            </Flex>
          </FlexRow>
        </ListItem>
        <ListItem
          onClick={() => {
            navigate('/career/scholarship')
          }} 
        >
          <FlexRow gap={2}>
            <motion.div
              animate={{
                // rotate: [0, 0, 360, 360, 0],
              }}
              transition={{
                // repeat: Infinity,
                // repeatDelay: 2,
              }}
            >
              <FaGraduationCap size={24} /* color={`#00477d`} */ />
            </motion.div>
            <Flex gap={0}>
              <H4>Beasiswa</H4> 
              <Small>Luar / dalam negeri ? S1 / S2 / S3 / Non gelar ? Siapkan dari sekarang</Small>
            </Flex>
          </FlexRow>
        </ListItem>
        <ListItem
          onClick={() => {
            navigate('/career/vacancy')
          }} 
        >
          <FlexRow gap={2}>
            <motion.div
              animate={{
                // rotate: [0, 0, 360, 360, 0],
              }}
              transition={{
                // repeat: Infinity,
                // repeatDelay: 2,
              }}
            >
              <FaIndustry size={24} /* color={`#08ae1b`} */ />
            </motion.div>
            <Flex gap={0}>
              <H4>MBKM, Magang, dan Kerja</H4> 
              <Small>Aneka peluang MBKM, magang dan info loker</Small>
            </Flex>
          </FlexRow>
        </ListItem>
      </List>
    </Page>
  )
}

export default Career 
