import dayjs from "dayjs"

const appConfig = {
  version: "0.2.1", 
  defaultYear: 2024,
  defaultSemester: 1,
  firstDateOfSemester: dayjs("2024-09-02"), 
}

export const APP_VERSION = `v0.1.202409162315`
export const API_URL = `https://dev-api.informatika.digital/academic/v1`
export const GRAPHQL_URL = `https://dev-api.informatika.digital/academic/graphql`

export default appConfig
