/** @jsxImportSource @emotion/react */

import { FaBell, FaComments, FaHome, FaLifeRing, FaMicrophoneAlt, FaPhoneAlt, FaQrcode, FaTicketAlt, FaUserCircle } from "react-icons/fa"
import { IoQrCode } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import Flex from "../IFSuites/Flex"
import FlexRow from "../IFSuites/Flex/Row"
import Small from "../IFSuites/typography/Small"

const BottomNavigation = () => {

  const navigate = useNavigate()

  return (
    <Flex
      className="BottomNavigation"
      css={t => ({
        position: `fixed`,
        left: 0,
        bottom: 0,
        height: t.spacing(7),
        background: `#ffffff`,
        borderTop: `1px solid ${t.palette?.gray?.[200]}`,
        padding: 0,
        zIndex: 4,
      })}
    >
      <FlexRow
        gap={0}
        css={t => ({
          height: `100%`,
          '& > .Flex': {
            color: t.palette?.blueGray?.[300],
            position: `relative`,
            transition: ".4s",
            gap: t.spacing(0.5),
            paddingBottom: 4,
            ':hover': {
              cursor: "pointer",
              color: t.palette?.primary?.main,
            },
            'svg, .Small': {
              zIndex: 1,
            },
            'svg': {
              marginTop: t.spacing(1),
            },
            '.Small': {
              marginTop: t.spacing(0),
              fontSize: 14,
            },
          },
          '.BottomNavigation_menuItemQRScan': {
            paddingBottom: 0,
            '.Flex': {
              // boxShadow: `${t.palette?.primary?.light} 0px 4px 8px 0px, ${t.palette?.primary?.light} 0px 0px 0px 0px`,
              color: t.palette?.blueGray?.[900],
              left: '50%',
              marginLeft: -t.spacing(4),
              top: -t.spacing(5),
              gap: 0,
              paddingBottom: 4,
              position: "absolute",
              // background: t.palette?.primary?.main,
              background: t.palette?.yellow?.[400],
              borderRadius: t.spacing(5),
              width: t.spacing(8),
              height: t.spacing(8),
              transition: ".4s",
              'svg': {
                color: t.palette?.blueGray?.[600],
              },
              '.Small small': {
                color: t.palette?.blueGray?.[900],
              },
              ':hover': {
                background: t.palette?.primary?.main,
                color: t.palette?.blueGray?.[900],
              },
            }
          },
        })}
      >
        <Flex
          ai="center"
          onClick={() => navigate("/player/dashboard")}
        >
          <FaHome size={20} /> 
          <Small>Home</Small>
        </Flex>
        <Flex
          ai="center"
        >
          <FaBell size={20} /> 
          <Small>Notif</Small>
        </Flex>
        <Flex
          jc="center"
          className="Flex BottomNavigation_menuItemQRScan"
          onClick={() => {
            navigate("/scan")
          }}
        >
          <Flex ai="center">
            <IoQrCode size={24} /> 
            <Small>Scan</Small>
          </Flex>
        </Flex>
        <Flex
          ai="center"
          onClick={() => navigate("/helpdesk/ticket")}
        >
          <FaPhoneAlt size={20} color={`#E53935`} /> 
          <Small>Bantuan</Small>
        </Flex>
        <Flex
          ai="center"
          onClick={() => {
            navigate("/player/menu")
          }}
        >
          <FaUserCircle size={20} /> 
          <Small>Profil</Small>
        </Flex>
      </FlexRow> 
    </Flex>
  )
}

export default BottomNavigation
