/** @jsxImportSource @emotion/react */

import Page from "../../../shared/components/Page"

const DefenseDetail = () => {

  return (
    <Page
      title={`Detail Sidang`}
    >
    </Page>
  )
} 

export default DefenseDetail
