import { atom } from "jotai";
import { LearningQuestionWS } from "../../models/learning";

export const initQuestionCreate: LearningQuestionWS = {
  question_type_id: 0,
  description: JSON.parse(
    `{"root":{"children":[{"children":[],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`
  ),
  attachments: [],
  score_weight: 0,
  answer_choices: [],
  true_values: [],
  course_year_sem_clo_id: "" ,
  clo_code: ""
}

interface Configs {
  pageTitle: string
  selectedCourseYearSemCLO: {
    clo_code: string
    course_year_sem_clo_id: string 
    id: string
  }
  questionCreate: LearningQuestionWS 
}

export const configsAtom = atom<Configs>({
  pageTitle: "Tambah Asesmen", 
  selectedCourseYearSemCLO: {
    clo_code: "",
    id: "",
    course_year_sem_clo_id: "",
  },
  questionCreate: initQuestionCreate,
})
