/** @jsxImportSource @emotion/react */

import { gql, useLazyQuery, useQuery } from "@apollo/client"
import { useState } from "react"
import IconLoading from "../../../shared/components/IconLoading"
import Flex from "../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import Small from "../../../shared/components/IFSuites/typography/Small"
import List from "../../../shared/components/List"
import ListItem from "../../../shared/components/List/Item"
import Page from "../../../shared/components/Page"
import useAuth from "../../../shared/hooks/use-auth"
import useBreakpoint from "../../../shared/hooks/use-breakpoint"
import { dayName, isToday } from "../../../shared/utils/datetime"
import appConfig from "../../../shared/variables/config"
import useClassroomList from "../hooks/use-classroom-list"
import { GET_CLASSROOMS } from "./get-classrooms"
import ClassroomListItem from "./Item"

interface DataOptions {
  year: number
  semester: number
  showAll: boolean
  keyword: string
} 

const ClassroomList = () => {

  const auth = useAuth()

  const [dataOptions, setDataOptions] = useState<DataOptions>({
    year: appConfig.defaultYear,
    semester: appConfig.defaultSemester,
    showAll: false,
    keyword: "",
  })
  const {classroomList} = useClassroomList({...dataOptions})

  const bp = useBreakpoint()

  const filterByKeywords = (title: string, lecturerName: string, keyword: string) => {
    const titleLower = title.toLowerCase()
    const lecturerNameLower = lecturerName.toLowerCase()
    const keywordLower = keyword.toLowerCase()

    if(dataOptions.keyword.length > 2) {
      if(titleLower.includes(keywordLower) || lecturerNameLower.includes(keywordLower)) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }


  return (
    <Page
      title="Kuliah"
      menus={[
        
      ]}
    >
      <List
        title={
          <Flex>
            Pilih Kelas Mata Kuliah
            <FlexRow ai="stretch">
              <Flex w="auto">
                <Small>Tahun ajar</Small>
                <select
                  value={dataOptions.year}
                  onChange={e => {
                    setDataOptions(v => ({...v, year: parseInt(e.target.value)}))
                  }}
                >
                  <option
                    value={2024}
                  >
                    2024 / 2025
                  </option>
                  <option
                    value={2023}
                  >
                    2023 / 2024
                  </option>
                  <option
                    value={2022}
                  >
                    2022 / 2023
                  </option>
                  <option
                    value={2021}
                  >
                    2021 / 2022
                  </option>
                </select>
              </Flex>
              <Flex w="auto">
                <Small>{bp === "tablet" ? "Sem." : "Semester"}</Small>
                <select
                  value={dataOptions.semester}
                  onChange={e => {
                    setDataOptions(v => ({...v, semester: parseInt(e.target.value)}))
                  }}
                >
                  <option
                    value={1}
                  >
                    1 
                  </option>
                  <option
                    value={2}
                  >
                    2
                  </option>
                </select>
              </Flex>
              <Flex w="auto" jc="flex-start">
                <FlexRow>
                  <input
                    type={"checkbox"}
                    checked={dataOptions.showAll}
                    onChange={e => {
                      console.log("check", e.target.checked)
                      setDataOptions(v => ({...v, showAll: e.target.checked}))
                    }}
                  />
                  <Small>Lihat Semua</Small> 
                </FlexRow>
                <FlexRow>
                  <input
                    value={dataOptions.keyword}
                    placeholder="Cari ..."

                    onChange={e => {
                      setDataOptions(v => ({
                        ...v,
                        keyword: e.target.value,
                      }))
                    }}
                    css={t => ({
                      width: `100%`,
                    })}
                  />
                </FlexRow>
              </Flex>
            </FlexRow>
          </Flex>
        }
      >
      {/* isToday: v.schedules ? isToday(v.schedules[0].day_number) : false, */}
        {classroomList.map(v => ({
          ...v,
          isToday: v.schedules ? isToday(v.schedules[0].day_number) : false,
        })).filter(v => v.isToday && filterByKeywords(v.title,  v.lecturer_name, dataOptions.keyword))
            .sort((a, b) => {
              const aTS = a.schedules?.[0].time_start
              const bTS = b.schedules?.[0].time_start
              return (aTS && bTS && aTS < bTS) ? -1 : (aTS && bTS && aTS > bTS) ? 1 : 0
            })
            .map((item, key) => !dataOptions.showAll ? (
          <ClassroomListItem
            key={`course-list-item-${key}`}
            title={item.title}
            classCode={item.classroom_code}
            facilitator={item.lecturer_name}
            date={item.schedules ? `${dayName(item.schedules[0].day_number)}, ${item.schedules[0].time_start} - ${item.schedules[0].time_end}` : `-`}
            location={item.schedules ? `${item.schedules[0].place_title}` : '-'}
            classroomID={item.classroom_id}
            isToday={item.schedules ? isToday(item.schedules[0].day_number) : false}
          />
          ) : 
          <ListItem key={`course-list-item-${key}`}>
            <Flex>
              {`${item.title} - ${item.classroom_code}`} 
              <Small>{item.lecturer_name}</Small>
            </Flex>
          </ListItem>
        )}
        {classroomList.map(v => ({
          ...v,
          isToday: v.schedules ? isToday(v.schedules[0].day_number) : false,
        })).filter(v => !v.isToday && filterByKeywords(v.title,  v.lecturer_name, dataOptions.keyword)).map((item, key) => !dataOptions.showAll ? (
          <ClassroomListItem
            key={`course-list-item-${key}`}
            title={item.title}
            classCode={item.classroom_code}
            facilitator={item.lecturer_name}
            date={item.schedules ? `${dayName(item.schedules[0].day_number)}, ${item.schedules[0].time_start} - ${item.schedules[0].time_end}` : `-`}
            location={item.schedules ? `${item.schedules[0].place_title}` : '-'}
            classroomID={item.classroom_id}
            isToday={item.schedules ? isToday(item.schedules[0].day_number) : false}
          />
        ) : (
          <ListItem key={`course-list-item-${key}`}>
            <Flex>
              {`${item.title} - ${item.classroom_code}`} 
              <Small>{item.lecturer_name}</Small>
            </Flex>
          </ListItem>
        ))}
      </List>
    </Page>
  )
}

export default ClassroomList
