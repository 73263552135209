/** @jsxImportSource @emotion/react */

import dayjs from "dayjs"
import id from "dayjs/locale/id"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import Flex from "../../Flex"
import FlexRow from "../../Flex/Row"
import { CommonProps } from "../../interfaces"

dayjs.locale('id')

interface InputTextProps {
  label?: string
  value: string
  onChange: (value: ChangeEvent<HTMLInputElement>) => void,
  fullWidth?: boolean 
  type?: "date" | "email" | "password"
  startIcon?: JSX.Element
  endicon?: JSX.Element
  required?: true
  slim?: true
  readonly?: true 
  size?: number
  placeholder?: string
}

const InputText = (props: CommonProps & InputTextProps) => {

  const {label, value, size, onChange, fullWidth, type, startIcon, slim, placeholder, ...other} = props 
  const [focus, setFocus] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const smallerLabel = (focus || value.length > 0)
  const isSlim = slim === undefined ? false : true 

  return (
    <Flex
      className={`InputText ${props.className}`}
      css={t => ({
        gap: 4,
        width: props.fullWidth ? `100%` : `auto`,
        position: `relative`,
        '.InputText_label': {
          lineHeight: 0,
          fontSize: smallerLabel ? 10 : 14,
          color: smallerLabel ? `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0.7)`,
          // color: `${t.palette?.secondary?.dark}cc`,
          position: `absolute`,
          top: smallerLabel ? t.spacing(1) + 4 : `50%`,
          left: smallerLabel ? t.spacing(1) + 4 : t.spacing(2),
          transition: ".1s",
        },
      })}
      {...other}
    >
      {props.label !== undefined && !isSlim &&
      <span
        className="InputText_label"
        css={t => ({
        })}
      >
        {props.label}
      </span>
      }
      <FlexRow
        css={{
          position: `relative`,
        }}
      >
        {props.startIcon !== undefined &&
          <FlexRow
            css={t => ({
              justifyContent: `start`,
              position: `absolute`,
              left: t.spacing(1),
              color: `${t.palette?.primary?.main}`,
              width: `auto`,
            })}
          >
            {props.startIcon}
          </FlexRow>
        }
        {props.type === "date" &&
          <input 
            onFocus={() => {
              setFocus(true)
            }}
            onBlur={() => {
              setFocus(false)
            }}
            onClick={() => {
              if(inputRef.current) {
                inputRef.current.showPicker()
              }
            }}
            size={size !== undefined ? size : 10}
            type={`text`}
            readOnly
            value={dayjs(props.value).format("DD MMMM YYYY")}
            onChange={e => {props.onChange(e)}}
            css={t => ({
              color: `rgba(0, 0, 0, 0.7)`,
              background: `none`,
              fontWeight: 700,
              // fontSize: `0.825rem`, 
              width: props.fullWidth !== undefined ? props.fullWidth ? `100%` : `auto` : `100%`,
              borderRadius: isSlim ? 4 : t.spacing(0) + 4,
              padding: isSlim ? 4 : t.spacing(1),
              paddingLeft: isSlim ? 4 : props.startIcon !== undefined ? t.spacing(4) : t.spacing(1) + 4,
              paddingTop: isSlim ? 4 : t.spacing(3) - 2,
              paddingBottom: isSlim ? 4 : t.spacing(1) - 2,
              transition: `.4s`,
              border: `1px solid ${t.palette?.gray?.[500]}`,
              outline: `none`,
              ':hover': {
                border: `1px solid ${t.palette?.primary?.main}`,
                // outline: `4px solid ${t.palette?.primary?.main}33`,
              },
              ':focus': {
                border: `1px solid ${t.palette?.primary?.main}`,
                boxShadow: `0 0 0 0.2rem ${t.palette?.primary?.main}33`,
                // outline: `4px solid ${t.palette?.primary?.main}33`,
              },
            })} 
          />
        }
        <input 
          placeholder={placeholder}
          ref={inputRef}
          onFocus={() => {
            setFocus(true)
          }}
          onBlur={() => {
            setFocus(false)
          }}
          readOnly={props.readonly}
          type={props.type !== undefined ? props.type : `text`}
          value={props.type === "date" ? props.value : props.value}
          onChange={e => {props.onChange(e)}}
          size={size !== undefined ? size : 10}
          css={t => ({
            color: `rgba(0, 0, 0, 0.7)`,
            background: `none`,
            fontWeight: value === '' ? 400 : 700,
            // fontSize: `0.825rem`, 
            width: props.fullWidth !== undefined ? props.fullWidth ? `100%` : `auto` : `100%`,
            visibility: props.type === "date" ? "hidden" : "visible",
            position: props.type === "date" ? "absolute" : "inherit",
            borderRadius: isSlim ? 4 : t.spacing(0) + 4,
            padding: isSlim ? 4 : props.type === "date" ? 0 :  t.spacing(1),
            paddingLeft: props.startIcon !== undefined ? t.spacing(4) : isSlim ? 4 :  t.spacing(1) + 4,
            paddingTop: isSlim ? 4 : t.spacing(3) - 2,
            paddingBottom: isSlim ? 4 : t.spacing(1) - 2,
            transition: `.4s`,
            border: `1px solid ${t.palette?.gray?.[500]}`,
            outline: `none`,
            ':hover': {
              border: `1px solid ${t.palette?.secondary?.main}`,
              // outline: `4px solid ${t.palette?.primary?.main}33`,
            },
            ':focus': {
              border: `1px solid ${t.palette?.secondary?.main}`,
              boxShadow: `0 0 0 0.2rem ${t.palette?.secondary?.main}33`,
              // outline: `4px solid ${t.palette?.primary?.main}33`,
            },
          })} 
        />
        {props.endicon !== undefined &&
          <FlexRow
            css={t => ({
              justifyContent: `end`,
              position: `absolute`,
              right: t.spacing(1),
              color: `${t.palette?.primary?.main}`,
              width: `auto`,
            })}
          >
            {props.endicon}
          </FlexRow>
        }
      </FlexRow>
    </Flex>
  )
}

export default InputText

// References
// https://blog.hubspot.com/marketing/form-design
// https://kevinyckim33.medium.com/jsx-spread-operator-component-props-meaning-3c9bcadd2493
