import { gql } from "@apollo/client";

export const INSERT_DEFENSE = gql`
mutation InsertDefense($object: defense_defense_insert_input = {}) {
  insert_defense_defense_one(object: $object, on_conflict: {constraint: defense_pk}) {
    title
  }
}
`

export interface InsertDefenseQ {
  datetime_start: string 
  place_id: string 
  student_id: string 
  defense_category_id: number 
  title: string 
  notification: {
    data: {} 
  }
  evaluators: {
    data: {
      evaluator_player_id: string 
      evaluator_type_id: number        
    }[]
  }
} 


