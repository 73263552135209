/** @jsxImportSource @emotion/react */

import { useEffect, useRef } from "react"
import { useWindowSize } from "react-use"
import Flex from "../../../../shared/components/IFSuites/Flex"
import Page from "../../../../shared/components/Page"

const GameNest = () => {

  const windowSize = useWindowSize()

  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  
  useEffect(() => {
    if(canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d")
      ctx?.strokeRect(200, 400, 400, 250)
    }
  }, [])

  return (
    <Page
      title={`Game Nest`}
    >
      <Flex
        css={t => ({
          width: windowSize.width / 2,
          height: (windowSize.height - 56) / 2,
          background: "yellow",
        })}
      >
        <canvas
          ref={canvasRef}
          width={windowSize.width}
          height={windowSize.height - 56}
          css={t => ({
          })}
        />
      </Flex>
    </Page>
  )
}

export default GameNest
