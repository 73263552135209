/** @jsxImportSource @emotion/react */

import { useState } from "react"
import { FaSearch } from "react-icons/fa"
import { Button } from "../../../shared/components/IFSuites/Button"
import Flex from "../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import InputText from "../../../shared/components/IFSuites/forms/InputText"
import Small from "../../../shared/components/IFSuites/typography/Small"
import List from "../../../shared/components/List"
import ListItem from "../../../shared/components/List/Item"
import Page from "../../../shared/components/Page"
import usePlayerLogAs from "./use-log-as"

const PlayerLogAs = () => {

  const [searchKeywords, setSearchKeywords] = useState("")
  const {players, find, logAs} = usePlayerLogAs()

  return (
    <Page
      title="Login Sebagai"
    >
      <FlexRow
        pt={2}
        pb={0}
        px={2}
      >
        <InputText
          fullWidth
          slim
          placeholder="Cari Player . . ."
          value={searchKeywords}
          onChange={e => {
            setSearchKeywords(e.currentTarget.value)
          }}
        />
        <Button
          onClick={() => {
            find(searchKeywords) 
          }}
        >
          <FaSearch />
        </Button>
      </FlexRow>
      <List
        title="Hasil Pencarian"
      >
        {players.map((player, key) => (
          <ListItem
            key={`player-log-as-search-results-item-${key}`}
            onClick={() => {
              logAs(player.player_id)
            }}
          >
            <Flex>
              {player.full_name}
              <Small>{`${player.player_roles.map(r => r.title).join(', ')} ${player.programme_title}`}</Small>
            </Flex>
          </ListItem>
        ))}
      </List>
    </Page>
  )
}

export default PlayerLogAs
