/** @jsxImportSource @emotion/react */

import { ReactNode, useEffect, useRef, useState } from "react"
import { FaChevronDown, FaChevronUp, } from "react-icons/fa"
import { Button } from "../IFSuites/Button"
import DividerGrow from "../IFSuites/Divider/Grow"
import Flex from "../IFSuites/Flex"
import FlexRow from "../IFSuites/Flex/Row"
import { CommonProps } from "../IFSuites/interfaces"

interface ListProps {
  title?: ReactNode
  collapsible?: true 
}

const List = ({children, title, collapsible, ...other}: ListProps & CommonProps) => {

  const [collapse, setCollapse] = useState(true)

  const [childrenHeight, setChildrenHeight] = useState<number | null>(null)
  const childrenRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if(childrenRef.current) {
      // console.log("height", childrenRef.current.clientHeight)
      setChildrenHeight(childrenRef.current.clientHeight)
    }
  }, [childrenRef, children, setChildrenHeight])

  return (
    <Flex
      className="List"
      gap={0}
      {...other}
    >
      {title &&
        <FlexRow
          p={1}
          px={2}
          css={t => ({
            color: t.palette?.gray?.[600],
          })}
        >
          {title}
          {collapsible && <DividerGrow />}
          {collapsible &&
            <Button
              size="small"
              color="info"
              onClick={() => {setCollapse(!collapse)}}
            >
              {collapse ? <FaChevronUp /> : <FaChevronDown />}
            </Button>
          }
        </FlexRow>
      }
      <div
        className="children-container"
        ref={childrenRef}
        css={t => ({
          height: collapsible ? !collapse ? 0 : childrenHeight && childrenHeight > 0 ? childrenHeight : "auto" : "auto",
          overflow: 'hidden',
          transition: ".2s",
          width: `100%`,
          display: "flex",
          flexDirection: "column",
          // paddingBottom: t.spacing(2),
        })}
      >
        {children}
      </div>
    </Flex>
  )
}

export default List
