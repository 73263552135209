/** @jsxImportSource @emotion/react */

import { useLazyQuery, useMutation } from "@apollo/client"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { FaCalendar, FaCheck, FaEdit, FaMapMarker } from "react-icons/fa"
import { useNavigate, useParams } from "react-router-dom"
import { Button } from "../../../shared/components/IFSuites/Button"
import DateTimePicker from "../../../shared/components/IFSuites/DateTimePicker"
import Flex from "../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../shared/components/IFSuites/Flex/Row"
import InputText from "../../../shared/components/IFSuites/forms/InputText"
import Select from "../../../shared/components/IFSuites/forms/Select"
import H3 from "../../../shared/components/IFSuites/typography/H3"
import Small from "../../../shared/components/IFSuites/typography/Small"
import List from "../../../shared/components/List"
import ListItem from "../../../shared/components/List/Item"
import Page from "../../../shared/components/Page"
import useAuth from "../../../shared/hooks/use-auth"
import useLayout from "../../../shared/hooks/use-layout"
import mq from "../../../shared/utils/media-query"
import PlaceSelector from "../../Place/Selector"
import PlayerSelector from "../../Player/Selector"
import { DefenseCategoryID, defenseCategoryByID } from "../variables"
import { GetDefensesQ, GET_UPCOMING_DEFENSES } from "./get-defenses.gql"
import { InsertDefenseQ, INSERT_DEFENSE } from "./insert-defense.gql"

interface InsertDefenseFormProps {
  defenseCategoryID: DefenseCategoryID 
  onSubmit: () => void
  evaluatorTypes: {
    evaluator_type_id: number
    title: string
  }[]
}

const InsertDefenseForm = ({defenseCategoryID, onSubmit, evaluatorTypes}: InsertDefenseFormProps) => {
  const layout = useLayout()

  const [evaluatorsKV, setEvaluatorsKV] = useState<{[key: number]: {
    evaluator_player_id: string,
    evaluator_type_id: number
  }}>({}) 

  const [insertPayload, setInsertPayload] = useState<InsertDefenseQ>({
    datetime_start: dayjs().format("YYYY-MM-DDTHH:[00]:[00]+00"), 
    place_id: "", 
    student_id: "", 
    defense_category_id: 1, 
    title: "", 
    notification: {
      data: {} 
    },
    evaluators: {
      data: [] 
    }
  })

  // console.log("init insertPayload", insertPayload)

  useEffect(() => {
    setInsertPayload(v => ({...v, defense_category_id: defenseCategoryID}))
  
  }, [defenseCategoryID])

  const [insert,] = useMutation(
    INSERT_DEFENSE, 
  )

  return (
    <Flex>
      <Flex
        css={t => ({
          height: t.spacing(6),
        })}
      >
        <H3>Jadwalkan Sidang {defenseCategoryByID(defenseCategoryID).label}</H3>
      </Flex>
      <Flex>
        <PlayerSelector
          label="Peserta Sidang"
          onChange={selected => {
            const studentID = selected.studentID
            if(typeof studentID === 'string') {
              setInsertPayload(v => ({...v, student_id: studentID}))
            }
          }}
        />
      </Flex>
      <Flex>
        <InputText
          label="Judul"
          value={insertPayload.title}
          fullWidth
          onChange={e => {
            setInsertPayload(v => ({...v, title: e.target.value}))
          }}
        />
      </Flex>
      {evaluatorTypes.map((item, key) => {
          
        return (
          <Flex key={`player-selector-item-${key}`}>
            <PlayerSelector
              target="lecturer"
              label={item.title}
              initialKeyword="teknik"
              onChange={selected => {
                setEvaluatorsKV(v => ({...v, [item.evaluator_type_id]: {
                    evaluator_player_id: selected.playerID,
                    evaluator_type_id: item.evaluator_type_id,
                }}))
              }}
            />
          </Flex>
        )

      })}
      <Flex>
        <DateTimePicker
          label="Waktu pelaksanaan"
          onChange={newDateTime => {
            // console.log(newDateTime)
            setInsertPayload(v => ({...v, datetime_start: newDateTime.format("YYYY-MM-DDTHH:[00]:[00]+07")}))
          }}
        />
      </Flex>
      <Flex>
        <PlaceSelector
          initialTags={["705"]}
          label={`Tempat pelaksanaan`}
          onChange={(selected) => {
            setInsertPayload(v => ({
              ...v,
              place_id: selected.placeID,
            }))
          }}
        />
      </Flex>
      <FlexRow jc="flex-end">
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {

            layout.confirmCancelable({
              content: "Submit data ini ?",
              onClick: () => {
                layout.loadingShow("Mengirim data ke server...")

                const evaluators = Object.keys(evaluatorsKV).map(w => {
                  const evaluator = evaluatorsKV[Number(w)]
                  return {
                    evaluator_type_id: evaluator.evaluator_type_id,
                    evaluator_player_id: evaluator.evaluator_player_id, 
                  }
                })

                insert({
                  variables: {
                    object: {
                      ...insertPayload,
                      evaluators: {
                        data: evaluators
                      },
                    } 
                  }
                }).then(response => {
                  layout.confirm({
                    content: <Flex>Jadwal berhasil diinput</Flex>,
                    onClick: () => {
                      onSubmit()
                    }
                  })

                }).finally(() => {
                  layout.loadingHide()
                })

              } 
            })

          }}
        >
          Submit
        </Button>
      </FlexRow>
    </Flex>
  )
}

const DefenseManage = () => {

  const auth = useAuth()

  // const [selectedDefense, setSelectedDefense] = useState<DefenseID>(1)
  const navigate = useNavigate()

  const { defenseCategoryID: defenseCategoryIDString } = useParams<{defenseCategoryID: string}>()
  const defenseCategoryID: DefenseCategoryID = defenseCategoryIDString === undefined ? 1 : Number(defenseCategoryIDString) as DefenseCategoryID

  const getDefenseCategoryTitleByID = (categoryID: number) => {
    switch(categoryID) {
      case 1:
        return "Munaqasyah"
      case 2:
        return "Kolokium"
      case 3:
        return "Sidang Proposal"
      case 4:
        return "Sidang Komprehensif"
      case 5:
        return "Sidang Kerja Praktik"
      default:
        return "Sidang"
    }
  }

  useEffect(() => {
    if(!auth.isProgrammeAdmin()) {
      navigate("/player/dashboard", {replace: true})
    } 

    get({
      variables: {
        defense_category_id: defenseCategoryID,
      }
    })

  }, [defenseCategoryID])

  const [get, getOps] = useLazyQuery<GetDefensesQ>(
    GET_UPCOMING_DEFENSES,
  )


  return (
    <Page
      title={`Kelola Sidang ${defenseCategoryByID(defenseCategoryID).label}`}
    >
      <FlexRow
        pt={2}
        pb={0}
        px={2}
      >
        <Select
          label="Pilih Sidang"
          value={defenseCategoryID.toString()}
          onChange={value => {
            navigate(`/defense/manage/${value}`, {replace: true})
          }}
          items={([1, 2, 3, 4, 5] as (1 | 2 | 3 | 4 | 5)[]).map(v => ({
            value: v.toString(),
            label: defenseCategoryByID(v).label,
          }))}
          fullWidth
        />
      </FlexRow>  
      <FlexRow
        pt={2}
        pb={0}
        px={2}
        ai="start"
        gap={2}
        css={t => ({
          flexDirection: "column",
          [mq[1]]: {
            flexDirection: "row",
          },
        })}
      >
        <Flex 
          css={t => ({
            [mq[1]]: {
              width: t.w12(6),
            },
            [mq[2]]: {
              width: t.w12(5),
            },
            [mq[3]]: {
              width: t.w12(4),
            },
          })}
        >
          {defenseCategoryID &&
            <InsertDefenseForm
              defenseCategoryID={defenseCategoryID}
              onSubmit={() => {
                getOps.refetch()
              }}
              evaluatorTypes={getOps.data?.defense_evaluator_type || []}
            />
          }
        </Flex>
        <Flex 
          css={t => ({
            [mq[1]]: {
              width: t.w12(6),
            },
            [mq[2]]: {
              width: t.w12(7),
            },
            [mq[3]]: {
              width: t.w12(9),
            },
          })}
        >
          <FlexRow
            css={t => ({
              height: t.spacing(6),
            })}
          >
            <H3>{defenseCategoryByID(defenseCategoryID).label} Akan Datang</H3>
            <Button
              color="secondary"
            >
              Arsip {defenseCategoryByID(defenseCategoryID).label} 
            </Button>
          </FlexRow>
          <List>
            {getOps.data?.defense_defense.map((item, key) => {
              return (
                <ListItem key={`defense-item-${key}`}>
                  <Flex>
                    <b>{item.student.full_name}</b> 
                    <span>{item.title}</span>
                    
                    <div
                      css={t => ({
                        display: "grid",
                        width: `100%`,
                        gridTemplateColumns: `1fr`,
                        [mq[3]]: {
                          gridTemplateColumns: `1fr 1fr`,
                        }
                      })}
                    >
                      <FlexRow>
                        <FaCalendar />{dayjs(item.datetime_start).format("dddd[,] D MMMM YYYY [-] HH:mm")}
                      </FlexRow> 
                      <FlexRow>
                        <FaMapMarker />{item.place.title}
                      </FlexRow> 
                    </div>
                    <div
                      css={t => ({
                        display: "grid",
                        width: `100%`,
                        gridTemplateColumns: `1fr`,
                        [mq[3]]: {
                          gridTemplateColumns: `1fr 1fr`,
                        }
                      })}
                    >
                      {item.evaluators.map((evaluator, evaluatorKey) => (
                        <div
                          css={t => ({
                          })}
                          key={`defense-item-${key}-evaluator-${evaluatorKey}`}
                        >
                          <Small>{evaluator.type.title}: {evaluator.player.as_staff.full_name}</Small> 
                        </div>
                      ))}
                    </div>
                    <FlexRow
                      jc="space-between"
                    >
                      <Button
                        variant="soft"
                        color="secondary"
                        size="small"
                        onClick={() => {navigate(`/defense/edit/${item.defense_id}`)}}
                      >
                        <FaEdit />
                        Perbarui
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => {
                          // markAsFinished()
                        }}
                      >
                        <FaCheck />
                        Selesai
                      </Button>
                    </FlexRow>
                  </Flex>
                </ListItem>
              )
            })}
          </List>
        </Flex>
      </FlexRow>
    </Page>
  )
} 

export default DefenseManage
