/** @jsxImportSource @emotion/react */

import { gql } from "@apollo/client"

export interface ClassroomMeetingQ {_: {
  classroom_id: string
  classroom: {
    cys: {
      course: {
        title: string
        code: string
      }
      clos: {
        clo: {
          clo_id: string
          code: string
          description: string
          plo: {
            code: string
            description: string
            plo_id: string
          }
        }
        weight: number
      }[]
    }
    code: string
    schedules: {
      day_number: number
      place: {
        title: string
      }
      time_end: string
      time_start: string
    }[]
    facilitator_enrollments: {
      lecturer: {
        lecturer_id: string
        staff: {
          full_name: string
        }
      }
    }[]
    student_enrollments: {
      student: {
        full_name: string
        nim: string
      }
    }[]
    items: {
      classroom_item_id: string
      title: string
    }[]
  }
  classroom_item_id: string
  classroom_item_type_id: string
  datetime_start: string
  datetime_end: string
  details: {
    duration: number
    llo_list: {
      code: string
      description: string
    }[]
    indicator: string
    materials: {
      nama_file: string
      url: string
    }[]
    description: string
    meeting_type: string
    meeting_number: string
  } 
  learnings: {
    title: string
    learning_template_id: number 
    learning_id: string
    datetime_end: string
    datetime_start: string
    attachments: {
      title: string
      url: string
    }[]
    description: object
  }[]
  title: string
}}

const GET_CLASSROOM_MEETING_BY_ID = gql`
  query GetClassroomItemByID($classroom_item_id: uuid!) {
    _: academic_classroom_item_by_pk(classroom_item_id: $classroom_item_id) {
      classroom_id
      classroom {
        cys {
          course {
            title
            code
          }
          clos {
            clo {
              clo_id
              code
              description
              plo {
                code
                description
                plo_id
              }
            }
            weight
          }
        }
        code
        schedules {
          day_number
          place {
            title
          }
          time_end
          time_start
        }
        facilitator_enrollments {
          lecturer {
            lecturer_id
            staff {
              full_name
            }
          }
        }
        student_enrollments(order_by: {student: {full_name: asc}}) {
          student {
            full_name
            nim
            player {
              profile_picture
            }
          }
        }
        items(order_by: {datetime_created: asc}, where: {classroom_item_type_id: {_eq: "1"}}) {
          classroom_item_id
          title
        }
      }
      classroom_item_id
      classroom_item_type_id
      datetime_start
      datetime_end
      details
      learnings {
        attachments
        title
        learning_template_id
        learning_id
        datetime_end
        datetime_start
        description
      }
      title
    }
  }
`

export {
  GET_CLASSROOM_MEETING_BY_ID
}
