import { gql } from "@apollo/client";

export const GET_DEFENSE_BY_ID = gql`
  query GetDefenseByID($defense_id: uuid = "") {
    defense_defense_by_pk(defense_id: $defense_id) {
      defense_id
      datetime_start
      student_id
      student {
        full_name
        nim
        whatsapp_number
      }
      title
      place {
        place_id
        title
      }
      evaluators {
        player {
          as_staff {
            full_name
            nip
          }
          player_id
        }
        type {
          title
          evaluator_type_id
        }
      }
      is_done
      decree_url
      document_url
      defense_category_id
      category {
        title
        types {
          evaluator_type_id
          title
        }
      }
    }
  }
`

export interface getDefenseByIDQ {
  defense_defense_by_pk: {
    defense_id: string
    datetime_start: string
    student_id: string
    student: {
      full_name: string
      nim: string
      whatsapp_number: string
    }
    title: string
    place: {
      place_id: string
      title: string
    }
    evaluators: {
      player: {
        as_staff: {
          full_name: string
          nip: string
        }
        player_id: string
      }
      type: {
        title: string
        evaluator_type_id: number
      }
    }[]
    is_done: number
    decree_url: string | null
    document_url: string | null
    defense_category_id: number
    category: {
      title: string
      types: {
        evaluator_type_id: number 
        title: string
      }[]
    }
  }
}
