/** @jsxImportSource @emotion/react */

import { ReactNode } from "react"
import { FaBuilding, FaClock, FaUser } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import DividerGrow from "../../../../shared/components/IFSuites/Divider/Grow"
import Flex from "../../../../shared/components/IFSuites/Flex"
import FlexRow from "../../../../shared/components/IFSuites/Flex/Row"
import Small from "../../../../shared/components/IFSuites/typography/Small"
import ListItem from "../../../../shared/components/List/Item"
import useBreakpoint from "../../../../shared/hooks/use-breakpoint"

interface ScheduleListItemProps {
  title: ReactNode 
  url: string 
  date: string
  location: string
  isToday?: boolean
}

const ScheduleListItem = ({
  title,
  url,
  date,
  location,
  isToday,
}: ScheduleListItemProps) => {
  const navigate = useNavigate()

  const bp = useBreakpoint()

  return (
    <ListItem
      glow={isToday}
      onClick={() => {
        navigate(url)
      }}
    >
        <Flex
          css={t => ({
            flexGrow: 1,
            'small, svg': {
              color: isToday ? t.palette?.blueGray?.[700] : t.palette?.gray?.[600],
            },
            flexDirection: bp === "mobile" ? "column" : "row",
          })}
        >
          <FlexRow ai="center" pr={2}>
            {title}
          </FlexRow>
          <FlexRow ai="center">
            {bp === "mobile" ?
              <Small><FaClock /> {isToday ? <b>{date}</b> : date}</Small> :  
              <><FaClock /> {isToday ? <b>{date}</b> : date}</>
            }
          </FlexRow>
          <FlexRow ai="center">
            {bp === "mobile" ?
              <Small><FaBuilding /> {location}</Small> :  
              <><FaBuilding /> {location}</>
            }
          </FlexRow>
        </Flex>
        {/* <Flex */}
        {/*   css={t => ({ */}
        {/*     width: 20, */}
        {/*     fontWeight: 700, */}
        {/*     color: `rgba(0, 0, 0, 0.2)`, */}
        {/*     fontSize: `1.5rem`, */}
        {/*   })} */}
        {/* > */}
        {/*   {classCode} */} 
        {/* </Flex> */}
    </ListItem>
  )
}

export default ScheduleListItem
