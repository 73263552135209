import { gql } from "@apollo/client";

export const UPDATE_MATERIAL = gql`
mutation UpdateMaterial(
  $learning_id: uuid!,
  $description: jsonb = "",
  $title: String = "",
  $attachments: jsonb = ""
) {
  update_academic_learning_by_pk(pk_columns: {learning_id: $learning_id}, _set: {description: $description, title: $title, attachments: $attachments}) {
    learning_id
    datetime_updated
  }
}
`
