/** @jsxImportSource @emotion/react */

import { atom } from "jotai";
import { ReactNode } from "react";

interface LayoutAtom {
  loading: {
    open: boolean
    status: "info" | "success" | "warning" | "error"
    message?: string
  }
  confirmDialog: {
    open: boolean
    status: "info" | "success" | "warning" | "error"
    content: ReactNode
    onClick: () => void
    isCancelable?: boolean
  }
}

export const initLayoutAtom: LayoutAtom = {
  loading: {
    open: false,
    status: "info",
    message: "Komunikasi ke server . . ."
  },
  confirmDialog: {
    open: false,
    status: "info",
    content: null,
    onClick: () => {},
    isCancelable: false,
  }
}

const layoutAtom = atom<LayoutAtom>(initLayoutAtom)

export default layoutAtom
