import { gql, useLazyQuery } from "@apollo/client"
import { useEffect } from "react"
import { BsMegaphoneFill } from "react-icons/bs"
import { FaBook, FaNewspaper } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import Flex from "../../../shared/components/IFSuites/Flex"
import List from "../../../shared/components/List"
import ListItem from "../../../shared/components/List/Item"
import Page from "../../../shared/components/Page"

const GET_LATEST_NEWS = gql`
query GetLatestNews {
  posts(order_by: {created_at: desc}, limit: 10) {
    title
    slug
    created_at
  }
}
`

interface GetLatestNews {
  posts: {
    title: string
    slug: string
    created_at: string
  }[]
}

const InfoNews = () => {

  const navigate = useNavigate()

  const [getLatestNews, getLatestNewsOps] = useLazyQuery<GetLatestNews>(
    GET_LATEST_NEWS,
    {
      nextFetchPolicy: "network-only", 
    }
  )

  useEffect(() => {
    getLatestNews()
  }, [])

  return (
    <Page
      title={`Berita Terbaru`}
    >
      <List>
        {getLatestNewsOps.data ? getLatestNewsOps.data.posts.map((item, index) => (
          <ListItem
            key={`item-${index}`}
            onClick={() => {
              window.location.href = `https://if.uinsgd.ac.id/post/${item.slug}` 
            }} 
          >
            <Flex
              w={40} 
            >
              <FaNewspaper />
            </Flex>
            <Flex
              css={t => ({
                flexGrow: 1,
              })}
            >
              {item.title} 
            </Flex>
          </ListItem>
        )) : []} 
      </List>
    </Page>
  )
}

export default InfoNews
